import gql from 'graphql-tag';

export const STAFF_ATTENDANCE_LIST = gql`
  query getStaffAttendancesByCentre($centreId: Int!, $date: Datetime!) {
    getStaffAttendancesByCentre(centreId: $centreId, date: $date) {
      total
      centre {
        ID
        label
      }
      staffAttendances {
        staff {
          ID
          firstname
          lastname
        }
        checkInOut {
          id
          time
          active
          type
        }
        sessions {
          id
          time
          active
          temperature
          remarks
          session
        }
        date
        present
        status
        remarks
        healthChecks {
          ID
          time
          remarks
          temperature
        }
      }
    }
  }
`;

export const GET_STAFF_ATTENDANCES = gql`
  query getStaffCheckInOutRecordsByRole(
    $centreID: Int!
    $date: Datetime!
    $pagination: Pagination
    $searchTerm: String
    $filter: [String!]
  ) {
    getStaffCheckInOutRecordsByRole(
      centreId: $centreID
      from: $date
      pagination: $pagination
      searchTerm: $searchTerm
      statuses: $filter
    ) {
      totalCount
      staffCheckInOut {
        staff {
          userByFkUser {
            ID
            firstname
            lastname
          }
        }
        checkIns {
          ID
          time
          status
          active
          type
          remarks
          onLeave
        }
        checkOuts {
          ID
          time
          status
          active
          onLeave
          type
          remarks
        }
        status
      }
    }
  }
`;

export const GET_STAFF_ATTENDANCE_FILTER = gql`
  query getCountStaffCheckInOutRecordsByRole(
    $centreID: Int!
    $date: Datetime!
  ) {
    getCountStaffCheckInOutRecordsByRole(centreId: $centreID, from: $date) {
      all
      absent
      present
    }
  }
`;
