const toSentenceCase = str =>
  str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();

const getPercentage = (numerator, denominator) => {
  if (denominator === 0) {
    return 0;
  }
  let result = numerator / denominator;

  result *= 100;
  if (!Number.isInteger(result)) {
    result = parseFloat(result.toFixed(1));
  }

  return result;
};

const formatNumber = num => {
  let result = num;
  if (!Number.isInteger(num)) {
    result = parseFloat(num.toFixed(1));
  }

  return result;
};

export default {
  toSentenceCase,
  getPercentage,
  formatNumber,
};
