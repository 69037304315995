import moment from 'moment';
import get from 'lodash/get';
import {
  DATE_PICKER_FORMAT,
  SAVE_DATE_FORMAT,
  DASHBOARD_REQUEST_KEYS,
  DASHBOARD_SORT_OPTIONS,
} from '../../utils/constants';
import { parseGraphQLError } from '../../utils';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  QR_CODE_CICO_TOKEN,
  FETCH_ALL_STAFF_DASHBOARD_REQUEST,
} from './actionTypes';
import {
  PENDING_EVENT_GROUP_OBJECT,
  EVENT_LISTING_OBJECT,
} from '../models/eventModels';
import {
  GET_TRASFER_DRAFT_QUERY,
  FIND_ALL_FEE_DRAFT_QUERY,
  GET_ALL_TODAY_VISITS,
  GET_FORM_QUERY,
  GET_CENTRE_CICO_TOKEN,
  ENROLMENT_PLANING_DRAFT_GROUP_BY_CENTER_QUERY,
  GET_PENDING_PROMOTION_BY_LEVEL,
  GET_ALL_WITHDRAWAL_DRAFTS,
  GET_ALL_WITHDRAWAL_DRAFTS_OBJECT,
  GET_ATTENDANCE_OBJECT,
  GET_PENDING_PROMOTION_FORM,
  GET_TRANSFER_DRAFTS_PENDING_APPROVAL,
} from '../models/dashboardModel';

export const WITHDRAWAL_OPTIONS = {
  pendingStatus: '@enum:PendingStaffSign',
};

const dispatchGetAllPendingRequest = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_STAFF_DASHBOARD_REQUEST,
    key: 'pendingRequests',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getAllPendingRequest = ({
  fkSchool,
  centreIDs,
  pagination,
  hasEnrollmentPlanningReadAccess,
}) => async dispatch => {
  const effectiveFrom = moment().startOf('day').format(SAVE_DATE_FORMAT);
  dispatchGetAllPendingRequest(dispatch);
  const enrichmentPlatform = JSON.parse(
    process.env.REACT_APP_ENRICHMENT_PLATFORM || 'false'
  );

  const enrichmentPlatformPendingRequest = [
    {
      key: 'getAllEnrichmentClass',
      query: `{ totalCount }`,
      variables: [{ actions: ['reschedule'] }],
    },
    {
      queryKey: 'missingEpAttendanceCount',
      key: 'getAllEnrichmentClass',
      query: `{ totalCount }`,
      variables: [{ centreIds: centreIDs }],
    },
    {
      key: 'GetAllEnrichmentClassWaitlistManagement',
      query: `{ totalCount }`,
      variables: [
        {
          centreIds: centreIDs,
          forDate: moment()
            .startOf('month')
            .add(1, 'month')
            .format(DATE_PICKER_FORMAT),
        },
      ],
    },
  ];

  let bulkPendingRequestQuery = enrichmentPlatformPendingRequest;

  if (!enrichmentPlatform) {
    bulkPendingRequestQuery = [
      {
        key: 'GetPendingEventGroupedByCenter',
        query: PENDING_EVENT_GROUP_OBJECT,
        variables: [
          {
            IDSchool: fkSchool,
            fkCentre: centreIDs,
          },
        ],
      },
      {
        key: 'getTransferDraftsPendingApproval',
        query: GET_TRASFER_DRAFT_QUERY,
        variables: [
          {
            schoolID: fkSchool,
            fkCentre: centreIDs,
            pagination: {
              ...pagination,
              sort: DASHBOARD_SORT_OPTIONS.TRANSFER_DRAFT[0].label,
            },
          },
        ],
      },
      {
        queryKey: 'pendingPromotionSignedChildren',
        key: 'getPendingForm2ESignatureChildren',
        query: GET_FORM_QUERY,
        variables: [
          {
            pendingStatus: WITHDRAWAL_OPTIONS.pendingStatus,
            centreIDs,
            pagination: {
              ...pagination,
              sort: DASHBOARD_SORT_OPTIONS.PROMOTION_SIGNED[0].label,
            },
          },
        ],
      },
      {
        key: 'findAllFeeDraftGroupedByCenter',
        query: FIND_ALL_FEE_DRAFT_QUERY,
        variables: [
          {
            filter: {
              fkCentre: centreIDs,
              status: 'pending',
            },
          },
        ],
      },
      {
        key: 'getAllWithdrawalDrafts',
        query: GET_ALL_WITHDRAWAL_DRAFTS_OBJECT,
        variables: [
          {
            schoolID: fkSchool,
            IDCentres: centreIDs,
            effectiveFrom,
            pagination: {
              ...pagination,
              sort: DASHBOARD_SORT_OPTIONS.WITHDRAWAL_DRAFT[0].label,
            },
            filter: {
              status: 'pending',
              additionalStatus: [
                'pending',
                'principal_approved',
                'hq_rejected',
                'hq_approved',
              ],
            },
          },
        ],
      },
    ];
  }

  if (hasEnrollmentPlanningReadAccess) {
    bulkPendingRequestQuery.push({
      key: 'capacityDraftsGroupedByCenter',
      query: ENROLMENT_PLANING_DRAFT_GROUP_BY_CENTER_QUERY,
      variables: [
        {
          IDSchool: fkSchool,
          filter: {
            fkCentre: centreIDs,
            status: 'pending',
          },
        },
      ],
    });
  }

  try {
    const data = await SkApolloRequest({
      params: bulkPendingRequestQuery,
      type: 'bulkQuery',
    });
    if (data.success) {
      dispatchGetAllPendingRequest(dispatch, false, data);
    } else {
      dispatchGetAllPendingRequest(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchGetAllPendingRequest(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

const dispatchCiCoQrCodeToken = (
  dispatch,
  inProgress = true,
  data,
  error = null
) => {
  dispatch({
    type: QR_CODE_CICO_TOKEN,
    key: 'cicoQrCode',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getQrCodeForCiCo = IDCentre => async dispatch => {
  dispatchCiCoQrCodeToken(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CENTRE_CICO_TOKEN,
        variables: { IDCentre },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchCiCoQrCodeToken(dispatch, false, data.data);
    } else {
      dispatchCiCoQrCodeToken(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchCiCoQrCodeToken(dispatch, false, null, {
      error: 'something Error',
    });
    return parseGraphQLError(ex);
  }
};

export const getPendingRequestByCategory = async ({
  schoolID,
  centreIDs,
  pagination,
  key,
}) => {
  try {
    let params = null;
    const effectiveFrom = moment().startOf('day').format(SAVE_DATE_FORMAT);
    if (key === DASHBOARD_REQUEST_KEYS.TRANSFER_DRAFT) {
      params = {
        query: GET_TRANSFER_DRAFTS_PENDING_APPROVAL,
        variables: {
          schoolID,
          fkCentre: centreIDs,
        },
        dataPath: 'data.getTransferDraftsPendingApproval.data',
      };
    }
    if (key === DASHBOARD_REQUEST_KEYS.PROMOTION_BY_LEVEL) {
      params = {
        query: GET_PENDING_PROMOTION_BY_LEVEL,
        variables: {
          filter: { ID: centreIDs },
        },
        dataPath: 'data.FindAllChildPendingPromotion.data',
      };
    }
    if (key === DASHBOARD_REQUEST_KEYS.WITHDRAWAL_DRAFT) {
      params = {
        query: GET_ALL_WITHDRAWAL_DRAFTS,
        variables: {
          schoolID,
          IDCentres: centreIDs,
          effectiveFrom,
          filter: {
            status: 'pending',
            additionalStatus: [
              'pending',
              'principal_approved',
              'hq_rejected',
              'hq_approved',
            ],
          },
        },
        dataPath: 'data.getAllWithdrawalDrafts.data',
      };
    }
    if (key === DASHBOARD_REQUEST_KEYS.PROMOTION_SIGNED) {
      params = {
        query: GET_PENDING_PROMOTION_FORM,
        variables: {
          pendingStatus: 'PendingStaffSign',
          centreIDs,
        },
        dataPath: 'data.getPendingForm2ESignatureChildren.data',
      };
    }
    if (params) {
      const res = await SkApolloRequest({
        params: {
          query: params.query,
          variables: {
            ...params.variables,
            pagination,
          },
        },
        type: 'query',
      });
      return get(res, params.dataPath) || [];
    }
    return [];
  } catch (ex) {
    return [];
  }
};

export const getCentreSummaries = async ({ fkSchool, centreId }) => {
  const bulkPendingRequestQuery = [
    {
      queryKey: 'staffAttendanceSummaries',
      key: 'getCentreAttendanceSummariesForStaff',
      query: GET_ATTENDANCE_OBJECT,
      variables: [
        {
          centreId,
          from: moment().subtract(6, 'd').format(DATE_PICKER_FORMAT),
          to: moment().format(DATE_PICKER_FORMAT),
        },
      ],
    },
    {
      queryKey: 'childAttendanceSummaries',
      key: 'getCentreAttendanceSummaries',
      query: GET_ATTENDANCE_OBJECT,
      variables: [
        {
          centreId,
          from: moment().subtract(6, 'd').format(DATE_PICKER_FORMAT),
          to: moment().format(DATE_PICKER_FORMAT),
        },
      ],
    },
    {
      key: 'getScheduledVisits',
      query: GET_ALL_TODAY_VISITS,
      variables: [
        {
          date: moment().format(DATE_PICKER_FORMAT),
          visitFilter: {
            fkCentre: centreId,
          },
        },
      ],
    },
    {
      key: 'getAllEvents',
      query: EVENT_LISTING_OBJECT,
      variables: [
        {
          IDSchool: fkSchool,
          fkCentre: [centreId],
          from: moment().startOf('day').format(SAVE_DATE_FORMAT),
          to: moment().endOf('day').format(SAVE_DATE_FORMAT),
          filter: {
            status: 'published',
          },
        },
      ],
    },
  ];
  try {
    const res = await SkApolloRequest({
      params: bulkPendingRequestQuery,
      type: 'bulkQuery',
    });
    if (res.success) {
      return res.data;
    }
    return null;
  } catch (ex) {
    return null;
  }
};

export default getAllPendingRequest;
