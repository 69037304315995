import SkRequest from './BaseRequest';
import {
  // QueryBuilder,
  MutationBuilder,
} from '../queryBuilder';

// const mQueryBuilder = new QueryBuilder();
const mMutationBuilder = new MutationBuilder();

const trialVisit = {
  ID: 'number',
  visitFrom: 'datetime',
  visitTo: 'datetime',
  visitType: 'string',
  status: 'string',
  fkEnquiry: 'number',
  fkCentre: 'number',
  fkUser: 'number',
  trialPeriodFee: 'string',
  trialPeriodPaymentStatus: 'string',
};

mMutationBuilder.setNewMutation('scheduleTrialPeriodVisit', trialVisit);
mMutationBuilder.setNewMutation('rescheduleTrialPeriodVisit', trialVisit);
mMutationBuilder.setNewMutation('approveTrialPeriodVisit', {});
mMutationBuilder.setNewMutation('rejectTrialPeriodVisit', {});

export const scheduleTrialPeriodVisit = reqData => async () => {
  const query = mMutationBuilder.getMutationAsString(
    'scheduleTrialPeriodVisit',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const rescheduleTrialPeriodVisit = reqData => async () => {
  const query = mMutationBuilder.getMutationAsString(
    'rescheduleTrialPeriodVisit',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveTrialPeriodVisit = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString(
          'approveTrialPeriodVisit',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectTrialVisit = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString(
          'rejectTrialPeriodVisit',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export default rejectTrialVisit;
