export class ResponseBuilder {
  static checkIfJsonObj(obj) {
    if (typeof obj !== 'object') {
      throw new Error(`Invalid data type for response params setting ${obj}`);
    }

    const tKeys = Object.keys(obj);
    if (tKeys.length && tKeys[0] === '0') {
      throw new Error('Doesnt support arrays');
    }
  }

  constructor() {
    this._responseParams = {};
    this._reqParams = '';
  }

  _requestParamsToString(reqParams = null) {
    let rStr = '';
    const rKeys = Object.keys(reqParams);

    rKeys.forEach(eachKey => {
      if (
        reqParams[eachKey] !== null &&
        typeof reqParams[eachKey] === 'object' &&
        Object.keys(reqParams[eachKey]).length &&
        Object.keys(reqParams[eachKey])[0] === '0'
      ) {
        rStr += `${eachKey}: [ `;

        reqParams[eachKey].forEach(arrItem => {
          if (
            arrItem &&
            typeof arrItem === 'object' &&
            typeof arrItem.length === 'undefined'
          ) {
            rStr += ` { ${this._requestParamsToString(arrItem)} }, `;
          } else if (arrItem && typeof arrItem === 'object') {
            rStr += `${this._requestParamsToString(arrItem)},`;
          } else if (arrItem && typeof arrItem === 'string') {
            rStr += `"${arrItem}", `;
          } else {
            rStr += `${arrItem}, `;
          }
        });

        rStr += '],';
      } else if (
        reqParams[eachKey] !== null &&
        typeof reqParams[eachKey] === 'object'
      ) {
        if (reqParams[eachKey].length === 0) {
          rStr += `${eachKey}: []`;
        } else {
          rStr += `${eachKey}: { ${this._requestParamsToString(
            reqParams[eachKey]
          )} }, `;
        }
      } else if (typeof reqParams[eachKey] === 'string') {
        rStr += `${eachKey}: "${reqParams[eachKey]}", `;
      } else {
        rStr += `${eachKey}: ${reqParams[eachKey]}, `;
      }
    });

    this._reqParams = rStr;
    return rStr;
  }

  _setAllKeys(isActive = 1, obj = this._responseParams) {
    ResponseBuilder.checkIfJsonObj(obj);

    Object.keys(obj).forEach(eachKey => {
      if (typeof obj[eachKey] === 'object') {
        this._setAllKeys(isActive, obj[eachKey]);
      } else {
        obj[eachKey] = isActive;
      }
    });
  }

  _setSelectedKeys(isActive, obj, currObj) {
    ResponseBuilder.checkIfJsonObj(obj);
    const eKeys = Object.keys(obj);
    let currParams = this._responseParams;

    if (currObj) {
      ResponseBuilder.checkIfJsonObj(currObj);
      currParams = currObj;
    }

    eKeys.forEach(eachKey => {
      if (typeof obj[eachKey] === 'object') {
        currParams[eachKey] = this._setSelectedKeys(
          isActive,
          obj[eachKey],
          currParams[eachKey]
        );
      } else if (typeof currParams[eachKey] === 'object') {
        this._setAllKeys(1, currParams[eachKey]);
      } else {
        currParams[eachKey] = isActive;
      }
    });

    return currParams;
  }

  setResponseParams(rParamObj) {
    ResponseBuilder.checkIfJsonObj(rParamObj);
    this._responseParams = rParamObj;
    this.enableAllResponseParams();
  }

  disableAllResponseParams() {
    this._setAllKeys(0);
  }

  enableAllResponseParams() {
    this._setAllKeys(1);
  }

  enableParams(obj) {
    this.disableAllResponseParams();
    this._responseParams = this._setSelectedKeys(1, obj);
  }

  disableParams(obj) {
    this.enableAllResponseParams();
    this._responseParams = this._setSelectedKeys(0, obj);
  }

  getResponseParamsAsString(obj = this._responseParams) {
    let retStr = '{ ';
    ResponseBuilder.checkIfJsonObj(obj);

    const myKeys = Object.keys(obj);
    const len = myKeys.length;

    if (!len) {
      return '';
    }

    for (let idx = 0; idx < len; idx++) {
      const eachKey = myKeys[idx];
      if (obj[eachKey] && typeof obj[eachKey] === 'object') {
        const respParFrag = this.getResponseParamsAsString(obj[eachKey]);
        if (respParFrag !== '{  }') {
          retStr += `${eachKey} ${respParFrag}, `;
        }
      } else if (obj[eachKey]) {
        retStr += eachKey;

        if (len - 1 !== idx) {
          retStr += ', ';
        }
      }
    }

    return `${retStr} }`;
  }
}

export default ResponseBuilder;
