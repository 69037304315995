import SkRequest from './BaseRequest';
import { QueryBuilder } from '../queryBuilder';
import { FETCH_SIDEMENU_CONFIG } from './actionTypes';

const _mQueryBuilder = new QueryBuilder();

_mQueryBuilder.setNewQuery('findSidebarMenuByUser', {
  totalCount: 'number',
  data: {
    ID: 'number',
    icon: 'string',
    path: 'string',
    active: 'bool',
    label: 'string',
    fkNavigation: 'number',
  },
});

const dispatchSidemenu = (
  dispatch,
  inprogress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SIDEMENU_CONFIG,
    key: 'sidemenu',
    value: {
      inprogress,
      data,
      error,
    },
  });
};

export const fetchSidemenuConfig = fkSchool => async dispatch => {
  dispatchSidemenu(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findSidebarMenuByUser', {
          filter: {
            fkSchool,
          },
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchSidemenu(dispatch, false, data);
    } else {
      dispatchSidemenu(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchSidemenu(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export default fetchSidemenuConfig;
