import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const SKIP_CENTRE_VISIT = gql`
  mutation skipCentreVisit($IDEnquiry: Int!, $remarks: String) {
    skipCentreVisit(IDEnquiry: $IDEnquiry, remarks: $remarks) {
      ID
      fkEnquiryState
      skipCentreVisit
    }
  }
`;

export const SKIP_TRAIL_VISIT = gql`
  mutation skipTrialVisit($IDEnquiry: Int!, $remarks: String!) {
    skipTrialVisit(IDEnquiry: $IDEnquiry, remarks: $remarks) {
      ID
      fkEnquiryState
      skipTrial
    }
  }
`;

export const GET_VISITOR_LOGS = gql`
  query getVisitorLog($filter: VisitorLogFilter, $pagination: Pagination) {
    getVisitorLog(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        name
        countryCode
        phone
        temperature
        checkInTime
        checkOutTime
        organization
        createdAt
        remarks
        code {
          ID
          label
          description
        }
        user {
          ID
          firstname
          lastname
        }
      }
    }
  }
`;
