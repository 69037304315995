import gql from 'graphql-tag';

export const REMOVE_GUARDIAN = gql`
  mutation removeGuardian($IDGuardian: Int!, $IDChildren: [Int!]) {
    removeChildGuardianV2(IDGuardian: $IDGuardian, IDChildren: $IDChildren)
  }
`;

export const UPDATE_GUARDIAN_DATA = gql`
  mutation updateGuardian($dto: GuardianUpdateDTO!) {
    updateChildGuardianV2(dto: $dto) {
      ID
      email
      mobilePhone
      mobilePhoneCountryCode
    }
  }
`;

export const GET_ALL_GUARDIANS = gql`
  query getAllGuardianV2($IDChild: Int!, $centreID: Int!) {
    getAllGuardianV2(IDChild: $IDChild) {
      totalCount
      data {
        ID
        firstname
        lastname
        email
        mobilePhone
        mobilePhoneCountryCode
        profilePhotoKey
        identificationNo
        icFrontImageKey
        icBackImageKey
        active
        verification(centreID: $centreID) {
          ID
          status
          updatedBy
          remark
          fkReason
        }
        guardianChildRelations(filter: { active: [{ eq: [true, false] }] }) {
          data {
            fkRelation
            from
            to
            otherRelation
            active
            fkChild
          }
        }
      }
    }
  }
`;

export const FIND_ALL_GUARDIANS = gql`
  query findAllGuardian($IDChild: Int!) {
    findAllGuardian(IDChild: $IDChild) {
      data {
        ID
        imageKey
        parent {
          ID
          firstname
          lastname
          mobilePhone
          mobilePhoneCountryCode
          email
          guardianAccess
          identificationNo
          childRelations(
            filter: { isGuardian: true }
            pagination: { sort: "-updatedAt" }
          ) {
            data {
              fkRelation
              role {
                name
              }
            }
          }
          guardianData {
            nricDocuments {
              ID
              filename
              label
              uRL
              documentTags {
                totalCount
                data {
                  ID
                  fkDocumentType
                  documentType {
                    documentTypeConfig {
                      ID
                      code
                      label
                    }
                  }
                }
              }
            }
          }
          nricStatus
        }
      }
    }
  }
`;

export const GET_ALL_SEARCH_GUARDIANS = gql`
  query getAllGuardianV2(
    $pagination: Pagination
    $filter: GuardianFilter
    $ftsInput: String
  ) {
    getAllGuardianV2(
      pagination: $pagination
      filter: $filter
      ftsInput: $ftsInput
    ) {
      totalCount
      data {
        ID
        firstname
        lastname
        email
        mobilePhone
        mobilePhoneCountryCode
        profilePhotoKey
        icFrontImageKey
        icBackImageKey
        identificationNo
        updatedAt
        active
        guardianCentreVerifications {
          totalCount
          data {
            ID
            fkGuardian
            fkCentre
            status
            remark
            centre {
              label
            }
          }
        }
        guardianChildRelations(filter: { active: [{ eq: [true, false] }] }) {
          totalCount
          data {
            ID
            fkRelation
            from
            to
            otherRelation
            active
            child {
              ID
              firstname
              lastname
              imageKey
              birthCertificate
              currentLevel {
                centre {
                  ID
                  label
                }
                level {
                  ID
                  label
                }
              }
              childLevels {
                data {
                  fkCentre
                  centre {
                    ID
                    label
                  }
                }
              }
              enrolmentStatus {
                status
                label
              }
            }
            code {
              label
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_GUARDIAN_ACTIVITY_LOGS = gql`
  query GetGuardianActivityLog($guardianID: Int!, $pagination: Pagination) {
    GetGuardianActivityLog(guardianID: $guardianID, pagination: $pagination) {
      totalCount
      data {
        Creator {
          firstname
          lastname
        }
        ActivityLog {
          auditAction
          data
          createdAt
        }
      }
    }
  }
`;
