import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import { FETCH_ALL_HOLIDAYS, FETCH_CLOSURE_DAYS } from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  GET_HOILDAY_CLOSURES,
  CREATE_HOLIDAY,
  UPDATE_HOLIDAY,
} from '../models/eventModels';

const _mQueryBuilder = new QueryBuilder();
const _mMutationBuilder = new MutationBuilder();

const holiday = {
  ID: 'number',
  title: 'string',
  description: 'string',
  holidayImageKey: 'string',
  from: 'timestamp',
  to: 'timestamp',
  isPublic: 'bool',
  centreHolidays: {
    totalCount: 'number',
    data: {
      centre: {
        label: 'string',
        ID: 'number',
      },
    },
  },
};

_mQueryBuilder.setNewQuery('getCentreHolidaysOfYear', {
  totalCount: 'number',
  data: holiday,
});

_mMutationBuilder.setNewMutation('deleteHolidayDetails', {});
_mMutationBuilder.setNewMutation('getHolidayPhotoUploadURL', {
  Url: 'string',
  Key: 'string',
});

const dispatchHolidaysFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_HOLIDAYS,
    key: 'holidays',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dipatchGetCentreClosureDays = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLOSURE_DAYS,
    key: 'closureDays',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const removeHoliday = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'deleteHolidayDetails',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchHolidays = (reqData, key = 'list') => async dispatch => {
  dispatchHolidaysFetch(dispatch, false, { key });

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'getCentreHolidaysOfYear',
          reqData
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchHolidaysFetch(dispatch, false, { data: data.data, key });
    } else {
      dispatchHolidaysFetch(dispatch, false, { key }, data.error);
    }

    return data;
  } catch (ex) {
    dispatchHolidaysFetch(dispatch, false, { key }, { error: ex.message });
    throw ex;
  }
};

export const getAllCentreClosureDays = reqData => async dispatch => {
  dipatchGetCentreClosureDays(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_HOILDAY_CLOSURES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dipatchGetCentreClosureDays(dispatch, false, { data: data.data });
    } else {
      dipatchGetCentreClosureDays(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dipatchGetCentreClosureDays(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const getHolidayPhotoUploadURL = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'getHolidayPhotoUploadURL',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const createHoliday = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_HOLIDAY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateHoliday = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_HOLIDAY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export default fetchHolidays;
