import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TimeInput from 'material-ui-time-picker';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  Grid,
  ListItemText,
  Checkbox,
  Breadcrumbs,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import Text from './Text';
import styles from '../../styles/jss';
import { getPrimaryColor } from '../../utils';
import FieldWrapper from './InputFields/FieldWrapper';

const UnauthorizedMessage = () => (
  <Paper elevation={0} className="s7t-auto-margin">
    <Grid container className="w3-center w3-padding-32">
      <Grid item xs={12}>
        <Text h3 bold className="s7t-text-brownish-grey w3-center">
          You do not have sufficient access to view this
        </Text>
      </Grid>
    </Grid>
  </Paper>
);

const LabelValue = ({
  handleOverflow,
  label,
  value,
  classes,
  valueClass,
  labelClass,
  xs,
  md,
}) => (
  <Grid item xs={xs} md={md}>
    <InputLabel shrink className={`s7t-label ${labelClass}`}>
      {label}
    </InputLabel>
    <Typography
      style={{ overflowWrap: handleOverflow ? 'break-word' : '' }}
      className={valueClass}
      classes={{
        root: classes.resize,
      }}
    >
      {value}
    </Typography>
  </Grid>
);

const Input = props => {
  const {
    name,
    label,
    value,
    type,
    disabled,
    autoComplete,
    InputProps,
    textFieldStyle,
    noBottomMargin,
    error,
    labelClass,
  } = props;

  let maxProp = {};
  if (type === 'date') {
    maxProp = {
      max: '9999-12-31',
    };
  }
  const textFieldProps = { ...props, label: '' };

  // TODO:  non supported properties for TextFields, throws a lot of warnings
  delete textFieldProps.labelClass;
  delete textFieldProps.classes;

  return (
    <div
      className={
        noBottomMargin ? 'w3-margin-top' : 'w3-margin-bottom w3-margin-top'
      }
    >
      <InputLabel shrink htmlFor={name} className={`s7t-label ${labelClass}`}>
        {label}
      </InputLabel>
      <TextField
        disabled={disabled}
        autoComplete={autoComplete || 'off'}
        className={`s7t-text-input ${textFieldStyle}`}
        InputProps={{
          ...InputProps,
          ...maxProp,
        }}
        value={
          type === 'date' && value ? moment(value).format('YYYY-MM-DD') : value
        }
        fullWidth
        {...textFieldProps}
      />
      <ErrorHelperText isField error={error} />
    </div>
  );
};

const Dropdown = ({
  label,
  name,
  value,
  onChange,
  defaultText,
  onBlur,
  options,
  classes,
  disabled,
  disabledSelect,
  error,
  textFieldStyle,
  labelClass,
  noWraperMargin = false,
}) => {
  let lblCls = disabled ? 's7t-disabled-label' : '';
  if (labelClass) {
    lblCls += ` ${labelClass}`;
  }

  return (
    <div className={!noWraperMargin ? 'w3-margin-bottom w3-margin-top' : ''}>
      {label && (
        <InputLabel shrink htmlFor={name} className={`${lblCls} s7t-label`}>
          {label}
        </InputLabel>
      )}
      <Select
        autoWidth
        value={value}
        className={`s7t-select-input s7t-font-500 ${textFieldStyle}`}
        onChange={onChange}
        disabled={disabledSelect}
        displayEmpty
        onBlur={onBlur}
        classes={{
          icon: classes.icon,
          select: classes.select,
        }}
        inputProps={{
          name,
        }}
      >
        {defaultText && (
          <MenuItem disabled>
            <em>{defaultText}</em>
          </MenuItem>
        )}
        {(options || []).map(option => (
          <MenuItem
            key={option.value}
            disabled={option.disabled || disabled}
            value={
              typeof option.value === 'object'
                ? option.value.value
                : option.value
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorHelperText isField error={error} />
    </div>
  );
};

const TimeField = ({
  label,
  name,
  disabled,
  value,
  onBlur,
  onChange,
  error,
  required,
}) => (
  <div className="w3-margin-bottom w3-margin-top">
    <FieldWrapper label={label} error={error} required={required}>
      <div>
        <TimeInput
          format="ampm"
          mode="12h"
          className="s7t-text-input"
          defaultValue={null}
          name={name}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={time => onChange(time, name)}
          fullWidth
        />
      </div>
    </FieldWrapper>
  </div>
);

const MultiDropdown = ({
  label,
  labelClass,
  name,
  value,
  onChange,
  onBlur,
  options,
  classes,
  disabled,
  error,
}) => (
  <div className="w3-margin-bottom w3-margin-top">
    {label && (
      <InputLabel shrink htmlFor={name} className={`s7t-label ${labelClass}`}>
        {label}
      </InputLabel>
    )}
    <Select
      multiple
      value={value}
      disabled={disabled}
      className="s7t-select-input s7t-font-500"
      onChange={onChange}
      onBlur={onBlur}
      classes={{
        icon: classes.icon,
        select: classes.select,
      }}
      renderValue={selected =>
        selected
          .flatMap(v => options.filter(o => o.value === v).map(o => o.label))
          .join(', ')
      }
      inputProps={{
        name,
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value} disabled={disabled}>
          <Checkbox
            color="primary"
            checked={value && value.indexOf(option.value) > -1}
            disabled={disabled}
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
    <ErrorHelperText isField error={error} />
  </div>
);

const ErrorHelperText = ({ isField, error, extraClass }) => {
  if (isField) {
    return (
      <span className={`w3-text-red s7t-font-size-12px ${extraClass}`}>
        {error}
      </span>
    );
  }
  return (
    <div className={`w3-text-red w3-margin-top ${extraClass}`}>{error}</div>
  );
};

const BreadcrumbsComp = props => {
  const { crumb } = props;
  return (
    <Breadcrumbs
      separator=" > "
      aria-label="breadcrumb"
      className="w3-margin-bottom w3-padding-16"
    >
      {crumb &&
        crumb.map((item, idx) =>
          idx === crumb.length - 1 ? (
            <Typography key={item.label} className="w3-text-grey">
              {item.label}
            </Typography>
          ) : (
            <Link
              key={item.label}
              color="primary"
              className="s7t-text-decoration-none"
              to={item.link}
              style={{ color: getPrimaryColor(props) }}
            >
              {item.label}
            </Link>
          )
        )}
    </Breadcrumbs>
  );
};

Input.defaultProps = {
  variant: 'standard',
  placeholder: '',
  onBlur: () => {},
};

MultiDropdown.defaultProps = {
  onBlur: () => {},
};

const InputField = withStyles(styles)(Input);
const SelectField = withStyles(styles)(Dropdown);
const MultiSelectField = withStyles(styles)(MultiDropdown);
const LabelField = withStyles(styles)(LabelValue);
const BreadcrumbNavigation = withTheme(BreadcrumbsComp);
const TimeInputField = withStyles(styles)(TimeField);

export {
  LabelField,
  InputField,
  SelectField,
  TimeInputField,
  MultiSelectField,
  ErrorHelperText,
  BreadcrumbNavigation,
  UnauthorizedMessage,
};
