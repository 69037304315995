import { combineReducers } from 'redux';
import { sidemenu } from './sidemenu';
import mobileNavigation from './MobileNavigation';
import assetsAction from './assetsAction';
import { getNotificationHistory, snackBarMessage } from './notifications';
import vendorTrainer from './vendorTrainerReducer';
import { fetchAllCalendarEvent, allCalendarData } from './calendar';
import allBusinessEntities from './marketingConsent';
import {
  vacancies,
  submitPlan,
  recallData,
  editedPlans,
  pendingRequest,
  vacanciesByYear,
  rejectModalData,
  updateModalData,
  vacancyHistories,
} from './vacancyPlanning';
import { scheduledVisits } from './visits';
import userEnqueries, {
  bookVisit,
  cancelEnquiry,
  enquiryStates,
  scheduleTimings,
  fetchChildrenBySchool,
  availableEnquiryTimes,
  allChildrenByParent,
} from './userEnqueries';
import {
  enrolmentForm,
  additionalInfo,
  documentListing,
  enquiryDetailById,
  uploadedDocumentList,
  getFormSigner,
} from './registration';
import {
  reset,
  login,
  linkpassUserInfoFromClaim,
  linkpassSN2UserInfo,
  forgot,
  validate,
  register,
  userDetails,
  approverList,
  accessibleCentres,
} from './auth';
import {
  blacklistDrafts,
  blacklistDetails,
  childRelationSearch,
} from './blacklist';
import {
  enquiry,
  centerSearch,
  selectedCenters,
  storedSearchData,
  availableDateSlots,
  enrollmentCenterSearch,
  applicationDetailPanel,
} from './parentEnquiry';
import { cicoQrCode, dashboardPendingRequest } from './dashboardRequest';
import {
  fetchWaitList,
  fetchVacancyList,
  fetchWaitListFilter,
  searchChildInWaitListResult,
} from './waitList';
import {
  reportSchema,
  reportList,
  qQLCheckListReportByCenter,
  qQLCheckListReportClassByCenter,
} from './reports';
import {
  levelsConfig,
  schoolConfig,
  fetchAllTags,
  fetchCategory,
  fetchAllCentre,
  allOccupations,
  fetchAllServiceLevel,
  centreLevels,
  appConfig,
  registrationConfig,
  suggestLevels,
} from './config';
import {
  fetchAllChild,
  listAllClasses,
  listAllReqDocs,
  listUploadedUrl,
  listAllTeachers,
  getChildrenData,
  pastChildDetails,
  documentTypeConfig,
  documentByCategory,
  listAllUploadedDocs,
  listAllLogs,
  assignChildrenToClass,
  documentTypeForUpload,
  currentChildIdDetails,
  pastChildSearchResults,
  allGuardiansDetails,
  assignOrPromoteDetail,
  classDetailById,
  pendingPromotionSignedChildren,
  pendingWithdrawalSignedChildren,
  allProgramsType,
  getUpdatedChild,
  classActivities,
  classActivityTags,
  classActivityStickers,
  classResources,
  learningGoalsOptionsList,
  feeTierForChild,
  emergencyContactDetails,
  getAllUploadingAssets,
  childHealthInfo,
  applicableFeeTierForChild,
  childFeeTierForChild,
  upcomingFeeTierForChild,
  marketingConsentByParent,
  allGuardiansSearchDetails,
} from './classManagement';
import {
  checkVacancyAvailable,
  findAllTransferDrafts,
  findAllCompletedDrafts,
  allTransferDrafts,
  feeTiersByChildByCentre,
  allVacancy,
  allCentreVacancy,
  hasLeadOffered,
} from './transferManagement';
import {
  availableBanks,
  userAccounts,
  paymentSession,
  regPaymentDetail,
  enquiryMonthlyPaymentDetailById,
  accountInfoByEnquiry,
  accountInfoByReg,
  accountInfoByEnquiryForGiro,
  giroSetupStepDetail,
  bankAccountByChild,
} from './payment';
import { waitlistParamTags } from './waitlistParamTags';
import waitlistParameters from './waitlistParameters';
import countryCodes from './countryCode';
import {
  findAllRoles,
  addNewRole,
  updateRole,
  allStaff,
  allStaffV2,
  staffSearchResultsV2,
  fetchAllModuleCategory,
  searchUserByNameResults,
  searchUserByEmailResults,
  staffSearchResults,
  findRolesForUserCreation,
  allReportingStaff,
} from './userAndRoleManagement';
import {
  managementCentres,
  managementCentresDraft,
  centreDocumentCategories,
  centreDocuments,
  centreLicenseDocuments,
} from './centreManagement';
import { managementLevels } from './levelManagement';
import { linkpass } from './linkpass';
import { holiday, centreClosureDays } from './holidayManagement';
import {
  fetchCurrentFeeTier,
  fetchUpcomingFeeTier,
  fetchAllFeeDraft,
  fetchAllFeeGroup,
  fetchFeelistCategory,
  bulkFeeUploads,
} from './feeManagement';
import {
  findAllStateMappingOptions,
  findAllStateMapping,
  findAllRegistrationState,
} from './registrationState';
import {
  allBusses,
  fetchAllBusDriver,
  availableChildrenForBus,
  allBusData,
  showDeleteBusPopUp,
  allBusChildren,
} from './busManagement';
import {
  allWithDrawalDraft,
  allWithDrawal,
  allRefunds,
  allWithdrawalDrafts,
} from './withDrawal';
import {
  admissions,
  admissionFilters,
  searchChildInAdmissions,
  admissionsWithFilter,
  registrations,
  existingChild,
  existingParent,
  suggestedFeeTier,
} from './admissions';
import {
  classAttendanceDetails,
  childAttendanceDetails,
  currentChildAttendance,
  latePickupDetails,
  filterForClassAttendance,
  attendanceDetails,
  childAttendanceHistory,
  childFiltersAndCounts,
} from './attendanceManagement';
import {
  classHealthDetails,
  childHealthDetails,
  doctorDetails,
  visualCheckDetails,
  healthDocs,
} from './healthManagement';
import {
  fetchAllEvents,
  fetchCurrentEvents,
  getEventDetails,
  getRsvpList,
  getRsvpData,
  getRsvpCount,
  currentEvent,
  getEventPhotos,
} from './eventManagement';
import { broadcasts, broadcastScope, broadcastById } from './broadcast';
import {
  fetchAllPurchaseItems,
  fetchAllInvoicesByFkChild,
  allInvoicesByChild,
  fetchChildBalance,
  childBankAccounts,
  banksList,
  fetchAllInvoicesForAllCenters,
  searchFinance,
  searchInvoice,
  fetchAllInvoicesWithRefund,
  fetchAllUploadedSubsidyFiles,
  allDownloadedGiroFiles,
  allValidDownloadedGiroFiles,
  allPaymentTypes,
  allReasonsForStatusChange,
  allAdvancePaymentReceipts,
  allAdvancePaymentReceiptsForRegistration,
  allCustomSubsidyTypes,
  allActiveSubsidies,
  allHistorySubsidies,
  subsidyDebitAmount,
  absentChildVoidSubsidy,
  allRefundsForChild,
  fetchChildBalanceSheet,
  allEnrolledCentres,
  allAdvancePaymentItemAmount,
  fetchChildFinancialAssistanceStatus,
  statementOfAccount,
  childSoaByYear,
  allBulkInvoices,
  debtWriteOffReasons,
  allProgramBillings,
  allCreditDebitNotes,
  allRegChildBalanceSheet,
  allRegChildRefunds,
  allRegTotalAdvancePayments,
  allAdjustInvoiceAmts,
  allSubsidies,
  tradeDebtNotifications,
  taxRateAndAmountDetails,
  checkedRefunds,
  allConsolidatedRefunds,
} from './finance';
import {
  fetchAllChildDiscounts,
  discountRegistration,
  fetchAllApplicableChildDiscount,
  discountGroups,
  discountOptionConfig,
  removeChildDiscoutConfig,
  deactivateChildDiscoutConfig,
  fetchAllApplicableRegistrationDiscount,
} from './discount';
import { staffAttendance, staffAttendanceFilters } from './staffAttendance';
import { centreManagementConfig, createCentre } from './centreMgmtReducer';
import { TCC_ID, MFS_ID, LSH_ID } from '../../utils/constants';
import setData from './setData';
import {
  enrichmentClassDetail,
  enrichmentProgrammeDraft,
  getAllRefundChild,
  enrichmentChildInvoices,
  enrichmentVendorInvoices,
  enrichmentClassList,
  enrichmentVendorPayable,
  fetchParentClassInfo,
  fetchEnrichmentPhoto,
  fetchEnrichmentVideo,
  fetchEnrichmentDocument,
} from './enrichmentProgramme';
import {
  savedRegGiroDocuments,
  fetchRegistrationDocumentsToUpload,
} from './documentV2';
import { regAdditionalInfoDetails } from './additionalInformationV2';
import { regConsentFormSigners, regAllOfflineConsentForm } from './consentV2';
import {
  listEnrollmentPlanningRequests,
  listEnrollmentPlanningActivityLog,
  listEnrollmentPlanningUtilisation,
} from './enrollmentPlanning';
import {
  allCentreWaitlist,
  enquiryDetailByID,
  allPOCUsers,
} from './enquiriesManagement';
import {
  allSystemAnnouncements,
  currentSystemAnnouncement,
} from './systemAnnouncements';

import { parentSatisfactionSurveyDetails } from './pss';

const cdnUrl = process.env.REACT_APP_CDN_URL;
const masterCardGatewayUrl = process.env.REACT_APP_MASTER_CARD_GATEWAY;
const masterCardMerchantName = process.env.REACT_APP_MASTERCARD_MERCHANT_NAME;
const masterCardMerchantAddrOne =
  process.env.REACT_APP_MASTERCARD_MERCHANT_ADDRESS_LINE_ONE;
const masterCardMerchantAddrTwo =
  process.env.REACT_APP_MASTERCARD_MERCHANT_ADDRESS_LINE_TWO;
const masterCardMerchantId = process.env.REACT_APP_MASTERCARD_MERCHANT_ID;
const masterCardAPIVersion = process.env.REACT_APP_MASTERCARD_API_VERSION;
const ocbcOnlineBankingUrl = process.env.REACT_APP_OCBC_LOGIN_URL;
const ocbcClientID = process.env.REACT_APP_OCBC_CLIENT_ID;
const dbsOnlineBankingUrl = process.env.REACT_APP_DBS_LOGIN_URL;
const dbsClientID = process.env.REACT_APP_DBS_CLIENT_ID;
const dbsPayment = process.env.REACT_APP_DBS_PAYMENT || 'DBS';
const ocbcPayment = process.env.REACT_APP_OCBC_PAYMENT || 'OCBC';
const schoolID = parseInt(process.env.REACT_APP_SCHOOL_ID, 10);
const enquiryUrl = process.env.REACT_APP_ENQUIRY_URL;
const ocbcScope = process.env.REACT_APP_OCBC_SCOPE;
const freshDeskSupportUrl = process.env.REACT_APP_FRESH_DESK_SUPPORT_URL;
const imageServiceUrl = process.env.REACT_APP_IMAGE_SERVICE_URL;

export default combineReducers({
  cdnUrl: () => ({ data: cdnUrl }),
  masterCardGatewayUrl: () => ({ data: masterCardGatewayUrl }),
  masterCardMerchantName: () => ({ data: masterCardMerchantName }),
  masterCardMerchantAddrOne: () => ({ data: masterCardMerchantAddrOne }),
  masterCardMerchantAddrTwo: () => ({ data: masterCardMerchantAddrTwo }),
  masterCardMerchantId: () => ({ data: masterCardMerchantId }),
  masterCardAPIVersion: () => ({ data: masterCardAPIVersion }),
  ocbcOnlineBankingUrl: () => ({ data: ocbcOnlineBankingUrl }),
  ocbcClientID: () => ({ data: ocbcClientID }),
  dbsOnlineBankingUrl: () => ({ data: dbsOnlineBankingUrl }),
  dbsClientID: () => ({ data: dbsClientID }),
  dbsPayment: () => ({ data: dbsPayment }),
  ocbcPayment: () => ({ data: ocbcPayment }),
  ocbcScope: () => ({ data: ocbcScope }),
  schoolID: () => ({ data: schoolID }),
  enquiryUrl: () => ({ data: enquiryUrl }),
  isTCC: () => schoolID === TCC_ID,
  isMFS: () => schoolID === MFS_ID,
  isLSH: () => schoolID === LSH_ID,
  freshDeskSupportUrl: () => ({ data: freshDeskSupportUrl }),
  imageServiceUrl: () => ({ data: imageServiceUrl }),
  countries: countryCodes,
  enquiryStates,
  scheduleTimings,
  approverList,
  accessibleCentres,
  vacancies,
  editedPlans,
  submitPlan,
  recallData,
  pendingRequest,
  vacancyHistories,
  userEnqueries,
  rejectModalData,
  enquiryMonthlyPaymentDetailById,
  regPaymentDetail,
  userAccounts,
  availableBanks,
  additionalInfo,
  documentListing,
  uploadedDocumentList,
  updateModalData,
  bookVisit,
  cancelEnquiry,
  userDetails,
  fetchAllCalendarEvent,
  allCalendarData,
  allBusses,
  allBusData,
  availableChildrenForBus,
  selectedCenters,
  availableDateSlots,
  storedSearchData,
  availableEnquiryTimes,
  sidemenu,
  centerSearch,
  enquiry,
  login,
  linkpassUserInfoFromClaim,
  linkpassSN2UserInfo,
  forgot,
  register,
  reset,
  paymentSession,
  fetchCategory,
  levelsConfig,
  schoolConfig,
  waitList: fetchWaitList,
  fetchWaitListFilter,
  fetchVacancyList,
  vacanciesByYear,
  waitlistParamTags,
  waitlistParameters,
  fetchAllCentre,
  fetchAllServiceLevel,
  enquiryDetailById,
  tags: fetchAllTags,
  blacklistDetails,
  blacklistDrafts,
  allOccupations,
  childRelationSearch,
  findAllRoles,
  findRolesForUserCreation,
  addNewRole,
  updateRole,
  fetchAllModuleCategory,
  allStaff,
  listAllClasses,
  pastChildDetails,
  pastChildSearchResults,
  fetchCurrentFeeTier,
  fetchUpcomingFeeTier,
  fetchAllFeeDraft,
  fetchAllFeeGroup,
  fetchFeelistCategory,
  searchChildInWaitListResult,
  listAllTeachers,
  assignChildrenToClass,
  managementCentres,
  managementCentresDraft,
  managementLevels,
  centreDocumentCategories,
  centreLicenseDocuments,
  centreDocuments,
  linkpass,
  fetchChildrenBySchool,
  currentChildIdDetails,
  getChildrenData,
  fetchAllChild,
  findAllStateMapping,
  findAllStateMappingOptions,
  findAllRegistrationState,
  fetchAllBusDriver,
  holiday,
  centreClosureDays,
  enrolmentForm,
  allWithDrawalDraft,
  allWithDrawal,
  findAllTransferDrafts,
  checkVacancyAvailable,
  findAllCompletedDrafts,
  enrollmentCenterSearch,
  applicationDetailPanel,
  listAllReqDocs,
  scheduledVisits,
  listAllUploadedDocs,
  listUploadedUrl,
  admissions,
  admissionFilters,
  searchChildInAdmissions,
  classAttendanceDetails,
  childAttendanceDetails,
  validate,
  doctorDetails,
  fetchAllEvents,
  fetchCurrentEvents,
  currentEvent,
  classHealthDetails,
  childHealthDetails,
  visualCheckDetails,
  healthDocs,
  allRefunds,
  broadcasts,
  broadcastScope,
  broadcastById,
  getEventDetails,
  currentChildAttendance,
  getRsvpList,
  getRsvpCount,
  getRsvpData,
  fetchAllPurchaseItems,
  fetchAllInvoicesByFkChild,
  allInvoicesByChild,
  fetchChildBalance,
  childBankAccounts,
  banksList,
  fetchAllInvoicesForAllCenters,
  searchFinance,
  searchInvoice,
  fetchAllInvoicesWithRefund,
  fetchAllUploadedSubsidyFiles,
  getEventPhotos,
  listAllLogs,
  documentByCategory,
  documentTypeConfig,
  documentTypeForUpload,
  searchUserByNameResults,
  searchUserByEmailResults,
  staffSearchResults,
  getNotificationHistory,
  allDownloadedGiroFiles,
  staffAttendance,
  setData,
  allGuardiansDetails,
  accountInfoByEnquiry,
  assignOrPromoteDetail,
  centreLevels,
  allChildrenByParent,
  classDetailById,
  appConfig,
  pendingPromotionSignedChildren,
  pendingWithdrawalSignedChildren,
  admissionsWithFilter,
  latePickupDetails,
  childAttendanceHistory,
  registrationConfig,
  allPaymentTypes,
  allReasonsForStatusChange,
  fetchAllChildDiscounts,
  discountGroups,
  allAdvancePaymentReceipts,
  giroSetupStepDetail,
  bankAccountByChild,
  discountOptionConfig,
  snackBarMessage,
  showDeleteBusPopUp,
  allBusChildren,
  fetchAllApplicableChildDiscount,
  removeChildDiscoutConfig,
  deactivateChildDiscoutConfig,
  allCustomSubsidyTypes,
  allActiveSubsidies,
  allHistorySubsidies,
  subsidyDebitAmount,
  absentChildVoidSubsidy,
  centreManagementConfig,
  createCentre,
  getUpdatedChild,
  filterForClassAttendance,
  allProgramsType,
  attendanceDetails,
  childFiltersAndCounts,
  staffAttendanceFilters,
  enrichmentClassDetail,
  classActivities,
  classActivityTags,
  classActivityStickers,
  classResources,
  enrichmentProgrammeDraft,
  enrichmentChildInvoices,
  enrichmentVendorInvoices,
  getAllRefundChild,
  enrichmentClassList,
  enrichmentVendorPayable,
  learningGoalsOptionsList,
  emergencyContactDetails,
  allRefundsForChild,
  fetchChildBalanceSheet,
  allEnrolledCentres,
  mobileNavigation,
  getFormSigner,
  cicoQrCode,
  dashboardPendingRequest,
  registrations,
  existingChild,
  existingParent,
  fetchParentClassInfo,
  fetchEnrichmentPhoto,
  fetchEnrichmentVideo,
  fetchEnrichmentDocument,
  assetsAction,
  allAdvancePaymentItemAmount,
  feeTierForChild,
  reportSchema,
  reportList,
  qQLCheckListReportByCenter,
  qQLCheckListReportClassByCenter,
  fetchRegistrationDocumentsToUpload,
  savedRegGiroDocuments,
  regAdditionalInfoDetails,
  accountInfoByReg,
  regConsentFormSigners,
  regAllOfflineConsentForm,
  accountInfoByEnquiryForGiro,
  discountRegistration,
  allAdvancePaymentReceiptsForRegistration,
  fetchAllApplicableRegistrationDiscount,
  fetchChildFinancialAssistanceStatus,
  getAllUploadingAssets,
  statementOfAccount,
  childSoaByYear,
  childHealthInfo,
  allReportingStaff,
  allBulkInvoices,
  allTransferDrafts,
  feeTiersByChildByCentre,
  allWithdrawalDrafts,
  listEnrollmentPlanningRequests,
  listEnrollmentPlanningUtilisation,
  listEnrollmentPlanningActivityLog,
  debtWriteOffReasons,
  allProgramBillings,
  allSubsidies,
  allCentreWaitlist,
  enquiryDetailByID,
  allVacancy,
  allCentreVacancy,
  hasLeadOffered,
  allCreditDebitNotes,
  allRegChildBalanceSheet,
  allRegChildRefunds,
  allRegTotalAdvancePayments,
  allPOCUsers,
  vendorTrainer,
  allBusinessEntities,
  applicableFeeTierForChild,
  childFeeTierForChild,
  upcomingFeeTierForChild,
  allAdjustInvoiceAmts,
  marketingConsentByParent,
  suggestedFeeTier,
  suggestLevels,
  allGuardiansSearchDetails,
  tradeDebtNotifications,
  bulkFeeUploads,
  allStaffV2,
  staffSearchResultsV2,
  taxRateAndAmountDetails,
  allSystemAnnouncements,
  currentSystemAnnouncement,
  parentSatisfactionSurveyDetails,
  checkedRefunds,
  allConsolidatedRefunds,
  allValidDownloadedGiroFiles,
});
