import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import {
  errorLink,
  defaultOptions,
  handleResponseForApollo,
} from './apolloUtils';
import DatadogHandler from './datadog';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_REACT_API_URL,
});

const link = ApolloLink.from([errorLink, httpLink]);

const __Client = () => {
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  return client;
};

export const SkApolloRequestWithoutHeaders = async ({ params = {}, type }) => {
  const client = await __Client();
  let result = null;
  try {
    result = await client.query(params);
    return handleResponseForApollo(result, type);
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return handleResponseForApollo({
      data: {
        errors: [
          {
            message: ex.message,
          },
        ],
      },
    });
  }
};

export default __Client;
