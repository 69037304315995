import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Text from 'components/common/Text';
import Dialog from 'components/common/Modal';
import SessionTimeoutHandler from './SessionTimeout.handler';
import { LOGIN } from '../../../routes/constants';
import { SECONDS } from '../../../utils/constants';

const SessionTimeout = props => {
  const {
    time,
    formatTime,
    userToken,
    isActive,
    dismissModalAndResetTimer,
    logoutUser,
    sessionTimeoutCountdown,
    sessionTimeout,
    t,
  } = SessionTimeoutHandler(props);

  return (
    <>
      {userToken &&
        sessionTimeout &&
        isActive &&
        time <= sessionTimeoutCountdown * SECONDS && (
          <>
            {time <= 0 ? (
              <Dialog
                maxWidth="xs"
                fullWidth
                isOpen
                dialogTitle={
                  <Text h6 bold>
                    {t('common.sessionTimeout')}
                  </Text>
                }
                dialogContent={
                  <>
                    <Text body>{t('common.sessionExpired')}</Text>
                  </>
                }
                buttonPrimaryName={t('common.okay2')}
                onPrimaryClick={() => {
                  window.location.href = LOGIN;
                }}
                buttonSize="small"
                buttonPrimaryVariant="text"
                customJustifyContent="flex-end"
                hideSecondaryBtn
              />
            ) : (
              <Dialog
                maxWidth="xs"
                fullWidth
                isOpen
                dialogTitle={
                  <Text h6 bold>
                    {t('common.sessionInfo')}
                  </Text>
                }
                dialogContent={
                  <>
                    <Text body>{`${t(
                      'common.yourSession'
                    )} ${sessionTimeoutCountdown} ${t(
                      'common.minutes'
                    )}`}</Text>
                    <Text body bold>
                      {formatTime(time)}
                    </Text>
                  </>
                }
                buttonSize="small"
                buttonPrimaryVariant="text"
                buttonSecondaryVariant="text"
                buttonPrimaryName={t('common.continue')}
                buttonSecondaryName={t('common.logout')}
                customJustifyContent="flex-end"
                onPrimaryClick={() => dismissModalAndResetTimer()}
                onSecondaryClick={() => logoutUser()}
              />
            )}
          </>
        )}
    </>
  );
};

const mapStateToProps = state => ({
  sessionTimeout: get(state, 'schoolConfig.list.asObject.session_timeout'),
  sessionTimeoutCountdown: get(
    state,
    'schoolConfig.list.asObject.session_timeout_countdown'
  ),
});

export default compose(withTranslation())(
  connect(mapStateToProps)(SessionTimeout)
);
