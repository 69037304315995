import { get, cloneDeep } from 'lodash';
import {
  FETCH_BROADCASTS,
  SEARCH_BROADCASTS,
  FETCH_BROADCAST_BY_ID,
  MARK_BROADCAST_AS_READ,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getBroadcasting": {
 *      "data": [
 *        {
 *          "ID": 61,
 *          "subject": "Test by HDV",
 *          "fkUser": 32,
 *          "description": "Test by HDV",
 *          "status": "published",
 *          "active": true,
 *          "createdAt": "2020-10-22 13:09:28",
 *          "updatedAt": "2020-10-22 13:09:28",
 *          "user": {
 *            "ID": 32,
 *            "firstname": "Erina",
 *            "lastname": "Nakiri",
 *            "imageKey": null,
 *            "userRoleRelations": {
 *              "data": [
 *                {
 *                  "role": {
 *                    "name": "super_admin",
 *                    "label": "Super Administrator"
 *                  }
 *                }
 *              ]
 *            }
 *          },
 *          "broadcastingScopes": {
 *            "data": [
 *              {
 *                "class": {
 *                  "ID": 1,
 *                  "label": "Senior Toddlers Class"
 *                },
 *                "child": null,
 *                "centre": null
 *              }
 *            ]
 *          },
 *          "broadcastingAttachments": {
 *            "data": []
 *          },
 *          "isRead": true
 *        },
 *      ]
 *    }
 *  }
 *}
 */

export const broadcasts = (state = {}, action) => {
  if (action.type === FETCH_BROADCASTS) {
    const data = get(action, 'value.data.getBroadcasting');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  if (action.type === MARK_BROADCAST_AS_READ) {
    const newState = cloneDeep(state);
    if (newState.data.length) {
      newState.data.find(item => {
        if (item.ID === action.value) {
          item.isRead = true;
        }
      });
    }
    return newState;
  }
  return state;
};

export const broadcastById = (state = {}, action) => {
  if (action.type === FETCH_BROADCAST_BY_ID) {
    const data = get(action, 'value.data.getBroadcasting.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "searchBroadcastingScope": {
 *      "listCentre": {
 *        "totalCount": 1,
 *        "data": [
 *          {
 *            "ID": 2,
 *            "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic",
 *            "code": "TC1",
 *            "classes": {
 *              "totalCount": 6,
 *              "data": [
 *                {
 *                  "ID": 8,
 *                  "label": "Infants Class"
 *                },
 *              ]
 *            }
 *          }
 *        ]
 *      },
 *      "listClass": {
 *        "totalCount": 0,
 *        "data": []
 *      },
 *      "listChild": {
 *        "totalCount": 66,
 *        "data": [
 *          {
 *            "ID": 5,
 *            "firstname": "",
 *            "lastname": "Azaria Tan Hui Xin",
 *            "birthCertificate": "T1402019A"
 *          },
 *        ]
 *      }
 *    }
 *  }
 *}
 */

export const broadcastScope = (state = {}, action) => {
  if (action.type === SEARCH_BROADCASTS) {
    const data = get(action, 'value.data.searchBroadcastingScope');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};
