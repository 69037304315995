import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FieldWrapper from './FieldWrapper';

export const SwitchField = ({
  name,
  required,
  label,
  value,
  labelStyle,
  color = 'primary',
  noSwitchLabel,
  trueSwitchLabel = 'Yes',
  falseSwitchLabel = 'No',
  handleChange,
  hint = null,
  boldLabel = false,
  placement,
  disabled,
  error,
  wrapperStyle = {},
}) => {
  const secondaryLabel = value ? trueSwitchLabel : falseSwitchLabel;
  return (
    <FieldWrapper
      required={required}
      label={label}
      styles={labelStyle}
      hint={hint}
      placement={placement}
      boldLabel={boldLabel}
      disabled={disabled}
      error={error}
      wrapperStyle={wrapperStyle}
    >
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            name={name}
            color={color}
          />
        }
        label={noSwitchLabel ? '' : secondaryLabel}
      />
    </FieldWrapper>
  );
};

export default SwitchField;
