import { get } from 'lodash';
import {
  FETCH_CENTRE_MANAGEMENT_CONFIG,
  CREATE_OR_UPDATE_CENTRE,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "workplace": {
 *      "data": [
 *        {
 *          "ID": 1126,
 *          "label": "ngee_ann_poly",
 *          "description": "Ngee Ann Poly"
 *        },
 *      ]
 *    },
 *    "govtScheme": {
 *      "data": [
 *        {
 *          "ID": 3436,
 *          "label": "pop",
 *          "description": "POP"
 *        },
 *      ]
 *    },
 *    "centreCertification": {
 *      "data": [
 *        {
 *          "ID": 3446,
 *          "label": "none",
 *          "description": "None"
 *        },
 *      ]
 *    }
 *  }
 *}
 */

export const centreManagementConfig = (state = {}, action) => {
  if (action.type === FETCH_CENTRE_MANAGEMENT_CONFIG) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 * data
 *  {
 *     ID
 *   }
 * }
 */

export const createCentre = (state = {}, action) => {
  if (action.type === CREATE_OR_UPDATE_CENTRE) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};
