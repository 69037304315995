import React from 'react';
import { Grid, Button } from '@material-ui/core';

const BtnComp = ({ name, disabled }) => (
  <Grid container justify="center" className="w3-margin-bottom w3-center">
    <Grid item xs={12}>
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
      >
        {name}
      </Button>
    </Grid>
  </Grid>
);

export default BtnComp;
