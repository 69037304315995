import { get } from 'lodash';
import {
  FETCH_ALL_TRANSFER_DRAFTS,
  IS_VACANCY_AVAILABLE,
  FETCH_ALL_COMPLETED_DRAFTS,
  FETCH_TRANSFER_DRAFTS_LOADING,
  FETCH_TRANSFER_DRAFTS_SUCCESS,
  FETCH_TRANSFER_DRAFTS_ERROR,
  FETCH_ALL_VACANCY,
  FETCH_CENTRE_VACANCY,
  HAS_LEAD_OFFERED,
  FEE_TIERS_BY_CHILD_CENTRE,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getTransferDrafts": {
 *      "totalCount": 39,
 *      "data": [
 *        null,
 *        {
 *          "ID": 11,
 *          "vacancyAvailableForTransferRequest": true,
 *          "status": "cancelled",
 *          "additionalStatus": "withdrawal",
 *          "withdrawalEConsentCompleted": false,
 *          "destinationCentreEnrollmentCompleted": false,
 *          "centreByFkCentre": {
 *            "ID": 1,
 *            "label": "The Caterpillar's Cove @ Jurong East"
 *          },
 *          "centreByDestinationFkCentre": {
 *            "ID": 2,
 *            "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *          },
 *          "userByDestinationCentreFkApprover": {
 *            "ID": 32,
 *            "firstname": "Erina",
 *            "lastname": "Nakiri"
 *          },
 *          "userBySourceCentreFkApprover": null,
 *          "forms": {
 *            "data": [
 *              {
 *                "formParticipants": {
 *                  "data": [
 *                    {
 *                      "ID": 1313,
 *                      "status": "cancelled",
 *                      "email": "sn2dummy+40001593@gmail.com",
 *                      "createdAt": "2020-10-14 18:13:57",
 *                      "updatedAt": "2020-11-13 13:49:39",
 *                      "fkParent": null,
 *                      "user": {
 *                        "firstname": "Jacquelynn",
 *                        "lastname": "Wong"
 *                      }
 *                    },
 *                    {
 *                      "ID": 1312,
 *                      "status": "cancelled",
 *                      "email": "sn2dummy+40000690@gmail.com",
 *                      "createdAt": "2020-10-14 18:13:57",
 *                      "updatedAt": "2020-11-13 13:49:39",
 *                      "fkParent": 691,
 *                      "user": {
 *                        "firstname": "Tan",
 *                        "lastname": "Rina"
 *                      }
 *                    }
 *                  ]
 *                }
 *              }
 *            ]
 *          },
 *          "transferDraftItem": {
 *            "ID": 11,
 *            "fkDraft": 11,
 *            "child": {
 *              "ID": 133,
 *              "firstname": "",
 *              "lastname": "Tan Le Shan Charlotte",
 *              "currentFee": {
 *                "amount": 1750
 *              }
 *            },
 *            "user": {
 *              "ID": 690,
 *              "firstname": "Tan",
 *              "lastname": "Rina"
 *            },
 *            "centreBySourceCentre": {
 *              "ID": 1,
 *              "label": "The Caterpillar's Cove @ Jurong East"
 *            },
 *            "centreByDestinationCentre": {
 *              "ID": 2,
 *              "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *            },
 *            "refundFeeAmount": 0,
 *            "refundDepositAmount": 1750,
 *            "effectiveDate": "2020-12-01 00:00:00",
 *            "code": {
 *              "ID": 3001,
 *              "label": "fee_expensive",
 *              "description": "Fees are too expensive"
 *            },
 *            "fkEnquiry": 762,
 *            "remark": "ok"
 *          }
 *        },
 *      ]
 *    }
 *  }
 * }
 *
 */

export const findAllTransferDrafts = (state = {}, action) => {
  if (action.type === FETCH_ALL_TRANSFER_DRAFTS) {
    const data = get(action, 'value.data.data.getTransferDrafts');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getCompletedTransferRequests": {
 *      "totalCount": 39,
 *      "data": [
 *        null,
 *        {
 *          "ID": 11,
 *          "vacancyAvailableForTransferRequest": true,
 *          "status": "cancelled",
 *          "additionalStatus": "withdrawal",
 *          "withdrawalEConsentCompleted": false,
 *          "destinationCentreEnrollmentCompleted": false,
 *          "centreByFkCentre": {
 *            "ID": 1,
 *            "label": "The Caterpillar's Cove @ Jurong East"
 *          },
 *          "centreByDestinationFkCentre": {
 *            "ID": 2,
 *            "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *          },
 *          "userByDestinationCentreFkApprover": {
 *            "ID": 32,
 *            "firstname": "Erina",
 *            "lastname": "Nakiri"
 *          },
 *          "userBySourceCentreFkApprover": null,
 *          "forms": {
 *            "data": [
 *              {
 *                "formParticipants": {
 *                  "data": [
 *                    {
 *                      "ID": 1313,
 *                      "status": "cancelled",
 *                      "email": "sn2dummy+40001593@gmail.com",
 *                      "createdAt": "2020-10-14 18:13:57",
 *                      "updatedAt": "2020-11-13 13:49:39",
 *                      "fkParent": null,
 *                      "user": {
 *                        "firstname": "Jacquelynn",
 *                        "lastname": "Wong"
 *                      }
 *                    },
 *                    {
 *                      "ID": 1312,
 *                      "status": "cancelled",
 *                      "email": "sn2dummy+40000690@gmail.com",
 *                      "createdAt": "2020-10-14 18:13:57",
 *                      "updatedAt": "2020-11-13 13:49:39",
 *                      "fkParent": 691,
 *                      "user": {
 *                        "firstname": "Tan",
 *                        "lastname": "Rina"
 *                      }
 *                    }
 *                  ]
 *                }
 *              }
 *            ]
 *          },
 *          "transferDraftItem": {
 *            "ID": 11,
 *            "fkDraft": 11,
 *            "child": {
 *              "ID": 133,
 *              "firstname": "",
 *              "lastname": "Tan Le Shan Charlotte",
 *              "currentFee": {
 *                "amount": 1750
 *              }
 *            },
 *            "user": {
 *              "ID": 690,
 *              "firstname": "Tan",
 *              "lastname": "Rina"
 *            },
 *            "centreBySourceCentre": {
 *              "ID": 1,
 *              "label": "The Caterpillar's Cove @ Jurong East"
 *            },
 *            "centreByDestinationCentre": {
 *              "ID": 2,
 *              "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *            },
 *            "refundFeeAmount": 0,
 *            "refundDepositAmount": 1750,
 *            "effectiveDate": "2020-12-01 00:00:00",
 *            "code": {
 *              "ID": 3001,
 *              "label": "fee_expensive",
 *              "description": "Fees are too expensive"
 *            },
 *            "fkEnquiry": 762,
 *            "remark": "ok"
 *          }
 *        },
 *      ]
 *    }
 *  }
 * }
 *
 */

export const findAllCompletedDrafts = (state = {}, action) => {
  if (action.type === FETCH_ALL_COMPLETED_DRAFTS) {
    const data = get(action, 'value.data.data.getCompletedTransferRequests');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 * {
 *   "data": {
 *     "vacancyAvailableInCentreForLevel": false
 *   }
 * }
 */

export const checkVacancyAvailable = (state = {}, action) => {
  if (action.type === IS_VACANCY_AVAILABLE) {
    const data = get(
      action,
      'value.data.data.vacancyAvailableInCentreForLevel'
    );

    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }
  return state;
};

export const allVacancy = (state = {}, action) => {
  if (action.type === FETCH_ALL_VACANCY) {
    const data = get(action, 'value.data.data.getAvailableVacancy');
    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }
  return state;
};

export const allCentreVacancy = (state = {}, action) => {
  if (action.type === FETCH_CENTRE_VACANCY) {
    const data = get(
      action,
      'value.data.data.vacancyAvailableInCentreForTransfer'
    );
    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }
  return state;
};

export const hasLeadOffered = (state = {}, action) => {
  if (action.type === HAS_LEAD_OFFERED) {
    const data = get(
      action,
      'value.data.data.hasLeadBeenOfferedForTransferDraft'
    );
    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }
  return state;
};

export const allTransferDrafts = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSFER_DRAFTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_TRANSFER_DRAFTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getAllTransferDrafts'),
      };
    case FETCH_TRANSFER_DRAFTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const feeTiersByChildByCentre = (state = {}, action) => {
  if (action.type === FEE_TIERS_BY_CHILD_CENTRE) {
    const data = get(action, 'value.data.data.getFeeTiersByLevelByCentre');
    return Object.assign({}, state, {
      ...action.value,
      data,
    });
  }
  return state;
};

export default findAllTransferDrafts;
