import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const FIND_CHILDREN_BY_PARENT = gql`
  query findAllChildrenByParent($childLevelAt: Datetime!) {
    findAllChildrenByParent {
      totalCount
      data {
        ID
        firstname
        lastname
        currentLevel {
          centre {
            ID
            label
          }
        }
        childLevelAt(time: $childLevelAt) {
          level {
            label
          }
          centre {
            ID
            label
          }
        }
      }
    }
  }
`;

export const FIND_NEAREST_CENTRES_BY_LAT_LNG = gql`
  query findNearestCentresByLatLong(
    $location: LatLng!
    $radius: Float
    $enrollmentDate: Datetime!
    $level: Int!
    $filter: CentreFilter
    $pagination: Pagination
  ) {
    findNearestCentresByLatLong(
      location: $location
      radius: $radius
      enrollmentDate: $enrollmentDate
      level: $level
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        distance
        centre {
          ID
          fkSchool
          label
          active
          availability
          createdAt
          updatedAt
          addresses {
            totalCount
            data {
              ID
              fkCentre
              postcode
              name
              address
              lat
              lng
              city
              country
              active
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const IS_EXISTING_PARENT = gql`
  query isExistingParent($email: String!) {
    isExistingParent(email: $email)
  }
`;

export const IS_EXISTING_CHILD = gql`
  query isExistingChild($birthCertificate: String!) {
    isExistingChild(birthCertificate: $birthCertificate)
  }
`;

export const GET_ENROLMENT_DATE_OPTIONS = gql`
  query getEnrolmentDates(
    $schoolID: Int!
    $centreID: Int!
    $programID: Int
    $from: Datetime!
    $to: Datetime!
  ) {
    getEnrolmentDates(
      IDSchool: $schoolID
      IDCentre: $centreID
      IDProgram: $programID
      from: $from
      to: $to
    )
  }
`;

export const SEND_APP_DOWNLOAD_LINK = gql`
  mutation sendDownloadMobileAppLink($mobile: String!, $countryCode: String!) {
    sendDownloadMobileAppLink(
      mobilePhone: $mobile
      mobilePhoneCountryCode: $countryCode
    )
  }
`;

export const FIND_ALL_REGISTRATION_CHILD = gql`
  query findAllRegistrationChild(
    $filter: RegistrationChildFilter
    $pagination: Pagination
  ) {
    findAllRegistrationChild(filter: $filter, pagination: $pagination) {
      data {
        ID
        firstname
        lastname
        fkChild
      }
      totalCount
    }
  }
`;
