import get from 'lodash/get';
import {
  FETCH_CLASS_ATTENDANCE,
  FETCH_CHILD_ATTENDANCE,
  CURRENT_CHILD_ATTENDANCE,
  GET_LATE_PICKUPS,
  FETCH_FILTER_FOR_CLASS_ATTENDANCE,
  FETCH_ATTENDANCE_DETAILS,
  GET_FILTERS_COUNTS,
  CURRENT_CHILD_ATTENDANCE_HISTORY,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *{
 *  "data": {
 *    "getAttendanceByClass": {
 *      "total": 137,
 *      "attendances": [
 *        {
 *          "child": {
 *            "ID": 8,
 *            "firstname": "",
 *            "lastname": "Alson Lim Yu Feng(Lin Yufeng)",
 *            "childClassAt": {
 *              "class": {
 *                "label": "K1 Class"
 *              }
 *            },
 *            "childRelations": {
 *              "data": [
 *                {
 *                  "parent": {
 *                    "firstname": "",
 *                    "lastname": "Tan Wei Nee (Chen Weini)",
 *                    "relation": ""
 *                  },
 *                  "role": {
 *                    "name": "guardian_admin_access",
 *                    "label": "Guardian Admin Access"
 *                  },
 *                  "fkUser": 475,
 *                  "fkRelation": 979
 *                },
 *                {
 *                  "parent": {
 *                    "firstname": "",
 *                    "lastname": "Lim Hock Chuan (Lin Fuquan)",
 *                    "relation": ""
 *                  },
 *                  "role": {
 *                    "name": "guardian_all_access",
 *                    "label": "Guardian All Access"
 *                  },
 *                  "fkUser": 476,
 *                  "fkRelation": 980
 *                }
 *              ]
 *            }
 *          },
 *          "date": "2020-12-12 00:00:00",
 *          "present": false,
 *          "status": "absent",
 *          "attendances": [],
 *          "health": [],
 *          "healthChecks": []
 *        },
 *      ]
 *    }
 *  }
 *}
 */

export const classAttendanceDetails = (state = {}, action) => {
  if (action.type === FETCH_CLASS_ATTENDANCE) {
    const data = get(action, 'value.data.getAttendanceByClass');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example data
 * {
 *   "data": {
 *     "getChildAttendances": {
 *       "total": 6,
 *       "childAttendances": [
 *         {
 *           "child": {
 *             "ID": 8,
 *             "firstname": "",
 *             "lastname": "Alson Lim Yu Feng(Lin Yufeng)"
 *           },
 *           "date": "2020-09-22 00:00:00",
 *           "present": true,
 *           "remarks": null,
 *           "health": [],
 *           "attendances": [
 *             {
 *               "id": 132433,
 *               "temperature": 0,
 *               "remarks": "",
 *               "type": "check_in",
 *               "active": true,
 *               "time": "2020-09-22 08:32:45"
 *             },
 *           ]
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const currentChildAttendance = (state = {}, action) => {
  if (action.type === CURRENT_CHILD_ATTENDANCE) {
    const data = get(action, 'value.data.getChildAttendances');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const childAttendanceDetails = (state = {}, action) => {
  if (action.type === FETCH_CHILD_ATTENDANCE) {
    const data = get(action, 'value.data.getChildAttendances');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example data
 * {
 *   "data": {
 *     "getAttendanceHistory": {
 *       "total": 54,
 *       "childAttendances": [
 *         {
 *           "status": "",
 *           "child": {
 *             "ID": 8,
 *             "firstname": "",
 *             "lastname": "Alson Lim Yu Feng(Lin Yufeng)",
 *             "childRelations": {
 *               "data": [
 *                 {
 *                   "parent": {
 *                     "firstname": "",
 *                     "lastname": "Tan Wei Nee (Chen Weini)",
 *                     "relation": ""
 *                   },
 *                   "role": {
 *                     "name": "guardian_admin_access",
 *                     "label": "Guardian Admin Access"
 *                   },
 *                   "fkUser": 475,
 *                   "fkRelation": 979
 *                 },
 *                 {
 *                   "parent": {
 *                     "firstname": "",
 *                     "lastname": "Lim Hock Chuan (Lin Fuquan)",
 *                     "relation": ""
 *                   },
 *                   "role": {
 *                     "name": "guardian_all_access",
 *                     "label": "Guardian All Access"
 *                   },
 *                   "fkUser": 476,
 *                   "fkRelation": 980
 *                 }
 *               ]
 *             }
 *           },
 *           "date": "2020-11-30 00:00:00",
 *           "present": false,
 *           "remarks": null,
 *           "healthChecks": [],
 *           "health": [],
 *           "attendances": []
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const childAttendanceHistory = (state = {}, action) => {
  if (action.type === CURRENT_CHILD_ATTENDANCE_HISTORY) {
    const data = get(action, 'value.data.getAttendanceHistory');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example data
 * {
 *   "data": {
 *     "filterCountForAttendanceHistory": [
 *       {
 *         "count": 54,
 *         "filter": "all"
 *       },
 *     ],
 *   }
 * }
 * */

export const childFiltersAndCounts = (state = {}, action) => {
  if (action.type === GET_FILTERS_COUNTS) {
    const data = get(action, 'value.data.filterCountForAttendanceHistory');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 * {
 *   "data": {
 *     "filterCountForClassAttendance": [
 *       {
 *         "count": 54,
 *         "filter": "all"
 *       },
 *     ],
 *   }
 * }
 * */

export const filterForClassAttendance = (state = {}, action) => {
  if (action.type === FETCH_FILTER_FOR_CLASS_ATTENDANCE) {
    const data = get(action, 'value.data.filterCountForClassAttendance');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Api error got in dev and dev1
 *
 */
export const latePickupDetails = (state = {}, action) => {
  if (action.type === GET_LATE_PICKUPS) {
    const data = get(action, 'value.data.getLatePickupDetails');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 * Example data
 * {
 *   "data": {
 *     "getAttendance": {
 *       "ID": 2839,
 *       "time": "2019-12-03 18:32:29",
 *       "dateOfTime": "2019-12-03 00:00:00",
 *       "childLatePickups": {
 *         "data": []
 *       },
 *       "child": {
 *         "currentClass": {
 *           "class": {
 *             "classTeachers": {
 *               "data": [
 *                 {
 *                   "user": {
 *                     "firstname": "Xin Hui",
 *                     "lastname": "Goh"
 *                   }
 *                 },
 *                 {
 *                   "user": {
 *                     "firstname": "Zhongqiu",
 *                     "lastname": "Li"
 *                   }
 *                 }
 *               ]
 *             }
 *           }
 *         },
 *         "childRelations": {
 *           "data": [
 *             {
 *               "parent": {
 *                 "firstname": "",
 *                 "lastname": "Tan Binly",
 *                 "relation": ""
 *               },
 *               "role": {
 *                 "name": "guardian_admin_access",
 *                 "label": "Guardian Admin Access"
 *               }
 *             },
 *             {
 *               "parent": {
 *                 "firstname": "",
 *                 "lastname": "Wong Hock Soon(Huang Fushun)",
 *                 "relation": ""
 *               },
 *               "role": {
 *                 "name": "guardian_all_access",
 *                 "label": "Guardian All Access"
 *               }
 *             }
 *           ]
 *         }
 *       },
 *       "userByFkUser": {
 *         "firstname": "",
 *         "lastname": "Wong Hock Soon(Huang Fushun)"
 *       },
 *       "userByFkVerifiedBy": null,
 *       "remarks": null,
 *       "type": "check_out",
 *       "authorisedPersonal": null,
 *       "createdAt": "2019-12-03 18:32:28",
 *       "updatedAt": "2020-06-24 22:07:18"
 *     }
 *   }
 * }
 */

export const attendanceDetails = (state = {}, action) => {
  if (action.type === FETCH_ATTENDANCE_DETAILS) {
    const data = get(action, 'value.data.getAttendance');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default childAttendanceDetails;
