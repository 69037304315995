import React from 'react';
import get from 'lodash/get';
import Input from '@material-ui/core/Input';
import FieldWrapper from './FieldWrapper';
import { onKeyPress } from '../../../utils';

const getCount = (string, inputProps) => {
  const maxLength = get(inputProps, 'maxLength', 0);
  if (!maxLength) {
    return '';
  }
  const valueLength = get(string, 'length', 0);
  return `${maxLength - valueLength}/${maxLength}`;
};

export const TextField = ({
  label,
  autoFocus,
  name,
  value,
  type = 'text',
  disabled,
  onChange,
  placeholder,
  error,
  onBlur,
  onMouseEnter,
  required,
  labelStyle,
  startAdornment,
  endAdornment,
  labelTextColor,
  info = '',
  hideError,
  inputProps = {},
  showCount = true,
  fieldWrapperProps = {},
  ...props
}) => (
  <FieldWrapper
    required={required}
    label={label}
    name={name}
    error={error}
    styles={labelStyle}
    disabled={disabled}
    labelTextColor={labelTextColor}
    info={info}
    hideError={hideError}
    count={getCount(value, inputProps)}
    showCount={showCount}
    {...fieldWrapperProps}
  >
    <Input
      onWheel={e => e.target.blur()}
      id={name}
      type={type}
      name={name}
      value={value}
      autoFocus={!disabled && Boolean(autoFocus)}
      disabled={disabled}
      inputProps={inputProps}
      placeholder={placeholder}
      onChange={onChange}
      onMouseEnter={onMouseEnter}
      onBlur={onBlur}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      onKeyPress={e => type && onKeyPress(e, type)}
      {...props}
    />
  </FieldWrapper>
);

export default TextField;
