import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import CloseIcon from '@material-ui/icons/Cancel';
import { withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Checkbox } from '@material-ui/core';
import TabGenerator from 'components/common/TabGenerator';
import Text from 'components/common/Text';
import { BASE_COLOR } from 'theme/variables';
import Button from 'components/common/Button';
import { get } from 'lodash';
import { schoolIDMap } from 'utils/constants';

import { ImageLazyLoader } from '../../../../utils';

import Style from './style';

const { CircleColor } = Style;

const getSchoolName = schoolID => {
  const currentSchoolDetails = schoolIDMap[schoolID];
  const schoolName = get(currentSchoolDetails, 'name');
  return schoolName;
};

const LINK_PASS_NOV_RELEASE = false;

const BenefitOfLinkpass = props => {
  const { cdnUrl, t, history, theme, schoolName } = props;
  const [tabValue, setTabValue] = useState(0);
  const singleSingOnLogo = `${cdnUrl}/Assets/linkpass/linkpass_sevices.png`;

  const linkpointImage = `${cdnUrl}/Assets/linkpass/myLinkPoints.png`;

  const leftLinkpassBanner = `${cdnUrl}/Assets/linkpass/benefitOfLink2x.png`;
  const connectToMyInfo = `${cdnUrl}/Assets/linkpass/myInfoLinkpass.png`;

  const onClose = () => {
    history.goBack();
  };

  const handleTabChange = status => {
    setTabValue(status);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const FAQs = () => (
    <Grid style={{ marginBottom: '100px' }}>
      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              1
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              <Trans
                i18nKey="linkpassFAQs.iAmEnrolling"
                values={{ schoolName }}
              />
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.youHaveTheOption')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              2
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.faqLinkpassCanIStillQuestion')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.faqLinkpassYesYouCanStill')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              3
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.ifIHaveAnExistingLinkpass')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.afterYouConnect')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              4
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.ifIHadDeletedMyLinkpassAccount')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.yourParentPortalAccount')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              5
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.mySpouseHasALinkpassAccount')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.yesAsEachParentWill')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              6
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.IfIDismissed')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.youCanGoToYourProfile')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              7
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.ICantLoginTo')}
            </Text>
          </Grid>
          <Grid md={12} style={{ display: 'flex' }}>
            <Text
              type="body2"
              style={{ minWidth: '20px' }}
              textColor={BASE_COLOR.neutral.darkGrey}
              md={1}
            >
              a.
            </Text>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey} md={11}>
              {t('linkpassFAQs.forNewParents')}
            </Text>
          </Grid>
          <Grid md={12} style={{ display: 'flex' }}>
            <Text
              type="body2"
              style={{ minWidth: '20px' }}
              textColor={BASE_COLOR.neutral.darkGrey}
              md={1}
            >
              b.
            </Text>
            <Text
              type="body2"
              textColor={BASE_COLOR.neutral.darkGrey}
              md={11}
              style={{ textAlign: 'left' }}
            >
              {t('linkpassFAQs.forExistingParents')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              8
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.ifIWouldLike')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.pleaseSubmitASupport')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              9
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.theParentApp')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.doMakeSureThat')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginBottom: '25px' }}>
        <Grid item style={{ width: '50px' }}>
          <CircleColor>
            <Text type="copySmall" color="white">
              10
            </Text>
          </CircleColor>
        </Grid>

        <Grid container md={11}>
          <Grid style={{ marginBottom: '4px' }}>
            <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
              {' '}
              {t('linkpassFAQs.iHaveAQuestion')}
            </Text>
          </Grid>
          <Grid>
            <Text type="body2" textColor={BASE_COLOR.neutral.darkGrey}>
              {' '}
              {t('linkpassFAQs.pleaseSubmitASupport')}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      {LINK_PASS_NOV_RELEASE && (
        <>
          <Grid container md={12} style={{ marginBottom: '25px' }}>
            <Grid item style={{ width: '50px' }}>
              <CircleColor>
                <Text type="copySmall" color="white">
                  11
                </Text>
              </CircleColor>
            </Grid>

            <Grid container md={11}>
              <Grid style={{ marginBottom: '4px' }}>
                <Text type="body1" textColor={BASE_COLOR.linkpass.default}>
                  {' '}
                  {t('linkpassFAQs.ifIDoNotWantToUseLinkpass')}
                </Text>
              </Grid>

              <Text
                type="body2"
                textColor={BASE_COLOR.neutral.darkGrey}
                md={12}
              >
                {t('linkpassFAQs.yourConsentToMarketing')}
              </Text>

              <Grid md={12} style={{ display: 'flex' }}>
                <Text
                  type="body2"
                  style={{ minWidth: '20px' }}
                  textColor={BASE_COLOR.neutral.darkGrey}
                  md={1}
                >
                  a.
                </Text>
                <Text
                  type="body2"
                  textColor={BASE_COLOR.neutral.darkGrey}
                  md={11}
                >
                  <Trans
                    i18nKey="linkpassFAQs.clickOnTheManagePreferences"
                    values={{ schoolName }}
                  />
                </Text>
              </Grid>
              <Grid md={12} style={{ display: 'flex' }}>
                <Text
                  type="body2"
                  style={{ minWidth: '20px' }}
                  textColor={BASE_COLOR.neutral.darkGrey}
                  md={1}
                >
                  b.
                </Text>
                <Text
                  type="body2"
                  textColor={BASE_COLOR.neutral.darkGrey}
                  md={11}
                  style={{ textAlign: 'left' }}
                >
                  {t('linkpassFAQs.alternativelyYouMay')}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  const Benefits = () => (
    <>
      <Grid
        item
        md={12}
        alignContent="center"
        justify="center"
        style={{ marginBottom: '100px' }}
      >
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          direction="column"
          style={{ paddingBottom: '50px', alignItems: 'center' }}
        >
          <Grid className="w3-margin-bottom w3-center">
            <Text
              body
              bold
              textColor={BASE_COLOR.linkpass.default}
              style={{ marginBottom: '20px' }}
            >
              {t('auth.singleSignOnLinkpass')}
            </Text>
            <ImageLazyLoader
              className=" s7t-login-grid-image"
              src={singleSingOnLogo}
            />
          </Grid>
          {LINK_PASS_NOV_RELEASE && (
            <>
              <Grid className="w3-margin-bottom  w3-center">
                <Text
                  body
                  bold
                  textColor={BASE_COLOR.linkpass.default}
                  style={{ marginBottom: '20px' }}
                >
                  {t('auth.connectToMyInfoLinkpass')}
                </Text>
                <ImageLazyLoader
                  className=" s7t-login-grid-image"
                  src={connectToMyInfo}
                />
              </Grid>

              <Grid className="w3-margin-bottom">
                <Text
                  body
                  bold
                  textColor={BASE_COLOR.linkpass.default}
                  style={{ marginBottom: '20px' }}
                >
                  {t('auth.manageYourMarketingLinkpass')}
                </Text>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div>
                      <Checkbox
                        color="primary"
                        name="IsSMS"
                        size="medium"
                        style={{ marginTop: '-3px' }}
                        disabled
                      />
                      <Text className="sn2-margin-4" color="black">
                        {t('marketingConsent.sms')}
                      </Text>
                    </div>
                    <div>
                      <Checkbox
                        color="primary"
                        name="IsEmail"
                        size="medium"
                        style={{ marginTop: '-3px' }}
                        disabled
                      />
                      <Text className="sn2-margin-4" color="black">
                        {t('marketingConsent.email')}
                      </Text>
                    </div>
                    <div>
                      <Checkbox
                        color="primary"
                        name="IsPhone"
                        size="medium"
                        checked
                        style={{ marginTop: '-3px' }}
                      />
                      <Text className="sn2-margin-4" color="black">
                        {t('marketingConsent.phone')}
                      </Text>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid class="w3-margin-bottom w3-center">
                <Text
                  body
                  bold
                  textColor={BASE_COLOR.linkpass.default}
                  style={{ marginBottom: '20px' }}
                >
                  {t('auth.viewYourPointLinkpass')}
                </Text>
                <ImageLazyLoader
                  className=" s7t-login-grid-image"
                  src={linkpointImage}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );

  const tabConfig = {
    Benefits: {
      data: <Benefits />,
    },
    FAQs: {
      data: <FAQs />,
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        background: BASE_COLOR.neutral.white,
      }}
    >
      <Grid item md={4} style={{ maxHeight: '100%' }}>
        <ImageLazyLoader
          alt="left linkpass banner"
          width="100%"
          height="100%"
          src={leftLinkpassBanner}
        />
      </Grid>
      <Grid item md={8}>
        <Grid item md={11} container justify="flex-end">
          <Button
            variant="text"
            button="icon"
            color="main"
            onClick={() => onClose()}
          >
            <Text color="main" bold>
              {t('common.close')}
            </Text>

            <CloseIcon
              color="main"
              style={{
                color: BASE_COLOR.neutral.main,
                fontSize: '20px',
                marginLeft: '8px',
                width: '20px',
                height: '20px',
              }}
            />
          </Button>
        </Grid>

        <div
          style={{
            display: 'flex',
            maxHeight: '100%',
            width: '100%',
            overflowY: 'scroll',
            justifyContent: 'center',
            paddingLeft: '100px',
            paddingRight: '100px',
          }}
        >
          <TabGenerator
            tabJustify="center"
            isFreeTabLabel
            className="s7t-tabs-no-shadow"
            containerStyle="w3-border-bottom"
            primaryColor={BASE_COLOR.linkpass.default}
            tabData={tabConfig}
            appBarColor={theme.baseColor.neutral.white}
            activeTab={tabValue}
            tabChanged={handleTabChange}
            tabSize="12"
          />
        </div>
      </Grid>
    </div>
  );
};

BenefitOfLinkpass.propTypes = {};

const mapStateToProps = state => ({
  cdnUrl: state.cdnUrl && state.cdnUrl.data,
  schoolName: getSchoolName(get(state, 'schoolID.data')),
});

const mapDispatchToProps = {};

export default compose(
  withTranslation(),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(BenefitOfLinkpass));
