import BenefitOfLinkpass from 'components/views/Auth/BenefitOfLinkpass';
import ContactDetailsNotMatch from 'components/views/Auth/ContactDetailsNotMatch';
import LinkpassHandler from 'components/views/Auth/LinkpassHandler';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import Maintenance from 'components/views/Maintenance/Maintenance';
import Login from '../components/views/Auth';
import ParentSatisfactionSurvey from '../components/views/ParentSatisfactionSurvey';

import localStore from '../utils/localStorage';
import {
  OVERVIEW,
  PARENT_SEARCH,
  PARENT_INFO,
  HOMEPAGE,
  REGISTER,
  ENQUIRY,
  FORGOT,
  LOGIN,
  RESET,
  SSO_RESPONSE,
  CHAT,
  CONNECT_TO_MFS_ACCOUNT,
  CONTACT_DETAILS_NOT_MATCH,
  LP_SSO_CALLBACK,
  BENEFIT_OF_LINKPASS,
  MAINTENANCE,
  PSS,
} from './constants';

const ParentInfo = lazy(() => import('../components/views/Enquiry/ParentInfo'));
const ParentSearch = lazy(() =>
  import('../components/views/Enquiry/ParentSearch')
);
const EnquirySummary = lazy(() =>
  import('../components/views/Enquiry/Summary')
);
const SSOHandler = lazy(() => import('../components/views/Auth/SSOHandler'));
const userToken = localStore.getValue('userToken');

const normalRoutes = [
  {
    url: LP_SSO_CALLBACK,
    component: () => <LinkpassHandler />,
  },
  {
    url: BENEFIT_OF_LINKPASS,
    component: () => <BenefitOfLinkpass />,
  },
  {
    url: CONTACT_DETAILS_NOT_MATCH,
    component: () => <ContactDetailsNotMatch />,
  },
  {
    url: HOMEPAGE,
    component: () => (
      <>{userToken ? <Redirect to={OVERVIEW} /> : <Redirect to={LOGIN} />}</>
    ),
  },
  {
    url: PSS,
    component: ParentSatisfactionSurvey,
  },
  {
    url: PARENT_INFO,
    component: ParentInfo,
  },
  {
    url: PARENT_SEARCH,
    component: ParentSearch,
  },
  {
    url: ENQUIRY,
    component: EnquirySummary,
  },
  {
    url: LOGIN,
    component: Login,
  },
  {
    url: CONNECT_TO_MFS_ACCOUNT,
    component: Login,
  },
  {
    url: REGISTER,
    component: Login,
  },
  {
    url: RESET,
    component: Login,
  },
  {
    url: FORGOT,
    component: Login,
  },
  {
    url: MAINTENANCE,
    component: Maintenance,
  },
  {
    url: SSO_RESPONSE,
    component: SSOHandler,
  },
  {
    url: CHAT,
    component: () => <div />,
  },
];

export default normalRoutes;
