import gql from 'graphql-tag';

export const GET_REG_CONSENT_FORM_SIGNER = gql`
  query getFormSigner($IDRegistration: Int!) {
    getFormSigner(IDRegistration: $IDRegistration) {
      DisplayName
      Email
      Order
      RegParentID
      UserId
      FamilyMemberID
      Status
      IsStaff
      Type
      UpdatedAt
    }
  }
`;

export const SEND_CONSENT_FORM = gql`
  mutation sendForm(
    $registrationId: Int!
    $formParticipants: [FormSignerDto!]!
  ) {
    sendForm(
      IDRegistration: $registrationId
      FormParticipants: $formParticipants
    ) {
      ID
    }
  }
`;

export const CANCEL_CONSENT_FORM = gql`
  mutation cancelForm($registrationId: Int!) {
    cancelForm(IDRegistration: $registrationId) {
      ID
    }
  }
`;

export const SAVE_REG_CONSENT_OFFLINE = gql`
  mutation saveRegistrationConsentOfflineForm(
    $registrationId: Int!
    $fileURL: String!
    $fileName: String!
  ) {
    saveRegistrationConsentOfflineForm(
      IDRegistration: $registrationId
      fileURL: $fileURL
      fileName: $fileName
    )
  }
`;

export const CONSENT_FORM_COMPLETED = gql`
  mutation markConsentAsCompleted($registrationId: Int!) {
    markConsentAsCompleted(IDRegistration: $registrationId)
  }
`;

export const GET_REG_CONSENT_UPLOAD_URL = gql`
  mutation getRegistrationConsentUploadURL(
    $registrationID: Int!
    $file: Upload!
  ) {
    getRegistrationConsentUploadURL(
      IDRegistration: $registrationID
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const GET_ALL_OFFLINE_CONSENT_FORM = gql`
  query getConsentFormRegs($registrationId: Int!) {
    getOfflineConsentFormByRegistration(IDRegistration: $registrationId) {
      ID
      filename
      label
      uRL
      documentTags {
        data {
          ID
          documentType {
            ID
            label
            documentTypeConfig {
              ID
              code
            }
          }
        }
      }
    }
  }
`;
