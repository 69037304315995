import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const BaseText = styled(Typography)`
  ${props => {
    if (props.center) {
      return `
                margin: auto;
            `;
    }
  }};

  ${props => {
    if (props.required) {
      return `
      &:after {
          content: "*";
          color: ${props.theme.baseColor.status.warning};
          padding-left: 3px;
      }`;
    }
  }}

  ${props => {
    if (props.vertical) {
      return `
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      `;
    }
  }};

  font-weight: ${props => {
    if (props.bold) {
      return '600';
    }
    if (props.extraBold) {
      return '900';
    }
    if (!props.variant && !props.type) {
      return '400';
    }
  }};
  font-family: ${props => {
    if (props.semibold) {
      return props.theme.fonts.bold;
    }
    if (props.bold) {
      return props.theme.fonts.extrabold;
    }
    if (props.extraBold) {
      return props.theme.fonts.extrabold;
    }
    return props.theme.fonts.regular;
  }};
  letter-spacing: 0.025em;
  font-size: ${props => {
    if (props.fontSize) {
      return props.fontSize;
    }
    if (!props.variant) {
      return props.theme.fonts.body.fontSize;
    }
  }};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  word-break: ${props => (props.normalWord ? 'normal' : 'break-word')};
  text-transform: ${props => {
    if (props.uppercase) {
      return 'uppercase';
    }
    if (props.capitalize) {
      return 'capitalize';
    }
    if (props.lowercase) {
      return 'lowercase';
    }
    return 'normal';
  }};
  white-space: ${props => (props.multiline ? 'pre-wrap' : 'inherit')};
  ${props => {
    if (!props.fontSize) {
      return `
        @media screen and ${props.theme.device.mobileL} {
          font-size: ${props.theme.fonts.copySmall.fontSize};
        }
      `;
    }
  }}
  ${props => {
    if (props.type) {
      return props.theme.fonts[props.type];
    }
  }};
  color: ${props => {
    if (props.textColor === 'primary') {
      return props.theme.palette.primary.main;
    }
    if (props.textColor === 'secondary') {
      return props.theme.palette.secondary.main;
    }
    if (props.textColor === 'inherit') {
      return 'inherit';
    }
    if (props.textColor === 'neutral') {
      return props.theme.baseColor.neutral.main;
    }
    if (props.textColor === 'customLabel') {
      return props.theme.baseColor.neutral.gridBox;
    }
    if (props.theme.baseColor.status[props.textColor]) {
      return props.theme.baseColor.status[props.textColor];
    }
    if (props.theme.baseColor.text[props.textColor]) {
      return props.theme.baseColor.text[props.textColor];
    }
    if (props.theme.baseColor.filter[props.textColor]) {
      return props.theme.baseColor.filter[props.textColor];
    }
    if (props.theme.baseColor.neutral[props.textColor]) {
      return props.theme.baseColor.neutral[props.textColor];
    }
    return props.textColor;
  }};
`;

export const LabelText = styled(BaseText)`
  font-size: ${props => props.fontSize || props.theme.fonts.copySmall.fontSize};
`;

export const SmallTitle = styled(BaseText)`
  font-size: ${props =>
    props.fontSize || props.theme.fonts.copySmallTitle.fontSize};
`;
export const PageHeader = styled(BaseText)`
  font-size: ${props => props.fontSize || props.theme.fonts.h1.fontSize};
  font-weight: 600;
  margin-top: 13px;
  margin-bottom: 13px;
`;
