import {
  FETCH_REG_CONSENT_FORM_SIGNER,
  GET_ALL_OFFLINE_CONSENT_FORM_ACTION,
} from '../actionTypes';

import {
  GET_REG_CONSENT_FORM_SIGNER,
  CANCEL_CONSENT_FORM,
  SEND_CONSENT_FORM,
  CONSENT_FORM_COMPLETED,
  SAVE_REG_CONSENT_OFFLINE,
  GET_REG_CONSENT_UPLOAD_URL,
  GET_ALL_OFFLINE_CONSENT_FORM,
} from '../../models/consentModelsV2';
import { SkApolloRequest } from '../../../utils/apolloUtils';

const dispatchConsentFormList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_REG_CONSENT_FORM_SIGNER,
    key: 'regConsentFormSigners',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchgetAllOfflineConsentForm = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_ALL_OFFLINE_CONSENT_FORM_ACTION,
    key: 'regAllOfflineConsentForm',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getRegConsentSignerList = IDRegistration => async dispatch => {
  dispatchConsentFormList(dispatch);

  try {
    const response = await SkApolloRequest({
      params: {
        query: GET_REG_CONSENT_FORM_SIGNER,
        variables: { IDRegistration },
      },
      type: 'query',
    });

    if (response.success) {
      dispatchConsentFormList(dispatch, false, response.data);
    } else {
      dispatchConsentFormList(dispatch, false, null, response.error);
    }

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchConsentFormList(dispatch, false, null, ex);
    return { error: errorMessage };
  }
};

export const sendRegConsentForm = (
  IDRegistration,
  participants
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SEND_CONSENT_FORM,
        variables: {
          registrationId: IDRegistration,
          formParticipants: participants,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const cancelConsentForm = IDRegistration => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: CANCEL_CONSENT_FORM,
        variables: {
          registrationId: IDRegistration,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const completeConsentForm = IDRegistration => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: CONSENT_FORM_COMPLETED,
        variables: {
          registrationId: IDRegistration,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const saveRegConsentOffline = (
  registrationId,
  fileURL,
  fileName
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SAVE_REG_CONSENT_OFFLINE,
        variables: {
          registrationId,
          fileURL,
          fileName,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getRegistrationConsentUploadURL = (
  registrationID,
  file
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: GET_REG_CONSENT_UPLOAD_URL,
        variables: {
          registrationID,
          file,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getAllOfflineConsentFormAction = registrationId => async dispatch => {
  dispatchgetAllOfflineConsentForm(dispatch);

  try {
    const response = await SkApolloRequest({
      params: {
        query: GET_ALL_OFFLINE_CONSENT_FORM,
        variables: { registrationId },
      },
      type: 'query',
    });
    if (response.success) {
      dispatchgetAllOfflineConsentForm(dispatch, false, response.data);
    } else {
      dispatchgetAllOfflineConsentForm(dispatch, false, null, response.error);
    }

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchgetAllOfflineConsentForm(dispatch, false, null, ex);
    return { error: errorMessage };
  }
};
