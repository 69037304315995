import gql from 'graphql-tag';

export const ADD_NEW_SYSTEM_ANNOUNCEMENT = gql`
  mutation addSystemAnnouncement($announcement: SystemAnnouncementCreate!) {
    addSystemAnnouncement(announcement: $announcement) {
      ID
    }
  }
`;

export const UPDATE_SYSTEM_ANNOUNCEMENT = gql`
  mutation updateSystemAnnouncement(
    $ID: Int!
    $announcement: SystemAnnouncementUpdate!
  ) {
    updateSystemAnnouncement(ID: $ID, announcement: $announcement) {
      ID
    }
  }
`;

export const DELETE_SYSTEM_ANNOUNCEMENT = gql`
  mutation deleteSystemAnnouncement($ID: Int!) {
    deleteSystemAnnouncement(ID: $ID)
  }
`;

export const GET_ALL_SYSTEM_ANNOUNCEMENTS = gql`
  query getAllSystemAnnouncements(
    $filter: SystemAnnouncementFilter
    $pagination: Pagination
  ) {
    getAllSystemAnnouncements(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        title
        message
        from
        to
        fkSchool
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CURRENT_SYSTEM_ANNOUNCEMENT = gql`
  query getCurrentSystemAnnouncement {
    getCurrentSystemAnnouncement {
      ID
      title
      message
      from
      to
    }
  }
`;
