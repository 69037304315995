import React from 'react';
import { TextField } from '@material-ui/core';
import { size } from 'lodash';
import FieldWrapper from './FieldWrapper';

export const TextArea = ({
  label,
  autoFocus,
  name,
  value,
  disabled,
  onChange,
  placeholder,
  error,
  onBlur,
  required,
  labelStyle,
  rows,
  rowsMax,
  helperText = '',
  withTextCount = null,
  labelTextColor,
  ...props
}) => {
  const helperTextCount = withTextCount
    ? parseInt(withTextCount) - size(value)
    : 0;
  return (
    <FieldWrapper
      required={required}
      label={label}
      name={name}
      error={error}
      styles={labelStyle}
      disabled={disabled}
      labelTextColor={labelTextColor}
    >
      <TextField
        id={name}
        type="text"
        name={name}
        value={value}
        autoFocus={!disabled && Boolean(autoFocus)}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        multiline
        fullWidth
        rows={rows}
        rowsMax={rowsMax}
        helperText={
          withTextCount
            ? `${helperTextCount >= 0 ? helperTextCount : 0}/${withTextCount}`
            : helperText
        }
        FormHelperTextProps={
          withTextCount
            ? {
                className: 'w3-right-align sn2-formHelpherStyle',
              }
            : null
        }
        {...props}
      />
    </FieldWrapper>
  );
};

export default TextArea;
