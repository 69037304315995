import {
  FETCH_CENTRE_MANAGEMENT_CONFIG,
  CREATE_OR_UPDATE_CENTRE,
  CREATE_OR_UPDATE_CENTRE_DRAFT,
  DELETE_CENTRE_DRAFT,
  SUBMIT_CENTRE_DRAFT,
} from './actionTypes';
import {
  GET_CENTRE_MANAGEMENT_CONFIG,
  GET_MARKETING_CONSENT_UPLOAD_URL,
  UPDATE_MARKETING_CONSENT,
  CREATE_OR_UPDATE_SCHOOL_CENTRE_DRAFT,
  CREATE_OR_UPDATE_SCHOOL_CENTRE,
  DELETE_SCHOOL_CENTRE_DRAFT,
  CANCEL_SCHOOL_CENTRE_DRAFT,
  SUBMIT_SCHOOL_CENTRE_DRAFT,
} from '../models/centreManagementModel';
import { SkApolloRequest } from '../../utils/apolloUtils';

const dispatchFetchCentreManagementConfig = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_CENTRE_MANAGEMENT_CONFIG,
    key: 'centreManagementConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCreateOrUpdateCentre = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: CREATE_OR_UPDATE_CENTRE,
    key: 'createOrUpdateCentre',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCreateOrUpdateCentreDraft = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: CREATE_OR_UPDATE_CENTRE_DRAFT,
    key: 'createOrUpdateCentreDraft',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSubmitCentreDraft = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: SUBMIT_CENTRE_DRAFT,
    key: 'submitCentreDraft',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchDeleteCentreDraft = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: DELETE_CENTRE_DRAFT,
    key: 'deleteCentreDraft',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCancelCentreDraft = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: DELETE_CENTRE_DRAFT,
    key: 'cancelCentreDraft',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchCentreManagementConfig = schoolID => async dispatch => {
  dispatchFetchCentreManagementConfig(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_CENTRE_MANAGEMENT_CONFIG,
        variables: {
          fkSchool: schoolID,
        },
      },
      type: 'query',
    });

    if (result.success) {
      dispatchFetchCentreManagementConfig(dispatch, false, result.data);
    } else {
      dispatchFetchCentreManagementConfig(dispatch, false, null, result.error);
    }
  } catch (err) {
    dispatchFetchCentreManagementConfig(dispatch, false, null, {
      error: err.message,
    });
  }
};

export const createOrUpdateSchoolCentre = reqData => async dispatch => {
  dispatchCreateOrUpdateCentre(dispatch);
  try {
    const {
      fkSchool,
      fkCentre,
      centreData,
      postcodes,
      levels,
      addressData,
      workplaces,
      centreAutoPromotionConfigs,
      centreLevelProgram,
    } = reqData;

    const result = await SkApolloRequest({
      params: {
        query: CREATE_OR_UPDATE_SCHOOL_CENTRE,
        variables: {
          schoolID: fkSchool,
          centreID: fkCentre,
          centreData,
          postcodes,
          levels,
          addressData,
          workplaces,
          centreAutoPromotionConfigs,
          centreLevelProgram,
        },
        type: 'mutation',
      },
    });

    if (result.success) {
      dispatchCreateOrUpdateCentre(dispatch, false, result.data);
    } else {
      dispatchCreateOrUpdateCentre(dispatch, false, null, result.error);
    }
    return result;
  } catch (err) {
    dispatchCreateOrUpdateCentre(dispatch, false, null, {
      error: err.message,
    });
  }
};

export const getMarketingConsentDocumentUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_MARKETING_CONSENT_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateMarketingConsentDashboard = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_MARKETING_CONSENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const createOrUpdateSchoolCentreDraft = reqData => async dispatch => {
  dispatchCreateOrUpdateCentreDraft(dispatch);
  try {
    const {
      fkSchool,
      fkCentre,
      centreData,
      postcodes,
      levels,
      addressData,
      workplaces,
      centreAutoPromotionConfigs,
      centreLevelProgram,
    } = reqData;

    const result = await SkApolloRequest({
      params: {
        query: CREATE_OR_UPDATE_SCHOOL_CENTRE_DRAFT,
        variables: {
          schoolID: fkSchool,
          centreID: fkCentre,
          centreData,
          postcodes,
          levels,
          addressData,
          workplaces,
          centreAutoPromotionConfigs,
          centreLevelProgram,
        },
        type: 'mutation',
      },
    });

    if (result.success) {
      dispatchCreateOrUpdateCentreDraft(dispatch, false, result.data);
    } else {
      dispatchCreateOrUpdateCentreDraft(dispatch, false, null, result.error);
    }
    return result;
  } catch (err) {
    dispatchCreateOrUpdateCentreDraft(dispatch, false, null, {
      error: err.message,
    });
  }
};

export const deleteSchoolCentreDraft = reqData => async dispatch => {
  dispatchDeleteCentreDraft(dispatch);
  try {
    const { fkSchool, centreID } = reqData;

    const result = await SkApolloRequest({
      params: {
        query: DELETE_SCHOOL_CENTRE_DRAFT,
        variables: {
          schoolID: fkSchool,
          centreID,
        },
        type: 'mutation',
      },
    });

    if (result.success) {
      dispatchCreateOrUpdateCentreDraft(dispatch, false, result.data);
    } else {
      dispatchCreateOrUpdateCentreDraft(dispatch, false, null, result.error);
    }
    return result;
  } catch (err) {
    dispatchCreateOrUpdateCentreDraft(dispatch, false, null, {
      error: err.message,
    });
  }
};

export const cancelSchoolCentreDraft = reqData => async dispatch => {
  dispatchCancelCentreDraft(dispatch);
  try {
    const { fkSchool, centreID } = reqData;

    const result = await SkApolloRequest({
      params: {
        query: CANCEL_SCHOOL_CENTRE_DRAFT,
        variables: {
          schoolID: fkSchool,
          centreID,
        },
        type: 'mutation',
      },
    });

    if (result.success) {
      dispatchCancelCentreDraft(dispatch, false, result.data);
    } else {
      dispatchCancelCentreDraft(dispatch, false, null, result.error);
    }
    return result;
  } catch (err) {
    dispatchCancelCentreDraft(dispatch, false, null, {
      error: err.message,
    });
  }
};

export const submitSchoolCentreDraft = reqData => async dispatch => {
  dispatchSubmitCentreDraft(dispatch);
  try {
    const { fkSchool, centreID } = reqData;

    const result = await SkApolloRequest({
      params: {
        query: SUBMIT_SCHOOL_CENTRE_DRAFT,
        variables: {
          schoolID: fkSchool,
          centreID,
        },
        type: 'mutation',
      },
    });

    if (result.success) {
      dispatchSubmitCentreDraft(dispatch, false, result.data);
    } else {
      dispatchSubmitCentreDraft(dispatch, false, null, result.error);
    }
    return result;
  } catch (err) {
    dispatchSubmitCentreDraft(dispatch, false, null, {
      error: err.message,
    });
  }
};

export default fetchCentreManagementConfig;
