import { LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_COLOR, BASE_COLOR } from 'theme/variables';

const useStyles = makeStyles({
  marginBottom10: {
    marginBottom: '10px',
  },
  errorOutlineIcon: {
    color: 'red',
    height: 66,
    width: 66,
  },
  checkCircleIcon: {
    color: '#12B886',
  },
  nextButton: {
    backgroundColor: PRIMARY_COLOR,
    textTransform: 'none',
  },
  submitOrNextButton: {
    backgroundColor: PRIMARY_COLOR,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: PRIMARY_COLOR,
      textTransform: 'none',
    },
  },
  submitOrNextButtonDisabled: {
    backgroundColor: '#ABABAB',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ABABAB',
      textTransform: 'none',
    },
  },
  previousButton: {
    borderColor: PRIMARY_COLOR,
    textTransform: 'none',
    marginRight: 10,
  },
  sectionNavButtonDesktop: {
    width: '140px',
  },
  sectionNavButtonMobile: {
    width: '125px',
  },
  surveyContainer: {
    minHeight: '100vh',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: PRIMARY_COLOR,
  },
  innerSurveyContainer: {
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
  welcomeScreenContainer: {
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: PRIMARY_COLOR,
  },
  welcomeScreenInnerContainer: {
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 20,
  },
  dashedDivider: {
    borderBottom: `1px dashed ${BASE_COLOR.neutral.mediumGrey}`,
  },
  marginBottom35: { marginBottom: 35 },
  marginLeft10: { marginLeft: '10px' },
  marginTop15: { marginTop: 15 },
  marginTop20: { marginTop: 20 },
  marginTop10: { marginTop: 10 },
  marginLeft0: {
    marginLeft: 0,
  },
  marginRight5: { marginRight: 5 },
  marginLeft15: { marginLeft: 15 },
  marginTopMinus15: { marginTop: -15 },
  marginTopMinus25: { marginTop: -25 },
  progressBarText: { color: '#12B886', fontWeight: 700 },
  textAlignRight: { textAlign: 'right' },
  paddingTopAndBottom16: { padding: '16px 0px' },
  errorText: { color: 'red', overflowWrap: 'break-word' },
});

export const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 5,
  },
  colorPrimary: {
    backgroundColor: '#CFCFCF',
  },
  bar: {
    backgroundColor: '#12B886',
  },
}))(LinearProgress);

export default useStyles;
