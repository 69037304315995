import {
  SAVE_UPLOADED_REG_DOCUMENTS,
  FETCH_DOCUMENTS_TO_UPLOAD,
} from '../actionTypes';

import {
  SUBMIT_REG_FORM,
  REVERT_REG_DOCUMENT,
  REMOVE_REG_DOCUMENT,
  APPROVE_REG_DOCUMENT,
  REJECT_REG_DOCUMENT,
  SKIP_REG_DOCUMENT,
  SAVE_REGISTRATION_DOCUMENT,
  GET_REG_DOCUMENT_UPLOAD_URL,
  GET_REGISTRATION_DOCUMENT_LIST,
  SEND_REG_DOCUMENT_NOTIFICATION,
} from '../../models/documentModelsV2';
import { SkApolloRequest } from '../../../utils/apolloUtils';

const dispatchDocumentUploadList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_DOCUMENTS_TO_UPLOAD,
    key: 'documentsToUpload',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSaveUploadedDocuments = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SAVE_UPLOADED_REG_DOCUMENTS,
    key: 'savedRegGiroDocuments',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getUploadDocumentList = IDRegistration => async dispatch => {
  dispatchDocumentUploadList(dispatch);

  try {
    const response = await SkApolloRequest({
      params: {
        query: GET_REGISTRATION_DOCUMENT_LIST,
        variables: { IDRegistration },
      },
      type: 'query',
    });

    if (response.success) {
      dispatchDocumentUploadList(dispatch, false, response.data);
    } else {
      dispatchDocumentUploadList(dispatch, false, null, response.error);
    }

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchDocumentUploadList(dispatch, false, null, ex);
    return { error: errorMessage };
  }
};

export const getRegistrationDocumentUploadURL = (
  IDRegistration,
  file
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: GET_REG_DOCUMENT_UPLOAD_URL,
        variables: {
          IDRegistration,
          file,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const saveUploadedRegDocument = (
  IDRegistration,
  uploads
) => async dispatch => {
  dispatchSaveUploadedDocuments(dispatch);

  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SAVE_REGISTRATION_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentUploads: uploads,
        },
      },
      type: 'mutation',
    });

    if (response.success) {
      dispatchSaveUploadedDocuments(dispatch, false, response.data);
    } else {
      dispatchSaveUploadedDocuments(dispatch, false, null, response.error);
    }

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchSaveUploadedDocuments(dispatch, false, null, ex);
    return { error: errorMessage };
  }
};

export const removeRegUploadedDocument = (
  IDRegistration,
  IDDocument,
  remarks
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: REMOVE_REG_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentId: IDDocument,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const approveRegDocument = (
  IDRegistration,
  IDDocumentType,
  remarks
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: APPROVE_REG_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentTypeId: IDDocumentType,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const rejectRegDocument = (
  IDRegistration,
  IDDocumentType,
  remarks
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: REJECT_REG_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentTypeId: IDDocumentType,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const skipRegDocument = (
  IDRegistration,
  IDDocumentType,
  remarks
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SKIP_REG_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentTypeId: IDDocumentType,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const revertRegDocument = (
  IDRegistration,
  IDDocumentType,
  remarks
) => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: REVERT_REG_DOCUMENT,
        variables: {
          registrationId: IDRegistration,
          documentTypeId: IDDocumentType,
          remarks,
        },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const submitRegForm = IDRegistration => async () => {
  try {
    const response = await SkApolloRequest({
      params: {
        mutation: SUBMIT_REG_FORM,
        variables: { registrationId: IDRegistration },
      },
      type: 'mutation',
    });

    return response;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const sendDocumentNotificationToParent = IDRegistration => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: SEND_REG_DOCUMENT_NOTIFICATION,
        variables: { IDRegistration },
      },
      type: 'mutation',
    });
  } catch (ex) {
    throw ex;
  }
};
