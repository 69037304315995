import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Checkbox, Popper } from '@material-ui/core';
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
  Cancel,
} from '@material-ui/icons';
import styled from 'styled-components';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import FieldWrapper from './FieldWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiAutocomplete-listbox': {
      '& li': {
        backgroundColor: theme.baseColor.neutral.white,
      },
      '& :hover': {
        backgroundColor: theme.baseColor.filter.tab,
        color: theme.baseColor.neutral.white,
      },
    },
  },
  checkbox: {
    backgroundColor: 'transparent !important',
    '& :hover': {
      color: 'rgba(0, 0, 0, 0.54) !important',
      backgroundColor: 'transparent !important',
    },
  },
}));

const CloseStyledIcon = styled(Cancel)`
  color: white;
  &:hover {
    color: white;
  }
`;

const AutoCompleteMultiSelect = ({
  options = [],
  optionLabelField = 'description',
  width = 300,
  placeholder = 'All',
  required = false,
  label = '',
  name = '',
  error = null,
  labelStyle = { marginBottom: '-19px' },
  disabled,
  labelTextColor,
  textFieldStyles = { marginBottom: 0 },
  theme,
  fullWidth = true,
  style = {},
  value = [],
  onChange = () => {},
  wrapperPadding,
  showLoader = false,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      value={value}
      onChange={(_, options) => onChange(options)}
      options={options}
      getOptionLabel={option => option[optionLabelField]}
      PopperComponent={props => (
        <Popper {...props} className={classes.root} placement="bottom" />
      )}
      ChipProps={{
        style: {
          backgroundColor: theme.baseColor.filter.tab,
          color: 'white',
          fontWeight: 500,
        },
        deleteIcon: <CloseStyledIcon />,
      }}
      fullWidth
      disableCloseOnSelect
      disableClearable
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={<CheckBoxIcon />}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
            className="sn2-checkbox"
            disableRipple
            classes={{ root: classes.checkbox }}
          />
          {option[optionLabelField]}
        </>
      )}
      style={Object.assign({}, style, !fullWidth && { width })}
      renderInput={params => (
        <FieldWrapper
          required={required}
          label={label}
          name={name}
          error={error}
          styles={labelStyle}
          disabled={disabled}
          labelTextColor={labelTextColor}
          wrapperPadding={wrapperPadding}
        >
          <TextField
            {...params}
            name={name}
            margin="normal"
            style={textFieldStyles}
            placeholder={placeholder}
            disabled={disabled}
          />
        </FieldWrapper>
      )}
      loading={showLoader}
    />
  );
};

export default withTheme(AutoCompleteMultiSelect);
