import { has } from 'lodash';
import {
  FETCH_ALL_ROLES,
  FETCH_ALL_STAFF,
  SEARCH_STAFF,
  FETCH_ALL_MODULE_CATEGORY,
  SEARCH_USER_BY_NAME,
  SEARCH_USER_BY_EMAIL,
  FETCH_ROLE_FOR_CREATE_USER,
  FETCH_ALL_REPORTING_STAFF,
  FETCH_ALL_STAFF_V2,
  SEARCH_STAFF_V2,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FIND_ALL_STAFF,
  FIND_ROLE_BY_FK_SCHOOL,
  GET_AD_USERS_BY_NAME,
  GET_AD_USERS_BY_EMAIL,
  FIND_ALL_MODULE_CATEGORY,
  ADD_ROLE,
  ADD_NEW_STAFF,
  UPDATE_STAFF,
  REMOVE_STAFF,
  UPDATE_ROLE,
  REMOVE_ROLE,
  ACTIVATE_ROLE,
  ACTIVATE_STAFF,
  ROLE_FOR_CREATE_USER,
  FIND_ALL_REPORTING_OFFICER,
  FIND_ALL_STAFF_V2,
} from '../models/userAndRoleModels';
import { parseGraphQLError } from '../../utils';

const dispatchSearchByName = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SEARCH_USER_BY_NAME,
    key: 'searchByNameResults',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchByEmail = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SEARCH_USER_BY_EMAIL,
    key: 'searchByEmailResults',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchStaff = (
  dispatch,
  inProgress = true,
  data = {},
  error = null,
  isSearching
) => {
  dispatch({
    type: isSearching ? SEARCH_STAFF : FETCH_ALL_STAFF,
    key: 'allStaff',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchStaffV2 = (
  dispatch,
  inProgress = true,
  data = {},
  error = null,
  isSearching
) => {
  dispatch({
    type: isSearching ? SEARCH_STAFF_V2 : FETCH_ALL_STAFF_V2,
    key: 'allStaffV2',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchReportingStaff = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_REPORTING_STAFF,
    key: 'allReportingStaff',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllRoles = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_ROLES,
    key: 'findAllRoles',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRolesForUserCreation = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ROLE_FOR_CREATE_USER,
    key: 'findRolesForUserCreation',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllModuleCategory = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_MODULE_CATEGORY,
    key: 'findAllModuleCategory',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const addNewStaff = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_NEW_STAFF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateStaff = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_STAFF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    console.error('Error updating staff: ', ex.message);
    return parseGraphQLError(ex);
  }
};

export const removeStaff = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_STAFF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    console.error('Error removing staff: ', ex.message);
    return parseGraphQLError(ex);
  }
};

export const addNewRole = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_ROLE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateRole = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_ROLE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    console.error('Error occurred while updating staff', ex.message);
    return parseGraphQLError(ex);
  }
};
export const removeRole = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_ROLE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    console.error('Error removing role: ', ex.message);
    return parseGraphQLError(ex);
  }
};
export const activateRole = reqData => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: ACTIVATE_ROLE,
        variables: reqData,
      },
      type: 'mutation',
    });
  } catch (ex) {
    console.error('Error while activating role: ', ex.message);
    return parseGraphQLError(ex);
  }
};
export const activateStaff = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ACTIVATE_STAFF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    console.error('Error while activating staff: ', ex.message);
    return parseGraphQLError(ex);
  }
};
export const findAllRoles = (reqData, key = 'list') => async dispatch => {
  dispatchAllRoles(dispatch, true, { key });
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ROLE_BY_FK_SCHOOL,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllRoles(dispatch, false, { data: data.data, key });
    } else {
      dispatchAllRoles(dispatch, false, { key }, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllRoles(
      dispatch,
      false,
      { key },
      { error: parseGraphQLError(ex) }
    );
    return parseGraphQLError(ex);
  }
};

export const findRolesForUserCreation = (
  reqData,
  key = 'list'
) => async dispatch => {
  dispatchRolesForUserCreation(dispatch, true, { key });
  try {
    const data = await SkApolloRequest({
      params: {
        query: ROLE_FOR_CREATE_USER,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchRolesForUserCreation(dispatch, false, {
        data: data.data,
        key,
      });
    } else {
      dispatchRolesForUserCreation(dispatch, false, { key }, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRolesForUserCreation(
      dispatch,
      false,
      { key },
      { error: parseGraphQLError(ex) }
    );
    return parseGraphQLError(ex);
  }
};

export const fetchAllStaff = reqData => async dispatch => {
  const isSearching = has(reqData, 'searchString');
  dispatchFetchStaff(dispatch, true, {}, null, isSearching);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_STAFF,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchStaff(
        dispatch,
        false,
        { data: data.data },
        null,
        isSearching
      );
    } else {
      dispatchFetchStaff(dispatch, false, null, data.error, isSearching);
    }

    return data.data;
  } catch (ex) {
    dispatchFetchStaff(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchAllStaffV2 = reqData => async dispatch => {
  const isSearching = has(reqData, 'searchString');
  dispatchFetchStaffV2(dispatch, true, {}, null, isSearching);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_STAFF_V2,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchStaffV2(
        dispatch,
        false,
        { data: data.data },
        null,
        isSearching
      );
    } else {
      dispatchFetchStaffV2(dispatch, false, null, data.error, isSearching);
    }

    return data.data;
  } catch (ex) {
    dispatchFetchStaffV2(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchReportingStaff = reqData => async dispatch => {
  dispatchFetchReportingStaff(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_REPORTING_OFFICER,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchReportingStaff(dispatch, false, { data: data.data });
    } else {
      dispatchFetchReportingStaff(dispatch, false, null, data.error);
    }
    return data.data;
  } catch (ex) {
    dispatchFetchReportingStaff(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchSearchResultsByName = reqData => async dispatch => {
  dispatchSearchByName(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_AD_USERS_BY_NAME,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchSearchByName(dispatch, false, { data: data.data });
    } else {
      dispatchSearchByName(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchSearchByName(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchSearchResultsByEmail = reqData => async dispatch => {
  dispatchSearchByEmail(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_AD_USERS_BY_EMAIL,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchSearchByEmail(dispatch, false, { data: data.data });
    } else {
      dispatchSearchByEmail(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchSearchByEmail(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchAllModuleCategory = reqData => async dispatch => {
  dispatchAllModuleCategory(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_MODULE_CATEGORY,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllModuleCategory(dispatch, false, { data: data.data });
    } else {
      dispatchAllModuleCategory(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllModuleCategory(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export default findAllRoles;
