import gql from 'graphql-tag';

export const FIND_ALL_APPOINTMENTS = gql`
  query findAllCalendarEvent($IDCentre: Int!, $year: Int, $month: Int) {
    findAllCalendarEvent(IDCentre: $IDCentre, year: $year, month: $month) {
      totalCount
      data {
        ID
        fkCentre
        fkUser
        title
        description
        meetingDuration
        from
        to
        start
        end
        recurNumber
        recurType
        recurOnDays
        available
        active
        date
        type
        visits(
          filter: { active: true, status: [{ neq: ["cancelled", "rejected"] }] }
        ) {
          data {
            ID
            user {
              firstname
              lastname
              mobilePhone
            }
            visitFrom
            visitTo
            completed
            status
            enquiry {
              ID
              fkChild
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SCHOOL_HOLIDAYS = `{
  totalCount
  data {
    ID
    title
    description
    from
    to
    isPublic
    centreHolidays {
      totalCount
    }
  }
}`;

export const ALL_EVENTS = `{
  data {
      ID
      title
      description
      from
      to
      active
      date
      eventType
      status
      location
      updatedAt
      }
}`;

export const ALL_STAFF_LEAVES = `{
  totalCount
  data {
  leave {
      ID
      child {
      ID
      firstname
      lastname
      }
      userByFkUser {
      ID
      firstname
      lastname
      }
      status
      approveRequired
      userByApprovedBy {
      ID
      firstname
      lastname
      }
      remark
      leaveType {
      leaveTypeCode
      halfDayApplicable
      description
      applicableToStaff
      applicableToChild
      }
      from
      to
  }
  start
  end
  halfDay
  }
}`;

export const ALL_APPOINTMENTS = `{
  totalCount
  data {
    ID
    fkCentre
    fkUser
    title,
    description,
    meetingDuration
    from
    to
    start
    end
    recurNumber
    recurType,
    recurOnDays,
    available
    active
    date
    type,
    visits(filter: {active: true, status: [{neq: ["cancelled","rejected"]}]}) {
        data {
            ID
            user {
                firstname
                lastname
                mobilePhone
            },
            visitFrom
            visitTo
            completed
            status
            enquiry {
                ID
                fkChild
            }
        }
    },
    createdAt
    updatedAt
  }
}`;

export default SCHOOL_HOLIDAYS;
