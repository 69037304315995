import {
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
  GET_ENROLLMENT_PLANNING_UTILISATION_LOADING,
  GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS,
  GET_ENROLLMENT_PLANNING_UTILISATION_ERROR,
  GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

/* eslint-disable import/prefer-default-export */
export const listEnrollmentPlanningRequests = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const listEnrollmentPlanningUtilisation = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case GET_ENROLLMENT_PLANNING_UTILISATION_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case GET_ENROLLMENT_PLANNING_UTILISATION_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const listEnrollmentPlanningActivityLog = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
