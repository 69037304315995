import SkRequest from './BaseRequest';
import { MutationBuilder, QueryBuilder } from '../queryBuilder';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FETCH_CLASS_HEALTH_DATA,
  FETCH_CHILD_HEALTH_DATA,
  FETCH_DOCTOR_ALLERGY_INFO,
  FETCH_VISUAL_CHECK_INFO,
} from './actionTypes';
import { GET_CLASS_ATTENDANCE } from '../models/attendanceManagementModels';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

_mQueryBuilder.setNewQuery('getChildAttendances', {
  total: 'number',
  childAttendances: {
    child: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    injury: {
      id: 'number',
      childId: 'number',
    },
    date: 'datetime',
    health: {
      id: 'number',
      temperature: 'number',
      type: 'string',
      active: 'boolean',
      time: 'datetime',
    },
  },
});

_mQueryBuilder.setNewQuery('getChildHealthInfo', {
  isImmunized: 'boolean',
  immunizationDetails: 'string',
  updatedAt: 'datetime',
  familyDoctorDetails: {
    name: 'string',
    remarks: 'string',
    contactNumber: 'string',
    countryCode: 'string',
    clinicName: 'string',
    clinicBuilding: 'string',
    clinicCity: 'string',
    clinicFloor: 'string',
    clinicUnit: 'string',
    clinicCountry: 'string',
    clinicPostalCode: 'string',
  },
  medicalCondition: {
    disease: 'string',
    exists: 'boolean',
  },
});

_mQueryBuilder.setNewQuery('getChildHealthInfoForDay', {
  id: 'number',
  childId: 'number',
  schoolId: 'number',
  injuries: {
    point: {
      top: 'string',
      left: 'string',
    },
    remarks: 'string',
    injuryType: 'string',
    reportedBy: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    reportedAt: 'datetime',
  },
});

_mMutationBuilder.setNewMutation('updateChildHealthInfo', {
  isImmunized: 'boolean',
});

_mMutationBuilder.setNewMutation('removeDocumentByStaff', {
  ID: 'number',
});

const dispatchClassHealthDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_HEALTH_DATA,
    key: 'classHealthDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};
const dispatchChildHealthDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_HEALTH_DATA,
    key: 'childHealthDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchDoctorDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_DOCTOR_ALLERGY_INFO,
    key: 'doctorDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchVisisbilityDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_VISUAL_CHECK_INFO,
    key: 'visualCheckDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchClassHealthDetails = reqData => async dispatch => {
  dispatchClassHealthDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CLASS_ATTENDANCE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassHealthDetails(dispatch, false, data.data);
    } else {
      dispatchClassHealthDetails(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchClassHealthDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchChildHealthDetails = reqData => async dispatch => {
  dispatchChildHealthDetails(dispatch);
  const query = _mQueryBuilder.getQueryAsString('getChildAttendances', reqData);
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchChildHealthDetails(dispatch, false, data.data);
    } else {
      dispatchChildHealthDetails(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchChildHealthDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchDoctorDetails = reqData => async dispatch => {
  dispatchDoctorDetails(dispatch);
  const query = _mQueryBuilder.getQueryAsString('getChildHealthInfo', reqData);
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchDoctorDetails(dispatch, false, { data: data.data });
    } else {
      dispatchDoctorDetails(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchDoctorDetails(dispatch, false, null, { error: ex.message });
  }
};

export const fetchVisualCheckDetails = reqData => async dispatch => {
  dispatchVisisbilityDetails(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'getChildHealthInfoForDay',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchVisisbilityDetails(dispatch, false, { data: data.data });
    } else {
      dispatchVisisbilityDetails(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchVisisbilityDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const updateDoctorInformation = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'updateChildHealthInfo',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const removeHealthDocument = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'removeDocumentByStaff',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export default fetchClassHealthDetails;
