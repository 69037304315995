import { ResponseBuilder } from './response';

export class MutationBuilder extends ResponseBuilder {
  constructor() {
    super();
    this._mutations = {};
  }

  /**
   * Save a mutation with a key
   * @param {String} name the key of mutation
   * @param {JSON} responseObj definition of the response params
   */
  setNewMutation(name, responseObj) {
    const newRespBuildr = new ResponseBuilder();
    newRespBuildr.setResponseParams(responseObj);
    this._mutations[name] = newRespBuildr;
  }

  /**
   * Get bulk mutation request string for defined mutations.
   * Each key must match the defined mutation.
   * Value for each key will be an array of JSON objects which are the request params.
   * @param {JSON} obj the json with keys and array of request params as values
   */
  getBulkMutationAsString(obj = {}, sameSchema = true) {
    const mKeys = Object.keys(obj);
    let retStr = 'mutation { ';

    mKeys.forEach(eachMutKey => {
      const mutParams = obj[eachMutKey];

      if (sameSchema) {
        mutParams.forEach((eachMutParams, idx) => {
          const mutKey = eachMutParams.key;
          delete eachMutParams.key;
          retStr += `${eachMutKey}${
            mutKey || idx + 1
          }: ${this.getMutationAsString(eachMutKey, eachMutParams, true)}`;
        });
      } else if (Array.isArray(mutParams)) {
        mutParams.forEach((mp, idx) => {
          retStr += `${eachMutKey}_${idx}: ${this.getMutationAsString(
            eachMutKey,
            mp,
            true
          )}`;
        });
      } else {
        retStr += `${eachMutKey}: ${this.getMutationAsString(
          eachMutKey,
          mutParams,
          true
        )}`;
      }
    });

    return `${retStr} }`;
  }

  /**
   * Get a mutation as a query string that is recognised by graphql.
   * @param {String} name the key under which the mutation is saved
   * @param {JSON} queryParams the request params in JSON format
   */
  getMutationAsString(name, queryParams = {}, forBulk = false) {
    this._mutations[name]._requestParamsToString(queryParams);
    const qry = this._mutations[name];
    let retStr = '';

    if (forBulk) {
      retStr = `${name} ( ${
        qry._reqParams
      } ) ${qry.getResponseParamsAsString()}`;
    } else {
      retStr = `mutation { ${name} ( ${
        qry._reqParams
      } ) ${qry.getResponseParamsAsString()} }`;
    }

    return retStr;
  }

  /**
   * Enable the response params for a particular mutation.
   * Params which are not mentioned are disabled.
   * @param {String} name the key of the mutation for which to enable params
   * @param {JSON} obj the params that need to be enabled
   */
  enableResponseParams(name, obj) {
    this._mutations[name].enableParams(obj);
  }

  /**
   * Disable the response params for a particular mutation.
   * Params which are not mentioned are enabled.
   * @param {String} name the key of the mutation for which to disable params
   * @param {JSON} obj the params that need to be disabled
   */
  disableResponseParams(name, obj) {
    this._mutations[name].disableParams(obj);
  }

  /**
   * Remove a mutation by key
   * @param {String} name the key of mutation to remove
   */
  removeMutation(name) {
    delete this._mutations[name];
  }
}

export default MutationBuilder;
