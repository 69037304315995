import React from 'react';
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
  StyledExpansionPanelDetails,
  StyledExpandMoreIcon,
} from './style';

export default ({
  expansionPanelSummary,
  expansionPanelDetails,
  defaultExpanded,
  fullWidth,
  summaryRootPadding,
  iconColor,
  panelDetailsPadding,
  withBorder = false,
  withBorderBottom = false,
  noMargin = false,
  onChange = () => null,
  expanded,
  disabled = false,
  backgroundColor,
  displayIcon = true,
  expansionSummaryOnClick,
}) => (
  <StyledExpansionPanel
    noMargin={noMargin}
    elevation={defaultExpanded ? 0 : 1}
    defaultExpanded={defaultExpanded}
    backgroundColor={backgroundColor}
    disabled={disabled}
    expanded={expanded}
    onChange={onChange}
  >
    <StyledExpansionPanelSummary
      withBorder={withBorder}
      withBorderBottom={withBorderBottom}
      expandIcon={<StyledExpandMoreIcon iconColor={iconColor} />}
      styleProps={{ defaultExpanded, summaryRootPadding }}
      displayIcon={displayIcon}
      onClick={expansionSummaryOnClick}
    >
      {expansionPanelSummary}
    </StyledExpansionPanelSummary>
    <StyledExpansionPanelDetails
      fullWidth={fullWidth}
      panelDetailsPadding={panelDetailsPadding}
    >
      {expansionPanelDetails}
    </StyledExpansionPanelDetails>
  </StyledExpansionPanel>
);
