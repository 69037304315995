import get from 'lodash/get';
import {
  FETCH_CLASS_HEALTH_DATA,
  FETCH_CHILD_HEALTH_DATA,
  FETCH_DOCTOR_ALLERGY_INFO,
  FETCH_VISUAL_CHECK_INFO,
  FETCH_HEALTH_DOCUMENTS,
} from '../actions/actionTypes';

/**
 *
 *Example Data
 *
 * {
 *   "data": {
 *     "getAttendanceByClass": {
 *       "total": 137,
 *       "attendances": [
 *         {
 *           "child": {
 *             "ID": 8,
 *             "firstname": "",
 *             "lastname": "Alson Lim Yu Feng(Lin Yufeng)",
 *             "childClassAt": {
 *               "class": {
 *                 "label": "K1 Class"
 *               }
 *             },
 *             "childRelations": {
 *               "data": [
 *                 {
 *                   "parent": {
 *                     "firstname": "",
 *                     "lastname": "Tan Wei Nee (Chen Weini)",
 *                     "relation": ""
 *                   },
 *                   "role": {
 *                     "name": "guardian_admin_access",
 *                     "label": "Guardian Admin Access"
 *                   },
 *                   "fkUser": 475,
 *                   "fkRelation": 979
 *                 },
 *                 {
 *                   "parent": {
 *                     "firstname": "",
 *                     "lastname": "Lim Hock Chuan (Lin Fuquan)",
 *                     "relation": ""
 *                   },
 *                   "role": {
 *                     "name": "guardian_all_access",
 *                     "label": "Guardian All Access"
 *                   },
 *                   "fkUser": 476,
 *                   "fkRelation": 980
 *                 }
 *               ]
 *             }
 *           },
 *           "date": "2020-12-12 00:00:00",
 *           "present": false,
 *           "status": "absent",
 *           "attendances": [],
 *           "health": [],
 *           "healthChecks": []
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const classHealthDetails = (state = {}, action) => {
  if (action.type === FETCH_CLASS_HEALTH_DATA) {
    const data = get(action, 'value.data.getAttendanceByClass');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getChildAttendances": {
 *       "total": 6,
 *       "childAttendances": [
 *         {
 *           "child": {
 *             "ID": 8,
 *             "firstname": "",
 *             "lastname": "Alson Lim Yu Feng(Lin Yufeng)"
 *           },
 *           "date": "2020-09-22 00:00:00",
 *           "present": true,
 *           "remarks": null,
 *           "health": [],
 *           "attendances": [
 *             {
 *               "id": 132433,
 *               "temperature": 0,
 *               "remarks": "",
 *               "type": "check_in",
 *               "active": true,
 *               "time": "2020-09-22 08:32:45"
 *             },
 *           ]
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const childHealthDetails = (state = {}, action) => {
  if (action.type === FETCH_CHILD_HEALTH_DATA) {
    const data = get(action, 'value.data.getChildAttendances');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const doctorDetails = (state = {}, action) => {
  if (action.type === FETCH_DOCTOR_ALLERGY_INFO) {
    const data = get(action, 'value.data.data.getChildHealthInfo');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const visualCheckDetails = (state = {}, action) => {
  if (action.type === FETCH_VISUAL_CHECK_INFO) {
    const data = get(action, 'value.data.data.getChildHealthInfoForDay');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const healthDocs = (state = {}, action) => {
  if (action.type === FETCH_HEALTH_DOCUMENTS) {
    const data = get(action, 'value.data.data.getChildDocuments');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export default classHealthDetails;
