import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_TRANSFER_DRAFTS = gql`
  query getAllTransferDrafts(
    $schoolID: Int!
    $sourceCentreIDs: [Int!]
    $destCentreIDs: [Int!]
    $fkChild: Int
    $effectiveFrom: Datetime
    $effectiveTo: Datetime
    $filter: TransferDraftFilter
    $pagination: Pagination
  ) {
    getAllTransferDrafts(
      schoolID: $schoolID
      sourceCentreIDs: $sourceCentreIDs
      destCentreIDs: $destCentreIDs
      fkChild: $fkChild
      effectiveFrom: $effectiveFrom
      effectiveTo: $effectiveTo
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        fkChild
        ID
        child {
          firstname
          lastname
          birthCertificate
        }
        status
        additionalStatus
        destinationCentreFkFeeTier
        transferDraftItem {
          sourceCentre
          destinationCentre
          program {
            label
          }
          level {
            label
          }
          createdAt
          effectiveDate
          notificationDate
        }
      }
    }
  }
`;

export const GET_ALL_AVAILABLE_VACANCY = gql`
  query getAvailableVacancy(
    $levelID: Int!
    $programID: Int!
    $centreID: Int!
    $enrolmentDate: Datetime!
  ) {
    getAvailableVacancy(
      levelID: $levelID
      programID: $programID
      centreID: $centreID
      enrolmentDate: $enrolmentDate
    )
  }
`;

export const GET_CENTRE_AVAILABLE_VACANCY = gql`
  query vacancyAvailableInCentreForTransfer(
    $childID: Int!
    $levelID: Int!
    $programID: Int!
    $centreID: Int!
    $effectiveDate: Datetime!
  ) {
    vacancyAvailableInCentreForTransfer(
      childID: $childID
      levelID: $levelID
      programID: $programID
      centreID: $centreID
      effectiveDate: $effectiveDate
    )
  }
`;

export const GET_LEAD_OFFERED_FOR_TRANSFER = gql`
  query hasLeadBeenOfferedForTransferDraft($transferDraftID: Int!) {
    hasLeadBeenOfferedForTransferDraft(transferDraftID: $transferDraftID)
  }
`;

export const GET_FEE_TIERS_BY_CHILD_CENTRE = gql`
  query getFeeTiersByLevelByCentre(
    $levelID: Int!
    $centreID: Int!
    $date: Datetime!
  ) {
    getFeeTiersByLevelByCentre(
      levelID: $levelID
      centreID: $centreID
      date: $date
    ) {
      data {
        ID
        fkGroup
        fkCentre
        label
        effectiveForRegistrationFrom
        effectiveForRegistrationTo
      }
    }
  }
`;

export const SUBMIT_NEW_TRANSFER_REQUEST = gql`
  mutation submitNewTransferRequest(
    $schoolID: Int!
    $fkChild: Int!
    $IDLevel: Int!
    $IDProgram: Int
    $toCentre: Int!
    $toCentreFeeTierID: Int
    $effectiveFrom: Datetime!
    $notificationDate: Datetime!
    $reason: Int!
    $remarks: String!
    $otherReason: String
    $bypassExceeding: Boolean
  ) {
    submitNewTransferRequest(
      schoolID: $schoolID
      fkChild: $fkChild
      IDLevel: $IDLevel
      IDProgram: $IDProgram
      toCentre: $toCentre
      toCentreFeeTierID: $toCentreFeeTierID
      effectiveFrom: $effectiveFrom
      notificationDate: $notificationDate
      reason: $reason
      remarks: $remarks
      otherReason: $otherReason
      bypassExceeding: $bypassExceeding
    ) {
      ID
    }
  }
`;
