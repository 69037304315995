import { get, set } from 'lodash';
import {
  GET_WAITLIST_PARAMETERS,
  GET_WAITLIST_PARAMETERS_SUCCESS,
  GET_WAITLIST_PARAMETERS_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  data: [],
  error: null,
  isLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_WAITLIST_PARAMETERS: {
      return Object.assign({}, state, action.value);
    }
    case GET_WAITLIST_PARAMETERS_SUCCESS: {
      let allTags = get(action.value, 'data.data.findAllTags.data', []);
      allTags = allTags.filter(t => t.label);
      set(action.value, 'data.data.findAllTags.data', allTags);
      return Object.assign({}, state, action.value);
    }
    case GET_WAITLIST_PARAMETERS_ERROR: {
      return Object.assign({}, state, action.value);
    }

    default: {
      return state;
    }
  }
};
