import gql from 'graphql-tag';

export const NOTIFICATION_HISTORY = gql`
  query getNotifications(
    $notificationFilter: NotificationFilter
    $pagination: Pagination
  ) {
    getNotifications(
      notificationFilter: $notificationFilter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        navigate
        channel
        content {
          title
          body
        }
        meta {
          iconType
          webLink
        }
        read
        createdAt
      }
    }
  }
`;

export const MARK_NOTIFICATIONS = gql`
  mutation markNotifications($notificationIds: [Int!]!, $read: Boolean!) {
    markNotifications(notificationIds: $notificationIds, read: $read)
  }
`;

export default MARK_NOTIFICATIONS;
