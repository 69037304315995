import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_WAITLIST = gql`
  query getWaitList(
    $filter: EnquiryFilter
    $tag: FilterOnField
    $preferEnrolDate: Int
    $filterByVacancy: Boolean
    $race: String
    $pagination: Pagination
  ) {
    getWaitList(
      filter: $filter
      tag: $tag
      preferEnrolDate: $preferEnrolDate
      filterByVacancy: $filterByVacancy
      race: $race
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        isTransferEnquiry
        fkUser
        fkChild
        fkCentre
        fkLevel
        fkProgram
        firstname
        lastname
        fkEnquiryState
        householdIncomeRange
        vacancyAvailable
        createdAt
        active
        waitlistStatus
        paymentStatus
        giroStatus
        enrollmentPlan
        centre {
          label
        }
        child {
          firstname
          lastname
          dateOfBirth
          birthCertificate
          nationality
          race
        }
        level {
          label
          fromMonth
          toMonth
        }
        program {
          label
          from
          to
        }
        user {
          firstname
          lastname
          email
          mobilePhoneCountryCode
          mobilePhone
          addresses {
            data {
              address
              postcode
            }
          }
          parent {
            identificationNo
            monthlyIncome
          }
        }
        enquiryStateHistories(
          pagination: { perPage: 1, page: 1, sort: ["-createdAt", "-ID"] }
        ) {
          data {
            ID
            fkEnquiryState
          }
        }
      }
    }
  }
`;

export const UPDATE_ENQUIRY_TAGS = gql`
  mutation updateEnquiryTags($IDEnquiry: Int!, $IDTag: [Int!]!) {
    updateEnquiryTags(IDEnquiry: $IDEnquiry, IDTag: $IDTag)
  }
`;

export const UPDATE_ENQUIRY_DETAILS = gql`
  mutation updateEnquiryDetail($ID: Int!, $data: EnquiryUpdate!) {
    updateEnquiryDetail(ID: $ID, data: $data) {
      ID
    }
  }
`;

export const GET_WAITLIST_PARAMETERS_GQL = gql`
  query findAllTags($IDSchool: Int!, $pagination: Pagination) {
    findAllTags(IDSchool: $IDSchool, pagination: $pagination) {
      totalCount
      data {
        label
        description
        weight
        type
        ID
        code
      }
    }
  }
`;
