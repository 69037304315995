import React from 'react';
import Select from 'react-select';
import { withTheme } from '@material-ui/core/styles';
import FieldWrapper from './FieldWrapper';

const SingleSelectField = ({
  label,
  name,
  value,
  disabled,
  onChange,
  options,
  error,
  theme,
  required,
  labelStyle,
  defaultValue,
  onBlur,
  isLoading,
  placeholder = '',
}) => (
  <FieldWrapper
    required={required}
    label={label}
    name={name}
    error={error}
    styles={labelStyle}
    disabled={disabled}
  >
    <Select
      classNamePrefix={name.replace('.', '-')}
      className="basic-single"
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      options={options.map(eachOption => ({
        label: eachOption.description,
        value: eachOption.label,
        ID: eachOption.ID ? eachOption.ID : '',
        isDisabled: eachOption.isDisabled || false,
      }))}
      onChange={onChange}
      onBlur={onBlur}
      theme={th => ({
        ...th,
        colors: {
          ...th.colors,
          primary25: theme.baseColor.neutral.main,
          primary: theme.baseColor.neutral.main,
        },
      })}
      isDisabled={disabled}
      isLoading={isLoading}
      styles={{
        control: provided => ({
          ...provided,
          minHeight: '32px',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isFocused
            ? theme.baseColor.neutral.white
            : theme.baseColor.neutral.black,
        }),
        dropdownIndicator: provided => ({
          ...provided,
          padding: '2px 8px',
          alignItems: 'center',
        }),
      }}
    />
  </FieldWrapper>
);

export default withTheme(SingleSelectField);
