import { get } from 'lodash';
import { GET_LISTING_VENDOR_TRAINER } from 'redux/actions/actionTypes';

const vendorTrainer = (state = {}, action) => {
  if (action.type === GET_LISTING_VENDOR_TRAINER) {
    const data = get(action, 'value.data.getVendorTrainers');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default vendorTrainer;
