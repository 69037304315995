import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';

import {
  FETCH_DEFAULT_STATE_MAPPING,
  FETCH_CURRENT_STATE_MAPPING,
  FETCH_ALL_STATE,
  SAVE_CURRENT_STATE_MAPPING,
} from './actionTypes';

const _mQueryBuilder = new QueryBuilder();
const _mMutationBuilder = new MutationBuilder();

_mQueryBuilder.setNewQuery('findAllEnquiryStateMachineMap', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'int',
    fromState: 'boolean',
    toState: 'number',
    priority: 'timestamp',
  },
});

_mQueryBuilder.setNewQuery('findAllEnquiryState', {
  totalCount: 'number',
  data: {
    ID: 'number',
    stateCode: 'string',
    stateName: 'string',
  },
});

_mMutationBuilder.setNewMutation('addEnquiryStateMachineMap', {});

const dispatchFetchMappingOptions = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_DEFAULT_STATE_MAPPING,
    key: 'mappingOptions',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchMapping = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CURRENT_STATE_MAPPING,
    key: 'currentMapping',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllState = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_STATE,
    key: 'allState',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSaveRegistrationState = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SAVE_CURRENT_STATE_MAPPING,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const findAllMappingOptions = reqData => async dispatch => {
  dispatchFetchMappingOptions(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'findAllEnquiryStateMachineMap',
          reqData
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchMappingOptions(dispatch, false, { data: data.data });
    } else {
      dispatchFetchMappingOptions(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchMappingOptions(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const findAllMapping = reqData => async dispatch => {
  dispatchFetchMapping(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'findAllEnquiryStateMachineMap',
          reqData
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchMapping(dispatch, false, { data: data.data });
    } else {
      dispatchFetchMapping(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchMapping(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const findAllRegistrationStates = reqData => async dispatch => {
  dispatchFetchAllState(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllEnquiryState', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchAllState(dispatch, false, { data: data.data });
    } else {
      dispatchFetchAllState(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchAllState(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const saveRegistrationState = reqData => async dispatch => {
  dispatchSaveRegistrationState(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'addEnquiryStateMachineMap',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchSaveRegistrationState(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchSaveRegistrationState(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchSaveRegistrationState(dispatch, false, null, {
      error: ex.message,
    });
  }
};
