import styled from 'styled-components';
import { BASE_COLOR } from 'theme/variables';

const CircleColor = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: ${BASE_COLOR.linkpass.default};
  color: #000;
  text-align: center;
`;
export default {
  CircleColor,
};
