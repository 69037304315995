import styled from 'styled-components';
import {
  AlertIconSvg,
  AnnouncementIconSvg,
  ApprovedIconSvg,
  BusIconSvg,
  CancelIconSvg,
  ClosureIconSvg,
  DocumentIconSvg,
  EventIconSvg,
  HealthIconSvg,
  PendingIconSvg,
  PhotoIconSvg,
  PromotionIconSvg,
  RejectedIconSvg,
  ReturnIconSvg,
  ScheduledIconSvg,
  FileUploadSVG,
} from './icons';

const getFillColor = (props, fillColor) => {
  if (props.primary) {
    return `fill: ${props.theme.palette.primary.main};`;
  }

  if (props.secondary) {
    return `fill: ${props.theme.palette.secondary.main};`;
  }

  return `fill: ${fillColor};`;
};

const iconStyle = props => `
    width: ${props.iconSize ? props.iconSize : props.theme.iconSize.medium};
    height: ${props.iconSize ? props.iconSize : props.theme.iconSize.medium};
    margin-top: 15px;
    margin-left: 15px;
  `;

export const AlertIcon = styled(AlertIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.status.error)}
`;

export const AnnouncementIcon = styled(AnnouncementIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const ApprovedIcon = styled(ApprovedIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.green)}
`;

export const BusIcon = styled(BusIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const CancelIcon = styled(CancelIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.status.error)}
`;

export const ClosureIcon = styled(ClosureIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.status.error)}
`;

export const DocumentIcon = styled(DocumentIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const EventIcon = styled(EventIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const HealthIcon = styled(HealthIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const PendingIcon = styled(PendingIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.darkYellow)}
`;

export const PhotoIcon = styled(PhotoIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const PromotionIcon = styled(PromotionIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const RejectedIcon = styled(RejectedIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.status.error)}
`;

export const ReturnIcon = styled(ReturnIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const ScheduledIcon = styled(ScheduledIconSvg)`
  ${iconStyle}
  ${props => getFillColor(props, props.theme.baseColor.neutral.black)}
`;

export const FileUpload = styled(FileUploadSVG)`
  ${props => getFillColor(props, props.theme.baseColor.neutral.main)}
`;
