import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import {
  FETCH_BLACK_LIST_DATA,
  FETCH_BLACK_LIST_DRAFTS,
  CHILD_RELATION_SEARCH,
} from './actionTypes';

const _mQueryBuilder = new QueryBuilder();
const _mMutationBuilder = new MutationBuilder();

_mQueryBuilder.setNewQuery('getBlackListData', {
  totalCount: 'number',
  data: {
    ID: 'number',
    child: {
      firstname: 'string',
      lastname: 'string',
    },
    updatedAt: 'timestamp',
    centre: {
      ID: 'number',
      label: 'string',
    },
    fkChild: 'number',
    parent: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
      childRelations: {
        data: {
          child: {
            firstname: 'string',
          },
        },
      },
    },
    code: {
      ID: 'number',
      label: 'string',
      description: 'string',
    },
  },
});

_mQueryBuilder.setNewQuery('blacklistDraftsByFkSchool', {
  totalCount: 'number',
  data: {
    ID: 'number',
    status: 'string',
    fkCentre: 'number',
    userByFkUser: {
      lastname: 'string',
      firstname: 'string',
      ID: 'number',
    },
    userByFkApprover: {
      lastname: 'string',
      firstname: 'string',
    },
    'blacklistDraftItems(filter: { active: [{ eq: [true, false] }], })': {
      data: {
        code: {
          label: 'string',
          description: 'string',
        },
        child: {
          firstname: 'string',
          lastname: 'string',
          dateOfBirth: 'string',
          birthCertificate: 'string',
        },
        parent: {
          lastname: 'string',
          firstname: 'string',
          email: 'string',
          identificationNo: 'string',
          childRelations: {
            data: {
              child: {
                firstname: 'string',
                lastname: 'string',
                dateOfBirth: 'string',
                birthCertificate: 'string',
              },
            },
          },
        },
        centre: {
          ID: 'number',
          label: 'string',
        },
        remark: 'string',
        updatedAt: 'timestamp',
        active: 'bool',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('getChildRelationData', {
  totalCount: 'number',
  data: {
    child: {
      ID: 'number',
      firstname: 'String',
      lastname: 'String',
      birthCertificate: 'timestamp',
      dateOfBirth: 'timestamp',
      childRelations: {
        data: {
          child: {
            ID: 'int',
            firstname: 'string',
            lastname: 'string',
          },
          parent: {
            ID: 'int',
            firstname: 'string',
            lastname: 'string',
          },
        },
      },
      childClasses: {
        data: {
          class: {
            centre: {
              ID: 'number',
              label: 'string',
            },
          },
        },
      },
      enquiries: {
        data: {
          centre: {
            ID: 'number',
            label: 'string',
          },
        },
      },
    },
    parent: {
      ID: 'number',
      firstname: 'String',
      lastname: 'String',
      email: 'String',
      identificationNo: 'string',
      childRelations: {
        data: {
          fkChild: 'int',
          child: {
            ID: 'number',
            firstname: 'string',
            lastname: 'string',
            birthCertificate: 'string',
          },
        },
      },
      mobilePhoneCountryCode: 'string',
      mobilePhone: 'string',
    },
  },
});

_mMutationBuilder.setNewMutation('submitRemoveBlacklistRequest', {
  ID: 'string',
});

_mMutationBuilder.setNewMutation('submitNewBlacklistRequest', {
  ID: 'string',
});

_mMutationBuilder.setNewMutation('rejectMemberBlacklistRequest', {});

_mMutationBuilder.setNewMutation('cancelMemberBlacklistRequest', {});

_mMutationBuilder.setNewMutation('approveMemberBlacklistRequest', {});

const dispatchBlacklist = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_BLACK_LIST_DATA,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchBlacklistDrafts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_BLACK_LIST_DRAFTS,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const clearChildSearchResults = (
  inProgress = true,
  data = null,
  error = null
) => async dispatch => {
  dispatch({
    type: CHILD_RELATION_SEARCH,
    key: 'centerSearch',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildRelation = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: CHILD_RELATION_SEARCH,
    key: '',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchBlacklistData = (reqData = {}) => async dispatch => {
  dispatchBlacklist(dispatch);

  const reqParams = Object.assign({}, reqData);
  const reqKeys = Object.keys(reqParams);

  reqKeys.forEach(eachKey => {
    if (!reqParams[eachKey]) {
      delete reqParams[eachKey];
    }
    if (eachKey === 'filter') {
      const filterData = reqParams[eachKey];
      const filterDataKeys = Object.keys(filterData);
      filterDataKeys.forEach(eachFilterKey => {
        if (!filterData[eachFilterKey]) {
          delete filterData[eachFilterKey];
        }
      });
    }
  });
  const query = _mQueryBuilder.getQueryAsString('getBlackListData', reqParams);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchBlacklist(dispatch, false, data.data);
    } else {
      dispatchBlacklist(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchBlacklist(dispatch, false, null, { error: ex.message });
  }
};

export const fetchBlacklistDrafts = (reqData = {}) => async dispatch => {
  dispatchBlacklistDrafts(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'blacklistDraftsByFkSchool',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchBlacklistDrafts(dispatch, false, data.data);
    } else {
      dispatchBlacklistDrafts(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchBlacklistDrafts(dispatch, false, null, { error: ex.message });
  }
};

export const removeFromBlacklist = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'submitRemoveBlacklistRequest',
    {
      removeBlacklist: reqData,
    }
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveBlacklistRequest = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'approveMemberBlacklistRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectBlacklistRequest = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'rejectMemberBlacklistRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelBlacklistRequest = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'cancelMemberBlacklistRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const addChildToBlackList = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'submitNewBlacklistRequest',
    {
      newBlacklist: reqData,
    }
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const searchChildRelationData = reqData => async dispatch => {
  dispatchChildRelation(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'getChildRelationData',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchChildRelation(dispatch, false, { data: data.data });
    } else {
      dispatchChildRelation(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchChildRelation(dispatch, false, null, { error: ex.message });
  }
};

export default fetchBlacklistData;
