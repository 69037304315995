import React from 'react';
import { connect } from 'react-redux';
import { Grid, TableSortLabel, Tooltip } from '@material-ui/core';

const SortHead = ({
  label,
  sortField,
  active,
  handleSort,
  cdnUrl,
  key,
  labelWrap = 'auto',
  labelWrapStyle = { width: 'max-content', overflowWrap: 'normal' },
}) => (
  <div key={key}>
    {sortField ? (
      <TableSortLabel hideSortIcon style={{ width: labelWrap }}>
        <Grid
          container
          direction="column"
          style={{ width: 'auto', padding: '1px' }}
        >
          <Tooltip title="ascending" enterDelay={300}>
            <img
              alt=""
              aria-label="button"
              role="presentation"
              src={`${cdnUrl}/common/${
                active === 'asc' ? 'arrow_up.png' : 'up.svg'
              } `}
              className="s7t-sort-icon"
              onClick={() => handleSort(sortField, 'asc')}
            />
          </Tooltip>
          <Tooltip title="descending" enterDelay={300}>
            <img
              alt=""
              aria-label="button"
              role="presentation"
              src={`${cdnUrl}/common/${
                active === 'desc' ? 'arrow_down.png' : 'down.svg'
              } `}
              className="s7t-sort-icon"
              onClick={() => handleSort(`-${sortField}`, 'desc')}
            />
          </Tooltip>
        </Grid>
        <span style={labelWrapStyle}>{label}</span>
      </TableSortLabel>
    ) : (
      label
    )}
  </div>
);

const mapStateToProps = state => ({
  cdnUrl: state.cdnUrl && state.cdnUrl.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SortHead);
