import gql from 'graphql-tag';

import {
  APPLICANT_ENROLMENT_FRAGMENT,
  SPOUSE_ENROLMENT_FRAGMENT,
} from './commonFragments';

export const START_REGISTRATION = gql`
  mutation startRegistration($IDRegistration: Int!) {
    startRegistration(IDRegistration: $IDRegistration)
  }
`;

export const GET_CONFIRMATION_FOR_SPOUSE = gql`
  query requireConfirmationForSpouse(
    $IDRegChild: Int!
    $identificationNo: String!
    $email: String!
  ) {
    requireConfirmationForSpouse(
      IDRegChild: $IDRegChild
      identificationNo: $identificationNo
      email: $email
    )
  }
`;

export const GET_REGISTRATION_BY_ID = gql`
  query registrationByID($IDRegistration: Int!) {
    registrationByID(IDRegistration: $IDRegistration) {
      ID
      fkUser
      registrationStartDate
      registrationDeadline
      state
      consentStatus
      documentStatus
      createdAt
      updatedAt
      active
      paymentStatus
      giroStatus
      showNOC
      isApplicationSubmitted
      currentFrontendStatus
      documentNotificationSent
      cancelledBy
      cancelledAt
      previousFrontendStatus
      remarks
      type
      code {
        description
      }
      staff {
        ID
        userByFkUser {
          firstname
          lastname
        }
      }
      forms {
        data {
          ID
          eDocumentStatus
          fkEnquiry
          formParticipants {
            data {
              ID
              fkForm
              parent {
                firstname
                lastname
                mobilePhone
              }
              user {
                firstname
                lastname
              }
              displayName
              order
              email
              status
              updatedAt
            }
          }
        }
      }
      registrationChildMappings {
        data {
          ID
          fkLevel
          fkCentre
          fkProgram
          enrolmentDate
          enquiryDate
          registrationChild {
            ID
            fkChild
            firstname
            lastname
            race
            nationality
            dateOfBirth
            gender
            birthCertificate
            householdIncomeRange
            mainApplicant {
              firstname
              lastname
              email
              mobilePhoneCountryCode
              mobilePhone
              identificationNo
              workplaceStaff
              workingStatus
              reasonOfUnemployment
              expectedDeliveryDate
            }
            siblings {
              data {
                ID
                firstname
                lastname
              }
            }
            registrationChildParents(filter: { type: "applicant" }) {
              data {
                ID
                fkRelationship
                registrationParent {
                  identificationNo
                  workplaceStaff
                  firstname
                  lastname
                  ID
                  mobilePhone
                  mobilePhoneCountryCode
                  email
                  addresses {
                    totalCount
                    data {
                      fkUser
                      address
                      building
                      floor
                      unit
                      city
                      country
                      postcode
                    }
                  }
                }
              }
            }
          }
          centre {
            ID
            label
            code
            payNowEmail
            addresses {
              totalCount
              data {
                ID
                name
                address
                postcode
                fkCentre
                lat
                lng
                building
                floor
                unit
                city
              }
            }
          }
          program {
            ID
            label
          }
          level {
            ID
            label
          }
        }
      }
      feeTier {
        ID
        label
      }
    }
  }
`;

export const GET_REGISTRATION_STATUS_BY_ID = gql`
  query registrationByID($IDRegistration: Int!) {
    registrationByID(IDRegistration: $IDRegistration) {
      ID
      state
      currentFrontendStatus
    }
  }
`;

export const GET_REG_ENROLMENT_FORM = gql`
  query getRegEnrolmentForm($registrationID: Int!) {
    getRegEnrolmentForm(IDRegistration: $registrationID) {
      allowedSteps
      registration {
        ID
        state
        documentStatus
        consentStatus
        registrationStartDate
        sugAcknowledgeBy
        documentNotificationSent
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
      }
      child {
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
        ID
        firstname
        lastname
        dateOfBirth
        nationality
        race
        gender
        birthCertificate
        householdIncomeRange
        applyForSug
        typeOfReferral
        referralChannel
        otherReferralChannel
        applyFinancialAssistance
        isBigHousehold
        numFamilyMembers
        numDependents
        coPaymentAmount
        hasEmergencyContact
        supportScheme
        financialStartDate
        financialEndDate
      }
      mainApplicant {
        ...applicantFragment
      }
      spouse {
        ...spouseFragment
      }
      familyMembers {
        totalCount
        data {
          firstname
          lastname
          email
          identificationNo
          workingStatus
          occupationalTitle
          dateOfBirth
          hasCpf
          hasNoa
          isRecentlyEmployed
          commencementDate
          salariedMonthlyIncome
          selfEmployedMonthlyIncome
          fkRelationship
        }
      }
    }
  }
  ${APPLICANT_ENROLMENT_FRAGMENT}
  ${SPOUSE_ENROLMENT_FRAGMENT}
`;

export const SAVE_REG_ENROLMENT_FORM_GQL = gql`
  mutation saveRegEnrolmentForm(
    $registrationID: Int!
    $step: Int!
    $regForm: RegistrationEnrolmentForm!
  ) {
    saveRegEnrolmentForm(
      IDRegistration: $registrationID
      step: $step
      regForm: $regForm
    ) {
      allowedSteps
      registration {
        ID
        sugAcknowledgeBy
        registrationChildMappings {
          data {
            enrolmentDate
            level {
              ID
              label
            }
          }
        }
      }
      child {
        ID
        firstname
        lastname
        dateOfBirth
        nationality
        race
        gender
        birthCertificate
        householdIncomeRange
        applyForSug
        typeOfReferral
        referralChannel
        otherReferralChannel
        applyFinancialAssistance
        isBigHousehold
        numFamilyMembers
        numDependents
        coPaymentAmount
        supportScheme
      }
      mainApplicant {
        ...applicantFragment
      }
      spouse {
        ...spouseFragment
      }
      familyMembers {
        totalCount
        data {
          firstname
          lastname
          email
          identificationNo
          workingStatus
          occupationalTitle
          dateOfBirth
          hasCpf
          hasNoa
          isRecentlyEmployed
          commencementDate
          salariedMonthlyIncome
          selfEmployedMonthlyIncome
          fkRelationship
        }
      }
    }
  }
  ${APPLICANT_ENROLMENT_FRAGMENT}
  ${SPOUSE_ENROLMENT_FRAGMENT}
`;
