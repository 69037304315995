import React, { Component } from 'react';
import { get } from 'lodash';
import { getQueryParams } from '../../../utils';

const urlParamsHOC = (WrappedComponent, paramParser) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.changeParam = this.changeParam.bind(this);
    }

    async setUrlWithParams(obj, cb, args = []) {
      const history = get(this, 'props.history');
      const location = get(this, 'props.history.location');

      let urlString = '';
      Object.keys(obj).forEach((name, index) => {
        if (index !== 0) {
          urlString += '&';
        }
        urlString += `${name}=${encodeURIComponent(
          decodeURIComponent(obj[name])
        )}`;
      });

      if (history && location) {
        const newUrl = `?${urlString}`;
        if (newUrl !== location.search) {
          await history.replace(`${location.pathname}${newUrl}`);
          if (cb) {
            const paramObj = paramParser();
            cb(paramObj, ...args);
          }
        }
      }
    }

    async changeParam(obj, cb = null, args = []) {
      const params = Object.assign({}, getQueryParams(), obj);
      await this.setUrlWithParams(params, cb, args);
    }

    render() {
      const paramObj = paramParser();
      return (
        <WrappedComponent
          urlParams={paramObj}
          changeParam={this.changeParam}
          setUrlWithParams={params => this.setUrlWithParams(params)}
          {...this.props}
        />
      );
    }
  };

export default urlParamsHOC;
