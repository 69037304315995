import { get } from 'lodash';
import {
  FETCH_ALL_BUSSES,
  FETCH_ALL_BUS_DRIVER,
  FETCH_ALL_CHILDREN_FOR_BUS,
  LIST_ALL_BUSES,
  SHOW_DELETE_BUS_POPUP,
  FETCH_ALL_BUS_CHILDREN,
} from '../actions/actionTypes';

export const allBusses = (state = {}, action) => {
  if (action.type === FETCH_ALL_BUSSES) {
    const data = get(action, 'value.data.data.findAllBus');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example Data
 * data: {
 *  data: {
 *    listAllBusEntries: {
 *      data: {
 *        ID: 3,
 *        active: true
 *        bus: {
 *          centre: {
 *             ID: 2
 *             label: "The Caterpillar's Cove @ Ngee A"
 *          }
 *        }
 *        fkCentre: 2
 *        plateNumber: "DF3434"
 *      }
 *    }
 *  }
 * }
 */

export const allBusData = (state = {}, action) => {
  if (action.type === LIST_ALL_BUSES) {
    const data = get(action, 'value.data.data.listAllBusEntries');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example Data
 * data: {
 *  data: {
 *    findAllChildrenNotAssignedToBus: {
 *      data: [{
 *        ID: 16,
 *        birthCertificate: "T1537385C",
 *          currentClass: {
 *            class: {
 *              label: "K1 Class"
 *            }
 *          }
 *        enquiries: {
 *          data: [{
 *              ID: 847
 *              fkCentre: 1
 *              fkEnquiryState: "cancelled"
 *          }]
 *        }
 *        firstname: ""
 *        imageKey: "upload/child/6/yen-jun-20200817-1597647022167646765-6418.jpg"
 *        lastname: "Toh Yen Jun(Zhuo Yanjun)"
 *        upcomingClass: null
 *      }]
 *    }
 *  }
 * }
 */
export const availableChildrenForBus = (state = {}, action) => {
  if (action.type === FETCH_ALL_CHILDREN_FOR_BUS) {
    const data = get(
      action,
      'value.data.data.findAllChildrenNotAssignedToBus.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * TODO -> NO Env having this API Currently -> LSH Only
 */

export const fetchAllBusDriver = (state = {}, action) => {
  if (action.type === FETCH_ALL_BUS_DRIVER) {
    const data = get(action, 'value.data.data.findAllBusUser');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example data
 * {
 *  shouldShowPopUp: false
 *  sourcePath: ""
 * }
 */

export const showDeleteBusPopUp = (state = {}, action) => {
  if (action.type === SHOW_DELETE_BUS_POPUP) {
    const data = get(action, 'value');
    if (data) {
      return Object.assign({}, state, {
        data: {
          ...data,
        },
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 * Example data
 * data: {
 *  data: {
 *    listAllBusChildren: {
 *      data: [{
 *        ID: 8
 *        active: true
 *        bus: {
 *          ID: 3
 *          plateNumber: "DF3434"
 *        }
 *        child: {
 *          ID: 6
 *          birthCertificate: "T1537385C"
 *          currentClass: {
 *            class: {
 *              label: "K1 Class"
 *            }
 *          }
 *          currentLevel: {
 *            fkCentre: 2
 *            fkLevel: 20
 *          }
 *          firstname: ""
 *          lastname: "Toh Yen Jun(Zhuo Yanjun)"
 *          upcomingClass: null
 *        }
 *        fkBus: 3
 *        fkChild: 6
 *        locationText: "jio"
 *        remarks: "j"
 *        serviceEndDate: "2020-12-02 19:02:00"
 *        serviceStartDate: "2020-12-01 19:02:00"
 *      }]
 *      totalCount: 1
 *    }
 *  }
 * }
 */

export const allBusChildren = (state = {}, action) => {
  if (action.type === FETCH_ALL_BUS_CHILDREN) {
    const data = get(action, 'value.data.data.listAllBusChildren');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};
