import { get } from 'lodash';
import {
  FETCH_DEFAULT_STATE_MAPPING,
  FETCH_CURRENT_STATE_MAPPING,
  FETCH_ALL_STATE,
  SAVE_CURRENT_STATE_MAPPING,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllEnquiryStateMachineMap": {
 *       "totalCount": 46,
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkSchool": null,
 *           "fromState": "created",
 *           "toState": "waiting_list",
 *           "priority": 5
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const findAllStateMappingOptions = (state = {}, action) => {
  if (action.type === FETCH_DEFAULT_STATE_MAPPING) {
    const data = get(
      action,
      'value.data.data.findAllEnquiryStateMachineMap.data'
    );
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, {
      [action.key]: action.value,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllEnquiryStateMachineMap": {
 *       "totalCount": 46,
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkSchool": null,
 *           "fromState": "created",
 *           "toState": "waiting_list",
 *           "priority": 5
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const findAllStateMapping = (state = {}, action) => {
  if (action.type === FETCH_CURRENT_STATE_MAPPING) {
    const data = get(
      action,
      'value.data.data.findAllEnquiryStateMachineMap.data'
    );
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, {
      [action.key]: action.value,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllEnquiryState": {
 *       "totalCount": 11,
 *       "data": [
 *         {
 *           "ID": 1,
 *           "stateCode": "created",
 *           "stateName": "Created"
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const findAllRegistrationState = (state = {}, action) => {
  if (action.type === FETCH_ALL_STATE) {
    const data = get(action, 'value.data.data.findAllEnquiryState.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          data,
          inProgress: action.value.inProgress,
          error: action.value.error,
        },
      });
    }
    return Object.assign({}, state, {
      [action.key]: action.value,
    });
  }
  return state;
};

export const saveRegistrationState = (state = {}, action) => {
  if (action.type === SAVE_CURRENT_STATE_MAPPING) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          data,
          ...action.value,
        },
      });
    }
    return Object.assign({}, state, {
      [action.key]: action.value,
    });
  }
  return state;
};
