import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import { ALL_LABEL } from 'utils/constants';
import {
  fetchAccessibleCentreForModule,
  listAllBusData,
  showDeleteBusPopUp,
  hideSnackBarMessage,
} from '../../../../redux/actions';
import {
  getQueryParams,
  parseObjFields,
  replaceUrlParam,
} from '../../../../utils';
import UrlPersistenceHOC from '../../HOC/UrlPersistenceHOC';
import BusListing from './BusListView';
import { BUS_DETAIL } from '../../../../routes/constants';
import { AbilityContext } from '../../../../utils/ability/abilityContext';

const parseQuery = () => {
  const fields = {
    centreID: 'int',
    page: 'int',
    perPage: 'int',
  };
  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      centreID: '',
      direction: '',
      status: '',
      year: '',
      page: 1,
      perPage: 10,
    },
    obj
  );
};

class BusListingMainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedBusEntryID: null,
    };
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.fetchBusDetailsWithFilter = this.fetchBusDetailsWithFilter.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentDidMount() {
    const { fetchCentres } = this.props;
    fetchCentres('bus_user');
    this.fetchBusDetailsWithFilter();
  }

  handleRowClick(rowData) {
    const { history, snackBarMessage, hideSnackBarMessageMethod } = this.props;
    if (get(snackBarMessage, 'visibility')) {
      hideSnackBarMessageMethod();
    }
    history.push({
      pathname: replaceUrlParam(BUS_DETAIL, {
        id: rowData[rowData.length - 1],
      }),
    });
  }

  handleMenuSelect(e, ID) {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      selectedBusEntryID: ID,
    });
  }

  fetchBusDetailsWithFilter() {
    const { fetchAllBusData, urlParams } = this.props;
    const { centreID, direction, status, year, page, perPage } = urlParams;
    const reqData = {};
    const pagination = {
      page,
      perPage,
      sort: ['-createdAt'],
    };

    if (status) {
      reqData.status = status === 'active';
    }
    if (centreID) {
      reqData.centreID = centreID;
    }
    if (direction) {
      reqData.direction = direction;
    }
    if (year) {
      reqData.year = moment(year).format('YYYY-MM-DD');
    }
    fetchAllBusData({ ...reqData, pagination });
  }

  handlePagination(page, perPage) {
    const { changeParam } = this.props;
    changeParam({ page, perPage }, this.fetchBusDetailsWithFilter);
  }

  handleFilterChange(e) {
    const { name, value } = e.target;
    const { changeParam } = this.props;
    const page = 1;
    const perPage = 10;
    changeParam(
      {
        [name]: value,
        page,
        perPage,
      },
      this.fetchBusDetailsWithFilter
    );
  }

  handleAutoCompleteChange = (e, value) => {
    e.preventDefault();
    const { changeParam } = this.props;
    const page = 1;
    const perPage = 10;
    changeParam(
      {
        centreID: get(value, 'label', ''),
        page,
        perPage,
      },
      this.fetchBusDetailsWithFilter
    );
  };

  render() {
    const { context } = this;
    const hasWriteAccess = context.can('write', 'bus_management');
    const { anchorEl, selectedBusEntryID } = this.state;
    const {
      centers,
      busData,
      urlParams,
      history,
      snackBarMessage,
      setDeletePopUpOpen,
      hideSnackBarMessageMethod,
      t,
      busDataIsLoading,
    } = this.props;
    let centreOptions = [ALL_LABEL];
    if (get(centers, 'data.length')) {
      centreOptions = centreOptions.concat(
        centers.data.map(eachCentre => ({
          description: eachCentre.label,
          label: eachCentre.ID,
        }))
      );
    }
    let tableData = [];
    if (busDataIsLoading) {
      tableData = null;
    }
    if (get(busData, 'data.data.length')) {
      tableData = get(busData, 'data.data', []).map(eachBus => {
        const ID = get(eachBus, 'ID');
        const plateNumber = get(eachBus, 'bus.plateNumber');
        const menuIcon = (
          <IconButton
            onClick={e => this.handleMenuSelect(e, ID)}
            className="w3-right"
          >
            <MenuIcon />
          </IconButton>
        );
        const centreName = get(eachBus, 'bus.centre.label');
        const busName = get(eachBus, 'label', '-');
        const year = moment(get(eachBus, 'yearTime')).format('YYYY');
        const time = moment(get(eachBus, 'yearTime')).format('HH:mm');
        const direction =
          get(eachBus, 'direction') === 'arrival'
            ? t('bus_management.directionTypeArrival')
            : t('bus_management.directionTypeDeparture');
        const status = get(eachBus, 'status')
          ? t('common.active')
          : t('common.disabled');
        return [
          busName,
          centreName,
          plateNumber,
          direction,
          time,
          year,
          status,
          get(eachBus, 'busCompany', ''),
          hasWriteAccess ? menuIcon : null,
          ID,
        ];
      });
    }

    return (
      <BusListing
        canWrite={hasWriteAccess}
        onRowClick={this.handleRowClick}
        year={get(urlParams, 'year')}
        direction={get(urlParams, 'direction')}
        centreOptions={centreOptions}
        centreID={get(urlParams, 'centreID')}
        status={get(urlParams, 'status')}
        tableData={tableData}
        page={get(urlParams, 'page')}
        totalCount={get(busData, 'data.totalCount')}
        perPage={get(urlParams, 'perPage')}
        handlePagination={this.handlePagination}
        handleFilterChange={this.handleFilterChange}
        anchorEl={anchorEl}
        parent={this}
        history={history}
        selectedBusEntryID={selectedBusEntryID}
        snackBarMessage={snackBarMessage}
        setDeletePopUpOpen={setDeletePopUpOpen}
        hideSnackBarMessage={hideSnackBarMessageMethod}
        t={t}
        handleAutoCompleteChange={this.handleAutoCompleteChange}
      />
    );
  }
}

BusListingMainComponent.contextType = AbilityContext;

const mapStateToProps = state => ({
  busData: get(state, 'allBusData'),
  centers: get(state, 'accessibleCentres', []),
  snackBarMessage: get(state, 'snackBarMessage.data'),
  busDataIsLoading: get(state, 'allBusData.inProgress'),
});

const mapDispatchToProps = {
  fetchCentres: fetchAccessibleCentreForModule,
  fetchAllBusData: listAllBusData,
  setDeletePopUpOpen: showDeleteBusPopUp,
  hideSnackBarMessageMethod: hideSnackBarMessage,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UrlPersistenceHOC(withRouter(BusListingMainComponent), parseQuery));
