import gql from 'graphql-tag';

export const GET_DOCUMENT_URL = gql`
  query getDocumentURL($IDDocument: Int!, $IDChild: Int!) {
    getDocumentURL(IDDocument: $IDDocument, IDChild: $IDChild)
  }
`;

export const SEND_ENQUIRY_DOCUMENT_NOTIFICATION = gql`
  mutation sendEnquiryDocumentNotification($enquiryID: Int!) {
    sendEnquiryDocumentNotification(IDEnquiry: $enquiryID)
  }
`;

export default GET_DOCUMENT_URL;
