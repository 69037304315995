import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import getConfig from './config';

const config = getConfig();

export const authProviderConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  appOrigin: `${process.env.REACT_APP_PARENT_PORTAL}`,
  redirectUri: `${process.env.REACT_APP_PARENT_PORTAL}/lp-sso-callback`,
};

const Auth0ProviderWithHistory = ({ children }) => {
  return <Auth0Provider {...authProviderConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
