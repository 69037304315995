import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { getPrimaryColor } from '../../utils';

const Loader = ({ style, className, ...props }) => (
  <div className={className || 'w3-center w3-padding'}>
    <span
      style={{
        style,
        borderTop: `6px solid ${getPrimaryColor(props)}`,
      }}
      className="s7t-loader w3-show-inline-block"
    />
  </div>
);

export default withTheme(Loader);
