import { SHOW_MOBILE_NAVIGATION } from '../actions/actionTypes';

const mobileNavigation = (state = {}, action) => {
  if (action.type === SHOW_MOBILE_NAVIGATION) {
    const data = { isOpen: action.isOpen };
    return Object.assign({}, state, data);
  }
  return state;
};
export default mobileNavigation;
