import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { ApolloProvider } from '@apollo/react-hooks';
import DatadogHandler from 'utils/datadog';
import Auth0ProviderWithHistory from 'auth/auth0-provider-with-history';
import reducerMap from './redux/reducers';
import App from './components/App';
import apolloUtils from './utils/apolloUtils';
import { initializeTracking } from './utils/trackingUtils';
import SentryHandler from './utils/sentry';
import './i18n';

// initialise Sentry
SentryHandler.init();
// initialise Datadog
DatadogHandler.init();

const initalState = {};
const str = localStorage.getItem('authUser');

if (str) {
  initalState.authUser = JSON.parse(str);
}

// initializes google analytics tracking
initializeTracking();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducerMap,
  initalState,
  composeEnhancers(applyMiddleware(thunk))
);
window.gSkoolstore = store;

const apolloClient = apolloUtils();

const AppRoute = () => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
);

ReactDOM.render(<AppRoute />, document.getElementById('app-container'));
