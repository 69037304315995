import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import { RadioGroup, Grid } from '@material-ui/core/';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  ${props => {
    if (props.yearSelector) {
      return `
      & .MuiInputBase-root {
        font-weight: bold;
        color: ${props.theme.baseColor.filter.label};
      }
      & svg {
        color: ${props.theme.baseColor.filter.label};
      }
      & input {
        margin-top: 6px;
        margin-left: 5px;
      }
    `;
    }
  }}
`;

export const MaskedTimeInput = styled(KeyboardTimePicker)`
  & .MuiOutlinedInputinput {
    padding: 10px 14px;
  }

  & .MuiIconButtonroot {
    pointerevents: none;
  }

  & .MuiOutlinedInputadornedEnd {
    paddingright: 0px;
  }

  & .MuiOutlinedInputroot {
    borderradius: 0;
  }

  & .MuiInputAdornmentpositionEnd {
    marginleft: 0px;
    width: 0px;
  }
`;

export const StyledField = styled.div`

  ${props => {
    if (props.wrapperPadding) {
      return `
        padding: ${props.wrapperPadding};
      `;
    }

    return `
      padding: 15px 0;
    `;
  }}

  & .MuiFormLabel-root {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    & span {
      color: ${props =>
        props.disabled
          ? props.theme.baseColor.neutral.mediumGrey
          : props.textColor};
      line-height: 1.25;
    }
  }

    & .MuiSvgIcon-root {
      margin-left: 5px;
    }
  }

  & .MuiInput-root {
    width: 100%;
  }

  & .MuiInputBase-input {
    padding: 0 0 7px;
  }
  & .MuiInput-input::placeholder {
    color: ${props => props.theme.baseColor.status.draft};
  }

  & [data-error] {
    clear: both;
  }

  ${props => {
    if (props.required) {
      return `
            & .MuiFormLabel-root {
                & span {
                    &:after {
                        content: "*";
                        color: ${props.theme.baseColor.status.warning};
                        padding-left: 3px;
                    }
                }
            }`;
    }
  }}
`;

export const StyledInput = styled(Input)`
  & .MuiInputBase-input {
    padding: 11px 0 10px;
    ${props => props.inputStyle}
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  ${props => {
    if (props.showHorizontal) {
      return `
                display: block;
                & .MuiFormControlLabel-root {
                    float: left;
                }
            `;
    }

    if (props.horizontalEquallySpaced) {
      return `
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
            `;
    }
  }}
`;

export const TagFieldWrapper = styled(Grid)`
  background: #e7e7e7;
  padding: 4px 8px 4px 8px;
  margin: 0px 0px 0px 4px;
  border-radius: 10px;
  text-align: center;
`;
