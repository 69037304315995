import { get } from 'lodash';
import {
  FETCH_BLACK_LIST_DATA,
  FETCH_BLACK_LIST_DRAFTS,
  CHILD_RELATION_SEARCH,
} from '../actions/actionTypes';

/**
 * Example data
 * {
 *  "data": {
 *    "getBlackListData": {
 *      "totalCount": 2,
 *      "data": [
 *        {
 *          "ID": 1,
 *          "child": {
 *            "firstname": "",
 *            "lastname": "Hannah Felise Lim"
 *          },
 *          "updatedAt": "2020-12-02 09:24:48",
 *          "centre": {
 *            "ID": 1,
 *            "label": "The Caterpillar's Cove @ Jurong East"
 *          },
 *          "fkChild": 12,
 *          "parent": {
 *            "ID": 665,
 *            "firstname": "",
 *            "lastname": "Chai Li Fah",
 *            "childRelations": {
 *              "data": [
 *                {
 *                  "child": {
 *                    "firstname": ""
 *                  }
 *                },
 *                {
 *                  "child": {
 *                    "firstname": ""
 *                  }
 *                }
 *              ]
 *            }
 *          },
 *          "code": {
 *            "ID": 1059,
 *            "label": "trade_debt_parent",
 *            "description": "Trade Debt Parent"
 *          }
 *        },
 *       ]
 *      }
 *    }
 *  }
 */
export const blacklistDetails = (state = {}, action) => {
  if (action.type === FETCH_BLACK_LIST_DATA) {
    const data = get(action, 'value.data.getBlackListData');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 * Example data
 * {
 *"data": {
 *  "blacklistDraftsByFkSchool": {
 *    "totalCount": 3,
 *    "data": [
 *      {
 *        "ID": 1,
 *        "status": "approved",
 *        "fkCentre": 1,
 *        "userByFkUser": {
 *          "firstname": "Erina",
 *          "lastname": "Nakiri",
 *          "ID": 32
 *        },
 *        "userByFkApprover": {
 *          "lastname": "Nakiri",
 *          "firstname": "Erina"
 *        },
 *        "blacklistDraftItems": {
 *          "data": [
 *            {
 *              "code": {
 *                "label": "trade_debt_parent",
 *                "description": "Trade Debt Parent"
 *              },
 *              "child": {
 *                "firstname": "",
 *                "lastname": "Hannah Felise Lim",
 *                "dateOfBirth": "2015-10-23 00:00:00",
 *                "birthCertificate": "T1533217J"
 *              },
 *              "parent": {
 *                "lastname": "Chai Li Fah",
 *                "firstname": "",
 *                "email": "sn2dummy+40000664@gmail.com",
 *                "identificationNo": "S8578597D",
 *                "childRelations": {
 *                  "data": [
 *                    {
 *                      "child": {
 *                        "firstname": "",
 *                        "lastname": "Hannah Felise Lim",
 *                        "dateOfBirth": "2015-10-23 00:00:00",
 *                        "birthCertificate": "T1533217J"
 *                      }
 *                    },
 *                    {
 *                      "child": {
 *                        "firstname": "",
 *                        "lastname": "Hazel Felise Lim",
 *                        "dateOfBirth": "2020-01-23 00:00:00",
 *                        "birthCertificate": "T2002208B"
 *                      }
 *                    }
 *                  ]
 *                }
 *              },
 *              "centre": {
 *                "ID": 1,
 *                "label": "The Caterpillar's Cove @ Jurong East"
 *              },
 *              "remark": "gdndg",
 *              "updatedAt": "2020-12-02 09:24:48",
 *              "active": true
 *            },
 *          ]
 *        }
 *      },
 *     ]
 *    }
 *  },
 */

export const blacklistDrafts = (state = {}, action) => {
  if (action.type === FETCH_BLACK_LIST_DRAFTS) {
    const data = get(action, 'value.data.blacklistDraftsByFkSchool.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 * Example data
 *  {
 *    "data": {
 *      "getChildRelationData": {
 *        "totalCount": 1,
 *        "data": [
 *          {
 *            "child": {
 *              "ID": 878,
 *              "firstname": "Sara-Ann",
 *              "lastname": "Arabad",
 *              "birthCertificate": "T1902720H",
 *              "dateOfBirth": "2019-01-30 00:00:00",
 *              "childRelations": {
 *                "data": [
 *                  {
 *                    "child": {
 *                      "ID": 878,
 *                      "firstname": "Sara-Ann",
 *                      "lastname": "Arabad"
 *                    },
 *                    "parent": {
 *                      "ID": 1426,
 *                      "firstname": "Elfy",
 *                      "lastname": "Lee"
 *                    }
 *                  }
 *                ]
 *              },
 *              "childClasses": {
 *                "data": []
 *              },
 *              "enquiries": {
 *                "data": [
 *                  {
 *                    "centre": {
 *                      "ID": 2,
 *                      "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *                    }
 *                  }
 *                ]
 *              }
 *            },
 *            "parent": {
 *              "ID": 1426,
 *              "firstname": "Elfy",
 *              "lastname": "Lee",
 *              "email": "sn2dummy+40001368@gmail.com",
 *              "identificationNo": null,
 *              "childRelations": {
 *                "data": [
 *                  {
 *                    "fkChild": 878,
 *                    "child": {
 *                      "ID": 878,
 *                      "firstname": "Sara-Ann",
 *                      "lastname": "Arabad",
 *                      "birthCertificate": "T1902720H"
 *                    }
 *                  }
 *                ]
 *              },
 *              "mobilePhone": "40001368",
 *              "mobilePhoneCountryCode": "+65000"
 *            }
 *          }
 *        ]
 *      }
 *    }
 *  }
 */

export const childRelationSearch = (state = {}, action) => {
  if (action.type === CHILD_RELATION_SEARCH) {
    const data = get(action, 'value.data.data.getChildRelationData.data');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }

    return Object.assign({}, action.value);
  }

  return state;
};

export default blacklistDetails;
