import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_ERROR, schoolIDMap } from 'utils/constants';
import Loader from 'components/common/Loader';
import { get } from 'lodash';
import { CONNECT_TO_MFS_ACCOUNT, LOGIN, OVERVIEW } from 'routes/constants';

import {
  linkpassUserInfoFromClaim as linkpassUserInfoFromClaimAction,
  loginByLinkpass as loginByLinkpassAction,
  linkUserWithLinkPass as linkUserWithLinkPassAction,
  fetchUserDetails as fetchUserDetailsAction,
  showSnackBarMessage as showSnackBarMessageAction,
  registerWithLinkPass as registerWithLinkPassAction,
  registerWithLinkPassInfo as registerWithLinkPassInfoAction,
  validateUser as validateUserAction,
} from 'redux/actions';
import DatadogHandler from 'utils/datadog';

import localStore from '../../../utils/localStorage';
import ContactDetailsNotMatch from './ContactDetailsNotMatch';
import OTP from './OTP';

export const getSn2UserInfo = (lpInfo, userDetail = {}) => {
  if (userDetail && (userDetail.email || userDetail.mobilePhone)) {
    return userDetail;
  }
  return lpInfo;
};

export const getLinkpassInfo = (
  linkpassUserInfoFromClaim = {},
  LinkPassAuthTokenClaimDto
) => {
  if (
    linkpassUserInfoFromClaim.Email ||
    linkpassUserInfoFromClaim.PhoneNumber
  ) {
    return linkpassUserInfoFromClaim;
  }

  return LinkPassAuthTokenClaimDto;
};
const LinkpassHandler = props => {
  const {
    loginByLinkpass,
    linkUserWithLinkPass,
    fetchUserDetails,
    registerWithLinkPass,
    registerWithLinkPassInfo,
    showSnackBarMessage,
    linkpassUserInfoFromClaimData,
    linkpassUserInfoFromClaim,
    userDetails,
    sn2UserInfo,
    forceOtp,
    schoolID,
  } = props;

  const [verifyOTP, setVerifyOTP] = useState(false);

  const [showAccountNotMatch, setShowAccountNotMatch] = useState(false);
  const [linkAction, setLinkAction] = useState(null);
  const [linkpassAccountInfo, setLinkpassAccountInfo] = useState(
    linkpassUserInfoFromClaimData
  );

  const [sn2AccountInfo, setSn2AccountInfo] = useState(sn2UserInfo);
  const [diffInfo, setDiffInfo] = useState({});
  const history = useHistory();
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();

  const linkUserWithLinkpassCallback = async (
    _userDetails,
    linkpassIDToken
  ) => {
    const resp = await linkUserWithLinkPass(
      _userDetails?.email,
      linkpassIDToken
    );
    const errors = get(resp, 'error', null);
    if (!errors) {
      const newUserToken = get(resp, 'data.linkUserWithLinkPass');
      if (
        newUserToken &&
        (typeof newUserToken === 'string' || newUserToken instanceof String)
      ) {
        localStore.setValue('userToken', newUserToken);
      }

      await fetchUserDetails();
      window.location.href = `${OVERVIEW}?linkedAccountSuccessfully=true`;
    } else {
      const errMsg = get(errors, '0.message', DEFAULT_ERROR);
      const errorCode = get(errors, '0.extensions.errorCode');
      localStore.setValue('linkpassIDToken', null);
      // need to  do AuthLogout as well
      if (errorCode === 'ERR_USER_LINKPASS_UID_MISMATCH') {
        localStore.setValue('linkFailError', true);
        localStore.setValue('linkpassIDToken', null);
        setTimeout(() => {
          history.push(`${OVERVIEW}`);
        }, 1000);
        logout({
          returnTo: window.location.origin,
        });
      } else {
        showSnackBarMessage(errMsg, 'error');
        setTimeout(() => {
          logout({
            returnTo: window.location.origin,
          });
        }, 2000);
      }
    }
  };

  const registerWithLinkPassCallBack = async (
    _registerToken,
    linkpassIDToken
  ) => {
    const onError = err => {
      const errMsg = get(err, 'message', DEFAULT_ERROR);
      const ERROR_CODE = get(err, 'extensions.errorCode');
      localStore.setValue('linkpassIDToken', null);
      localStore.setValue('registerToken', null);
      if (ERROR_CODE === 'ERR_USER_LINKPASS_UID_MISMATCH') {
        localStore.setValue('linkFailError', true);
        localStore.setValue('linkpassIDToken', null);
        setTimeout(() => {
          history.push(`${LOGIN}`);
        }, 1000);
        logout({
          returnTo: window.location.origin,
        });
      } else {
        localStore.setValue('linkpassIDToken', null);
        localStore.setValue('userToken', null);
        showSnackBarMessage(errMsg, 'error');
        logout({
          returnTo: window.location.origin,
        });
      }
    };
    try {
      const resp = await registerWithLinkPass(_registerToken, linkpassIDToken);
      let errors = get(resp, 'error', null);
      if (!errors) {
        const loginByLinkpassResp = await loginByLinkpass(linkpassIDToken);
        errors = get(loginByLinkpassResp, 'error', null);
        if (!errors) {
          // already registeredWithLinkpass successfully before
          const _userToken = get(loginByLinkpassResp, 'data.loginByLinkPass');
          localStore.setValue('userToken', _userToken);
          window.location.href = `${OVERVIEW}?linkedAccountSuccessfully=true`;
        } else {
          onError(get(errors, '[0]'));
        }
      } else {
        onError(get(errors, '[0]'));
      }
    } catch (error) {
      onError();
    }
  };

  const onSubmitOTPSuccess = () => {
    registerWithLinkPassCallBack(
      localStore.getValue('registerToken'),
      localStore.getValue('linkpassIDToken')
    );
  };

  useEffect(() => {
    // 👇️ run function when component unmounts 👇️
    return () => {
      localStore.setValue('registerToken', null);
    };
  }, []);

  const isDetailsNotMatched = async _linkpassIDToken => {
    const linkpassUserInfo = await linkpassUserInfoFromClaim(_linkpassIDToken);
    setLinkpassAccountInfo(
      get(
        linkpassUserInfo,
        'data.linkpassUserInfoFromClaim.LinkPassAuthTokenClaimDto'
      )
    );
    setSn2AccountInfo(
      get(linkpassUserInfo, 'data.linkpassUserInfoFromClaim.User')
    );

    const _diffInfo = get(
      linkpassUserInfo,
      'data.linkpassUserInfoFromClaim.LinkPassCompareWithUser'
    );
    setDiffInfo(_diffInfo);

    const _isDetailsNotMatch = _diffInfo.IsDiffEmail || _diffInfo.IsDiffPhone;
    return _isDetailsNotMatch;
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      const registerToken = localStore.getValue('registerToken');
      try {
        await getAccessTokenSilently();
        const claims = await getIdTokenClaims();
        const linkpassIDToken = claims.__raw;
        localStore.setValue('linkpassIDToken', linkpassIDToken);
        const userToken = localStore.getValue('userToken');

        if (userToken) {
          localStore.setValue('registerToken', null);
          const _userDetails = await fetchUserDetails();
          // check AccountNotMatch before linking
          const _isDetailsNotMatch = await isDetailsNotMatched(linkpassIDToken);
          if (_isDetailsNotMatch) {
            setShowAccountNotMatch(true);
            setLinkAction('linkUserWithLinkpass');
            return;
          }
          // need to bind account
          linkUserWithLinkpassCallback(
            get(_userDetails, 'me'),
            linkpassIDToken
          );
        } else if (registerToken && registerToken !== 'undefined') {
          // check AccountNotMatch before linking
          const linkpassUserInfo = await registerWithLinkPassInfo(
            registerToken,
            linkpassIDToken
          );

          setLinkpassAccountInfo(
            get(
              linkpassUserInfo,
              'data.registerWithLinkPassInfo.LinkPassAuthTokenClaimDto'
            )
          );
          setSn2AccountInfo(
            get(linkpassUserInfo, 'data.registerWithLinkPassInfo.User')
          );

          const _diffInfo = get(
            linkpassUserInfo,
            'data.registerWithLinkPassInfo.LinkPassCompareWithUser'
          );
          setDiffInfo(_diffInfo);

          const _isDetailsNotMatch =
            _diffInfo.IsDiffEmail || _diffInfo.IsDiffPhone;

          if (_isDetailsNotMatch) {
            setShowAccountNotMatch(true);
            setLinkAction('registerWithLinkPass');
          } else if (forceOtp) {
            setVerifyOTP(true);
          } else {
            onSubmitOTPSuccess();
          }
        } else {
          const resp = await loginByLinkpass(linkpassIDToken);
          const errors = get(resp, 'error', null);

          if (!errors) {
            // already bound successfully before
            const _userToken = get(resp, 'data.loginByLinkPass');
            localStore.setValue('userToken', _userToken);
            window.location.pathname = OVERVIEW;
          } else {
            const errMsg = get(resp, 'error[0].message');
            const ERROR_CODE = get(resp, 'error[0].extensions.errorCode');
            // Need redirect to  'bind MFS account page'
            if (ERROR_CODE === 'ERR_INVALID_PARAM_USER_DOES_NOT_EXIST') {
              setTimeout(() => {
                window.location.pathname = `${CONNECT_TO_MFS_ACCOUNT}`;
                // then need to check information NotMatch !
              }, 2000);
            } else {
              showSnackBarMessage(errMsg, 'error');
              setTimeout(() => {
                window.location.pathname = `${LOGIN}`;
                // then need to check information NotMatch !
              }, 2000);
            }
          }
        }
      } catch (e) {
        localStore.setValue('linkpassIDToken', null);
        localStore.setValue('userToken', null);
        showSnackBarMessage(DEFAULT_ERROR, 'error');
        let navigationUrl = `${LOGIN}`;

        DatadogHandler.addError(e);
        DatadogHandler.sendLog(e, {}, 'error');

        if (registerToken && registerToken !== 'undefined') {
          navigationUrl = `/register?token=${registerToken}`;
        }
        logout({
          returnTo: window.location.origin,
        });
        setTimeout(() => {
          window.location.href = navigationUrl;
        }, 2000);
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const onProceedLinkAccount = () => {
    const _linkpassIDToken = localStore.getValue('linkpassIDToken');
    switch (linkAction) {
      case 'linkUserWithLinkpass': {
        linkUserWithLinkpassCallback(userDetails, _linkpassIDToken);
        break;
      }
      case 'registerWithLinkPass': {
        setShowAccountNotMatch(false);
        if (forceOtp) {
          setVerifyOTP(true);
        } else {
          onSubmitOTPSuccess();
        }

        break;
      }
      default: {
        break;
      }
    }
  };

  const onCancelContactDetailsNotMatch = () => {
    localStore.setValue('linkpassIDToken', null);
    setTimeout(() => {
      history.push(`${OVERVIEW}`);
    }, 1000);
    logout({
      returnTo: window.location.origin,
    });
  };

  if (!verifyOTP && !showAccountNotMatch) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {verifyOTP && (
        <OTP
          userId={sn2AccountInfo.ID}
          phoneNumber={sn2AccountInfo.mobilePhone}
          onSuccess={onSubmitOTPSuccess}
        />
      )}
      {showAccountNotMatch && (
        <ContactDetailsNotMatch
          onProceedLinkAccount={onProceedLinkAccount}
          linkpassUserInfoFromClaim={linkpassAccountInfo}
          sn2UserInfo={sn2AccountInfo}
          isDiffEmail={diffInfo.IsDiffEmail}
          isDiffPhone={diffInfo.IsDiffPhone}
          onCancel={onCancelContactDetailsNotMatch}
          schoolID={schoolID}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const currentSchoolDetails = schoolIDMap[get(state, 'schoolID.data')];
  const schoolName = get(currentSchoolDetails, 'name');
  return {
    schoolID: get(state, 'schoolID.data'),
    forceOtp: parseInt(
      get(state, 'schoolConfig.list.asObject.force_otp_on_registration', '0'),
      10
    ),
    schoolName,
    userDetails: get(state, 'userDetails.data'),
    linkpassUserInfoFromClaimData: getLinkpassInfo(
      get(
        state,
        'linkpassUserInfoFromClaim.data.data.linkpassUserInfoFromClaim'
      ),
      get(
        state,
        'linkpassSN2UserInfo.data.data.linkpassSn2UserInfo.LinkPassAuthTokenClaimDto'
      )
    ),
    sn2UserInfo: getSn2UserInfo(
      get(state, 'linkpassSN2UserInfo.data.data.linkpassSn2UserInfo.User'),
      get(state, 'userDetails.data')
    ),
  };
};

const mapDispatchToProps = {
  linkpassUserInfoFromClaim: linkpassUserInfoFromClaimAction,
  loginByLinkpass: loginByLinkpassAction,
  linkUserWithLinkPass: linkUserWithLinkPassAction,
  fetchUserDetails: fetchUserDetailsAction,
  showSnackBarMessage: showSnackBarMessageAction,
  registerWithLinkPass: registerWithLinkPassAction,
  registerWithLinkPassInfo: registerWithLinkPassInfoAction,
  validateUser: validateUserAction,
};

export default compose(
  withTranslation(),
  withTheme
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkpassHandler)));
