import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import {
  fetchAccessibleCentreForModule,
  listAllBusData,
} from '../../../../redux/actions';
import {
  constructFullName,
  getQueryParams,
  parseObjFields,
} from '../../../../utils';
import UrlPersistenceHOC from '../../HOC/UrlPersistenceHOC';
import CommonPageLayout from '../../../common/Page';
import BusForm from '../BusForm/index';
import {
  BUS_SETTINGS,
  CENTRE_SETTINGS_MANAGEMENT,
} from '../../../../routes/constants';
import Loader from '../../../common/Loader';

const parseQuery = () => {
  const fields = {
    centreID: 'int',
    page: 'int',
    perPage: 'int',
  };
  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      centreID: '',
      direction: '',
      status: '',
      year: '',
      page: 1,
      perPage: 10,
    },
    obj
  );
};

class BusEditMainComponent extends Component {
  constructor() {
    super();
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { fetchSelectedBusData, match, fetchCentres } = this.props;
    fetchCentres('bus_user');
    fetchSelectedBusData({ busEntryID: match.params.id });
  }

  handleCancel() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { busData, fkSchool, t, busDataIsLoading } = this.props;
    const crumbValues = [
      {
        label: t('common.centreManagementLabel'),
        link: CENTRE_SETTINGS_MANAGEMENT,
      },
      {
        label: t('bus_management.busManagementLabel'),
        link: BUS_SETTINGS,
      },
      {
        label: t('bus_management.editBusLabel'),
      },
    ];
    const busInfo = {
      driverName: constructFullName(
        get(busData, 'busUserByFkBusDriver.firstname', ''),
        get(busData, 'busUserByFkBusDriver.lastname', '')
      ),
      driverContactNoCountryCode: get(
        busData,
        'busUserByFkBusDriver.mobilePhoneCountryCode'
      ),
      driverContactNo: get(busData, 'busUserByFkBusDriver.mobilePhone'),
      attendantName: constructFullName(
        get(busData, 'busUserByFkBusAttendant.firstname', ''),
        get(busData, 'busUserByFkBusAttendant.lastname', '')
      ),
      attendantContactNoCountryCode: get(
        busData,
        'busUserByFkBusAttendant.mobilePhoneCountryCode'
      ),
      attendantContactNo: get(busData, 'busUserByFkBusAttendant.mobilePhone'),
      attendantPassCode: get(busData, 'busUserByFkBusAttendant.passcode'),
      busPlateNo: get(busData, 'bus.plateNumber'),
      directionType: get(busData, 'direction'),
      departureTime: get(busData, 'yearTime'),
      year: get(busData, 'yearTime') && moment(get(busData, 'yearTime')),
      associatedCentre: get(busData, 'bus.centre.ID'),
      status: get(busData, 'status') ? '1' : '0',
      driverID: get(busData, 'fkBusDriver'),
      attendantID: get(busData, 'fkBusAttendant'),
      busID: get(busData, 'fkBus'),
      busEntryID: get(busData, 'ID'),
      busName: get(busData, 'label'),
      busCompany: get(busData, 'busCompany'),
    };

    let renderFrag = (
      <BusForm
        isEdit
        busInfo={busInfo}
        fkSchool={fkSchool}
        handleCancel={this.handleCancel}
      />
    );
    if (busDataIsLoading) {
      renderFrag = <Loader />;
    }

    return (
      <CommonPageLayout
        title={t('bus_management.busManagementLabel')}
        breadcrumbList={crumbValues}
      >
        {renderFrag}
      </CommonPageLayout>
    );
  }
}

const mapStateToProps = state => ({
  busData: get(state, 'allBusData.data.data[0]'),
  centers: get(state, 'accessibleCentres', []),
  busDataIsLoading: get(state, 'allBusData.inProgress'),
});

const mapDispatchToProps = {
  fetchSelectedBusData: listAllBusData,
  fetchCentres: fetchAccessibleCentreForModule,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UrlPersistenceHOC(withRouter(BusEditMainComponent), parseQuery));
