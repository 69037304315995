import { get } from 'lodash';
import {
  FETCH_REG_CONSENT_FORM_SIGNER,
  GET_ALL_OFFLINE_CONSENT_FORM_ACTION,
} from '../actions/actionTypes';

export const regConsentFormSigners = (state = {}, action) => {
  if (action.type === FETCH_REG_CONSENT_FORM_SIGNER) {
    const data = get(action, 'value.data.getFormSigner');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const regAllOfflineConsentForm = (state = {}, action) => {
  if (action.type === GET_ALL_OFFLINE_CONSENT_FORM_ACTION) {
    const data = get(
      action,
      'value.data.getOfflineConsentFormByRegistration',
      null
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export default regConsentFormSigners;
