import gql from 'graphql-tag';
import {
  ENRICHMENT_CLASS_SCHEDULE_FRAGMENT,
  ENRICHMENT_CLASS_INFO_FRAGMENT,
} from './commonFragments';

export const GET_CHILD_INVOICE_FOR_MONTH = gql`
  query getChildInvoiceForMonth(
    $childID: Int!
    $enrichmentID: Int!
    $forMonth: Datetime!
  ) {
    getChildInvoiceForMonth(
      IDChild: $childID
      IDEnrichment: $enrichmentID
      forMonth: $forMonth
    ) {
      status
      invoiceDate
      invoiceNo
    }
  }
`;

export const REGISTER_WAITLIST_PROGRAM_SUBSCRIBE = gql`
  mutation subscribeEnrichmentProgram(
    $childID: Int!
    $enrichmentClassID: Int!
    $effectiveFrom: Datetime!
  ) {
    subscribeEnrichmentClassChild(
      IDChild: $childID
      IDEnrichmentClass: $enrichmentClassID
      effectiveFrom: $effectiveFrom
    ) {
      ID
    }
  }
`;

export const GET_CLASS_SCHEDULED_BY_START_DATE_AND_END_DATE = gql`
  query classOcurrenceByMonth(
    $enrichmentClassID: Int!
    $startDate: Datetime
    $endDate: Datetime
  ) {
    getEnrichmentClassOccurrencesByClassIdWithRange(
      IDEnrichmentClass: $enrichmentClassID
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCount
      data {
        ID
        to
        date
        from
        status
        displayStatus
        parentEnrichmentClassOccurrence {
          date
        }
        childEnrichmentClassOccurrence {
          date
        }
      }
    }
  }
`;

export const CANCEL_WAILIST_SUBSCRIPTION = gql`
  mutation cancelSubscription($enrichmentClassIDByChild: Int!) {
    cancelSubscription(enrichmentClassChildId: $enrichmentClassIDByChild)
  }
`;

export const GET_ENRICHMENT_ATTACHMENTS = gql`
  query(
    $programmeID: Int!
    $attachmentCategory: String!
    $filter: EnrichmentAttachmentFilter
    $pagination: Pagination
  ) {
    getEnrichmentAttachments(
      IDEnrichment: $programmeID
      attachmentCategory: $attachmentCategory
      filter: $filter
      pagination: $pagination
    ) {
      data {
        ID
        fileURL
        fileKey
        fileSize
        fileName
        type
      }
    }
  }
`;

export const GET_ENRICHMENT_CLASS_DETAIL = gql`
  query getClassDetail($classID: Int!) {
    enrichmentClassByID(id: $classID) {
      ID
      name
      status
      displayStatus
      currentSessionsActive
      upcomingSessionsActive
      createdAt
      updatedAt
      remark
      maxClassSize
      centre {
        ID
        label
      }
      currentSchedule {
        ID
        day
        startTime
        endTime
        effectiveFrom
        effectiveTo
        fkEnrichmentClass
      }
      enrichmentClassOccurrences {
        data {
          date
          status
        }
      }
      futureSchedules {
        data {
          ID
          day
          startTime
          endTime
          effectiveFrom
          effectiveTo
          fkEnrichmentClass
        }
      }
      enrichmentClassChildren(filter: { status: "enrolled" }) {
        totalCount
        data {
          child {
            childLevels {
              data {
                fkLevel
              }
            }
          }
        }
      }
      enrichmentClassSchedules {
        data {
          ID
          effectiveFrom
          effectiveTo
        }
      }
      enrichment {
        ID
        title
        minClassSize
        maxClassSize
        type
        enrichmentLevels {
          data {
            level {
              ID
              label
            }
          }
        }
        enrichmentPrices {
          data {
            amount
            effectiveFrom
            effectiveTo
          }
        }
        currentPrice {
          ID
          amount
          effectiveFrom
          effectiveTo
        }
        futurePrices {
          data {
            ID
            amount
            effectiveFrom
            effectiveTo
          }
        }
        sessionDuration
      }
      totalChildren
      enrichmentClassLevels {
        data {
          level {
            ID
            label
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_PROGRAMMES = gql`
  query getAllEnrichmentProgram(
    $centreID: Int
    $filter: EnrichmentFilter
    $pagination: Pagination
    $statuses: [String!]
  ) {
    getAllEnrichmentProgram(
      centreID: $centreID
      filter: $filter
      pagination: $pagination
      statuses: $statuses
    ) {
      totalCount
      data {
        ID
        title
        type
        active
        status
        sessionDuration
        displayStatus
        vendor {
          name
        }
        effectiveRange {
          from
          to
        }
        enrichmentPrices {
          data {
            amount
            effectiveFrom
            effectiveTo
            active
          }
        }
        currentPrice {
          ID
          amount
        }
        enrichmentLevels {
          data {
            level {
              ID
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_ENRICHMENT_PROGRAMMES = gql`
  query getAllEnrichmentProgram(
    $centreID: Int
    $filter: EnrichmentFilter
    $pagination: Pagination
    $statuses: [String!]
  ) {
    getAllEnrichmentProgram(
      centreID: $centreID
      filter: $filter
      pagination: $pagination
      statuses: $statuses
    ) {
      totalCount
      data {
        ID
        title
        type
        active
        status
        sessionDuration
        displayStatus
        effectiveRange {
          from
          to
        }
        enrichmentPrices {
          data {
            effectiveFrom
            effectiveTo
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_CLASS_LIST = gql`
  query getAllEnrichmentClass(
    $centreIds: [Int!]
    $actions: [String!]
    $missingAttendanceOnly: Boolean
    $nextCalendarDate: Datetime
    $vendorDecision: String
    $enrichmentIds: [Int!]
    $statuses: [String!]
    $unverifiedMonths: [Int!]
    $searchTerm: String
    $filter: EnrichmentClassFilter
    $pagination: Pagination
  ) {
    getAllEnrichmentClass(
      centreIds: $centreIds
      unverifiedMonths: $unverifiedMonths
      actions: $actions
      missingAttendanceOnly: $missingAttendanceOnly
      nextCalendarDate: $nextCalendarDate
      enrichmentIds: $enrichmentIds
      vendorDecision: $vendorDecision
      statuses: $statuses
      q: $searchTerm
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkEnrichment
        name
        enrichmentClassAttendances {
          totalCount
        }
        enrichmentClassLevels {
          data {
            level {
              label
            }
          }
        }
        enrichmentClassOccurrences {
          data {
            displayStatus
            status
          }
        }
        currentSchedule {
          effectiveFrom
          effectiveTo
          day
          startTime
          endTime
        }
        enrichmentClassSchedules(filter: { active: [0, 1] }) {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        futureSchedules {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        pastSchedules {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        enrichment {
          title
          minClassSize
          maxClassSize
        }
        centre {
          ID
          label
        }
        status
        displayStatus
        sessionsOnHoliday
        currentActiveEnrolments
        upcomingActiveEnrolments
        currentSessionsActive
        upcomingSessionsActive
        waitlistCount
        waitlistCountInUpcomingMonth
        totalChildrenNextMonth
        vendorDecision {
          key
          value
        }
        enrichmentClassLevels {
          data {
            level {
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_CLASS_VENDOR_LIST = gql`
  query getAllEnrichmentClassVendor(
    $centreIds: [Int!]
    $nextCalendarDate: Datetime
    $vendorDecision: String
    $enrichmentIds: [Int!]
    $missingAttendanceMonths: [Int!]
    $statuses: [String!]
    $isOngoing: Boolean
    $filter: EnrichmentClassFilter
    $pagination: Pagination
  ) {
    getAllEnrichmentClassVendor(
      centreIds: $centreIds
      isOngoing: $isOngoing
      nextCalendarDate: $nextCalendarDate
      enrichmentIds: $enrichmentIds
      statuses: $statuses
      vendorDecision: $vendorDecision
      missingAttendanceMonths: $missingAttendanceMonths
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkEnrichment
        name
        enrichmentClassAttendances {
          totalCount
        }
        enrichmentClassLevels {
          data {
            level {
              label
            }
          }
        }
        enrichmentClassOccurrences {
          data {
            displayStatus
            status
          }
        }
        currentSchedule {
          effectiveFrom
          effectiveTo
          day
          startTime
          endTime
        }
        enrichmentClassSchedules(filter: { active: [0, 1] }) {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        futureSchedules {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        pastSchedules {
          data {
            day
            startTime
            endTime
            effectiveFrom
            effectiveTo
          }
        }
        enrichment {
          title
          minClassSize
          maxClassSize
        }
        centre {
          ID
          label
        }
        status
        displayStatus
        sessionsOnHoliday
        currentActiveEnrolments
        upcomingActiveEnrolments
        currentSessionsActive
        upcomingSessionsActive
        waitlistCount
        totalChildrenNextMonth
        maxClassSize
        vendorDecision {
          key
          value
        }
        enrichmentClassLevels {
          data {
            level {
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_REASSIGN_CLASS_LIST = gql`
  query getReassignClassListForClassChild(
    $enrichmentClassChildID: Int!
    $effectiveDate: Datetime!
    $filter: EnrichmentClassFilter
  ) {
    getReassignClassListForClassChild(
      IDEnrichmentClassChild: $enrichmentClassChildID
      effectiveDate: $effectiveDate
      filter: $filter
    ) {
      totalCount
      data {
        ID
        name
        status
        remainingSlots(at: $effectiveDate)
      }
    }
  }
`;

export const GET_ENRICHMENT_ENROLMENT_COUNT = gql`
  query getEnrolmentChildCount(
    $filterForThisMonthCount: EnrichmentClassChildFilter
    $filterForNextMonthCount: EnrichmentClassChildFilter
    $filterForWithdrawalCount: EnrichmentClassChildFilter
    $startOfCurrentMonth: Datetime
    $startOfNextMonth: Datetime
  ) {
    currentMonthChildrenCount: getAllEnrichmentClassChild(
      period: $startOfCurrentMonth
      filter: $filterForThisMonthCount
    ) {
      totalCount
    }
    nextMonthChildrenCount: getAllEnrichmentClassChild(
      period: $startOfNextMonth
      filter: $filterForNextMonthCount
    ) {
      totalCount
      data {
        status
      }
    }
    withdrawnChildrenCount: getAllEnrichmentClassChild(
      filter: $filterForWithdrawalCount
    ) {
      totalCount
    }
  }
`;

export const GET_ENRICHMENT_CLASS_ENROLMENT_LIST = gql`
  query getAllEnrichmentClassChild(
    $period: Datetime
    $previousMonthPeriod: Datetime
    $nextMonthPeriod: Datetime
    $withdrawalStatus: [String!]
    $refundStatus: [String!]
    $searchTerm: String
    $filter: EnrichmentClassChildFilter
    $pagination: Pagination
  ) {
    getAllEnrichmentClassChild(
      period: $period
      q: $searchTerm
      refundStatuses: $refundStatus
      withdrawalStatuses: $withdrawalStatus
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        fkEnrichmentClass
        effectiveFrom
        effectiveTo
        status
        remark
        active
        createdAt
        refundStatus
        withdrawalStatus
        isNotRegisteredChild
        paymentStatus(forMonth: $period)
        previousMonthPaymentStatus: paymentStatus(
          forMonth: $previousMonthPeriod
        )
        nextMonthPaymentStatus: paymentStatus(forMonth: $nextMonthPeriod)
        updatedAt
        currentLevel {
          level {
            label
          }
        }
        currentClass {
          class {
            label
          }
        }
        enrichmentWithdrawal {
          ID
          initiatedDate
          parentInitiated
          userByCreatedBy {
            ID
            firstname
            lastname
          }
          userByApprovedBy {
            firstname
            lastname
          }

          userByCancelledBy {
            firstname
            lastname
            isParent
          }
          effectiveDate
          approved
          rejected
          cancelled
          rejectReason
        }
        adhocRefundType
        isMakeRefundLastMonth
        refundInfo {
          UnUtilizedAmount
          NextMonthFee
          Initiator {
            firstname
            lastname
          }
          Approver {
            ID
            firstname
            lastname
          }
        }
        enrichmentClass {
          displayStatus
          currentSessionsActive
          upcomingSessionsActive
          maxClassSize
          enrichment {
            title
            ID
          }
        }
        child {
          ID
          firstname
          lastname
          currentLevel {
            level {
              label
            }
          }
          currentClass {
            class {
              label
            }
          }
          enrichmentClassChildren {
            data {
              fkChild
              effectiveFrom
              enrichmentClass {
                name
                fkEnrichment
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_AVAILABLE_CHILDREN_FOR_ENRICHMENT_CLASS = gql`
  query getAllAvailableChildForEnrichmentClass(
    $classID: Int!
    $searchTerm: String
    $effectiveFrom: Datetime!
  ) {
    getAllAvailableChildForEnrichmentClass(
      classID: $classID
      q: $searchTerm
      effectiveFrom: $effectiveFrom
    ) {
      totalCount
      data {
        ID
        firstname
        lastname
        childLevelAt(time: $effectiveFrom) {
          level {
            label
          }
        }
      }
    }
  }
`;

export const SAVE_ENRICHMENT_CLASS = gql`
  mutation saveEnrichmentClass($dto: EnrichmentClassCreateUpdateDTO!) {
    createOrSaveEnrichmentClass(dto: $dto) {
      ID
      name
      enrichment {
        ID
        title
      }
      centre {
        ID
        label
      }
    }
  }
`;

export const SAVE_ENRICHMENT_TRAINER = gql`
  mutation createEnrichmentTrainerUser(
    $name: String!
    $email: String!
    $phoneNumberCountryCode: String
    $phoneNumber: String
    $centreIds: [Int!]!
    $documents: [VendorTrainerDocumentDto!]!
  ) {
    createEnrichmentTrainerUser(
      name: $name
      email: $email
      phoneNumberCountryCode: $phoneNumberCountryCode
      phoneNumber: $phoneNumber
      centreIds: $centreIds
      documents: $documents
    ) {
      ID
    }
  }
`;

export const WITHDRAW_CHILD_FROM_EP_CLASS = gql`
  mutation withdrawEnrichmentClassChild(
    $childID: Int!
    $initiatedDate: Datetime!
    $withdrawDate: Datetime!
    $reason: String!
    $refund: Boolean!
  ) {
    withdrawEnrichmentClassChild(
      IDEnrichmentClassChild: $childID
      initiatedDate: $initiatedDate
      effectiveDate: $withdrawDate
      remark: $reason
      refund: $refund
    )
  }
`;

export const REASSIGN_CHILD_FROM_EP_CLASS = gql`
  mutation reassignEnrichmentClassChild(
    $enrichmentClassChildID: Int!
    $toIDClass: Int!
    $effectiveDate: Datetime!
    $notifyParent: Boolean!
  ) {
    reassignEnrichmentClassChild(
      IDEnrichmentClassChild: $enrichmentClassChildID
      toIDClass: $toIDClass
      effectiveDate: $effectiveDate
      notifyParent: $notifyParent
    )
  }
`;

export const REGISTER_CHILD_IN_EP_CLASS = gql`
  mutation registerWaitlistedClassChild($enrichmentClassChildID: Int!) {
    registerWaitlistedClassChild(
      IDEnrichmentClassChild: $enrichmentClassChildID
    ) {
      ID
    }
  }
`;

export const SAVE_ENRICHMENT_CLASS_SCHEDULES = gql`
  mutation createOrSaveEnrichmentClassSchedules(
    $IDEnrichmentClass: Int!
    $dtoList: [EnrichmentClassScheduleCreateUpdateDTO!]!
  ) {
    createOrSaveEnrichmentClassSchedules(
      IDEnrichmentClass: $IDEnrichmentClass
      dtoList: $dtoList
    ) {
      totalCount
      data {
        ID
        fkEnrichmentClass
        day
        startTime
        endTime
        effectiveFrom
        effectiveTo
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const CANCEL_ENRICHMENT_CLASS = gql`
  mutation cancelClass(
    $classID: Int!
    $effectiveDate: Datetime!
    $remark: String!
    $notifyParent: Boolean!
  ) {
    cancelEnrichmentClass(
      IDEnrichmentClass: $classID
      cancelDate: $effectiveDate
      remark: $remark
      notifyParent: $notifyParent
    )
  }
`;

export const DELETE_ENRICHMENT_CLASS = gql`
  mutation deleteClass($classID: Int!) {
    removeEnrichmentClassByID(ID: $classID)
  }
`;

export const DELETE_ENRICHMENT_CLASS_SCHEDULE = gql`
  mutation deleteEnrichmentClassScheduleById(
    $IDEnrichmentClass: Int!
    $IDEnrichmentClassSchedule: Int!
  ) {
    deleteEnrichmentClassScheduleById(
      IDEnrichmentClass: $IDEnrichmentClass
      IDEnrichmentClassSchedule: $IDEnrichmentClassSchedule
    )
  }
`;

export const ADD_CHILD_TO_ENRICHMENT_CLASS = gql`
  mutation enrolEnrichmentClassChild(
    $classID: Int!
    $childID: Int!
    $effectiveFrom: Datetime!
  ) {
    enrolEnrichmentClassChild(
      IDEnrichmentClass: $classID
      IDChild: $childID
      effectiveFrom: $effectiveFrom
    ) {
      ID
      fkChild
      fkEnrichmentClass
      effectiveFrom
      effectiveTo
      status
    }
  }
`;

export const DOWNLOAD_ATTENDANCE_LIST = gql`
  query exportAttendanceList($classID: Int!, $period: Datetime!) {
    exportAttendanceList(IDEnrichmentClass: $classID, period: $period)
  }
`;

export const GET_ENRICHMENT_ATTENDANCE_CLASS_SUMMARY = gql`
  query getEnrichmentClassSummary(
    $classID: Int!
    $sessionStatuses: [String!]
    $attendanceStatuses: [String!]
    $year: Int
    $pagination: Pagination
  ) {
    getEnrichmentClassSummary(
      IDClass: $classID
      sessionStatuses: $sessionStatuses
      year: $year
      pagination: $pagination
      attendanceStatuses: $attendanceStatuses
    ) {
      totalCount
      data {
        initialMarkedUser {
          firstname
          lastname
        }
        enrichmentClassOccurrence {
          ID
          fkEnrichmentClass
          fkEnrichmentClassOccurrence
          date
          status
          displayStatus
          enrichmentClassOccurrence {
            date
          }
          isVendorInvoiceCreated
          enrichmentClass {
            name
            currentSessionsActive
            upcomingSessionsActive
            displayStatus
            centre {
              label
            }
            enrichment {
              title
            }
            currentSchedule {
              effectiveFrom
              effectiveTo
            }
            futureSchedules {
              data {
                effectiveFrom
                effectiveTo
              }
            }
          }
        }
      }
    }
  }
`;

export const SAVE_PROGRAMME = gql`
  mutation saveEnrichmentDraft($dto: EnrichmentDraftItemCreateDTO!) {
    saveEnrichmentDraft(dto: $dto) {
      ID
      fkDraft
      fkEnrichment
      fkVendor
      fkSchool
      type
      title
      cover
      accPacCode
      description
      gst
      cda
      sessionDuration
      sessionPerWeek
      sessionPerMonth
      minClassSize
      maxClassSize
      active
      createdAt
      updatedAt
    }
  }
`;

export const PUBLISH_PROGRAM = gql`
  mutation publishEnrichmentDraft($dto: EnrichmentDraftItemCreateDTO!) {
    publishEnrichmentDraft(dto: $dto) {
      ID
      title
    }
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation saveEnrichment($dto: EnrichmentCreateDTO!) {
    saveEnrichment(dto: $dto) {
      ID
    }
  }
`;

export const GET_ADHOC_REFUND_INFO = gql`
  query getSessionRefundInformation($IDEnrichmentClass: Int!, $IDChild: Int!) {
    getSessionRefundInformation(
      IDEnrichmentClass: $IDEnrichmentClass
      IDChild: $IDChild
    ) {
      date
      status
    }
  }
`;

export const COVER_PHOTO_UPLOAD = gql`
  mutation getEnrichmentCoverPhotoUploadUrl($vendorID: Int!, $file: Upload!) {
    getEnrichmentCoverPhotoUploadUrl(IDVendor: $vendorID, file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const DOCUMENT_ATTACHMENT_UPLOAD = gql`
  mutation getEnrichmentAttachmentUploadURL($vendorID: Int!, $file: Upload!) {
    getEnrichmentAttachmentUploadURL(IDVendor: $vendorID, file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const GET_ALL_ENRICHMENT_VENDOR = gql`
  query getAllEnrichmentVendor($pagination: Pagination) {
    getAllEnrichmentVendor(pagination: $pagination) {
      data {
        ID
        name
        fkSchool
        fkUser
      }
    }
  }
`;

export const GET_ALL_ENRICHMENT_DRAFT = gql`
  query findAllEnrichmentDraft(
    $filter: EnrichmentDraftFilter
    $pagination: Pagination
  ) {
    findAllEnrichmentDraft(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        label
        status
        enrichmentDraftItems {
          data {
            title
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_PROGRAM_LIST = gql`
  query getEnrichmentClassesForChild(
    $childID: Int!
    $centreID: Int!
    $status: String
    $dataAt: Datetime!
    $enrichmentType: EnrichmentType
    $month: Datetime
    $filter: EnrichmentClassFilter
    $pagination: Pagination
  ) {
    getEnrichmentClassesForChild(
      IDChild: $childID
      IDCentre: $centreID
      status: $status
      at: $dataAt
      enrichmentType: $enrichmentType
      month: $month
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        name
        fkEnrichment
        currentFee
        displayStatus
        availableFrom
        childRegistrationStatus
        waitlistCount
        currentSchedule {
          ...EnrichmentClassScheduleFragment
        }
        remainingSlots
        childRegistrationStatus
        enrichment {
          ID
          cover
          title
          currentPrice {
            amount
          }
          gst
        }
        futureSchedules {
          data {
            ...EnrichmentClassScheduleFragment
          }
        }
      }
    }
  }
  ${ENRICHMENT_CLASS_SCHEDULE_FRAGMENT}
`;

export const GET_ENRICHMENT_PROGRAM_DETAIL = gql`
  query getEnrichmentProgramDetail($id: Int!, $filter: EnrichmentFilter) {
    enrichmentByID(id: $id, filter: $filter) {
      ID
      fkVendor
      createdAt
      updatedAt
      title
      type
      displayStatus
      cover
      coverURL
      description
      gst
      cda
      sessionDuration
      sessionPerWeek
      sessionPerMonth
      minClassSize
      maxClassSize
      status
      accPacCode
      gst
      cda
      remark
      notify
      effectiveRange {
        from
        to
      }
      currentCost {
        ID
        effectiveFrom
        effectiveTo
        amount
      }
      enrichmentCosts {
        data {
          fkEnrichment
        }
      }
      futureCosts {
        data {
          effectiveFrom
          effectiveTo
          amount
          ID
        }
      }
      futurePrices {
        data {
          effectiveFrom
          effectiveTo
          amount
          ID
        }
      }
      pastCosts {
        data {
          effectiveFrom
          effectiveTo
          amount
          ID
        }
      }
      pastPrices {
        data {
          effectiveFrom
          effectiveTo
          amount
          ID
        }
      }
      currentPrice {
        ID
        effectiveFrom
        effectiveTo
        amount
        fkEnrichment
      }

      enrichmentLevels {
        data {
          level {
            ID
            label
          }
        }
      }
      enrichmentClasses {
        totalCount
        data {
          enrichmentClassLevels {
            data {
              level {
                ID
                label
              }
            }
          }
        }
      }
      enrichmentAttachments {
        data {
          ID
          type
          fileName
          fileKey
          fkEnrichment
          fileURL
          fileSize
          mime
        }
      }
      enrichmentCentres {
        data {
          centre {
            ID
            label
          }
        }
      }
    }
  }
`;

export const GET_ENRICHMENT_PROGRAMME_DRAFT_BY_ID = gql`
  query enrichmentDraftByID($draftID: Int!, $filter: EnrichmentDraftFilter) {
    enrichmentDraftByID(id: $draftID, filter: $filter) {
      ID
      enrichmentDraftItems {
        data {
          ID
          fkVendor
          title
          cover
          description
          type
          gst
          cda
          sessionDuration
          sessionPerWeek
          sessionPerMonth
          minClassSize
          maxClassSize
          accPacCode
          gst
          cda
          documents
          media
          notify
          cost {
            amount
            effectiveFrom
            effectiveTo
          }
          price {
            amount
            effectiveFrom
            effectiveTo
          }
          levels {
            data {
              ID
              label
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const REMOVE_ENRICHMENT_BY_ID = gql`
  mutation removeEnrichmentByID($id: Int!) {
    removeEnrichmentByID(ID: $id)
  }
`;

export const DEACTIVATE_ENRICHMENT = gql`
  mutation deactivateEnrichment(
    $IDEnrichment: Int!
    $effectiveDate: Datetime!
    $remark: String!
  ) {
    deactivateEnrichment(
      IDEnrichment: $IDEnrichment
      effectiveDate: $effectiveDate
      remark: $remark
    )
  }
`;

export const SAVE_ENRICHMENT_COST = gql`
  mutation saveEnrichmentCostPrice(
    $programmeID: Int!
    $costList: [EnrichmentPriceCostCreateDTO!]!
  ) {
    saveEnrichmentCost(IDEnrichment: $programmeID, costs: $costList) {
      data {
        ID
        effectiveFrom
        effectiveTo
      }
    }
  }
`;

export const SAVE_ENRICHMENT_PRICE = gql`
  mutation saveEnrichmentPrice(
    $programmeID: Int!
    $priceList: [EnrichmentPriceCostCreateDTO!]!
  ) {
    saveEnrichmentPrice(IDEnrichment: $programmeID, prices: $priceList) {
      data {
        ID
        effectiveFrom
        effectiveTo
      }
    }
  }
`;

export const GET_ALL_ENRICHMENT_CLASS_SESSION = gql`
  query getAllEnrichmentClassSession(
    $enrichmentClassID: Int!
    $isClosure: Boolean
    $statuses: [String!]
    $months: [Datetime!]
    $filter: EnrichmentClassOccurrenceFilter
    $pagination: Pagination
  ) {
    getAllEnrichmentClassSession(
      enrichmentClassID: $enrichmentClassID
      isClosure: $isClosure
      statuses: $statuses
      months: $months
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        isCentreClosure
        fkEnrichmentClass
        fkEnrichmentClassOccurrence
        enrichmentClassOccurrence {
          date
          from
          to
          remarks
          updatedAt
          user {
            firstname
            lastname
          }
        }
        status
        from
        to
        remarks
        accessLink
        accessLinkDescription
        cancelAffectedChildren
        refundFee
        displayStatus
        date
        createdAt
        updatedAt
        user {
          firstname
          lastname
        }
        enrichmentClass {
          name
          centre {
            label
          }
          enrichment {
            title
            type
          }
        }
      }
    }
  }
`;

export const REMOVE_CARD_FROM_CHILD = gql`
  mutation removeCard($cardId: Int!) {
    removeCard(IDCard: $cardId)
  }
`;

export const GET_ALL_SAVED_CARDS = gql`
  query getAllSavedCardDetails {
    getCards {
      cardId
      tokenId
      status
      isDefault
      card {
        brand
        expiry
        fundingMethod
        number
        scheme
      }
      addedBy {
        firstname
        lastname
      }
      pgwStatus
      updated_at
      used_at
    }
  }
`;

export const GET_ALL_ASSIGNED_CARDS = gql`
  query getAllAssignedCards {
    assignedCards {
      card {
        tokenId
        cardId
        status
        isDefault
        card {
          brand
          expiry
          fundingMethod
          number
          scheme
        }
        pgwStatus
        updated_at
        used_at
      }
      child {
        ID
        firstname
        lastname
        imageKey
      }
    }
  }
`;

export const SUBMIT_CARD_SESSION_DATA = gql`
  mutation submitCardDetails($sessionID: String!, $isDefault: Boolean) {
    tokenizePaymentCard(sessionId: $sessionID, isDefault: $isDefault)
  }
`;

export const SET_DEFAULT_CARD = gql`
  mutation setCardAsDefault($cardId: Int!) {
    setCardAsDefault(cardId: $cardId)
  }
`;

export const ASSIGN_CARD_TO_CHILD = gql`
  mutation assignCardToChild(
    $childID: Int!
    $cardID: Int!
    $status: EnrichmentPaymentCardRelationStatus!
  ) {
    assignCardToChild(childId: $childID, cardId: $cardID, status: $status)
  }
`;

export const GET_ATTENDANCE_BY_SESSION = gql`
  query getAttendanceBySession(
    $enrichmentClassID: Int!
    $enrichmentClassOccurrenceID: Int!
  ) {
    getAttendanceBySession(
      IDEnrichmentClass: $enrichmentClassID
      IDEnrichmentClassOccurrence: $enrichmentClassOccurrenceID
    ) {
      enrichmentClass {
        ID
        name
        enrichment {
          fkVendor
        }
        centre {
          ID
        }
        enrichmentClassChildren {
          data {
            ID
            paymentStatus
          }
        }
      }
      enrichmentClassOccurrence {
        ID
        displayStatus
        updatedAt
      }
      initialMarkedUser {
        firstname
        lastname
      }
      children {
        child {
          firstname
          lastname
          ID
        }
        attendanceId
        enrichmentClassChildId
        status
        invoiceNo
        paidOn
        paymentStatus
      }
    }
  }
`;

export const GET_ENRICHMENT_CHILD_INVOICES = gql`
  query getEnrichmentChildInvoiceList(
    $enrichmentID: [Int!]
    $enrichmentClassID: [Int!]
    $from: Datetime
    $to: Datetime
    $ftsInput: String
    $pagination: Pagination
    $filter: EnrichmentChildInvoiceFilter
    $status: [EnrichmentChildInvoiceStatus!]
  ) {
    getEnrichmentChildInvoiceList(
      IDEnrichment: $enrichmentID
      IDEnrichmentClass: $enrichmentClassID
      from: $from
      to: $to
      ftsInput: $ftsInput
      pagination: $pagination
      status: $status
      filter: $filter
    ) {
      totalCount
      data {
        ID
        fkChild
        invoiceNo
        label
        invoiceDate
        invoiceDateYearMonth
        grandTotal
        pdfURLKey
        status
        updatedAt
        voidBy
        voidRemark
        child {
          firstname
          lastname
          birthCertificate
        }
        enrichment {
          title
        }
        centre {
          label
        }
        enrichmentClass {
          name
        }
        createdAt
      }
    }
  }
`;

export const GET_STATIC_CODE = gql`
  query codesByLabelFkSchool(
    $label: String!
    $fkSchool: Int!
    $filter: CodeFilter
    $pagination: Pagination
  ) {
    codesByLabelFkSchool(
      label: $label
      fkSchool: $fkSchool
      filter: $filter
      pagination: $pagination
    ) {
      data {
        ID
      }
    }
  }
`;

export const GET_ENRICHMENT_VENDOR_INVOICES = gql`
  query getEnrichmentVendorInvoiceList(
    $vendorIDs: [Int!]
    $centreIDs: [Int!]
    $from: Datetime
    $to: Datetime
    $searchString: String
    $pagination: Pagination
  ) {
    getEnrichmentVendorInvoiceList(
      vendorIDs: $vendorIDs
      centreIDs: $centreIDs
      from: $from
      to: $to
      searchString: $searchString
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkVendor
        vendor {
          ID
          name
          code
        }
        fkSchool
        fkCentre
        centre {
          ID
          label
        }
        invoiceNo
        label
        totalTaxAmount
        totalAmount
        grandTotal
        pdfURLKey
        status
        invoiceDate
        invoiceDateYearMonth
        createdAt
      }
    }
  }
`;

export const GET_CLASS_OCCURRENCE_LIST = gql`
  query enrichmentClassOccurrencesByFkEnrichmentClass(
    $enrichmentClassID: Int!
    $filter: EnrichmentClassOccurrenceFilter
  ) {
    enrichmentClassOccurrencesByFkEnrichmentClass(
      IDEnrichmentClass: $enrichmentClassID
      filter: $filter
    ) {
      totalCount
      data {
        ID
        date
        enrichmentClass {
          enrichment {
            fkVendor
          }
          centre {
            ID
          }
        }
      }
    }
  }
`;

export const SUBMIT_ATTENDANCE_BY_SESSION = gql`
  mutation submitAttendanceBySession(
    $enrichmentClassID: Int!
    $enrichmentClassOccurrenceID: Int!
    $attendanceList: [EnrichmentAttendanceDto!]!
  ) {
    submitAttendanceBySession(
      IDEnrichmentClass: $enrichmentClassID
      IDEnrichmentClassOccurrence: $enrichmentClassOccurrenceID
      attendanceList: $attendanceList
    )
  }
`;

export const VERIFY_ATTENDANCE_BY_SESSION = gql`
  mutation verifyAttendanceBySession(
    $enrichmentClassID: Int!
    $enrichmentClassOccurrenceID: Int!
    $attendanceList: [EnrichmentAttendanceDto!]!
  ) {
    verifyAttendanceBySession(
      IDEnrichmentClass: $enrichmentClassID
      IDEnrichmentClassOccurrence: $enrichmentClassOccurrenceID
      attendanceList: $attendanceList
    )
  }
`;

export const DELETE_PROGRAMME_DRAFT_BY_ID = gql`
  mutation removeEnrichmentDraftByID($draftID: Int!) {
    removeEnrichmentDraftByID(ID: $draftID)
  }
`;

export const VOID_ADHOC_REFUND = gql`
  mutation voidAdhocRefund($refundID: Int!, $remark: String!) {
    voidAdhocRefund(refundID: $refundID, remark: $remark)
  }
`;

export const RESCHEDULE_ENRICHMENT_CLASS_SESSION = gql`
  mutation rescheduleEnrichmentClassSession(
    $IDEnrichmentClass: Int!
    $dto: RescheduleEnrichmentClassSessionDto!
    $notifyParent: Boolean!
  ) {
    rescheduleEnrichmentClassSession(
      IDEnrichmentClass: $IDEnrichmentClass
      dto: $dto
      notifyParent: $notifyParent
    ) {
      ID
    }
  }
`;

export const CANCEL_ENRICHMENT_CLASS_SESSION = gql`
  mutation cancelEnrichmentClassSessions(
    $IDEnrichmentClass: Int!
    $IDEnrichmentClassOccurrences: [Int!]!
    $remarks: String!
    $notifyParent: Boolean!
  ) {
    cancelEnrichmentClassSessions(
      IDEnrichmentClass: $IDEnrichmentClass
      IDEnrichmentClassOccurrences: $IDEnrichmentClassOccurrences
      remarks: $remarks
      notifyParent: $notifyParent
    )
  }
`;

export const DOWNLOAD_ATTENDANCE_LIST_FOR_MONTH = gql`
  query exportAttendanceList($classID: Int!, $from: Datetime!, $to: Datetime!) {
    exportAttendanceList(IDEnrichmentClass: $classID, from: $from, to: $to)
  }
`;

export const GET_APPROVER_LIST = gql`
  query getEnrichmentApprovers($filter: UserFilter) {
    getEnrichmentApprovers(filter: $filter) {
      ID
      firstname
      lastname
    }
  }
`;
export const CONTINUE_ENRICHMENT_CLASS_BY_VENDOR = gql`
  mutation continueEnrichmentClassByVendor(
    $classIDs: [Int!]!
    $forMonth: Datetime!
  ) {
    continueEnrichmentClassByVendor(
      enrichmentClassIDs: $classIDs
      forMonth: $forMonth
    )
  }
`;

export const SAVE_ACCESS_LINK = gql`
  mutation addOrEditOccurrenceAccessLink(
    $IDEnrichmentClassOccurrence: Int!
    $IDEnrichmentClass: Int!
    $accessLink: String!
    $accessLinkDescription: String!
  ) {
    addOrEditOccurrenceAccessLink(
      IDEnrichmentClassOccurrence: $IDEnrichmentClassOccurrence
      IDEnrichmentClass: $IDEnrichmentClass
      accessLink: $accessLink
      accessLinkDescription: $accessLinkDescription
    ) {
      accessLink
    }
  }
`;

export const INITIATE_ADHOC_REFUND = gql`
  mutation initiateAdhocRefundForChild(
    $IDClass: Int!
    $IDChild: Int!
    $IDOccurrenceList: [Int!]!
    $IDApprover: Int!
    $reason: String!
  ) {
    initiateAdhocRefundForChild(
      IDClass: $IDClass
      IDChild: $IDChild
      IDOccurrenceList: $IDOccurrenceList
      IDApprover: $IDApprover
      reason: $reason
    )
  }
`;

export const GET_REFUND_OCCURRENCE_LIST = gql`
  query getAllEnrichmentOccurrenceRefund(
    $IDEnrichmentClass: Int!
    $IDChild: Int!
  ) {
    getAllEnrichmentOccurrenceRefund(
      IDEnrichmentClass: $IDEnrichmentClass
      IDChild: $IDChild
    ) {
      EnrichmentClassOccurrence {
        ID
        date
      }
      paymentStatus
    }
  }
`;

export const TOTAL_REFUND_FEE_FOR_CANCEL_SESSIONS = gql`
  query getTotalRefundFeeForCancellingSessions(
    $enrichmentID: Int!
    $classOccurrenceIDs: [Int!]!
  ) {
    getTotalRefundFeeForCancellingSessions(
      IDEnrichmentClass: $enrichmentID
      IDEnrichmentClassOccurrences: $classOccurrenceIDs
    )
  }
`;

export const ENRICHMENT_CLASS_WAITLIST_MANAGEMENT = gql`
  query GetAllEnrichmentClassWaitlistManagement(
    $centreIds: [Int!]
    $enrichmentIds: [Int!]
    $forDate: Datetime
    $filter: EnrichmentClassFilter
    $pagination: Pagination
  ) {
    GetAllEnrichmentClassWaitlistManagement(
      centreIds: $centreIds
      enrichmentIds: $enrichmentIds
      forDate: $forDate
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;

export const GET_ENRICHMENT_CHILD_REFUND_LIST = gql`
  query getEnrichmentChildRefundList(
    $IDEnrichment: [Int!]
    $IDEnrichmentClass: [Int!]
    $status: String
    $from: Datetime
    $to: Datetime
    $ftsInput: String
    $filter: EnrichmentRefundFilter
    $pagination: Pagination
  ) {
    getEnrichmentChildRefundList(
      IDEnrichment: $IDEnrichment
      IDEnrichmentClass: $IDEnrichmentClass
      status: $status
      from: $from
      to: $to
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        refundNo
        effectiveDate
        status
        totalAmount
        pdfURLKey
        type
        child {
          firstname
          lastname
          birthCertificate
        }
        enrichment {
          title
        }
        enrichmentClass {
          name
          centre {
            label
          }
        }
        enrichmentManualRefund {
          paymentMode
          payNowNo
          bankAccountNo
          paymentNo
          manualRefundReason
          paymentDate
        }
        enrichmentRefundMeta {
          initiatedBy {
            ID
            firstname
            lastname
          }
          approvedBy {
            ID
            firstname
            lastname
          }
          refundReason
        }
        cancelledBy
        cancelledRemark
        updatedAt
      }
    }
  }
`;

export const INITIATE_MANUAL_REFUND = gql`
  mutation enrichmentRefundManually(
    $idRefund: Int!
    $paymentMode: EnrichmentManualRefundPaymentMode!
    $paymentNo: String!
    $paymentDate: Datetime!
    $payNowNo: String
    $bankAccountNo: String
  ) {
    enrichmentRefundManually(
      idRefund: $idRefund
      paymentMode: $paymentMode
      paymentNo: $paymentNo
      paymentDate: $paymentDate
      payNowNo: $payNowNo
      bankAccountNo: $bankAccountNo
    ) {
      active
    }
  }
`;

export const CHILD_CLASSES_OCCURRENCE_DETAILS = gql`
  query getClassOccurrencesDetailForChild(
    $IDEnrichmentClass: Int!
    $IDChild: Int!
  ) {
    getClassOccurrencesDetailForChild(
      IDEnrichmentClass: $IDEnrichmentClass
      IDChild: $IDChild
    ) {
      currentMonth {
        month
        occurrences {
          IDOccurrence
          dayOccurrence
          dateOccurrence
          timeFrom
          timeTo
          occurrenceStatus
          paymentStatus
          dateRescheduleTo
          dateRescheduleFrom
        }
      }
      upcomingMonths {
        month
        occurrences {
          IDOccurrence
          dayOccurrence
          dateOccurrence
          timeFrom
          timeTo
          occurrenceStatus
          paymentStatus
          dateRescheduleTo
          dateRescheduleFrom
        }
      }
      pastMonths {
        month
        occurrences {
          IDOccurrence
          dayOccurrence
          dateOccurrence
          timeFrom
          timeTo
          occurrenceStatus
          paymentStatus
          dateRescheduleTo
          dateRescheduleFrom
        }
      }
    }
  }
`;

export const CHECK_VENDOR_CREATED_FOR_OCCURRENCE = gql`
  query checkVendorInvoiceCreatedForOccurrence(
    $IDVendor: Int!
    $IDCentre: Int!
    $IDOccurrence: Int!
  ) {
    CheckVendorInvoiceCreatedForOccurrence(
      IDVendor: $IDVendor
      IDCentre: $IDCentre
      IDOccurrence: $IDOccurrence
    )
  }
`;

export const GET_ALL_CENTRES_WITH_EXISTNG_VENDOR = gql`
  query getAllCentreWithExistingClassVendor(
    $filter: CentreFilter
    $pagination: Pagination
  ) {
    getAllCentreWithExistingClassVendor(
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        label
        fkSchool
      }
    }
  }
`;

export const GET_CARD_TRANSACTION = gql`
  query getPaymentTransactionsByUser {
    getPaymentTransactionsByUser {
      cardId
      orderId
      errMsg
      errCode
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_VENDOR_PAYABLE_LIST = gql`
  query getEnrichmentVendorPaymentList(
    $filter: EnrichmentVendorPaymentFilter
    $pagination: Pagination
  ) {
    getEnrichmentVendorPaymentList(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        status
        paymentDate
        importedAt
        giroExportFileURLKey
        updatedAt
        exportedAt
      }
    }
  }
`;

export const GET_VENDOR_PAYABLE_GIRO_UPLOAD_URL = gql`
  mutation getEnrichmentVendorPaymentGiroUploadURL(
    $month: Datetime!
    $file: Upload!
  ) {
    getEnrichmentVendorPaymentGiroUploadURL(forMonth: $month, file: $file) {
      Url
      Key
    }
  }
`;

export const UPLOAD_VENDOR_PAYABLE_GIRO_FILE = gql`
  mutation uploadVendorPaymentGiroFile($vendorPaymentID: Int!, $url: String!) {
    uploadVendorPaymentGiroFile(IDVendorPayment: $vendorPaymentID, url: $url) {
      ID
    }
  }
`;

export const GET_TOTAL_REFUND_FOR_WITHDRAWN_CHILD = gql`
  query getTotalRefundFeeForWithdrawnChild(
    $IDEnrichmentClassChild: Int!
    $includeFee: Boolean!
    $includeUntilised: Boolean!
    $effectiveFrom: Datetime!
  ) {
    getTotalRefundFeeForWithdrawnChild(
      IDEnrichmentClassChild: $IDEnrichmentClassChild
      includeFee: $includeFee
      includeUntilised: $includeUntilised
      effectiveFrom: $effectiveFrom
    )
  }
`;

export const INSTANT_ENRICHMENT_FEE_DEDUCTION_FOR_CHILD = gql`
  mutation instantEnrichmentFeeDeductionForChild(
    $IDChild: Int!
    $IDEnrichmentClass: Int!
    $forMonth: Datetime!
  ) {
    instantEnrichmentFeeDeductionForChild(
      childId: $IDChild
      enrichmentClassId: $IDEnrichmentClass
      forMonth: $forMonth
    ) {
      pdfURLKey
    }
  }
`;

export const ENRICHMENT_CLASSES_BY_CHILD_ID = gql`
  query getEnrichmentClassesByChildID(
    $childID: Int!
    $classChildStatus: EnrichmentClassesChildStatus
    $classStatus: EnrichmentClassesByChildStatus
    $pagination: Pagination
  ) {
    getEnrichmentClassesByChildID(
      childID: $childID
      classChildStatus: $classChildStatus
      classStatus: $classStatus
      pagination: $pagination
    ) {
      totalCount
      data {
        id
        className
        programName
        programID
        programType
        cover
        registerSince
        inActiveSince
        classStatus
        reassignedClass
        reassignedDate
        classEffectiveTo
        idClassChild
        withdrawalStatus
        upComingSession {
          date
          from
          to
          accessLink
          fkEnrichmentClassOccurrence
        }
      }
    }
  }
`;

export const ENRICHMENT_WITHDRAWAL_CLASSES_FOR_CHILD = gql`
  query getEnrichmentWithdrawalClassesForChild(
    $childID: Int!
    $status: EnrichmentClassChildWithdrawalStatus
    $pagination: Pagination
  ) {
    getEnrichmentWithdrawalClassesForChild(
      childID: $childID
      status: $status
      pagination: $pagination
    ) {
      totalCount
      data {
        id
        programName
        programID
        programType
        cover
        className
        initiatedDate
        lastDayInClass
        withdrawalStatus
        enrichmentClassChildStatus
        withdrawalID
        newClassName
        reassignedTo
      }
    }
  }
`;

export const CANCEL_WITHDRAWAL_REQUEST = gql`
  mutation cancelEnrichmentClassChildWithdrawalByParent(
    $IDWithdrawal: Int!
    $IDChild: Int!
  ) {
    cancelEnrichmentClassChildWithdrawalByParent(
      IDWithdrawal: $IDWithdrawal
      IDChild: $IDChild
    )
  }
`;

export const REGISTER_CHILD_FOR_CURRENT_MONTH = gql`
  mutation registerChildForCurrentMonth($IDClassChild: Int!) {
    registerChildForCurrentMonth(IDClassChild: $IDClassChild)
  }
`;

export const WITHDRAWAL_REQUEST_REFUND_APPROVAL = gql`
  mutation enrichmentApproveRejectWithdrawRequest(
    $withdrawalID: Int!
    $isApproved: Boolean!
    $reason: String
  ) {
    enrichmentApproveRejectWithdrawRequest(
      withdrawalID: $withdrawalID
      isApproved: $isApproved
      reason: $reason
    )
  }
`;

export const GET_PARENT_CLASS_DETAIL = gql`
  query getEnrichmentClassByIDAndChildID(
    $classID: Int!
    $childID: Int!
    $classOccurrenceMonthFilter: FilterOnField
    $enrichmentChildFilterID: FilterOnField
  ) {
    getEnrichmentClassByIDAndChildID(classID: $classID, childID: $childID) {
      ...EnrichmentClassInfoFragment
      enrichmentClassLevels(filter: { active: [0, 1] }) {
        data {
          ID
          fkLevel
          fkEnrichmentClass
          level {
            ID
            label
          }
        }
      }
      enrichmentClassChildren(filter: { fkChild: $enrichmentChildFilterID }) {
        data {
          ID
          fkChild
        }
      }
      enrichmentClassOccurrences(
        filter: { date: $classOccurrenceMonthFilter }
      ) {
        totalCount
        data {
          fkEnrichmentClassOccurrence
          date
          status
          displayStatus
          remarks
          from
          to
        }
      }
      futurePriceForClass {
        programmeFee
        price {
          effectiveFrom
        }
      }
      enrichmentClassSchedules {
        totalCount
        data {
          ...EnrichmentClassScheduleFragment
        }
      }
      currentSchedule {
        ...EnrichmentClassScheduleFragment
      }
      futureSchedules {
        data {
          ...EnrichmentClassScheduleFragment
        }
      }
    }
  }
  ${ENRICHMENT_CLASS_INFO_FRAGMENT}
  ${ENRICHMENT_CLASS_SCHEDULE_FRAGMENT}
`;

export const GET_CLASS_INFO = gql`
  query getEnrichmentClassesForChild(
    $childID: Int!
    $centreID: Int!
    $at: Datetime!
    $status: String
    $filter: EnrichmentClassFilter
    $pagination: Pagination
    $classOccurrenceMonthFilter: FilterOnField
    $enrichmentChildFilterID: FilterOnField
    $enrichmentType: EnrichmentType
  ) {
    getEnrichmentClassesForChild(
      IDChild: $childID
      IDCentre: $centreID
      at: $at
      status: $status
      filter: $filter
      pagination: $pagination
      enrichmentType: $enrichmentType
    ) {
      totalCount
      data {
        ...EnrichmentClassInfoFragment
        maxClassSize
        availableFrom
        waitlistCount
        enrichmentClassLevels {
          data {
            ID
            fkLevel
            fkEnrichmentClass
            level {
              ID
              label
            }
          }
        }
        enrichmentClassChildren(filter: { fkChild: $enrichmentChildFilterID }) {
          data {
            ID
            fkChild
          }
        }
        enrichmentClassOccurrences(
          filter: { date: $classOccurrenceMonthFilter }
        ) {
          totalCount
          data {
            fkEnrichmentClassOccurrence
            date
            status
            displayStatus
            remarks
            from
            to
          }
        }
        enrichmentClassSchedules {
          totalCount
          data {
            ...EnrichmentClassScheduleFragment
          }
        }
        currentSchedule {
          ...EnrichmentClassScheduleFragment
        }
        futureSchedules {
          data {
            ...EnrichmentClassScheduleFragment
          }
        }
        futurePriceForClass {
          programmeFee
          price {
            effectiveFrom
          }
        }
        remainingSlots
        vendorDecision {
          key
          value
        }
      }
    }
  }
  ${ENRICHMENT_CLASS_INFO_FRAGMENT}
  ${ENRICHMENT_CLASS_SCHEDULE_FRAGMENT}
`;

export const CANCEL_ENRICHMENT_WITHDRAWAL_CLASS_CHILD = gql`
  mutation cancelEnrichmentClassChildWithdrawal($IDWithdrawal: Int!) {
    cancelEnrichmentClassChildWithdrawal(IDWithdrawal: $IDWithdrawal)
  }
`;

export const ENRICHMENT_DELETE_ACCESS_LINK = gql`
  mutation deleteOccurrenceAccessLink(
    $IDEnrichmentClass: Int!
    $IDEnrichmentClassOccurrence: Int!
  ) {
    deleteOccurrenceAccessLink(
      IDEnrichmentClassOccurrence: $IDEnrichmentClassOccurrence
      IDEnrichmentClass: $IDEnrichmentClass
    ) {
      ID
    }
  }
`;

export const UNVERIFY_ATTENDANCE_SESSION = gql`
  mutation unverifiedAttendanceSession($IDEnrichmentClassOccurrence: Int!) {
    unverifiedAttendanceSession(
      IDEnrichmentClassOccurrence: $IDEnrichmentClassOccurrence
    )
  }
`;

export const PARENT_WITHDRAWAL_ENRICHMENT_CLASS_CHILD = gql`
  mutation parentWithdrawEnrichmentClassChild(
    $IDChild: Int!
    $IDEnrichmentClassChild: Int!
    $effectiveDate: Datetime!
    $remark: String!
  ) {
    parentWithdrawEnrichmentClassChild(
      IDChild: $IDChild
      IDEnrichmentClassChild: $IDEnrichmentClassChild
      effectiveDate: $effectiveDate
      remark: $remark
    )
  }
`;

export const VERIFY_CENTRE_DESELECTION_ON_EDIT_PROGRAM = gql`
  query verifyCentreRemovalOnProgramEdit(
    $IDEnrichment: Int!
    $centreList: [Int!]!
  ) {
    verifyCentreRemovalOnProgramEdit(
      IDEnrichment: $IDEnrichment
      centreList: $centreList
    )
  }
`;
