import React from 'react';

import { render } from '@testing-library/react';

import { StylesProvider } from '@material-ui/core';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducerMap from '../redux/reducers';
/* eslint-disable import/prefer-default-export */
export const isActionDispatched = (actionType, actionList) => {
  return !!actionList.find(item => item.type === actionType);
};
export const getAction = (actionType, actionList) => {
  return actionList.find(item => item.type === actionType);
};
export const generateClassName = (rule, sheet) =>
  `${sheet.options.classNamePrefix}-${rule.key}`;

export const createTestStore = initialState => {
  return createStore(reducerMap, initialState, compose(applyMiddleware(thunk)));
};

const snapshotFriendlyClassNameGenerator = (rule, styleSheet) =>
  `${styleSheet.options.classNamePrefix}-${rule.key}`;

const SnapshotFriendlyStylesProvider = ({ children }) => (
  <StylesProvider generateClassName={snapshotFriendlyClassNameGenerator}>
    {children}
  </StylesProvider>
);
const customRender = (ui, options) =>
  render(ui, {
    wrapper: SnapshotFriendlyStylesProvider,
    ...options,
  });

export { customRender as render };

export default {
  isActionDispatched,
  createTestStore,
};
