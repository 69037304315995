import { get } from 'lodash';
import {
  LOGIN_USER,
  FETCH_SSO_URL,
  FORGOT_PASSWORD_REQUEST,
  REGISTER_USER_PASSWORD,
  RESET_USER_PASSWORD,
  VALIDATE_REGISTERED_USER,
  APPROVER_ACCESS,
  FETCH_USER_DETAILS,
  ACCESSIBLE_CENTRES,
  LINKPASS_USER_INFO_FROM_CLAIM,
  LINKPASS_SN2_USER_INFO,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "login": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYXV0aG9yaXphdGlvbl90b2tlbiIsImlkIjozMiwiZW1haWwiOiJzYTNAZXhhbXBsZS5jb20iLCJleHBpcmVfYXQiOiIyMDIxLTAxLTAyVDAyOjUyOjMxLjczODQ5ODE2NSswODowMCIsImlzc3VlZF9hdCI6IjIwMjAtMTItMDJUMDI6NTI6MzEuNzM4NDk5NzYxKzA4OjAwIn0.F0NbctG1Ltrv2yVLvtfS8gqsm-BAW7up-5wrx00LL0w"
 *   }
 * }
 */

export const login = (state = {}, action) => {
  if (action.type === LOGIN_USER) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const linkpassSN2UserInfo = (state = {}, action) => {
  if (action.type === LINKPASS_SN2_USER_INFO) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const linkpassUserInfoFromClaim = (state = {}, action) => {
  if (action.type === LINKPASS_USER_INFO_FROM_CLAIM) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * {
 *   "data": {
 *     "getLoginUrl": "https://login.microsoftonline.com/5fd9b91d-ba26-45c4-a9ff-04d1535d7b54/saml2?RelayState=https%3A%2F%2Fstaff.dev.skool.sg%2Fsso&SAMLRequest=fJJBb9QwEIX%2FSjR3J45J2MZqVlq6QqxUYNVdOHBBjj3ZWjie4HEK%2FHvEtpXKoXue9%2BZ9mjfXbKYw682S7%2BMd%2FlyQc%2FF7CpH1edDDkqImw551NBOyzlYfNh9vtSqlnhNlshTgheWywzBjyp4iFLttD98Ha0Y7ShSjkyiase1E10knBlfjalDNqrtSUHzFxJ5iD6qUUOyfUt%2F56Hw8XQ4cHkWsPxyPe7H%2FfDhCsXmGuKHIy4TpgOnBW%2Fxyd9vDfc4z66py%2BFCa2Zf8gyiUfKrSbCtmgmLHvOAucjYx96CkkqJWQtbH%2Bkq3jVbNNyi2yNlHk8%2FUzysDnXwsJ28TMY2ZYvARS0tT1Y6uG7raicGot6JpbSNMN45CNq5u37RuNbRN9e%2B6CtbnxvQZIq1fpb2uXsqeWv5kJtxt9xS8%2FVNsQqBfNwlNxh5yWhCK95Qmk3uAav3o%2F%2F8x1n8DAAD%2F%2Fw%3D%3D"
 *   }
 * }
 */

export const ssoURL = (state = {}, action) => {
  if (action.type === FETCH_SSO_URL) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example data
 * {
 *   "data": {
 *     "getAccessibleCentresForModule": {
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkSchool": 3,
 *           "label": "The Caterpillar's Cove @ Jurong East"
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const approverList = (
  state = {
    inProgress: false,
    data: null,
    error: null,
  },
  action
) => {
  if (action.type === APPROVER_ACCESS) {
    const data = get(
      action,
      'value.data.data.getAccessibleCentresForModule.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example data
 * {
 *   "data": {
 *     "getAccessibleCentresForModule": {
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkSchool": 3,
 *           "label": "The Caterpillar's Cove @ Jurong East"
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const accessibleCentres = (state = {}, action) => {
  if (action.type === ACCESSIBLE_CENTRES) {
    const data = get(
      action,
      'value.data.data.getAccessibleCentresForModule.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example data
 * {
 *   "data": {
 *     "forgetPassword": true
 *   }
 * }
 */

export const forgot = (state = {}, action) => {
  if (action.type === FORGOT_PASSWORD_REQUEST) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 * Example Data --*--
 */

export const register = (state = {}, action) => {
  if (action.type === REGISTER_USER_PASSWORD) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const validate = (state = {}, action) => {
  if (action.type === VALIDATE_REGISTERED_USER) {
    const data = get(action, 'value.data.data.validateUser');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const reset = (state = {}, action) => {
  if (action.type === RESET_USER_PASSWORD) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 *
 * Example data
 *
 * {
 *    "data": {
 *      "me": {
 *        "ID": 32,
 *        "firstname": "Erina",
 *        "lastname": "Nakiri",
 *        "email": "sa3@example.com",
 *        "userRoleRelations": {
 *          "data": [
 *            {
 *              "ID": 1,
 *              "role": {
 *                "ID": 13,
 *                "label": "Super Administrator",
 *                "isStaff": true,
 *                "roleTemplates": {
 *                  "data": [
 *                    {
 *                      "fkModuleCategory": "student_management",
 *                      "access": 7
 *                    },
 *                  ]
 *                }
 *              }
 *            }
 *          ]
 *        },
 *        "birthdate": "1986-02-07 00:00:00",
 *        "gender": "f",
 *        "homePhone": "81691565",
 *        "mobilePhone": "81691565",
 *        "mobilePhoneCountryCode": "+65",
 *        "isHQStaff": true,
 *        "hasCentreScopeAccess": true,
 *        "hasClassScopeAccess": true,
 *        "userAccessControls": {
 *          "data": [
 *            {
 *              "school": {
 *                "ID": 3,
 *                "name": "The Caterpillar's Cove"
 *              },
 *              "centre": null
 *            }
 *          ]
 *        },
 *        "parent": null,
 *        "childRelations": {
 *          "data": []
 *        }
 *      }
 *    }
 *  }
 */
export const userDetails = (state = {}, action) => {
  if (action.type === FETCH_USER_DETAILS) {
    const data = get(action, 'value.data.me');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export default login;
