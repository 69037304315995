import gql from 'graphql-tag';
import { ATTENDANCE_FRAGMENT, HEALTH_CHECK_FRAGMENT } from './commonFragments';

export const GET_CLASS_ATTENDANCE = gql`
  query(
    $searchTerm: String
    $filter: String
    $pagination: Pagination
    $centreId: Int!
    $classId: Int
    $date: Datetime!
  ) {
    getAttendanceByClass(
      searchTerm: $searchTerm
      filter: $filter
      pagination: $pagination
      centreId: $centreId
      classId: $classId
      date: $date
    ) {
      total
      attendances {
        child {
          ID
          firstname
          lastname
          childClassAt(time: $date) {
            class {
              label
            }
          }
          childRelations {
            data {
              parent {
                firstname
                lastname
                relation
              }
              role {
                name
                label
              }
              fkUser
              fkRelation
              isGuardian
            }
          }
        }
        date
        present
        status
        measurements {
          measuredDate
          weight
          height
        }
        attendances {
          id
          temperature
          remarks
          type
          active
          time
          status
          source
          confirmedLatePickup {
            status
            remarks
            quantity
            exemptionRemarks
            isRevertible
          }
        }
        health {
          id
          status
          source
          temperature
          remarks
          type
          session
          active
          time
          confirmedLatePickup {
            status
            remarks
            quantity
          }
        }
        healthChecks {
          ...HealthCheckUps
        }
      }
    }
  }
  ${HEALTH_CHECK_FRAGMENT}
`;

export const GET_ATTENDANCE_DETAILS = gql`
  query($attendanceId: Int!) {
    getAttendance(attendanceId: $attendanceId) {
      ID
      time
      dateOfTime
      childLatePickups {
        data {
          status
          remarks
          exemptionRemarks
          penaltyQuantity
          invoiceItem {
            invoice {
              invoiceNo
            }
          }
        }
      }
      child {
        currentClass {
          class {
            classTeachers {
              data {
                user {
                  firstname
                  lastname
                }
              }
            }
          }
        }
        childRelations {
          data {
            parent {
              firstname
              lastname
              relation
            }
            role {
              name
              label
            }
          }
        }
      }
      userByFkUser {
        firstname
        lastname
      }
      userByFkVerifiedBy {
        firstname
        lastname
      }
      remarks
      type
      authorisedPersonal {
        ID
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

export const ATTENDANCE_DETAILS = `{
    ID
    time
    dateOfTime
    userByFkUser {
        firstname
        lastname
    }
    userByFkVerifiedBy { 
        firstname
        lastname
    }
    temperature
    child {
        healthChecks {
            data {
                ID
                healthCheckType {
                    description
                }
            }
        }
    }
}`;

export const FILTERS_FOR_CLASS_ATTENDANCE = gql`
  query($centreId: Int!, $classId: Int, $date: Datetime) {
    filterCountForClassAttendance(
      centreId: $centreId
      classId: $classId
      date: $date
    ) {
      count
      filter
    }
  }
`;

export const GET_CHILD_ATTENDANCE_HISTORY = gql`
  query getAttendanceHistory(
    $childId: Int!
    $from: Datetime!
    $to: Datetime
    $filter: String
    $pagination: Pagination
  ) {
    getAttendanceHistory(
      childId: $childId
      from: $from
      to: $to
      filter: $filter
      pagination: $pagination
    ) {
      total
      childAttendances {
        status
        child {
          ID
          firstname
          lastname
          childRelations {
            data {
              parent {
                firstname
                lastname
                relation
              }
              role {
                name
                label
              }
              fkUser
              fkRelation
              isGuardian
            }
          }
        }
        date
        present
        remarks
        healthChecks {
          ...HealthCheckUps
        }
        health {
          id
          temperature
          remarks
          type
          active
          time
        }
        attendances {
          status
          id
          temperature
          remarks
          type
          active
          source
          time
          confirmedLatePickup {
            id
            status
            remarks
            exemptionRemarks
            quantity
            isRevertible
            staffsOnDuty {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
  ${HEALTH_CHECK_FRAGMENT}
`;

export const GET_STAFF_ATTENDANCE_BY_CENTRE = gql`
  query getCentreAttendanceSummariesForStaff(
    $centreId: Int!
    $from: Datetime!
    $to: Datetime!
  ) {
    getCentreAttendanceSummariesForStaff(
      centreId: $centreId
      from: $from
      to: $to
    ) {
      attendanceSummaries {
        ...Attendance
      }
    }
  }
  ${ATTENDANCE_FRAGMENT}
`;

export const GET_CHILDREN_ATTENDANCE_BY_CENTRE = gql`
  query getCentreAttendanceSummaries(
    $centreId: Int!
    $from: Datetime!
    $to: Datetime!
  ) {
    getCentreAttendanceSummaries(centreId: $centreId, from: $from, to: $to) {
      attendanceSummaries {
        ...Attendance
      }
    }
  }
  ${ATTENDANCE_FRAGMENT}
`;

export const GET_FILTERS_COUNTS_MODEL = gql`
  query($childId: Int!, $from: Datetime!, $to: Datetime!) {
    filterCountForAttendanceHistory(childId: $childId, from: $from, to: $to) {
      count
      filter
    }
  }
`;

export const GET_LATE_PICKUPS_DETAILS = gql`
  query($childID: Int!, $checkoutDate: Datetime!) {
    getLatePickupDetails(childID: $childID, checkoutDate: $checkoutDate) {
      totalCount
      data {
        id
        status
        remarks
        quantity
        child {
          id
          firstname
          lastname
        }
        pickupBy {
          id
          firstname
          lastname
        }
        staffsOnDuty {
          id
          firstname
          lastname
        }
        invoice {
          id
          invoiceNo
          label
          totalAmount
          generatedTime
        }
        exemptionRemarks
        pickupTime
        feeAmount
        taxAmount
        taxRate
        chargeableAmount
      }
      statistic {
        totalInYear
        totalExemptedInYear
      }
    }
  }
`;

export const CREATE_SESSION_ENTRY = gql`
  mutation createSessionEntry($dto: AttendanceUpdate!, $forChild: Boolean!) {
    addOrUpdateAttendance(attendanceUpdate: $dto, forChild: $forChild) {
      active
      remarks
      time
      type
      sessionID
    }
  }
`;

export const CREATE_CHECK_IN_CHECK_OUT = gql`
  mutation createCheckInCheckOut($dto: AttendanceUpdate!, $forChild: Boolean!) {
    addOrUpdateAttendance(attendanceUpdate: $dto, forChild: $forChild) {
      active
      remarks
      time
      type
    }
  }
`;

export const UPDATE_CHECK_IN_CHECK_OUT = gql`
  mutation updateCheckInCheckOut(
    $dto: AttendanceUpdate!
    $forChild: Boolean!
    $attendanceId: Int!
  ) {
    addOrUpdateAttendance(
      attendanceUpdate: $dto
      forChild: $forChild
      attendanceId: $attendanceId
    ) {
      active
      remarks
      time
      type
    }
  }
`;

export const UPDATE_LATE_PICKUP_STATUS = gql`
  mutation updateLatePickupStatus(
    $childID: Int!
    $checkoutDate: Datetime!
    $childLatePickupID: Int!
    $status: ChildLatePickupStatus!
    $remarks: String!
  ) {
    updateLatePickupStatus(
      childID: $childID
      checkoutDate: $checkoutDate
      childLatePickupID: $childLatePickupID
      status: $status
      remarks: $remarks
    )
  }
`;
