import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18n';
import { AbilityContext } from 'utils/ability/abilityContext';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import {
  ENRICHMENT_MY_CLASS_LIST,
  ENRICHMENT_OVERVIEW,
  MY_DOWNLOADS,
  PAYMENT_DETAILS,
} from 'routes/constants';
import { getQueryParams } from 'utils';
import { SUPER_ADMIN_HQ_FINANCE_ROLE_OPTIONS } from 'utils/constants';
import DesktopSidemenu from './DesktopSidemenu';
import MobileSidemenu from './MobileSidemenu';

const Sidemenu = props => {
  const ability = useContext(AbilityContext);
  const roleLabel = useSelector(state =>
    get(state, 'userDetails.data.userRoleRelations.data[0].role.label')
  );
  const getClassDecisionLabelVendor = (label, path) =>
    ability.can('canMarkAttendance', roleLabel) &&
    label.includes(i18n.t('enrichment_class.classManagement')) &&
    !path.includes('myclasses')
      ? i18n.t('enrichment_class.classDecision')
      : label;
  const history = useHistory();
  const { theme, userDetails, pathname } = props;
  const hasEpReportsAccess = SUPER_ADMIN_HQ_FINANCE_ROLE_OPTIONS.includes(
    get(userDetails, 'userRoleRelations.data[0].role.label')
  );
  const getLogo = () => {
    let logo = '';
    if (get(theme, 'logo')) {
      logo = theme.logo;
    }
    return logo;
  };

  const goToLink = link => {
    if (
      ENRICHMENT_OVERVIEW.includes(link) ||
      ENRICHMENT_MY_CLASS_LIST.includes(link) ||
      MY_DOWNLOADS.includes(link) ||
      PAYMENT_DETAILS.includes(link)
    ) {
      const searchParams = getQueryParams();
      history.push({
        pathname: `/${link}`,
        state: {
          child: get(searchParams, 'childID', get(searchParams, 'childId', '')),
          centre: get(searchParams, 'centreID', ''),
        },
      });
    } else {
      history.push(`/${link}`);
    }
  };
  return (
    <>
      <Hidden only="xs">
        <DesktopSidemenu
          getLogo={getLogo}
          goToLink={goToLink}
          getClassDecisionLabelVendor={getClassDecisionLabelVendor}
          hasEpReportsAccess={hasEpReportsAccess}
        />
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
        <MobileSidemenu
          userDetails={userDetails}
          getLogo={getLogo}
          theme={theme}
          pathname={pathname}
          getClassDecisionLabelVendor={getClassDecisionLabelVendor}
          goToLink={goToLink}
          hasEpReportsAccess={hasEpReportsAccess}
        />
      </Hidden>
    </>
  );
};
export default withTheme(Sidemenu);
