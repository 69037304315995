import { get, uniqBy } from 'lodash';
import moment from 'moment';
import {
  FETCH_APPOINTMENTS,
  FETCH_ALL_CALENDAR_DATA,
} from '../actions/actionTypes';
import { processCalendarEvents } from '../../utils';

const processEventData = calendarData => {
  const processedData = {};

  // Process holidays data
  const holidayUniqList = uniqBy(
    get(calendarData, 'getHolidaysOfYearForCalendar.data', []),
    'from'
  );
  processedData.holidays = processCalendarEvents(holidayUniqList, 'holidays');

  // Process visit data
  const visitData = get(calendarData, 'findAllCalendarEvent.data', []);
  const cancelledEvents = visitData.filter(e => !e.available);
  processedData.visits = processCalendarEvents(visitData, 'visits');

  processedData.visits = processedData.visits.filter(visit => {
    const isHoliday = processedData.holidays.some(holiday =>
      moment(holiday.start).isSame(moment(visit.start), 'day')
    );
    const isCancelled = cancelledEvents.some(
      cancelledItem =>
        new Date(
          `${moment(cancelledItem.start).format('YYYY-MM-DD')} ${
            cancelledItem.from
          }`
        ).getTime() === visit.start.getTime() &&
        new Date(
          `${moment(cancelledItem.end).format('YYYY-MM-DD')} ${
            cancelledItem.to
          }`
        ).getTime() === visit.end.getTime() &&
        moment(cancelledItem.createdAt).isAfter(moment(visit.createdAt))
    );

    if (get(visit, 'user')) {
      // Don't hide parent appointment so staff can reschedule
      // This case is when staff created a holiday after parent has already scheduled appointments
      return true;
    }

    return !isCancelled && !isHoliday;
  });

  // Process leaves data
  processedData.leaves = processCalendarEvents(
    get(calendarData, 'findLeaves.data', []),
    'leaves'
  );

  // Process events data
  processedData.events = processCalendarEvents(
    get(calendarData, 'getAllEventsForCalendar.data', []),
    'events'
  );

  return [
    ...processedData.holidays,
    ...processedData.visits,
    ...processedData.leaves,
    ...processedData.events,
  ];
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "findAllCalendarEvent": {
 *      "totalCount": 80,
 *      "data": [
 *        {
 *          "ID": 20,
 *          "fkCentre": 2,
 *          "fkUser": 890,
 *          "title": "",
 *          "description": null,
 *          "meetingDuration": 0,
 *          "from": "12:00:00",
 *          "to": "12:30:00",
 *          "start": "2019-12-07 00:00:00",
 *          "end": "2019-12-07 00:00:00",
 *          "recurNumber": null,
 *          "recurType": null,
 *          "recurOnDays": null,
 *          "available": true,
 *          "active": true,
 *          "date": "",
 *          "type": "visit",
 *          "visits": {
 *            "data": [
 *              {
 *                "ID": 304,
 *                "user": {
 *                  "firstname": "Elfy",
 *                  "lastname": "Lee",
 *                  "mobilePhone": "40001368"
 *                },
 *                "visitFrom": "2019-12-07 12:00:00",
 *                "visitTo": "2019-12-07 12:30:00",
 *                "completed": false,
 *                "status": "completed",
 *                "enquiry": {
 *                  "ID": 302,
 *                  "fkChild": 878
 *                }
 *              }
 *            ]
 *          },
 *          "createdAt": "2019-12-02 15:00:00",
 *          "updatedAt": "2019-12-02 15:00:00"
 *        },
 *      ]
 *    }
 *  }
 *}
 */
export const fetchAllCalendarEvent = (state = {}, action) => {
  if (action.type === FETCH_APPOINTMENTS) {
    const data = get(action, 'value.data.data.findAllCalendarEvent.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  data {
 *    findAllCalendarEvent {
 *      data [{
 *         ID: 52
 *         active: true
 *         available: true
 *         createdAt: "2020-01-14 10:09:28"
 *         date: ""
 *         description: null
 *         end: "2019-01-26 00:00:00"
 *         fkCentre: 1
 *         fkUser: 1506
 *         from: "11:30:00"
 *         meetingDuration: 30
 *         recurNumber: null
 *         recurOnDays: null
 *         recurType: null
 *         start: "2019-01-26 00:00:00"
 *         title: "Parent Centre Visit"
 *         to: "12:00:00"
 *         type: "visit"
 *         updatedAt: "2020-01-14 10:09:28"
 *      }]
 *      totalCount: 171
 *    }
 *    findLeaves {
 *      totalCount: 205
 *      data: [{
 *        end: "2020-01-04 23:59:59"
 *          halfDay: 0
 *          leave: {
 *            ID: 1052
 *            approveRequired: true
 *            child: null
 *            from: "2020-01-04 00:00:00"
 *            leaveType: {
 *              applicableToChild: true
 *              applicableToStaff: true
 *              description: "Others"
 *              halfDayApplicable: false
 *              leaveTypeCode: "others"
 *            }
 *            remark: "NOT ON DUTY"
 *            status: "approved"
 *            to: "2020-01-04 00:00:00"
 *            userByApprovedBy: {
 *              ID: 893
 *              firstname: "Woan Wei"
 *              lastname: "Tan"
 *            }
 *            userByFkUser {
 *              ID: 865
 *              firstname: "Nurul Elma"
 *              lastname: "Rahmat"
 *            }
 *          }
 *          start: "2020-01-04 00:00:00"
 *      }]
 *    }
 *    getAllEventsForCalendar {
 *      data {
 *          ID: 9
 *          active: true
 *          date: "2020-10-26 14:50:15"
 *          description: "asa"
 *          eventType: 1131
 *          from: "2020-10-26 14:50:15"
 *          location: "vhvg"
 *          status: "published"
 *          title: "attendance"
 *          to: "2020-10-26 15:50:15"
 *          updatedAt: "2020-10-14 17:21:45"
 *      }
 *    }
 *    getHolidaysOfYearForCalendar {
 *        data {
 *          ID: 12
 *          centreHolidays: {
 *            totalCount: 2
 *          }
 *          description: "Professional Development"
 *          from: "2020-02-07 00:00:00"
 *          title: "Professional Development"
 *          to: "2020-02-07 23:59:59"
 *        }
 *    }
 *  }
 * }
 */
export const allCalendarData = (state = {}, action) => {
  if (action.type === FETCH_ALL_CALENDAR_DATA) {
    const response = get(action, 'value.data');

    return Object.assign({}, state, {
      ...action.value,
      data: processEventData(get(response, 'data')),
    });
  }
  return state;
};

export default allCalendarData;
