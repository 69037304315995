import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import { FETCH_ALL_MANAGEMENT_LEVELS } from './actionTypes';

import localstorage from '../../utils/localStorage';

const _mQueryBuilder = new QueryBuilder();
const _mMutationBuilder = new MutationBuilder();

const lvlResp = {
  ID: 'number',
  label: 'String',
  fromMonth: 'number',
  toMonth: 'number',
  promotionDate: 'date',
  updatedAt: 'datetime',
  offOnSaturday: 'boolean',
  isInfant: 'boolean',
  reservePromotionSeatFrom: 'number',
  reservePromotionSeatTo: 'number',
};

_mQueryBuilder.setNewQuery('getLevelsForSchool', {
  data: lvlResp,
});

_mMutationBuilder.setNewMutation('insertOrUpdateLevelForSchool', lvlResp);

_mMutationBuilder.setNewMutation('removeLevelForSchool', {});

const dispatchManagementLevelFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_MANAGEMENT_LEVELS,
    key: 'managementLevels',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const removeLevel = async (IDSchool, levelID) => {
  try {
    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString('removeLevelForSchool', {
          IDSchool,
          levelID,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const createOrUpdateLevel = async (IDSchool, levelData, levelID = 0) => {
  try {
    const saveData = { IDSchool, levelID, levelData };
    if (!levelID) {
      delete saveData.levelID;
    }

    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'insertOrUpdateLevelForSchool',
          saveData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchManagementLevels = (
  IDSchool,
  pagination = {},
  filter = {}
) => async dispatch => {
  dispatchManagementLevelFetch(dispatch);

  try {
    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mQueryBuilder.getQueryAsString('getLevelsForSchool', {
          IDSchool,
          pagination,
          filter,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchManagementLevelFetch(dispatch, false, data);
    } else {
      dispatchManagementLevelFetch(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchManagementLevelFetch(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export default fetchManagementLevels;
