import React from 'react';
import { Grid } from '@material-ui/core';
import { Trans } from 'react-i18next';
import Button from '../Button';
import Text from '../Text';
import ActionWrapper from './style';

const FormWrapper = ({
  children,
  handleSave,
  handleCancel,
  error,
  saveButtonText,
  cancelButtonText,
  cancelLink,
  loading,
  saveButtonDisabled = false,
}) => (
  <>
    {children}
    <Grid container>
      <Grid item xs={12} className="w3-center">
        <Text color="error">{error}</Text>
      </Grid>
      <ActionWrapper item xs={12}>
        <Button
          onClick={handleSave}
          disabled={loading || saveButtonDisabled}
          loading={loading}
        >
          {saveButtonText || <Trans i18nKey="common.save" />}
        </Button>
        <Button
          onClick={handleCancel}
          button="secondary"
          link={cancelLink}
          disabled={loading}
        >
          {cancelButtonText || <Trans i18nKey="common.cancelButton" />}
        </Button>
      </ActionWrapper>
    </Grid>
  </>
);

export default FormWrapper;
