import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ListItem, Grid, Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '../Button';
import Text from '../Text';

export const NavigationGrid = styled(Grid)`
  padding: 0px 0px 5px 0px !important;
`;

export const TitleGrid = styled(Grid)`
  margin-left: 7%;
  text-align: left;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  color: ${props => props.theme.baseColor.neutral.divider};
`;

export const UnreadNotificationBubble = styled.div`
  ${props => `
    border-radius: 50%;
    width: ${props.theme.iconSize.medium};
    height: ${props.theme.iconSize.medium};
    margin: auto;
  `}
`;

export const NavigationArrow = styled(ArrowForwardIosIcon)`
  ${props => `
    width: ${props.theme.iconSize.medium};
    height: ${props.theme.iconSize.medium};
  `}
`;

export const StyledListItem = styled(ListItem)`
  ${props => {
    if (props.addTopMargin) {
      return `
        margin-top: 5px;
      `;
    }
  }}
`;

export const ListItemContainer = styled.div`
  ${props => {
    if (props.isRead) {
      return `
        filter: grayscale(1);
        opacity: 0.7;
      `;
    }
  }}
`;

export const NotificationText = styled(Text)`
  text-transform: none;
`;

export const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: inherit;
`;
