import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { has, get } from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import Button from 'components/common/Button';
import IconButton from 'components/common/SnackBar/iconButton';
import Text from 'components/common/Text';

import {
  showSnackBarMessage as showSnackBarMessageAction,
  fetchUserDetails as fetchUserDetailsAction,
  sendOtp as sendOtpAction,
  validateOtp as validateOtpAction,
} from 'redux/actions';

import { getPrimaryColor, ImageLazyLoader } from '../../../../utils';
import PasswordField from '../PasswordField';

const formgrid = 12;
const logoHeight = '77';

const OTP_EXPIRATION_TIME = 30;

const OTPVerification = props => {
  const {
    cdnurl,
    theme,
    t,
    onSuccess,
    sendOtp,
    userId,
    phoneNumber,
    validateOtp,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const [oneTimePsd, setOneTimePsd] = useState('');
  const [timeLeft, setTimeLeft] = useState(30);

  const [errors, setErrors] = useState({});

  let logo = `${cdnurl}/Auth/logo.png`;
  let banner = `${cdnurl}/Auth/banner-right.png`;
  if (has(theme, 'banner')) {
    ({ banner } = theme);
  }
  if (get(theme, 'logo')) {
    ({ logo } = theme);
  }

  const onChange = e => {
    setOneTimePsd(e.target.value);
  };

  const resendButtonDisable = !!timeLeft;

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const resendCode = async mode => {
    const data = {
      userId,
    };

    try {
      const response = await sendOtp(data);
      if (get(response, 'data.sendOTP')) {
        if (mode === 'resend') {
          errors.oneTimePsd = t('auth.newoneTimeOtp');
          errors.otpMisMatch = '';
        } else {
          errors.oneTimePsd = get(response, 'error[0]');
        }
      }
    } catch (error) {
      errors.oneTimePsd = t('common.defaultErrorMessage');
      setErrors(errors);
    }

    if (mode === 'resend') {
      setTimeLeft(OTP_EXPIRATION_TIME);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmitOTP = async () => {
    const newErrors = {};
    const data = {
      userId,
      otpReceived: oneTimePsd,
    };
    await validateOtp(data)
      .then(res => {
        if (res.success) {
          onSuccess();
        } else if (get(res, 'error.length')) {
          newErrors.otpMisMatch =
            t('auth.inCorrectOtp') || res.error[0].message;
          newErrors.oneTimePsd = '';
          setErrors(newErrors);
        }
      })
      .catch(() => {
        newErrors.otpMisMatch = t('auth.inCorrectOtp');
        newErrors.oneTimePsd = '';
        setErrors(newErrors);
      });
  };

  const hidePassword = (
    <InputAdornment position="end">
      <IconButton
        aria-label="Toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? (
          <Visibility className="s7t-text-lightgrey" />
        ) : (
          <VisibilityOff className="s7t-text-lightgrey" />
        )}
      </IconButton>
    </InputAdornment>
  );

  useEffect(() => {
    resendCode('send');
  }, []);

  return (
    <div className="s7t-auth-container">
      <Grid container className="s7t-loging-background s7t-full-height">
        <Grid item xs={12} sm={12} md={5}>
          <Card className="s7t-full-height s7t-auth-card-shadow s7t-auth-card-overflow">
            <CardContent className="s7t-center-div">
              <Grid container justify="center" className="s7t-display-content">
                <Grid item sm={formgrid} className="s7t-auto-margin">
                  <Grid item xs={12} className="s7t-margin-bottom4pct">
                    <img alt="" src={logo} height={logoHeight} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="s7t-width-100pct w3-left s7t-margin-bottom10"
                  >
                    <hr
                      className="s7t-Rectangle-4"
                      style={{
                        background: getPrimaryColor(props),
                      }}
                    />
                    <Typography className="s7t-Parent-Registration">
                      {t('auth.OtpLabel')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  {errors.otpMisMatch && (
                    <Grid
                      container
                      className="w3-margin-bottom w3-margin-top w3-padding"
                      style={{ background: '#ffe6de' }}
                    >
                      <Grid item xs={1}>
                        <ErrorOutline
                          style={{
                            color: '#e02020',
                            fontSize: '20px',
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} style={{ color: '#e02020' }}>
                        {errors.otpMisMatch}
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid>
                  <Grid>
                    <p style={{ textAlign: 'justify' }}>
                      <Trans
                        i18nKey="auth.otpPhoneNumber"
                        values={{ phoneNumber }}
                      />
                    </p>
                  </Grid>

                  <PasswordField
                    passClass="s7t-margin-bottom10"
                    adornment={hidePassword}
                    passType={showPassword ? 'text' : 'password'}
                    fieldName={t('auth.oneTimePass')}
                    name="oneTimePsd"
                    id="oneTimePsd"
                    value={oneTimePsd}
                    onChange={onChange}
                    errmsg={errors.oneTimePsd}
                    placeHolder={t('auth.otpEnter')}
                  />

                  <Button
                    type="button"
                    button="primary"
                    fullWidth
                    onClick={() => {
                      onSubmitOTP();
                    }}
                    className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
                  >
                    <Trans i18nKey="common.submitButton" />
                  </Button>

                  <Button
                    fullWidth
                    variant="text"
                    disabled={timeLeft === 30}
                    onClick={() => resendCode('resend')}
                    className={`${
                      resendButtonDisable ? 's7t-disabled' : 's7t-approved'
                    }  s7t-cursor s7t-font-size-16px s7t-font-500`}
                    style={{ textAlign: 'center' }}
                  >
                    <Trans i18nKey="auth.resendOtp" />
                  </Button>
                  <Grid item xs={12} className="w3-center">
                    <Text fontSize="13px">
                      {!timeLeft ? (
                        ''
                      ) : (
                        <Trans
                          i18nKey="auth.resendInterval"
                          values={{
                            resendOTPInterval: timeLeft,
                          }}
                        />
                      )}
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <ImageLazyLoader
            alt="school banner for login"
            className="s7t-full-height s7t-login-grid-image"
            src={banner}
          />
        </Grid>
      </Grid>
    </div>
  );
};

OTPVerification.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showSnackBarMessage: showSnackBarMessageAction,
  fetchUserDetails: fetchUserDetailsAction,
  sendOtp: sendOtpAction,
  validateOtp: validateOtpAction,
};

export default compose(
  withTranslation(),
  withTheme
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(OTPVerification)));
