import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { StyledButton, Loading, ButtonHint, StyledIconButton } from './style';

const buttonLink = (buttonComp, link, loading) => (
  <>
    {link ? (
      <Link to={link} className="s7t-text-decoration-none">
        {buttonComp}
      </Link>
    ) : (
      buttonComp
    )}
    {loading && <Loading />}
  </>
);

const renderButton = (button, hint, props) => {
  if (hint && !get(props, 'disabled')) {
    return (
      <Tooltip title={<ButtonHint>{hint}</ButtonHint>} placement="top" arrow>
        {button}
      </Tooltip>
    );
  }

  return button;
};

const Button = ({
  children,
  loading,
  button = 'primary',
  size,
  link,
  hint,
  uppercase,
  color = 'primary',
  iconSize = 'large',
  ...props
}) => {
  let variant = 'contained';

  if (button === 'secondary' || button === 'destructive_secondary') {
    variant = 'outlined';
  }

  if (button === 'text') {
    variant = 'text';
  }

  let buttonComp = (
    <StyledButton
      disabled={loading}
      color={color}
      iconSize={iconSize}
      variant={variant}
      size={size}
      uppercase={uppercase}
      button={button}
      {...props}
    >
      {children}
    </StyledButton>
  );

  let getButtonContent = buttonLink(buttonComp, link, loading);

  if (button === 'icon') {
    buttonComp = (
      <StyledIconButton color={color} iconSize={iconSize} {...props}>
        {children}
      </StyledIconButton>
    );
    getButtonContent = buttonLink(buttonComp, link, loading);
  }

  return renderButton(getButtonContent, hint, props);
};
Button.propTypes = {
  size: PropTypes.oneOf(['rowButton', 'small', 'medium', 'large']),
};
export default Button;
