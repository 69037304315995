import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const ActionWrapper = styled(Grid)`
  margin-top: 1em;

  & .MuiButton-root {
    float: right;
    margin-right: 16px;
  }
`;

export default ActionWrapper;
