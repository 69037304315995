import { ACTION_SET_DATA } from '../actions/actionTypes';

const setData = (state = {}, action) => {
  if (action.type === ACTION_SET_DATA) {
    const data = { [action.key]: action.value };
    return Object.assign({}, state, data);
  }
  return state;
};
export default setData;
