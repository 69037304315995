const { callingCountries } = require('country-data');

const countryCodes = () => {
  const countryCode = [];
  callingCountries.all.map(countryKey => {
    countryCode.push({
      value: countryKey.countryCallingCodes[0],
      country: countryKey.alpha2,
    });
  });
  return countryCode;
};
export default countryCodes;
