import React from 'react';
import { BaseText, LabelText, SmallTitle as SmallTitleText } from './style';

export default ({
  children,
  h1,
  h2,
  h3,
  h4,
  h5,
  body1,
  body,
  label,
  color,
  fontSize,
  bold,
  italic,
  center,
  smallTitle,
  type,
  required,
  ...props
}) => {
  let variant = null;
  let TextComponent = BaseText;
  if (!type) {
    if (h1) {
      variant = 'h1';
    } else if (h2) {
      variant = 'h2';
    } else if (h3) {
      variant = 'h3';
    } else if (h4) {
      variant = 'h4';
    } else if (h5) {
      variant = 'h5';
    } else if (body || body1) {
      variant = 'body1';
    } else if (label) {
      TextComponent = LabelText;
    } else if (smallTitle) {
      TextComponent = SmallTitleText;
    }
  }

  return (
    <TextComponent
      required={required}
      center={center}
      variant={variant}
      type={type}
      textColor={color}
      fontSize={fontSize}
      {...props}
      bold={bold}
      italic={italic}
    >
      {children}
    </TextComponent>
  );
};
// defaul variant of Material UI
// 'body1'
// | 'body2'
// | 'button'
// | 'caption'
// | 'h1'
// | 'h2'
// | 'h3'
// | 'h4'
// | 'h5'
// | 'h6'
// | 'inherit'
// | 'overline'
// | 'subtitle1'
// | 'subtitle2'
// | string
