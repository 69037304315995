class LocalStore {
  constructor() {
    this.store = null;
    this.storeKey = 'skoolnet';

    try {
      this.store = window.localStorage;
    } catch (err) {
      this.tempData = {};

      this.store = {
        clear: () => {
          this.tempData = {};
        },
        getItem: key => this.tempData[key],
        setItem: (key, val) => {
          this.tempData[key] = val;
        },
      };
    }
  }

  _cleanStore() {
    return this.store.clear();
  }

  _getData() {
    let data = this.store.getItem(this.storeKey);

    if (!data) {
      data = '{}';
    }

    return JSON.parse(data);
  }

  _setValToStore(key, val) {
    const data = this._getData();
    data[key] = val;
    return this.store.setItem(this.storeKey, JSON.stringify(data));
  }

  _getValFromStore(key) {
    const data = this._getData();
    return data[key];
  }

  setValue(key, val) {
    const saveData = val && JSON.stringify(val);
    return this._setValToStore(key, saveData);
  }

  getValue(key) {
    const val = this._getValFromStore(key);
    return val ? JSON.parse(val) : val;
  }
}

const store = new LocalStore();

export default store;
