import React, { Fragment } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

let countryOptions = [];

const CountrySelector = ({
  multipleRow,
  countryCodes,
  name,
  handleSelectChange,
  theme,
  defaultValue,
  isDisabled,
  onBlur,
  hideIndicatorSeparator = false,
}) => {
  countryOptions = countryCodes.reduce((result, currentValue) => {
    if (result.findIndex(res => res.value === currentValue.value) === -1) {
      result.push({
        value: currentValue.value,
        label: currentValue.value,
      });
      return result;
    }
    return result;
  }, []);

  return (
    <div>
      <Fragment>
        <Select
          className="basic-single"
          classNamePrefix="select"
          name={name}
          options={countryOptions}
          onChange={handleSelectChange || multipleRow}
          value={defaultValue}
          isSearchable
          theme={th => ({
            ...th,
            colors: {
              ...th.colors,
              primary25: theme.baseColor.neutral.main,
              primary: theme.baseColor.neutral.main,
            },
          })}
          isDisabled={isDisabled}
          onBlur={onBlur}
          styles={{
            control: provided => ({
              ...provided,
              minHeight: '32px',
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isFocused
                ? theme.baseColor.neutral.white
                : theme.baseColor.neutral.black,
            }),
            dropdownIndicator: provided => ({
              ...provided,
              padding: '2px 8px',
              alignItems: 'center',
              color: theme.baseColor.neutral.main,
            }),
          }}
          components={
            hideIndicatorSeparator
              ? {
                  IndicatorSeparator: () => null,
                }
              : {}
          }
        />
      </Fragment>
    </div>
  );
};
const mapStateToProps = state => ({
  countryCodes: state.countries,
});

export default withTheme(connect(mapStateToProps)(CountrySelector));
