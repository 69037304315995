import { isStaffPortal } from '../../../utils';

const ActionType = (myprops, t) => {
  const key = myprops.location.pathname;
  const loginLabel = isStaffPortal() ? t('auth.staffSignIn') : t('auth.signIn');
  let action = {
    type: 'login',
    label: loginLabel,
    inputLabel: t('auth.loginID'),
  };
  if (key.indexOf('register') >= 0) {
    action = {
      type: 'register',
      label: t('auth.parentSignUp'),
      inputLabel: t('auth.createLogIn'),
    };
  } else if (key.indexOf('login') >= 0) {
    action = {
      type: 'login',
      label: loginLabel,
      inputLabel: t('auth.loginID'),
    };
  }
  if (key.indexOf('reset') >= 0) {
    action = {
      type: 'reset',
      label: t('auth.setPassword'),
      inputLabel: t('auth.loginID'),
    };
  }
  if (key.indexOf('connecttomfsaccount') >= 0) {
    action = {
      type: 'connectToMFSAccount',
      label: t('auth.connectToMFSAccount', {
        schoolName: myprops.schoolName,
      }),
      inputLabel: t('auth.loginID'),
    };
  }
  if (key.indexOf('forgot') >= 0) {
    action = {
      type: 'forgot',
      label: t('common.forgotPassword2'),
      inputLabel: t('common.email'),
    };
  }
  return action;
};

export default ActionType;
