/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export const StyledSnackBar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    border-radius: 10px;
    box-shadow: 1px 5px 10px 0px #333333;
    background-color: ${props => props.theme.baseColor.snackBar.greyBackground};
    color: ${props => props.theme.baseColor.neutral.lighterGrey};
    width: 100%;
    margin-bottom: 32px;
    padding: 8px 16px;
    & p {
      color: ${props => props.theme.baseColor.neutral.lighterGrey};

      > span {
        color: ${props => props.theme.baseColor.neutral.lighterGrey};
      }
    }
  }

  & .MuiSnackbarContent-message {
    display: flex;
    align-items: center;
    & svg {
      margin-right: 10px;
      height: ${props => props.theme.iconSize.large};
      width: ${props => props.theme.iconSize.large};
    }
  }

  & .MuiSnackbarContent-action {
    margin-right: 0;
  }
  display: contents;
  ${props => {
    if (props.variant === 'success') {
      return `
                & .MuiSnackbarContent-message {
                    & svg {
                        color: ${props.theme.baseColor.status.success};
                    }
                }
                & .MuiSnackbarContent-action {
                    & svg {
                        color: ${
                          props.priority === 'high'
                            ? props.theme.palette.primary.main
                            : props.theme.baseColor.status.success
                        };
                    }
                    & button {
                        color: ${
                          props.priority === 'high'
                            ? props.theme.palette.primary.main
                            : props.theme.baseColor.status.success
                        };
                    }
                }
            `;
    }

    if (props.variant === 'error') {
      return `
                & .MuiSnackbarContent-message {
                    & svg {
                        color: ${props.theme.baseColor.status.error};
                    }
                }
                & .MuiSnackbarContent-action {
                    & svg {
                        color: ${
                          props.priority === 'high'
                            ? props.theme.palette.primary.main
                            : props.theme.baseColor.status.error
                        };
                    }
                    & button {
                        color: ${
                          props.priority === 'high'
                            ? props.theme.palette.primary.main
                            : props.theme.baseColor.status.error
                        };
                    }
                }
            `;
    }

    return `
            & .MuiSnackbarContent-message {
                & svg {
                    color: ${props.theme.baseColor.neutral.lighterGrey};
                }
            }
            & .MuiSnackbarContent-action {
                & svg {
                    color: ${
                      props.priority === 'high'
                        ? props.theme.palette.primary.main
                        : props.theme.baseColor.neutral.lighterGrey
                    };
                }
                & button {
                    color: ${
                      props.priority === 'high'
                        ? props.theme.palette.primary.main
                        : props.theme.baseColor.neutral.lighterGrey
                    };
                }
            }
        `;
  }}
`;

export const StyledActionButton = styled(IconButton)`
  ${props => {
    if (props.priority === 'medium') {
      return `
            &.MuiIconButton-root {
                margin-top: 0;
                margin-right: -10px;
                padding-top: 0;
                align-items: baseline;
            }`;
    }
  }}
`;
export const StyledErrorIcon = styled(ErrorIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const StyledCheckCircle = styled(CheckCircle)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e7ac08;
    border-radius: 50%;
  }
`;
