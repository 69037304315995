import { get } from 'lodash';
import {
  FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

export const allSystemAnnouncements = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SYSTEM_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getAllSystemAnnouncements'),
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const currentSystemAnnouncement = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getCurrentSystemAnnouncement'),
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
