import gql from 'graphql-tag';

const FRAGMENT_CONFIG = gql`
  fragment configData on ListCode {
    data {
      ID
      label
      description
    }
  }
`;

export const GET_CENTRE_MANAGEMENT_CONFIG = gql`
  query getCentreManagementConfig($fkSchool: FilterOnField) {
    workplace: findAllConfigByCategory(
      searchCategory: "workplace_staff"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
    govtScheme: findAllConfigByCategory(
      searchCategory: "govt_scheme"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
    centreCertification: findAllConfigByCategory(
      searchCategory: "centre_certification"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
  }
  ${FRAGMENT_CONFIG}
`;

export const CREATE_OR_UPDATE_SCHOOL_CENTRE = gql`
  mutation insertCentreForSchool(
    $schoolID: Int!
    $centreID: Int
    $centreData: CentreUpdate!
    $postcodes: [String!]
    $levels: [Int!]!
    $addressData: AddressUpdate
    $workplaces: [Int!]
    $centreAutoPromotionConfigs: [CentreAutoPromotionConfigInput!]
    $centreLevelProgram: [CentreLevelProgramUpdate!]
  ) {
    insertOrUpdateCentreForSchool(
      IDSchool: $schoolID
      centreID: $centreID
      centreData: $centreData
      workplaces: $workplaces
      postcodes: $postcodes
      levels: $levels
      addressData: $addressData
      centreAutoPromotionConfigs: $centreAutoPromotionConfigs
      centreLevelProgram: $centreLevelProgram
    ) {
      ID
    }
  }
`;

export const CREATE_OR_UPDATE_SCHOOL_CENTRE_DRAFT = gql`
  mutation insertCentreDraftForSchool(
    $schoolID: Int!
    $centreID: Int
    $centreData: CentreDraftUpdate!
    $postcodes: [String!]
    $levels: [Int!]!
    $addressData: CentreDraftAddressUpdate
    $workplaces: [Int!]
    $centreAutoPromotionConfigs: [CentreAutoPromotionConfigInput!]
    $centreLevelProgram: [CentreDraftLevelProgramUpdate!]
  ) {
    insertOrUpdateCentreDraftForSchool(
      IDSchool: $schoolID
      centreID: $centreID
      centreData: $centreData
      workplaces: $workplaces
      postcodes: $postcodes
      levels: $levels
      addressData: $addressData
      centreAutoPromotionConfigs: $centreAutoPromotionConfigs
      centreDraftLevelProgram: $centreLevelProgram
    ) {
      ID
    }
  }
`;

export const FIND_ALL_CENTRE_DRAFT_FOR_SCHOOL = gql`
  query findAllCentreDraftForSchool(
    $schoolID: Int!
    $pagination: Pagination
    $filter: CentreDraftFilter
  ) {
    findAllCentreDraftForSchool(
      IDSchool: $schoolID
      pagination: $pagination
      filter: $filter
    ) {
      totalCount
      data {
        ID
        code
        ecdaCode
        costCentreCode
        fkPrimaryEmailContact
        label
        email
        fkSchool
        fkGovtScheme
        fkCertification
        teacherRatio
        createdAt
        updatedAt
        linkBranchID
        sparkExpirationDate
        firstLicenseDate
        licenseDuration
        firstOperationDate
        licenseRenewalDate
        licenseRenewalDuration
        licensedInfantCareCapacity
        licensedChildcareCapacity
        isPublic
        enrolmentDatesSetting
        status
        to
        registrationFee
        departmentCentre
        centreDraftWorkplaces {
          totalCount
          data {
            ID
            fkCentreDraft
            fkWorkplace
          }
        }
        centreDraftAddresses {
          totalCount
          data {
            ID
            name
            floor
            unit
            address
            building
            postcode
            city
            country
            lat
            lng
            building
          }
        }
        centreDraftLevels {
          totalCount
          data {
            ID
            fkLevel
            autoPromotionFlg
            autoPromotionJobDay
            autoPromotionJobMonth
            reminderDays
            createdAt
            updatedAt
          }
        }
        centreDraftNearbyPostcodes {
          totalCount
          data {
            ID
            fkCentreDraft
            postcode
            createdAt
            updatedAt
          }
        }
        centreDraftLevelPrograms {
          data {
            fkLevel
            fkProgram
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CENTRE_FOR_SCHOOL = gql`
  query findAllCentreForSchool(
    $schoolID: Int!
    $includeClosedCentres: Boolean
    $pagination: Pagination
    $filter: CentreFilter
  ) {
    findAllCentreForSchool(
      IDSchool: $schoolID
      includeClosedCentres: $includeClosedCentres
      pagination: $pagination
      filter: $filter
    ) {
      totalCount
      data {
        ID
        code
        ecdaCode
        costCentreCode
        fkPrimaryEmailContact
        label
        email
        fkSchool
        fkGovtScheme
        fkCertification
        teacherRatio
        createdAt
        updatedAt
        linkBranchID
        sparkExpirationDate
        firstLicenseDate
        licenseDuration
        firstOperationDate
        licenseRenewalDate
        licenseRenewalDuration
        licensedInfantCareCapacity
        licensedChildcareCapacity
        isPublic
        enrolmentDatesSetting
        active
        to
        departmentCentre
        centreWorkplaces {
          totalCount
          data {
            ID
            fkCentre
            fkWorkplace
          }
        }
        addresses {
          totalCount
          data {
            ID
            name
            floor
            unit
            address
            building
            postcode
            city
            country
            lat
            lng
            building
          }
        }
        centreLevels {
          totalCount
          data {
            ID
            fkLevel
            autoPromotionFlg
            autoPromotionJobDay
            autoPromotionJobMonth
            reminderDays
            createdAt
            updatedAt
          }
        }
        centreNearbyPostcodes {
          totalCount
          data {
            ID
            fkCentre
            postcode
            createdAt
            updatedAt
          }
        }
        centreLevelPrograms {
          data {
            fkLevel
            fkProgram
          }
        }
        billableItems(filter: { type: "registration_fee" }) {
          totalCount
          data {
            unitPrice
            type
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CENTRE_LIST_FOR_SCHOOL = gql`
  query findAllCentreForSchool(
    $schoolID: Int!
    $includeClosedCentres: Boolean
    $pagination: Pagination
    $filter: CentreFilter
  ) {
    findAllCentreForSchool(
      IDSchool: $schoolID
      includeClosedCentres: $includeClosedCentres
      pagination: $pagination
      filter: $filter
    ) {
      totalCount
      data {
        ID
        code
        label
        updatedAt
        active
        userByLastUpdatedByFkUser {
          firstname
          lastname
        }
      }
    }
  }
`;

export const FIND_ALL_CENTRE_LIST_DRAFT_FOR_SCHOOL = gql`
  query findAllCentreDraftForSchool(
    $schoolID: Int!
    $pagination: Pagination
    $filter: CentreDraftFilter
  ) {
    findAllCentreDraftForSchool(
      IDSchool: $schoolID
      pagination: $pagination
      filter: $filter
    ) {
      totalCount
      data {
        ID
        code
        label
        fkSchool
        updatedAt
        status
        userByLastUpdatedByFkUser {
          firstname
          lastname
        }
      }
    }
  }
`;

export const GET_MARKETING_CONSENT = gql`
  query getMarketingConsentDashboard(
    $centreIds: [Int!]
    $searchString: String
    $pagination: Pagination
  ) {
    getMarketingConsentDashboard(
      centreIds: $centreIds
      searchString: $searchString
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        documentURL
        fkMarketingConsentUser
        fkUser
        updatedAt
        marketingConsentUser {
          fullname
          email
          mobilePhone
          mobilePhoneCountryCode
        }
        user {
          firstname
          lastname
          email
          mobilePhoneCountryCode
          mobilePhone
        }
        marketingConsentBusinessEntities {
          data {
            ID
            phone
            sms
            email
            businessEntity {
              ID
              name
              type
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_MARKETING_CONSENT_UPLOAD_URL = gql`
  mutation getMarketingConsentDocumentUploadURL($file: Upload!) {
    getMarketingConsentDocumentUploadURL(file: $file) {
      Url
      Key
    }
  }
`;

export const UPDATE_MARKETING_CONSENT = gql`
  mutation updateMarketingConsentDashboard(
    $input: MarketingConsentDashboardInput!
  ) {
    updateMarketingConsentDashboard(input: $input)
  }
`;

export const GET_MARKETING_CONSENT_LOGS = gql`
  query findAllMarketingConsentAuditLog(
    $pagination: Pagination
    $filter: MarketingConsentAuditLogFilter
  ) {
    findAllMarketingConsentAuditLog(pagination: $pagination, filter: $filter) {
      totalCount
      data {
        ID
        marketingConsentJSON
        active
        remarks
        createdAt
        updatedAt
        user {
          firstname
          lastname
        }
      }
    }
  }
`;

export const DELETE_SCHOOL_CENTRE_DRAFT = gql`
  mutation deleteCentreDraftForSchool($schoolID: Int!, $centreID: Int!) {
    deleteCentreDraftForSchool(IDSchool: $schoolID, centreID: $centreID) {
      ID
    }
  }
`;

export const CANCEL_SCHOOL_CENTRE_DRAFT = gql`
  mutation cancelCentreDraftForSchool($schoolID: Int!, $centreID: Int!) {
    cancelCentreDraftForSchool(IDSchool: $schoolID, centreID: $centreID) {
      ID
    }
  }
`;

export const SUBMIT_SCHOOL_CENTRE_DRAFT = gql`
  mutation submitCentreDraftForSchool($schoolID: Int!, $centreID: Int!) {
    submitCentreDraftForSchool(IDSchool: $schoolID, centreID: $centreID) {
      ID
    }
  }
`;

export const GET_CENTRE_DOC_CATEGORIES = gql`
  query getCentreDocumentCategoriesConfig {
    getCentreDocumentCategoriesConfig {
      data {
        ID
        label
      }
    }
  }
`;

export const GET_CENTRE_DOCS = gql`
  query listAllCentreDocumentsByCategories(
    $centreID: Int!
    $centreDocumentCategoriesIDs: [Int!]!
    $pagination: Pagination
  ) {
    listAllCentreDocumentsByCategories(
      centreID: $centreID
      centreDocumentCategoriesIDs: $centreDocumentCategoriesIDs
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkCentre
        user {
          firstname
          lastname
          email
        }
        fkCentreDocumentCategory
        uRL
        filename
        remark
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CENTRE_ACTIVITY_LOGS = gql`
  query getCentreSnapshotByCentreID($centreID: Int!, $pagination: Pagination) {
    getCentreSnapshotByCentreID(centreID: $centreID, pagination: $pagination) {
      totalCount
      data {
        ID
        createdAt
        updatedAt
        oldData
        updatedData
        changeType
        changeTypeKey
        user {
          ID
          firstname
          lastname
          email
        }
      }
    }
  }
`;

export const GET_CENTRE_DOC_CONFIG = gql`
  mutation getCentreDocumentUploadURLDocumentCategoryConfig(
    $centreID: Int!
    $centreDocumentCategoryID: Int!
    $file: Upload!
  ) {
    getCentreDocumentUploadURLDocumentCategoryConfig(
      centreID: $centreID
      centreDocumentCategoryID: $centreDocumentCategoryID
      file: $file
    ) {
      Url
      Key
    }
  }
`;

export const POST_CENTRE_DOCUMENTS = gql`
  mutation saveCentreDocument(
    $uploadedCentreDocuments: [CentreDocumentUpload!]!
  ) {
    saveCentreDocument(uploadedCentreDocuments: $uploadedCentreDocuments)
  }
`;
