import { get } from 'lodash';
import {
  FETCH_ALL_BUSINESS_ENTITIES,
  FETCH_ALL_BUSINESS_ENTITIES_ERROR,
  FETCH_ALL_BUSINESS_ENTITIES_SUCCESS,
} from './actionTypes';

import { SkApolloRequest } from '../../utils/apolloUtils';
import { FIND_ALL_BUSINESS_ENTITIES_GQL } from '../models/marketingConsentModel';

export const fetchAllBusinessEntities = reqData => async dispatch => {
  dispatch({ type: FETCH_ALL_BUSINESS_ENTITIES });
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_BUSINESS_ENTITIES_GQL,
        variables: { ...reqData },
      },
      type: 'query',
    });
    const success = get(data, 'success', false);

    const allBusinessEntity = get(data, 'data.findAllBusinessEntity', {});
    if (success) {
      dispatch({
        type: FETCH_ALL_BUSINESS_ENTITIES_SUCCESS,
        value: allBusinessEntity,
      });
    } else {
      dispatch({
        type: FETCH_ALL_BUSINESS_ENTITIES_ERROR,
        value: get(data, 'error'),
      });
    }
    return allBusinessEntity;
  } catch (ex) {
    dispatch({
      type: FETCH_ALL_BUSINESS_ENTITIES_ERROR,
      value: { error: get(ex, 'message') },
    });
    return ex;
  }
};
export default fetchAllBusinessEntities;
