import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import List from '@material-ui/core/List';

const AvatarWrapper = styled(Avatar)`
  margin: 0 15px 5px 0;
`;

export const ListWrapper = styled(List)`
  ${props =>
    ` padding: ${props.subMenu ? '0px' : '15px'};
`}
`;

export const LogoutWraper = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  ${props =>
    `
    @media ${props.theme.device.mobileL} {
      padding-left: 0px;
    }
    `}
`;

export const RouteContent = styled.div`
  ${props =>
    `
    @media ${props.theme.device.mobileL} {
      padding: 0px;
      margin: 10px 30px;
    }
    `}
`;

export default AvatarWrapper;
