import SkRequest from './BaseRequest';
import { MutationBuilder } from '../queryBuilder';

const _mMutationBuilder = new MutationBuilder();

_mMutationBuilder.setNewMutation('updateSchoolConfig', {
  ID: 'number',
  key: 'string',
  value: 'string',
});

_mMutationBuilder.setNewMutation('getSchoolLogoUploadURL', {
  Url: 'string',
  Key: 'string',
});

export const updateSchoolConfig = reqData => async () => {
  const query = _mMutationBuilder.getBulkMutationAsString({
    updateSchoolConfig: reqData,
  });
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getSchoolLogoUploadURL = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'getSchoolLogoUploadURL',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default updateSchoolConfig;
