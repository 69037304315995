import { get } from 'lodash';
import {
  FETCH_ALL_REPORT_SCHEMA,
  FETCH_ALL_REPORT_LIST,
  FETCH_ALL_QQL_CHECKLIST_REPORT,
  FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER,
} from '../actions/actionTypes';

export const reportSchema = (state = {}, action) => {
  if (action.type === FETCH_ALL_REPORT_SCHEMA) {
    const data = get(action, 'value.data.getReportSchema');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const reportList = (state = {}, action) => {
  if (action.type === FETCH_ALL_REPORT_LIST) {
    const data = get(action, 'value.data.getReportList');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const qQLCheckListReportByCenter = (state = {}, action) => {
  if (action.type === FETCH_ALL_QQL_CHECKLIST_REPORT) {
    const data = get(action, 'value.data.getMigratedChecklistAllCentres', {});
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const qQLCheckListReportClassByCenter = (state = {}, action) => {
  if (action.type === FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER) {
    const data = get(
      action,
      'value.data.getMigratedChecklistClassByCentre',
      {}
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default reportSchema;
