import {
  FETCH_ALL_LEADS,
  FETCH_ALL_LEADS_SUCCESS,
  FETCH_ALL_LEADS_ERROR,
  FETCH_LEAD_BY_ID,
  FETCH_LEAD_BY_ID_SUCCESS,
  FETCH_LEAD_BY_ID_ERROR,
  FETCH_ALL_POC_USER,
  FETCH_ALL_POC_USER_ERROR,
  FETCH_ALL_POC_USER_SUCCESS,
  SAVE_LEAD_FILTERS,
} from '../actions/actionTypes';

const CENTRE_WAITLIST_INIT_STATE = {
  isGettingCentreWaitlist: false,
  fetchCentreWaitListSuccess: false,
  findAllLeads: {},
  filter: {},
};

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

const ENQUIRY_DETAIL_INIT_STATE = {
  isGettingCentreWaitlist: false,
  fetchEnquryDetailSuccess: false,
  leadByID: {},
};

export const allCentreWaitlist = (
  state = CENTRE_WAITLIST_INIT_STATE,
  action
) => {
  const { type, value } = action || {};
  switch (type) {
    case SAVE_LEAD_FILTERS: {
      const { filter } = value;
      return {
        ...state,
        filter,
      };
    }
    case FETCH_ALL_LEADS: {
      return {
        ...CENTRE_WAITLIST_INIT_STATE,
        isGettingCentreWaitlist: true,
        fetchCentreWaitListSuccess: false,
      };
    }
    case FETCH_ALL_LEADS_SUCCESS: {
      const { findAllLeads } = value || {};
      return {
        ...state,
        isGettingCentreWaitlist: false,
        fetchCentreWaitListSuccess: true,
        findAllLeads,
      };
    }
    case FETCH_ALL_LEADS_ERROR: {
      const { error } = value || {};
      return {
        ...state,
        isGettingCentreWaitlist: false,
        fetchCentreWaitListSuccess: false,
        error,
      };
    }
    default:
      return { ...state };
  }
};

export const enquiryDetailByID = (
  state = ENQUIRY_DETAIL_INIT_STATE,
  action
) => {
  const { type, value } = action || {};
  switch (type) {
    case FETCH_LEAD_BY_ID: {
      return {
        ...ENQUIRY_DETAIL_INIT_STATE,
        isGettingDetailEnquiry: true,
        fetchEnquryDetailSuccess: false,
      };
    }
    case FETCH_LEAD_BY_ID_SUCCESS: {
      const { leadByID } = value || {};
      return {
        ...state,
        isGettingDetailEnquiry: false,
        fetchEnquryDetailSuccess: true,
        leadByID,
      };
    }
    case FETCH_LEAD_BY_ID_ERROR: {
      const { error } = value || {};
      return {
        ...state,
        isGettingDetailEnquiry: false,
        fetchEnquryDetailSuccess: false,
        error,
      };
    }
    default:
      return { ...state };
  }
};

export const allPOCUsers = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_POC_USER:
      return {
        ...state,
        inProgress: true,
        error: null,
        data: [],
      };
    case FETCH_ALL_POC_USER_ERROR:
      return {
        ...state,
        inProgress: false,
        ...action.value,
      };
    case FETCH_ALL_POC_USER_SUCCESS:
      return {
        ...state,
        inProgress: false,
        ...action.value,
      };
    default:
      return state;
  }
};

export default allCentreWaitlist;
