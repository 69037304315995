import { get } from 'lodash';
import {
  ALL_WITHDRAWAL_REQUEST,
  ALL_WITHDRAWAL,
  ALL_REFUNDS,
  FETCH_WITHDRAWAL_DRAFTS_LOADING,
  FETCH_WITHDRAWAL_DRAFTS_SUCCESS,
  FETCH_WITHDRAWAL_DRAFTS_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

/**
 *
 * Example data
 *{
 * "data": {
 *  "findAllWithdrawalDraft": {
 *    "totalCount": 491,
 *    "data": [
 *      {
 *        "ID": 4,
 *        "fkSchool": 3,
 *        "fkCentre": 1,
 *        "status": "approved",
 *        "eConsentStatus": "completed",
 *        "updatedAt": "2014-09-15 00:00:00",
 *        "additionalStatus": "declaration",
 *        "forms": {
 *          "data": []
 *        },
 *        "estimateRefundAmount": {
 *          "defaultDepositRefundAmount": 0,
 *          "defaultFeeRefundAmount": 0,
 *          "monthlyFee": 0,
 *          "depositAmount": 0
 *        },
 *        "withdrawalDraftItem": {
 *          "refundFeeAmount": 722.25,
 *          "refundDepositAmount": 1350,
 *          "effectiveDate": "2014-09-15 00:00:00",
 *          "createdAt": "2014-08-16 00:00:00",
 *          "submissionDate": "2014-08-15 16:00:00",
 *          "child": {
 *            "ID": 297,
 *            "firstname": "",
 *            "lastname": "Dylan Arthur Colman"
 *          },
 *          "codeByReason": {
 *            "ID": 2066,
 *            "label": "Others",
 *            "description": "Others"
 *          },
 *          "codeByParentReason": null
 *        },
 *        "withdrawalDraftActivityLogs": {
 *          "data": [
 *            {
 *              "ID": 4,
 *              "remark": ""
 *            }
 *          ]
 *        }
 *      },
 *    ]
 *  }
 * }
 *
 */

export const allWithDrawalDraft = (state = {}, action) => {
  if (action.type === ALL_WITHDRAWAL_REQUEST) {
    if (action.value && action.value.data) {
      return Object.assign({}, state, {
        data: action.value.data,
      });
    }
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllWithdrawal": {
 *       "totalCount": 366,
 *       "data": [
 *         {
 *           "ID": 4,
 *           "refundFeeAmount": 722.25,
 *           "refundDepositAmount": 1350,
 *           "effectiveDate": "2014-09-15 00:00:00",
 *           "child": {
 *             "ID": 297,
 *             "firstname": "",
 *             "lastname": "Dylan Arthur Colman"
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const allWithDrawal = (state = {}, action) => {
  if (action.type === ALL_WITHDRAWAL) {
    if (action.value && action.value.data) {
      return Object.assign({}, state, {
        data: action.value.data,
      });
    }
  }
  return state;
};

/**
 *Example data
 * {
 *   "data": {
 *     "getWithdrawalDefaultRefundAmount": {
 *       "depositAmount": 850,
 *       "defaultFeeRefundAmount": 278,
 *       "defaultDepositRefundAmount": 0,
 *       "monthlyFee": 556
 *     }
 *   }
 * }
 */
export const allRefunds = (state = {}, action) => {
  if (action.type === ALL_REFUNDS) {
    const data = get(
      action,
      'value.data.data.getWithdrawalDefaultRefundAmount'
    );
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const allWithdrawalDrafts = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_WITHDRAWAL_DRAFTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_WITHDRAWAL_DRAFTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getAllWithdrawalDrafts'),
      };
    case FETCH_WITHDRAWAL_DRAFTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default allWithDrawalDraft;
