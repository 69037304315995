import gql from 'graphql-tag';

const FIND_ALL_CHILD_ACTIVITY_LOG = gql`
  query findAllChildActivityLog(
    $filter: ChildActivityLogFilter
    $pagination: Pagination
  ) {
    findAllChildActivityLog(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        mode
        action
        updatedAt
        code {
          label
          description
        }
        user {
          firstname
          lastname
        }
        remarks
      }
    }
  }
`;

export default FIND_ALL_CHILD_ACTIVITY_LOG;
