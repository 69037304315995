import { getSecondaryColor } from '../utils';

const styles = theme => ({
  autoCompleteSearch: {
    root: {
      backgroundColor: 'white !important',
      borderBottom: '1px solid #c7c7c7 !important',
    },
    selected: {
      backgroundColor: getSecondaryColor({}),
    },
    hover: {
      backgroundColor: getSecondaryColor({}),
    },
  },
  resize: {
    fontSize: '18px',
    color: '#717171',
    fontWeight: '500',
  },
  pickerwidth: {
    width: '145px',
    marginTop: '35px',
  },
  customdatepick: {
    width: '180px',
    fontSize: '18px',
    color: '#717171',
    fontWeight: '500',
  },
  select: {
    fontSize: '18px',
    color: '#717171',
    fontWeight: '500',
  },
  icon: {
    fill: '#4a4a4a',
  },

  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    fontSize: '14px',
  },
  chipFocused: {
    backgroundColor: 'white',
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing() * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    color: '#dadada',
    left: 2,
    fontSize: 18,
    fontWeight: 500,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing() * 2,
  },
  paperRoot: {
    padding: '24px',
  },
  checkBoxRoot: {
    padding: '12px 0px !important',
  },
  checkboxMargin: {
    marginLeft: '14px',
  },
  group: {
    margin: `${theme.spacing()}px 0`,
  },
  label: {
    fontSize: '16px',
  },
  radioLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    fontSize: '16px',
  },
  tooltip: {
    color: '#22c6ad',
    backgroundColor: 'transparent',
  },
});

export default styles;
