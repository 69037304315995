import React from 'react';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';
import moment from 'moment';
import { BUS_SETTINGS } from '../../../../routes/constants';
import FormWrapper from '../../../common/FormWrapper';
import Text from '../../../common/Text';
import Modal from '../../../common/Modal';
import {
  TextField,
  LabelField,
  SelectField,
  PhoneField,
  DateTimePicker,
  RadioGroupField,
} from '../../../common/InputFields';
import { RowFieldWrapper } from './style';
import MaskedTimeInput from '../../../common/TimePicker/index';

const styles = {
  wrapperInputStyle: {
    padding: '5px 0',
  },
  inputStyle: {
    marginBottom: '-1px',
  },
  yearLabelStyle: {
    padding: '6px 0',
  },
};

const BusFormView = ({
  data,
  apiError,
  loading,
  handleBlur,
  handleChange,
  handleSave,
  handleCancel = {},
  setFieldValue,
  centreOptions,
  isEdit,
  t,
  showPopUp,
  togglePopUpState,
  setSubmitting,
  editBusAction,
  addNewBusRoute,
  saveButtonDisabled,
}) => (
  <FormWrapper
    handleSave={handleSave}
    error={apiError}
    saveButtonText={isEdit ? null : t('bus_management.addBusButton')}
    cancelLink={isEdit ? null : BUS_SETTINGS}
    loading={loading}
    handleCancel={handleCancel}
    saveButtonDisabled={saveButtonDisabled}
  >
    <Grid container>
      <RowFieldWrapper item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <TextField
              required
              name="busName"
              label={t('bus_management.busNameLabel')}
              placeholder={t('bus_management.busNamePlaceholder')}
              value={get(data, 'busName')}
              error={
                get(data, 'touched.busName') && get(data, 'errors.busName')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <TextField
              required
              name="busCompany"
              label={t('bus_management.busCompanyLabel')}
              placeholder={t('bus_management.busCompanyNamePlaceholder')}
              value={get(data, 'busCompany')}
              error={
                get(data, 'touched.busCompany') &&
                get(data, 'errors.busCompany')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
        </Grid>
      </RowFieldWrapper>
      <RowFieldWrapper item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <TextField
              required
              name="driverName"
              label={t('bus_management.driverNameLabel')}
              placeholder={t('bus_management.driverNamePlaceholder')}
              value={get(data, 'driverName')}
              error={
                get(data, 'touched.driverName') &&
                get(data, 'errors.driverName')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <PhoneField
              required
              name="driverContactNo"
              label={t('bus_management.driverContactNoLabel')}
              placeholder={t('bus_management.contactNoPlaceholder')}
              value={get(data, 'driverContactNo')}
              defaultCountryCode={{
                value: get(data, 'driverContactNoCountryCode'),
                label: get(data, 'driverContactNoCountryCode'),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              onCodeChange={cc => {
                setFieldValue('driverContactNoCountryCode', cc.value);
              }}
              error={
                (get(data, 'touched.driverContactNo') &&
                  get(data, 'errors.driverContactNo')) ||
                (get(data, 'touched.driverContactNoCountryCode') &&
                  get(data, 'errors.driverContactNoCountryCode'))
              }
              disabled={isEdit}
              wrapperStyle={styles.wrapperInputStyle}
              inputStyle={styles.inputStyle}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <TextField
              required
              name="busPlateNo"
              label={t('bus_management.busPlateNoLabel')}
              placeholder={t('bus_management.busPlateNoPlaceholder')}
              value={get(data, 'busPlateNo')}
              error={
                get(data, 'touched.busPlateNo') &&
                get(data, 'errors.busPlateNo')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
              inputProps={{
                maxLength: 32,
              }}
            />
          </Grid>
        </Grid>
      </RowFieldWrapper>
      <RowFieldWrapper item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <TextField
              required
              name="attendantName"
              label={t('bus_management.attendantNameLabel')}
              placeholder={t('bus_management.attendantNamePlaceholder')}
              value={get(data, 'attendantName')}
              error={
                get(data, 'touched.attendantName') &&
                get(data, 'errors.attendantName')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <PhoneField
              required
              name="attendantContactNo"
              label={t('bus_management.attendantContactNoLabel')}
              placeholder={t('bus_management.attendanceContactPlaceholder')}
              value={get(data, 'attendantContactNo')}
              defaultCountryCode={{
                value: get(data, 'attendantContactNoCountryCode'),
                label: get(data, 'attendantContactNoCountryCode'),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              onCodeChange={cc => {
                setFieldValue('attendantContactNoCountryCode', cc.value);
              }}
              error={
                (get(data, 'touched.attendantContactNo') &&
                  get(data, 'errors.attendantContactNo')) ||
                (get(data, 'touched.attendantContactNoCountryCode') &&
                  get(data, 'errors.attendantContactNoCountryCode'))
              }
              wrapperStyle={styles.wrapperInputStyle}
              inputStyle={styles.inputStyle}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <LabelField
              name="attendantPasscode"
              label={t('bus_management.attendantPasscodeLabel')}
              value={isEdit ? get(data, 'attendantPassCode', '-') : '-'}
            />
          </Grid>
        </Grid>
      </RowFieldWrapper>
      <RowFieldWrapper item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <SelectField
              required
              name="directionType"
              label={t('bus_management.directionTypeLabel')}
              options={[
                {
                  label: 'departure',
                  description: t('bus_management.directionTypeDeparture'),
                },
                {
                  label: 'arrival',
                  description: t('bus_management.directionTypeArrival'),
                },
              ]}
              value={get(data, 'directionType')}
              error={
                get(data, 'touched.directionType') &&
                get(data, 'errors.directionType')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <MaskedTimeInput
              required
              name="departureTime"
              label={t('bus_management.departureTimeLabel')}
              margin="none"
              placeholder="00:00 AM"
              mask="__:__ _M"
              selectedTime={get(data, 'departureTime')}
              handleTimeChange={handleChange}
              error={
                get(data, 'touched.departureTime') &&
                get(data, 'errors.departureTime')
              }
              inputVariant="standard"
              invalidFormatErrorNotNeeded
              labelStyle={{ paddingBottom: '13px' }}
              fullWidth
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <SelectField
              required
              name="associatedCentre"
              label={t('bus_management.associatedCentre')}
              options={centreOptions}
              value={get(data, 'associatedCentre')}
              error={
                get(data, 'touched.associatedCentre') &&
                get(data, 'errors.associatedCentre')
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isEdit}
            />
          </Grid>
          {isEdit && (
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <RadioGroupField
                name="status"
                label={t('common.status')}
                showHorizontal
                value={get(data, 'status')}
                onChange={handleChange}
                onBlur={handleChange}
                options={[
                  {
                    description: t('common.active'),
                    label: '1',
                  },
                  {
                    description: t('common.disabled'),
                    label: '0',
                  },
                ]}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            <DateTimePicker
              required
              isNormal
              label={t('common.year')}
              name="year"
              type="date"
              value={get(data, 'year')}
              error={get(data, 'touched.year') && get(data, 'errors.year')}
              handleDateChange={handleChange}
              minDate={moment().startOf('year')}
              views={['year']}
              disabled={isEdit}
              labelStyle={styles.yearLabelStyle}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
            {showPopUp && (
              <Modal
                isOpen={showPopUp}
                onPrimaryClick={isEdit ? editBusAction : addNewBusRoute}
                onSecondaryClick={() => {
                  togglePopUpState(false);
                  setSubmitting(false);
                }}
                dialogTitle={
                  isEdit
                    ? t('bus_management.saveBusLabel')
                    : t('bus_management.addBusButton')
                }
                dialogContent={
                  <>
                    <Text body>
                      {isEdit
                        ? t('bus_management.saveBusDialogText')
                        : t('bus_management.createBusDialogText')}
                    </Text>
                  </>
                }
                fullWidth
                buttonPrimaryName={t('common.confirmButton')}
                buttonSecondaryName={t('common.cancelButton')}
              />
            )}
          </Grid>
        </Grid>
      </RowFieldWrapper>
    </Grid>
  </FormWrapper>
);

export default BusFormView;
