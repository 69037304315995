import { get, set, uniqWith } from 'lodash';
import moment from 'moment';
import DatadogHandler from 'utils/datadog';
import {
  NOTIFICATION_ENDPOINT,
  notificationMsInstance,
} from 'utils/msEndpointConstants';
import { checkMSEnabled, isObjectId } from 'utils';
import { SAVE_DATE_FORMAT } from 'utils/constants';
import {
  FETCH_NOTIFICATIONS_HISTORY,
  SHOW_SNACKBAR_MESSAGE,
  HIDE_SNACKBAR_MESSAGE,
} from './actionTypes';
import apolloUtils from '../../utils/apolloUtils';
import {
  MARK_NOTIFICATIONS,
  NOTIFICATION_HISTORY,
} from '../models/notificationModels';

export const NOTIFICATION_MS = {
  page: 1,
  perPage: 10,
  channel: 'app',
  sort: 'created_at',
  customSort: 'desc',
};

const dispatchNotificationHistory = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_NOTIFICATIONS_HISTORY,
    key: 'notification_history',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

// Transform to satisfy current structure
export const transformNotificationMsData = msData => {
  const transformedData = (msData || []).map(item => {
    return {
      ID: get(item, 'id'),
      channel: get(item, 'channel'),
      content: {
        title: get(item, 'appNotificationContent.title', ''),
        body: get(item, 'appNotificationContent.body', ''),
      },
      read: get(item, 'read', false),
      navigate: get(item, 'appNotificationContent.navigateTo', ''),
      createdAt: get(item, 'createdAt'),
      meta: {
        iconType: get(item, 'appNotificationContent.iconType'),
        webLink: get(item, 'appNotificationContent.webLink'),
      },
    };
  });
  return transformedData;
};

export const fetchNotificationHistory = reqData => async (
  dispatch,
  getState
) => {
  // get MS flag config from store
  const isMSEnabled = checkMSEnabled(getState());
  dispatchNotificationHistory(dispatch);
  const client = await apolloUtils();
  try {
    const result = {
      data: {
        getNotifications: {
          data: [],
        },
      },
    };

    // We're getting the SN2 notification data by default
    const sn2Result = await client.query({
      query: NOTIFICATION_HISTORY,
      variables: reqData,
    });

    const sn2NotificationData = get(
      sn2Result,
      'data.getNotifications.data',
      []
    );

    if (isMSEnabled) {
      let msResponse;
      try {
        // Getting the MS notification data
        msResponse = await notificationMsInstance.get(
          NOTIFICATION_ENDPOINT.LIST,
          NOTIFICATION_MS
        );
      } catch (ex) {
        msResponse = {};
        DatadogHandler.addError(ex);
        DatadogHandler.sendLog(ex, {}, 'error');
      }

      let data = transformNotificationMsData(get(msResponse, 'data.data', []));
      data = uniqWith(
        [...data, ...sn2NotificationData].sort(
          (a, b) =>
            moment(b.createdAt, SAVE_DATE_FORMAT) -
            moment(a.createdAt, SAVE_DATE_FORMAT)
        ),
        (a, b) => {
          /**
           *  Find and remove duplicate notification by `content`, `createdAt`,
           * `navigate`, `read` and `meta`
           * */
          return (
            JSON.stringify(a?.content || {}) ===
              JSON.stringify(b?.content || {}) &&
            a?.createdAt === b?.createdAt &&
            a?.navigate === b?.navigate &&
            JSON.stringify(a?.meta || {}) === JSON.stringify(b?.meta || {}) &&
            a?.read === b?.read
          );
        }
      );
      set(result, 'data.getNotifications.data', data);
    } else {
      set(result, 'data.getNotifications.data', sn2NotificationData);
    }

    dispatchNotificationHistory(dispatch, false, result);

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchNotificationHistory(dispatch, false, null, {
      error: errorMessage,
    });
    return { error: errorMessage };
  }
};

export const markNotificationRead = (notificationIds, read = true) => async (
  _,
  getState
) => {
  // get MS flag config from store
  const isMSEnabled = checkMSEnabled(getState());
  const client = await apolloUtils();
  try {
    // Separating the ids for MS and SN2
    const sn2NotificationIds = notificationIds.filter(id => !isObjectId(id));

    if (isMSEnabled) {
      const msNotificationIds = notificationIds.filter(id => isObjectId(id));
      // Mark as read for notifications that belong to MS if msNotificationIds > 0
      if (msNotificationIds.length) {
        await notificationMsInstance.post(NOTIFICATION_ENDPOINT.MARK_READ, {
          notificationIds: msNotificationIds,
        });
      }
    }

    // Mark as read for notifications that belong to SN2 if sn2NotificationIds > 0
    if (sn2NotificationIds.length) {
      await client.mutate({
        mutation: MARK_NOTIFICATIONS,
        variables: {
          notificationIds: sn2NotificationIds,
          read,
        },
      });
    }

    return {
      data: {
        markNotifications: true,
      },
    };
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const showSnackBarMessage = (
  message,
  variant = 'success',
  priority = 'low',
  icon = null,
  anchor = 'top'
) => dispatch => {
  dispatch({
    type: SHOW_SNACKBAR_MESSAGE,
    key: 'snackbarMessage',
    value: {
      message,
      variant,
      priority,
      icon,
      anchor,
    },
  });
};

export const hideSnackBarMessage = () => dispatch => {
  dispatch({
    type: HIDE_SNACKBAR_MESSAGE,
    key: 'snackbarMessage',
    value: {},
  });
};

export default fetchNotificationHistory;
