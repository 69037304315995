import { get, union } from 'lodash';

import {
  GET_STAFF_ATTENDANCES,
  GET_STAFF_ATTENDANCE_FILTER,
} from '../models/staffAttendanceModels';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FETCH_STAFF_ATTENDANCE,
  FETCH_STAFF_ATTENDANCE_FILTERS,
} from './actionTypes';

const dispatchFetchStaffAttendance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_STAFF_ATTENDANCE,
    key: 'staffAttendance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchStaffAttendanceFilters = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_STAFF_ATTENDANCE_FILTERS,
    key: 'staffAttendanceFilters',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchStaffAttendance = reqData => async dispatch => {
  dispatchFetchStaffAttendance(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_STAFF_ATTENDANCES,
        variables: reqData,
      },
      type: 'query',
    });

    if (result.success) {
      const data = {
        total: get(result, 'data.getStaffCheckInOutRecordsByRole.totalCount'),
        staffAttendances: get(
          result,
          'data.getStaffCheckInOutRecordsByRole.staffCheckInOut',
          []
        ).map(item => ({
          checkInOut: union(
            get(item, 'checkIns', []),
            get(item, 'checkOuts', [])
          ),
          staff: get(item, 'staff.userByFkUser'),
          status: get(item, 'status'),
          date: get(reqData, 'date'),
        })),
      };

      dispatchFetchStaffAttendance(dispatch, false, data);
    } else {
      dispatchFetchStaffAttendance(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchFetchStaffAttendance(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchStaffAttendanceFilters = reqData => async dispatch => {
  dispatchFetchStaffAttendanceFilters(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_STAFF_ATTENDANCE_FILTER,
        variables: reqData,
      },
      type: 'query',
    });

    const countObj = get(
      result,
      'data.getCountStaffCheckInOutRecordsByRole',
      {}
    );

    const data = Object.keys(countObj)
      .filter(item => ['all', 'absent', 'present'].includes(item))
      .map(filter => ({
        filter,
        count: countObj[filter],
      }));

    if (result.success) {
      dispatchFetchStaffAttendanceFilters(dispatch, false, data);
    } else {
      dispatchFetchStaffAttendanceFilters(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchStaffAttendanceFilters(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const verifyDeclineStaffAttendance = (
  attendanceIDs,
  status
) => async () => {
  try {
    const bulkMutationReqData = attendanceIDs.map((id, index) => ({
      queryKey: `${status}StaffAttendance_${index + 1}`,
      key: 'verifyCheckInOutForStaff',
      query: '',
      variables: [
        {
          attendanceId: id,
          status,
        },
      ],
    }));

    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });

    return response;
  } catch (ex) {
    return ex;
  }
};
