import { ResponseBuilder } from './response';

export class QueryBuilder extends ResponseBuilder {
  constructor() {
    super();

    this._queries = {};
  }

  /**
   * Save a query with a key
   * @param {String} name the key of query
   * @param {JSON} responseObj definition of the response params
   */
  setNewQuery(name, responseObj) {
    const newRespBuildr = new ResponseBuilder();
    newRespBuildr.setResponseParams(responseObj);
    this._queries[name] = newRespBuildr;
  }

  /**
   * Get bulk query request string for defined queries.
   * Each key must match the defined query.
   * Value for each key will be an array of JSON objects which are the request params.
   * @param {JSON} obj the json with keys and array of request params as values
   */
  getBulkQueryAsString(obj = {}) {
    const qKeys = Object.keys(obj);
    let retStr = 'query { ';

    qKeys.forEach(eachQryKey => {
      const qryParams = obj[eachQryKey];

      qryParams.forEach((eachQryParams, idx) => {
        const qryKey = eachQryParams.key;
        delete eachQryParams.key;
        retStr += `${eachQryKey}${qryKey || idx + 1}: ${this.getQueryAsString(
          eachQryKey,
          eachQryParams,
          true
        )}`;
      });
    });

    return `${retStr} }`;
  }

  /**
   * Get a query as a query string that is recognised by graphql.
   * @param {String} name the key under which the query is saved
   * @param {JSON} queryParams the request params in JSON format
   */
  getQueryAsString(name, queryParams = {}, forBulk = false) {
    this._queries[name]._requestParamsToString(queryParams);
    const qry = this._queries[name];
    let retStr = '';

    if (forBulk) {
      retStr = `${name} ( ${
        qry._reqParams
      } ) ${qry.getResponseParamsAsString()}`;
    } else {
      retStr = `query { ${name} ( ${
        qry._reqParams
      } ) ${qry.getResponseParamsAsString()} }`;
    }

    return retStr;
  }

  /**
   * Enable the response params for a particular query.
   * Params which are not mentioned are disabled.
   * @param {String} name the key of the query for which to enable params
   * @param {JSON} obj the params that need to be enabled
   */
  enableResponseParams(name, obj) {
    if (!obj) {
      this._queries[name].enableAllResponseParams();
    } else {
      this._queries[name].enableParams(obj);
    }
  }

  /**
   * Disable the response params for a particular query.
   * Params which are not mentioned are enabled.
   * @param {String} name the key of the query for which to disable params
   * @param {JSON} obj the params that need to be disabled
   */
  disableResponseParams(name, obj) {
    this._queries[name].disableParams(obj);
  }

  /**
   * Remove a query by key
   * @param {String} name the key of query to remove
   */
  removeQuery(name) {
    delete this._query[name];
  }
}

export default QueryBuilder;
