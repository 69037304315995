import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import {
  FETCH_SCHEDULED_VISITS,
  BOOK_VISIT,
  FETCH_AVAILABLE_TIMES,
  FETCH_AVAILABLE_DATES,
  CLEAR_SCHEDULED_VISITS,
} from './actionTypes';

import { SKIP_CENTRE_VISIT, SKIP_TRAIL_VISIT } from '../models/visitModel';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';

const mQueryBuilder = new QueryBuilder();
const mMutationBuilder = new MutationBuilder();

mQueryBuilder.setNewQuery('getScheduledVisitsOfChild', {
  totalCount: 'number',
  data: {
    ID: 'number',
    visitFrom: 'datetime',
    visitTo: 'datetime',
    visitType: 'string',
    status: 'strng',
    fkEnquiry: 'number',
    fkCentre: 'number',
    fkUser: 'number',
    trialPeriodFee: 'string',
    comment: 'string',
    trialPeriodPaymentStatus: 'string',
    centre: {
      ID: 'number',
      label: 'string',
    },
  },
});

mQueryBuilder.setNewQuery('getAvailableDaysOfMonth', {
  date: 'string',
});

mQueryBuilder.setNewQuery('getVisitTimeSlot', {
  ID: 'number',
  start: 'datetime',
  end: 'datetime',
  from: 'time',
  to: 'time',
});

mMutationBuilder.setNewMutation('bookVisit', {
  ID: 'number',
  fkCentre: 'number',
  fkUser: 'number',
  fkEnquiry: 'number',
  fkVisitAvailability: 'number',
  visitFrom: 'datetime',
  visitTo: 'datetime',
  status: 'string',
  comment: 'string',
  completed: 'bool',
  centre: {
    ID: 'number',
    label: 'string',
  },
});

mMutationBuilder.setNewMutation('rejectScheduledVisit', {});
mMutationBuilder.setNewMutation('approveParentMeeting', {});
mMutationBuilder.setNewMutation('rescheduleVisit', {
  ID: 'number',
  fkCentre: 'number',
  visitFrom: 'timestamp',
  visitTo: 'timestamp',
  centre: {
    ID: 'number',
    label: 'string',
  },
});
mMutationBuilder.setNewMutation('cancelParentMeeting', {});

const dispatchAvailableDates = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_AVAILABLE_DATES,
    key: 'availableDateSlots',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchfetchScheduledVisits = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SCHEDULED_VISITS,
    key: 'scheduledVisits',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearScheduledVisits = (data = {}) => dispatch => {
  dispatch({
    type: CLEAR_SCHEDULED_VISITS,
    key: 'scheduledVisits',
    value: data,
  });
};

const dispatchBookVisit = (
  dispatch,
  inProgress = true,
  queryData = {},
  error = null
) => {
  dispatch({
    type: BOOK_VISIT,
    key: 'bookVisit',
    value: {
      inProgress,
      queryData,
      error,
    },
  });
};

const dispatchAvailableTimes = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_AVAILABLE_TIMES,
    key: 'availableEnquiryTimes',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const clearVisitModal = () => async dispatch => {
  dispatchBookVisit(dispatch);
};

export const bookNewVisitWithPrincipal = ({
  eqId,
  date,
  from,
  to,
}) => async dispatch => {
  dispatchBookVisit(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString('bookVisit', {
          IDEnquiry: eqId,
          date,
          from,
          to,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchBookVisit(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchBookVisit(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchBookVisit(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const getTimeSlots = (
  visitDate,
  eqId,
  visitType = null
) => async dispatch => {
  dispatchAvailableTimes(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: mQueryBuilder.getQueryAsString('getVisitTimeSlot', {
          date: visitDate,
          IDEnquiry: eqId,
          visitType,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchAvailableTimes(dispatch, false, data.data);
    } else {
      dispatchAvailableTimes(dispatch, false, null, data);
    }

    return data;
  } catch (ex) {
    dispatchAvailableTimes(dispatch, false, null, { error: ex.message });
  }
};

export const getVisitSlots = (eqId, visitType = null) => async dispatch => {
  dispatchAvailableDates(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: mQueryBuilder.getQueryAsString('getAvailableDaysOfMonth', {
          IDEnquiry: eqId,
          visitType,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchAvailableDates(dispatch, false, data.data);
    } else {
      dispatchAvailableDates(dispatch, false, null, data);
    }

    return data;
  } catch (ex) {
    dispatchAvailableDates(dispatch, false, null, { error: ex.message });
  }
};

export const fetchScheduledVisits = reqData => async dispatch => {
  dispatchfetchScheduledVisits(dispatch);
  const query = mQueryBuilder.getQueryAsString(
    'getScheduledVisitsOfChild',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchfetchScheduledVisits(dispatch, false, data.data);
    } else {
      dispatchfetchScheduledVisits(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchfetchScheduledVisits(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const cancelParentMeeting = reqData => async () => {
  const query = mMutationBuilder.getMutationAsString(
    'cancelParentMeeting',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const rescheduleVisit = reqData => async dispatch => {
  dispatchBookVisit(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString('rescheduleVisit', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchBookVisit(dispatch, false, { data: data.data });
    } else {
      dispatchBookVisit(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchBookVisit(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const rejectScheduledVisit = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString(
          'rejectScheduledVisit',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveScheduledVisit = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationBuilder.getMutationAsString(
          'approveParentMeeting',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const getSkipCentreVisit = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SKIP_CENTRE_VISIT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getSkipTrailVisit = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SKIP_TRAIL_VISIT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export default rejectScheduledVisit;
