import React from 'react';
import Grid from '@material-ui/core/Grid';
import CountrySelector from '../CountryCodeSelector';
import { StyledInput } from './style';
import FieldWrapper from './FieldWrapper';
import Text from '../Text';
import { onKeyPress } from '../../../utils';

export const PhoneField = ({
  label,
  name,
  value,
  disabled,
  onChange,
  error,
  autoFocus,
  onCodeChange,
  required,
  labelStyle = { marginTop: '0px', paddingBottom: '0px' },
  defaultCountryCode,
  countryCodeDisabled = false,
  onBlur,
  placeholder,
  labelTextColor,
  wrapperStyle,
  inputStyle = {},
  alternateView = false,
  hideIndicatorSeparator = false,
}) => {
  return (
    <FieldWrapper
      required={required}
      label={label}
      name={name}
      styles={labelStyle}
      disabled={disabled}
      labelTextColor={labelTextColor}
      wrapperStyle={wrapperStyle}
    >
      <Grid
        container
        spacing={alternateView ? 2 : 0}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item xs={5} md={alternateView ? 3 : 5}>
          <CountrySelector
            hideIndicatorSeparator={hideIndicatorSeparator}
            defaultValue={defaultCountryCode}
            isDisabled={countryCodeDisabled || disabled}
            handleSelectChange={onCodeChange}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={6} md={alternateView ? 9 : 6}>
          <StyledInput
            id={name}
            name={name}
            value={value}
            autoFocus={!disabled && Boolean(autoFocus)}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            onKeyPress={e => onKeyPress(e, 'phone')}
            inputStyle={{ padding: '9px 0px', ...inputStyle }}
          />
          <Text label color="error">
            {error}
          </Text>
        </Grid>
      </Grid>
    </FieldWrapper>
  );
};

export default PhoneField;
