import React from 'react';
import { Trans } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';

const ForgotPassword = ({ forgot, disableForgot }) => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <Button
        type="button"
        color="primary"
        size="medium"
        className="s7t-text-capitialise"
        onClick={forgot}
        disabled={disableForgot}
      >
        <Trans i18nKey="auth.forgotPassword" />
      </Button>
    </Grid>
  </Grid>
);

export default ForgotPassword;
