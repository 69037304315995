import { get } from 'lodash';
import {
  QR_CODE_CICO_TOKEN,
  FETCH_ALL_STAFF_DASHBOARD_REQUEST,
} from '../actions/actionTypes';

export const dashboardPendingRequest = (state = {}, action) => {
  if (action.type === FETCH_ALL_STAFF_DASHBOARD_REQUEST) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const cicoQrCode = (state = {}, action) => {
  if (action.type === QR_CODE_CICO_TOKEN) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }

  return state;
};

export default dashboardPendingRequest;
