import gql from 'graphql-tag';

export const FIND_ALL_BUSINESS_ENTITIES_GQL = gql`
  query findAllBusinessEntity(
    $filter: BusinessEntityFilter
    $pagination: Pagination
  ) {
    findAllBusinessEntity(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        name
      }
    }
  }
`;

export default FIND_ALL_BUSINESS_ENTITIES_GQL;
