import { SHOW_ASSETS, HIDE_ASSETS } from '../actions/actionTypes';

const assetsAction = (state = {}, action) => {
  switch (action.type) {
    case SHOW_ASSETS:
      return Object.assign({}, state, { asset: action.asset });

    case HIDE_ASSETS:
      return Object.assign({}, state, { asset: null });
    default:
      return state;
  }
};
export default assetsAction;
