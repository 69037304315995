import gql from 'graphql-tag';

export const CAPACITY_DRAFTS_BY_FK_SCHOOL = gql`
  query capacityDraftsByFkSchool(
    $IDSchool: Int!
    $filter: CapacityDraftFilter
    $pagination: Pagination
  ) {
    capacityDraftsByFkSchool(
      IDSchool: $IDSchool
      filter: $filter
      pagination: $pagination
    ) {
      data {
        ID
        fkSchool
        fkCentre
        status
        year
        updatedAt
        userByFkApprover {
          firstname
          lastname
        }
        userByFkUser {
          firstname
          lastname
        }
        capacityDraftItems {
          data {
            fkLevel
            capacity
            date
            level {
              label
            }
          }
        }
        capacityDraftActivityLogs {
          data {
            ID
            remark
            status
            createdAt
          }
        }
      }
    }
  }
`;

export const CANCEL_CAPACITY_CHANGE_REQUEST = gql`
  mutation cancelCapacityChangeRequest($DraftID: Int!, $Remarks: String!) {
    cancelCapacityChangeRequest(DraftID: $DraftID, Remarks: $Remarks)
  }
`;

export const SUBMIT_VACANCY_PLANNING = gql`
  mutation submitVacancyPlanning(
    $CapacityDraft: CapacityDraftUpdate!
    $remarks: String!
    $CapacityDraftItems: [CapacityDraftItemUpdate!]!
  ) {
    submitVacancyPlanning(
      CapacityDraft: $CapacityDraft
      remarks: $remarks
      CapacityDraftItems: $CapacityDraftItems
    )
  }
`;

export const APPROVE_CAPACITY_CHANGE_REQUEST = gql`
  mutation approveCapacityChangeRequest($DraftID: Int!, $Remarks: String) {
    approveCapacityChangeRequest(DraftID: $DraftID, Remarks: $Remarks)
  }
`;

export const REJECT_CAPACITY_CHANGE_REQUEST = gql`
  mutation rejectCapacityChangeRequest($DraftID: Int!, $Remarks: String!) {
    rejectCapacityChangeRequest(DraftID: $DraftID, Remarks: $Remarks)
  }
`;
