import { get } from 'lodash';

import { GET_REG_ADDITIONAL_INFORMATION } from '../actions/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const regAdditionalInfoDetails = (state = {}, action) => {
  if (action.type === GET_REG_ADDITIONAL_INFORMATION) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};
