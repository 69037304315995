import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withTranslation } from 'react-i18next';
import MaskedTimeInput from './style';
import FieldWrapper from '../InputFields/FieldWrapper';

const MaskedTimeInputComponent = ({
  margin,
  placeholder,
  mask,
  required,
  label,
  name,
  error,
  labelStyle,
  selectedTime,
  handleTimeChange,
  inputVariant = 'outlined',
  invalidFormatErrorNotNeeded,
  fullWidth = false,
  onBlur,
  t,
  format,
  disabled = false,
}) => {
  const invalidTimeErrorText = invalidFormatErrorNotNeeded
    ? ''
    : t('error.invalidTimeFormatError');
  const handleChange = date => {
    handleTimeChange({ target: { name, value: date } });
  };
  return (
    <FieldWrapper
      required={required}
      label={label}
      name={name}
      error={error}
      styles={labelStyle}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MaskedTimeInput
          margin={margin}
          placeholder={placeholder}
          mask={mask}
          name={name}
          value={selectedTime}
          onChange={handleChange}
          open={false}
          keyboardIcon
          inputVariant={inputVariant}
          invalidDateMessage={invalidTimeErrorText}
          fullWidth={fullWidth}
          onBlur={onBlur}
          format={format}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </FieldWrapper>
  );
};

export default withTranslation()(MaskedTimeInputComponent);
