import styled from 'styled-components';
import { KeyboardTimePicker } from '@material-ui/pickers';

const MaskedTimeInput = styled(KeyboardTimePicker)`
  & .MuiOutlinedInput-input {
    padding: 10px 14px;
  }

  & .MuiIconButton-root {
    pointer-events: none;
  }

  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 0;
  }

  & .MuiInputAdornment-positionEnd {
    margin-left: 0px;
    width: 0px;
  }
`;

export default MaskedTimeInput;
