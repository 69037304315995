import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import moment from 'moment';
import get from 'lodash/get';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';
import {
  CREATE_SYSTEM_ANNOUNCEMENT,
  SETTINGS,
  SYSTEM_ANNOUNCEMENT,
} from 'routes/constants';
import {
  showSnackBarMessage,
  hideSnackBarMessage,
  addNewSystemAnnouncement,
  getAllSystemAnnouncements,
  updateSystemAnnouncement,
} from '../../../../../../redux/actions';
import Page from '../../../../../common/Page';
import Text from '../../../../../common/Text';

import SystemAnnouncementForm from './SystemAnnouncementForm';

const createNewSystemAnnouncement = (
  values,
  addNewSystemAnnouncement,
  updateSystemAnnouncement,
  schoolID,
  isEdit,
  itemId,
  showSnackBarMessage,
  setSubmitting
) => {
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
  const parsedFrom = moment(values.from).format('YYYY-MM-DD HH:mm:ss');
  const parsedStartTime = moment(values.startTime).format('HH:mm:ss');
  const from = `${parsedFrom.substring(0, 10)} ${parsedStartTime}`;

  const parsedTo = moment(values.to).format('YYYY-MM-DD HH:mm:ss');
  const parsedEndTime = moment(values.endTime).format('HH:mm:ss');
  const to = `${parsedTo.substring(0, 10)} ${parsedEndTime}`;

  if (currentTime > from) {
    showSnackBarMessage("'From' must be in the future.", 'error');
    setSubmitting(false);
    return;
  }
  if (from > to) {
    showSnackBarMessage("'From' date must be less than 'To' date", 'error');
    setSubmitting(false);
    return;
  }

  const SystemAnnouncementItemDetails = {
    announcement: {
      title: values.title,
      message: values.message,
      from,
      to,
      fkSchool: schoolID,
    },
  };
  if (isEdit) {
    SystemAnnouncementItemDetails.ID = itemId;
    return updateSystemAnnouncement(SystemAnnouncementItemDetails);
  }
  return addNewSystemAnnouncement(SystemAnnouncementItemDetails);
};

const CreateSystemAnnouncement = ({
  t,
  addNewSystemAnnouncement,
  updateSystemAnnouncement,
  history,
  fkSchool,
  showSnackBarMessage,
  hideSnackBarMessage,
  allSystemAnnouncements,
  match,
  getAllSystemAnnouncements,
}) => {
  const [submitError, setSubmitError] = useState(null);
  const isEdit = get(history, 'location.state.isEdit', false);
  const itemId = parseInt(get(match, 'params.id'), 10);

  const crumbValues = [
    {
      label: t('common.settingsLabel'),
      link: SETTINGS,
    },
    {
      label: t('systemAnnouncement.systemAnnouncementName'),
      link: SYSTEM_ANNOUNCEMENT,
    },
    {
      label: isEdit
        ? t('systemAnnouncement.editSysAnnouncement')
        : t('systemAnnouncement.createSysAnnouncement'),
      link: CREATE_SYSTEM_ANNOUNCEMENT,
    },
  ];

  useEffect(() => {
    getAllSystemAnnouncements();
  }, []);

  const requiredErrorMsg = t('common.requiredError');
  const initialData = () => {
    if (get(allSystemAnnouncements, 'data.length')) {
      const announcementData = get(allSystemAnnouncements, 'data');
      const announcementItem = announcementData.find(
        item => item.ID === itemId
      );

      return {
        title: get(announcementItem, 'title'),
        message: get(announcementItem, 'message'),
        from: get(announcementItem, 'from'),
        startTime: get(announcementItem, 'from'),
        to: get(announcementItem, 'to'),
        endTime: get(announcementItem, 'to'),
      };
    }
    return {
      title: '',
      message: '',
      from: '',
      startTime: '',
      to: '',
      endTime: '',
    };
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData(),
    validationSchema: Yup.object().shape({
      title: Yup.string().required(requiredErrorMsg),
      message: Yup.string().required(requiredErrorMsg),
      from: Yup.mixed().required(requiredErrorMsg),
      startTime: Yup.mixed().required(requiredErrorMsg),
      to: Yup.mixed().required(requiredErrorMsg),
      endTime: Yup.mixed().required(requiredErrorMsg),
    }),
    onSubmit: (values, { setSubmitting }) => {
      createNewSystemAnnouncement(
        values,
        addNewSystemAnnouncement,
        updateSystemAnnouncement,
        fkSchool,
        isEdit,
        itemId,
        showSnackBarMessage,
        setSubmitting
      ).then(response => {
        if (response.success) {
          history.goBack();
          showSnackBarMessage(
            isEdit
              ? t('systemAnnouncement.updateSuccess')
              : t('systemAnnouncement.creationSuccess')
          );
        } else {
          showSnackBarMessage(get(response, 'error[0].message'), 'error');
          setSubmitError(get(response, 'error[0].message'));
          setSubmitting(false);
        }
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <Page
      title={t('systemAnnouncement.systemAnnouncementName')}
      breadcrumbList={crumbValues}
    >
      <Text h2>
        <Trans i18nKey="systemAnnouncement.createSysAnnouncement" />
      </Text>
      <form onSubmit={handleSubmit}>
        <SystemAnnouncementForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          submitError={submitError}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          hideSnackBarMessage={hideSnackBarMessage}
          isEdit={isEdit}
        />
      </form>
    </Page>
  );
};

const mapStateToProps = state => ({
  allSystemAnnouncements: get(state, 'allSystemAnnouncements.data', []),
});

const mapDispatchToProps = {
  showSnackBarMessage,
  hideSnackBarMessage,
  addNewSystemAnnouncement,
  getAllSystemAnnouncements,
  updateSystemAnnouncement,
};

export default compose(withTranslation())(
  connect(mapStateToProps, mapDispatchToProps)(CreateSystemAnnouncement)
);
