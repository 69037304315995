import ReactGA from 'react-ga';
import { get } from 'lodash';

export const initializeTracking = async () => {
  try {
    const trackingID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (!trackingID) {
      throw Error('No Tracking ID Found');
    }
    ReactGA.initialize(trackingID, {
      debug: get(process, 'env.APP_ENV', 'development') !== 'production',
      titleCase: false,
    });
  } catch (ex) {
    console.error(ex.message);
  }
};

export const trackPageView = async path => {
  await ReactGA.pageview(path);
};

export const trackEvents = async (
  appModule,
  action = 'click',
  label,
  extraOptions = {}
) => {
  await ReactGA.event({
    category: appModule,
    action,
    label,
    ...extraOptions,
  });
};
