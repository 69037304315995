import { parseGraphQLError } from 'utils';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  ADD_NEW_SYSTEM_ANNOUNCEMENT,
  GET_ALL_SYSTEM_ANNOUNCEMENTS,
  UPDATE_SYSTEM_ANNOUNCEMENT,
  DELETE_SYSTEM_ANNOUNCEMENT,
  GET_CURRENT_SYSTEM_ANNOUNCEMENT,
} from '../models/systemAnnouncementModel';
import {
  FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
} from './actionTypes';

const systemAnnouncementsLoading = () => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: true,
  },
});

const systemAnnouncementsSuccess = data => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: false,
    data,
  },
});

const systemAnnouncementsError = error => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});
const currentSystemAnnouncementLoading = () => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: true,
  },
});

const currentSystemAnnouncementSuccess = data => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: false,
    data,
  },
});

const currentSystemAnnouncementError = error => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const addNewSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_NEW_SYSTEM_ANNOUNCEMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_SYSTEM_ANNOUNCEMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const deleteSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_SYSTEM_ANNOUNCEMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getAllSystemAnnouncements = reqData => async dispatch => {
  dispatch(systemAnnouncementsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_SYSTEM_ANNOUNCEMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(systemAnnouncementsSuccess(data.data));
    } else {
      dispatch(systemAnnouncementsError(data.error));
    }
  } catch (ex) {
    dispatch(systemAnnouncementsError(ex.ex.message));
  }
};

export const getCurrentSystemAnnouncement = () => async dispatch => {
  dispatch(currentSystemAnnouncementLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CURRENT_SYSTEM_ANNOUNCEMENT,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(currentSystemAnnouncementSuccess(data.data));
    } else {
      dispatch(currentSystemAnnouncementError(data.error));
    }
  } catch (ex) {
    dispatch(currentSystemAnnouncementError(ex.ex.message));
  }
};
