import { SkApolloRequest } from '../../../utils/apolloUtils';
import { GET_REG_ADDITIONAL_INFORMATION } from '../actionTypes';
import {
  GET_REG_ADDITIONAL_INFO,
  SET_REG_ADDITIONAL_INFO,
  FETCH_APPLICANT_CHANGES,
} from '../../models/Registration/aditionalInfomodels';
import { parseGraphQLError } from '../../../utils';

const dispatchGetRegAdditionalInfo = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_REG_ADDITIONAL_INFORMATION,
    key: 'regAdditionalInfo',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchRegAdditionalInfo = regID => async dispatch => {
  dispatchGetRegAdditionalInfo(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_REG_ADDITIONAL_INFO,
        variables: {
          registrationID: regID,
        },
      },
      type: 'query',
    });

    if (result.success) {
      dispatchGetRegAdditionalInfo(dispatch, false, result);
    } else {
      dispatchGetRegAdditionalInfo(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchGetRegAdditionalInfo(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const saveRegAdditionalInfo = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: SET_REG_ADDITIONAL_INFO,
        variables: reqData,
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return ex;
  }
};

export const getApplicantChanges = IDRegChild => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: FETCH_APPLICANT_CHANGES,
        variables: { IDRegChild },
      },
      type: 'query',
    });

    return result;
  } catch (ex) {
    return ex;
  }
};
