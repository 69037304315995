import gql from 'graphql-tag';

export const LIST_BULK_FEE_UPLOADS = gql`
  query listBulkFeeUploadRequest(
    $filter: FeeBulkUploadFilter
    $pagination: Pagination
  ) {
    listBulkFeeUploadRequest(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        status
        filename
        pdfURLKey
        errorLog
        effectiveDate
        createdAt
        updatedAt
        user {
          ID
          firstname
          lastname
        }
      }
    }
  }
`;

export const GET_BULK_FEE_UPLOAD_URL = gql`
  mutation getBulkFeeUploadURL($file: Upload!) {
    getBulkFeeUploadURL(file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const GET_BULK_FEE_UPLOAD_TEMPLATE = gql`
  query getBulkFeeUploadTemplate($centreIds: [Int]) {
    getBulkFeeUploadTemplate(centreIds: $centreIds)
  }
`;

export const UPLOAD_BULK_FEE_FILE = gql`
  mutation uploadBulkFeeFile(
    $IDSchool: Int!
    $fileUrl: String!
    $fileName: String!
    $effectiveDate: Datetime!
  ) {
    uploadBulkFeeFile(
      IDSchool: $IDSchool
      fileUrl: $fileUrl
      fileName: $fileName
      effectiveDate: $effectiveDate
    ) {
      ID
    }
  }
`;

export default LIST_BULK_FEE_UPLOADS;
