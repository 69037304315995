import React, { Component } from 'react';
import { isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Box,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StatusNoteTooltip from '../views/PortfoliosManagement/Portfolios/StatusNoteTooltip';
import {
  textNewline,
  getDocumentWidth,
  getPrimaryColor,
  getSecondaryColor,
} from '../../utils';
import SortHead from './SortHead';
import Loader from './Loader';

const styles = {
  head: {
    fontSize: '16px',
  },
  body: {
    fontSize: '14px',
  },
};

class CommonTable extends Component {
  constructor(args) {
    super(args);

    this.state = {
      activeRow: null,
    };

    this.mouseEntered = this.mouseEntered.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.mouseLeft = this.mouseLeft.bind(this);
  }

  mouseEntered(activeRow) {
    const { enableHover } = this.props;

    if (enableHover) {
      this.setState({ activeRow });
    }
  }

  mouseLeft() {
    const { enableHover } = this.props;

    if (enableHover) {
      this.setState({ activeRow: null });
    }
  }

  rowClicked(rowData, rowIdx) {
    const { onRowClick, rowLinks } = this.props;
    if (!rowLinks) {
      onRowClick(rowData, rowIdx);
    }
  }

  render() {
    const {
      headCellProps,
      bodyCellProps,
      enableHover,
      bodyRowCls,
      bodyRowCellCls,
      headRowCls,
      tableData = [],
      tableCls,
      labels,
      noContentText,
      handleSort,
      classes,
      headerPadding,
      noWrap,
      rowLinks,
    } = this.props;
    const { activeRow } = this.state;

    let tableContent = (
      <TableRow
        key="loader"
        align="center"
        className="w3-center s7t-waitlistCard-tableRow"
      >
        <TableCell align="center" colSpan={labels.length}>
          <Loader />
        </TableCell>
      </TableRow>
    );
    const borderColor = getPrimaryColor(this.props);
    if (get(tableData, 'length') > 0) {
      tableContent = (tableData || []).map((eachRow, rowIdx) => {
        const rowInfo = (eachRow || []).find(
          (row, idx) => idx === get(row, 'index')
        );
        return (
          <>
            <TableRow
              key={`common-table-row-${rowIdx}}`}
              className={bodyRowCls}
              style={{ cursor: enableHover ? 'pointer' : 'auto' }}
              onMouseEnter={() => {
                this.mouseEntered(rowIdx);
              }}
              onMouseLeave={() => {
                this.mouseLeft();
              }}
              onClick={() => {
                this.rowClicked(eachRow, rowIdx);
              }}
              component={rowLinks ? Link : undefined}
              to={get(rowLinks, rowIdx, '')}
              hover={false}
            >
              {eachRow.map((eachCell, cellIdx) => {
                if (cellIdx + 1 > labels.length) {
                  return;
                }

                let cellStyle = {};

                if (enableHover && activeRow === rowIdx) {
                  if (cellIdx === 0) {
                    cellStyle.borderLeft = `2px solid ${borderColor}`;
                    cellStyle.borderBottom = cellStyle.borderLeft;
                    cellStyle.borderTop = cellStyle.borderLeft;
                  } else if (
                    (eachRow.length === labels.length &&
                      cellIdx === eachRow.length - 1) ||
                    (eachRow.length > labels.length &&
                      cellIdx === eachRow.length - 2)
                  ) {
                    cellStyle.borderRight = `2px solid ${borderColor}`;
                    cellStyle.borderBottom = cellStyle.borderRight;
                    cellStyle.borderTop = cellStyle.borderRight;
                  } else {
                    cellStyle.borderBottom = `2px solid ${borderColor}`;
                    cellStyle.borderTop = cellStyle.borderBottom;
                  }
                }
                if (bodyCellProps && bodyCellProps.style) {
                  cellStyle = Object.assign(cellStyle, bodyCellProps.style);
                  delete bodyCellProps.style;
                }

                let cellData = eachCell;
                let cellLineLimit = 25;

                const windowWidth = getDocumentWidth();
                if (windowWidth > 1300) {
                  cellLineLimit = 35;
                }

                if (windowWidth > 1500) {
                  cellLineLimit = 50;
                }

                if (
                  typeof eachCell === 'string' &&
                  eachCell.length > cellLineLimit
                ) {
                  cellData = textNewline(eachCell, cellLineLimit);
                }
                if (
                  typeof eachCell === 'object' &&
                  get(eachCell, 'index', '') === cellIdx
                ) {
                  cellData = eachCell.cellData;
                }
                return (
                  <TableCell
                    classes={{
                      body: classes.body,
                    }}
                    className={`${bodyRowCellCls} s7t-font-14 ${
                      noWrap ? 's7t-text-no-wrap' : ''
                    }`}
                    key={`eachCell-${rowIdx}-${cellIdx}`}
                    style={cellStyle}
                    {...bodyCellProps}
                  >
                    {cellData}
                  </TableCell>
                );
              })}
            </TableRow>
            {!isEmpty(rowInfo) && !isEmpty(rowInfo.cellInfo) && (
              <TableRow style={{ backgroundColor: '#F8F8F8' }}>
                <TableCell colSpan={12}>
                  <Grid container className="w3-padding">
                    {get(rowInfo, 'cellInfo', []).map(row => (
                      <Grid md={row.col} className={row.className}>
                        {row.text}
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </>
        );
      });
    } else if (tableData && tableData.length === 0) {
      tableContent = (
        <TableRow
          key="no-content"
          align="center"
          className="w3-center s7t-waitlistCard-tableRow"
        >
          <TableCell align="center" colSpan={labels.length}>
            <Typography variant="h5" className="w3-center s7t-data-font">
              {noContentText}
            </Typography>
          </TableCell>
        </TableRow>
      );
    } else {
      tableContent = (
        <TableRow
          key="no-content"
          align="center"
          className="w3-center s7t-waitlistCard-tableRow"
        >
          <TableCell align="center" colSpan={labels.length}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    return (
      <Paper elevation={0} className="s7t-overflow s7t-table-overflow">
        <Table className={`s7t-common-table ${tableCls}`}>
          <TableHead
            style={{
              background: getSecondaryColor(this.props),
            }}
          >
            <TableRow key="common-table-head" className={headRowCls}>
              {labels.map((eachLabel, idx) => (
                <TableCell
                  classes={{
                    head: classes.head,
                  }}
                  className={headerPadding}
                  key={`${eachLabel.title}-${idx}`}
                  {...headCellProps}
                  width={eachLabel.width || null}
                >
                  <Box display="flex" alignItems="center">
                    <SortHead
                      key={`${eachLabel.title}-${idx}`}
                      label={eachLabel.title}
                      active={eachLabel.active}
                      sortField={eachLabel.sortField}
                      handleSort={handleSort}
                      labelWrap={eachLabel.labelWrap}
                      labelWrapStyle={eachLabel.labelWrapStyle}
                    />
                    {get(eachLabel, 'showInfoTooltip', false) ? (
                      <StatusNoteTooltip
                        tooltip={get(eachLabel, 'infoTooltipText', '')}
                        iconColor={get(eachLabel, 'tooltipColor', '')}
                      />
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </Paper>
    );
  }
}

CommonTable.defaultProps = {
  headCellProps: {},
  noContentText: <Trans i18nKey="common.noContent" />,
  bodyCellProps: {},
  headRowCls: '',
  bodyRowCls: '',
  onRowClick: () => {},
  enableHover: false,
  noWrap: true,
  tableCls: '',
  tableData: [],
  rowLinks: undefined,
};

CommonTable.propTypes = {
  headCellProps: PropTypes.objectOf(PropTypes.string),
  bodyCellProps: PropTypes.shape({
    style: PropTypes.objectOf(PropTypes.string),
  }),
  headRowCls: PropTypes.string,
  bodyRowCls: PropTypes.string,
  tableCls: PropTypes.string,
  enableHover: PropTypes.bool,
  noWrap: PropTypes.bool,
  onRowClick: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.shape).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.array),
  noContentText: PropTypes.string,
  rowLinks: PropTypes.arrayOf(PropTypes.string),
};

export default withTheme(withStyles(styles)(CommonTable));
