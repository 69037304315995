import React from 'react';
import Text from '../Text';
import FieldWrapper from './FieldWrapper';

export const LabelField = ({
  required,
  label,
  value,
  labelStyle,
  valueStyle,
  bold,
  color = 'base',
  hint = null,
  boldLabel = false,
  placement,
  disabled,
  error,
  labelTextColor,
  wrapperPadding,
  fontSize,
  fieldWrapperProps = {},
}) => (
  <FieldWrapper
    required={required}
    label={label}
    styles={labelStyle}
    hint={hint}
    placement={placement}
    boldLabel={boldLabel}
    disabled={disabled}
    error={error}
    labelTextColor={labelTextColor}
    wrapperPadding={wrapperPadding}
    {...fieldWrapperProps}
  >
    <Text fontSize={fontSize} bold={bold} color={color} style={valueStyle}>
      {value}
    </Text>
  </FieldWrapper>
);

export default LabelField;
