import { get } from 'lodash';
import {
  FETCH_ALL_PURCHASE_ITEMS,
  FETCH_ALL_INVOICE_BY_FKCHILD,
  FETCH_CHILD_BALANCE,
  FETCH_CHILD_BALANCE_SHEET,
  FETCH_CHILD_BANK_ACCOUNTS,
  FETCH_ALL_BANKS,
  FETCH_ALL_INVOICES_FOR_ALL_CENTRES,
  FETCH_FINANCE,
  FETCH_ALL_REFUND,
  FETCH_ALL_UPLOADED_SUBSIDY_FILES,
  FETCH_ALL_DOWNLOADED_GIRO_FILES,
  FETCH_PAYMENT_TYPES,
  BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS,
  EMPTY_REASONS_GIRO,
  FETCH_ADVANCE_PAYMENT_RECEIPTS,
  ALL_CUSTOM_SUBSIDY_TYPE,
  ACTIVE_SUBSIDIES,
  SUBSIDY_DEBIT_AMOUNT,
  ALL_ABSENT_CHILD_VOID_SUBSIDY,
  FETCH_SEARCH_INVOICE,
  FETCH_CHILD_REFUNDS,
  FETCH_ENROLLED_CENTRES,
  FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT,
  FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION,
  FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS,
  FETCH_STATEMENT_OF_ACCOUNT_LOADING,
  FETCH_STATEMENT_OF_ACCOUNT_SUCCESS,
  FETCH_STATEMENT_OF_ACCOUNT_ERROR,
  FETCH_HISTORY_SUBSIDIES_LOADING,
  FETCH_HISTORY_SUBSIDIES_SUCCESS,
  FETCH_HISTORY_SUBSIDIES_ERROR,
  FETCH_ALL_BULK_INVOICES_LOADING,
  FETCH_ALL_BULK_INVOICES_SUCCESS,
  FETCH_ALL_BULK_INVOICES_ERROR,
  FETCH_DEBT_WRITE_OFF_LOADING,
  FETCH_DEBT_WRITE_OFF_SUCCESS,
  FETCH_DEBT_WRITE_OFF_ERROR,
  FETCH_ALL_PGM_BILLING_LOADING,
  FETCH_ALL_PGM_BILLING_SUCCESS,
  FETCH_ALL_PGM_BILLING_ERROR,
  FETCH_CREDIT_DEBIT_NOTE_LOADING,
  FETCH_CREDIT_DEBIT_NOTE_SUCCESS,
  FETCH_CREDIT_DEBIT_NOTE_ERROR,
  FETCH_ALL_REG_CHILD_BALANCE_LOADING,
  FETCH_ALL_REG_CHILD_BALANCE_SUCCESS,
  FETCH_ALL_REG_CHILD_BALANCE_ERROR,
  FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
  FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR,
  FETCH_ADJUST_INVOICE_AMT_LOADING,
  FETCH_ADJUST_INVOICE_AMT_SUCCESS,
  FETCH_ADJUST_INVOICE_AMT_ERROR,
  FETCH_ALL_INVOICES_BY_CHILD,
  FETCH_SUBSIDIES_LOADING,
  FETCH_SUBSIDIES_SUCCESS,
  FETCH_SUBSIDIES_ERROR,
  FETCH_TRADE_DEBT_NOTIFICATION_LOADING,
  FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS,
  FETCH_TRADE_DEBT_NOTIFICATION_ERROR,
  FETCH_TAX_RATE_AND_AMOUNT,
  CLEAR_TAX_RATE_AND_AMOUNT,
  FETCH_CHILD_SOA_BY_YEAR_LOADING,
  FETCH_CHILD_SOA_BY_YEAR_SUCCESS,
  FETCH_CHILD_SOA_BY_YEAR_ERROR,
  FETCH_CHILD_REFUND_STATUS_LOADING,
  FETCH_CHILD_REFUND_STATUS_SUCCESS,
  FETCH_CHILD_REFUND_STATUS_ERROR,
  ALL_CONSOLIDATED_REFUNDS,
  FETCH_ALL_VALID_DOWNLOADED_GIRO_FILES,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  FIND_ALL_UPLOADED_SUBSIDY_FILES,
  FIND_ALL_PURCHASE_ITEM,
  GET_CHILD_CURRENT_BALANCE,
  INVOICES_BY_FK_CHILD,
  BANK_ACCOUNTS_INFO_BY_CHILD_ID,
  FIND_ALL_BANK_BIC_CODE,
  SEARCH_FINANCE,
  FIND_ALL_INVOICE,
  FIND_ALL_CONSOLIDATED_REFUND,
  UPLOAD_SUBISDY_FILE,
  UPLOAD_GIRO_FILE,
  FIND_ALL_DOWNLOADED_GIRO_FILES,
  GENERATE_GIRO_REFUND_ARCHIVE,
  GENERATE_GIRO_DEDUCTION_ARCHIVE,
  EXPORT_GIRO_APPLICATION_PDF,
  SAVE_GIRO_APPLICATION,
  CREATE_OFFLINE_INVOICE,
  CREATE_RECEIPT_FOR_OFFLINE_PAYMENT,
  GET_ID_DOCUMENT_TYPE_CONFIG_UPLOAD_URL,
  GET_ID_DOCUMENT_TYPE_CONFIG_GIRO_URL,
  GET_FINANCE_DOCUMENT_UPLOAD_URL,
  CREATE_ADHOC_CREDIT_NOTE,
  SAVE_DOCUMENT,
  PAYMENT_TYPES,
  GET_BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS,
  UPDATE_GIRO_STATUS_WITH_REASON_MODEL,
  GET_ALL_CUSTOM_SUBSIDY_TYPE,
  GET_ACTIVE_SUBSIDIES,
  GET_SUBSIDIES,
  GET_HISTORY_SUBSIDIES,
  ADD_CUSTOM_SUBSIDY_TO_CHILD,
  VOID_CUSTOM_SUBSIDY,
  APPROVE_CUSTOM_SUBSIDY,
  REJECT_CUSTOM_SUBSIDY,
  CANCEL_CUSTOM_SUBSIDY,
  GET_SUBSIDY_DEBIT_AMOUNT,
  CREATE_ADVANCE_PAYMENT,
  GET_ADVANCE_PAYMENT_RECEIPTS,
  GET_ALL_ABSENT_CHILD_VOID_SUBSIDY,
  REMOVE_CUSTOM_SUBSIDY_BY_CHILD,
  SEARCH_INVOICE,
  EXEMPT_CUSTOM_SUBSIDY_BY_CHILD,
  FIND_CHILD_REFUNDS,
  GET_LIST_OF_ENROLLED_CENTRES,
  CREATE_ADHOC_CHILD_REFUND,
  CANCEL_RECEIPT,
  UNLINK_RECEIPT,
  CREATE_ADVANCE_PAYMENT_FOR_REGISTRATION,
  GET_ADVANCE_PAYMENT_ITEM_AMOUNT,
  GET_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION,
  GET_CHILD_FINANCIAL_ASSISTANCE_STATUS,
  SAVE_REG_GIRO_APPLICATION,
  UPDATE_BILL_REF_NO,
  GET_CHILD_STATEMENT_OF_ACCOUNT,
  GET_CHILD_SOA_BY_YEAR,
  SAVE_CANCEL_REG_GIRO_APPLICATION,
  EDIT_HISTORY_SUBSIDY,
  LIST_BULK_INVOICES,
  GET_BULK_INVOICE_TEMPLATE,
  CANCEL_BULK_INVOICE,
  VALIDATE_BULK_INVOICE,
  SAVE_BULK_INVOICE,
  UPLOAD_INVOICE_FILE,
  GET_DEBT_WRITE_OFF_REASONS,
  WRITE_OFF_INVOICES,
  GET_PROGRAM_BILLING_MASTER_FILE,
  GET_PROGRAM_BILLING_UPLOAD_TEMPLATE,
  UPLOAD_PROGRAM_BILLING_FILE,
  VALIDATE_PROGRAM_BILLING_REQUEST,
  SAVE_PROGRAM_BILLING_REQUEST,
  LIST_PROGRAM_BILLINGS,
  FIND_ALL_CREDIT_DEBIT_NOTES,
  LIST_REGISTRATION_CHILD_BALANCE_SHEET,
  FIND_ALL_REG_CHILD_REFUNDS,
  LIST_REGISTRATION_TOTAL_ADVANCE_PAYMENTS,
  TRANSFER_CHILD_BALANCE,
  TRANSFER_REG_CHILD_BALANCE,
  UPDATE_REFUND_STATUS,
  ADJUST_INVOICE_AMOUNT_ITEMS_PREVIEW,
  ADJUST_INVOICE_AMOUNT_ITEMS,
  SKIP_GIRO_REG,
  SKIP_PAYMENT_REG,
  CANCEL_ADVANCE_PAYMENT_RECEIPT,
  WRITE_OFF_CREDIT,
  UPDATE_CHILD_TRADE_DEBT_NOTIFY,
  TRADE_DEBT_NOTIFICATIONS,
  UPDATE_CHILD_FINANCIAL_ASSISTANCE,
  GET_CHILD_BALANCE_SHEET,
  FIND_TAX_RATE_AND_AMOUNT,
  MARK_CHECKED_REFUND,
  UNMARK_CHECKED_REFUND,
  CHECKED_REFUND_COUNTS,
  CANCEL_EXPORT_GIRO_REFUND,
  FIND_ALL_VALID_DOWNLOADED_GIRO_FILES,
} from '../models/financeModel';
import { SAVE_REGISTRATION_DOCUMENT } from '../models/documentModelsV2';

const dispatchFetchAllPurchaseItems = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_PURCHASE_ITEMS,
    key: 'allPurchaseItems',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetChildCurrentBalance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_BALANCE,
    key: 'childBalance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const statementOfAccountLoading = () => ({
  type: FETCH_STATEMENT_OF_ACCOUNT_LOADING,
  key: 'statementOfAccount',
  value: {
    inProgress: true,
  },
});

const statementOfAccountSuccess = data => ({
  type: FETCH_STATEMENT_OF_ACCOUNT_SUCCESS,
  key: 'statementOfAccount',
  value: {
    inProgress: false,
    data,
  },
});

const statementOfAccountError = error => ({
  type: FETCH_STATEMENT_OF_ACCOUNT_ERROR,
  key: 'statementOfAccount',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const childSOAByYearLoading = () => ({
  type: FETCH_CHILD_SOA_BY_YEAR_LOADING,
  key: 'childSoaByYear',
  value: {
    inProgress: true,
  },
});

const childSOAByYearSuccess = data => ({
  type: FETCH_CHILD_SOA_BY_YEAR_SUCCESS,
  key: 'childSoaByYear',
  value: {
    inProgress: false,
    data,
  },
});

const childSOAByYearError = error => ({
  type: FETCH_CHILD_SOA_BY_YEAR_ERROR,
  key: 'childSoaByYear',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allBulkInvoicesLoading = () => ({
  type: FETCH_ALL_BULK_INVOICES_LOADING,
  key: 'allBulkInvoices',
  value: {
    inProgress: true,
  },
});

const allBulkInvoicesSuccess = data => ({
  type: FETCH_ALL_BULK_INVOICES_SUCCESS,
  key: 'allBulkInvoices',
  value: {
    inProgress: false,
    data,
  },
});

const allBulkInvoicesError = error => ({
  type: FETCH_ALL_BULK_INVOICES_ERROR,
  key: 'allBulkInvoices',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allProgramBillingLoading = () => ({
  type: FETCH_ALL_PGM_BILLING_LOADING,
  key: 'allProgramBillings',
  value: {
    inProgress: true,
  },
});

const allProgramBillingSuccess = data => ({
  type: FETCH_ALL_PGM_BILLING_SUCCESS,
  key: 'allProgramBillings',
  value: {
    inProgress: false,
    data,
  },
});

const allProgramBillingError = error => ({
  type: FETCH_ALL_PGM_BILLING_ERROR,
  key: 'allProgramBillings',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const creditDebitNoteLoading = () => ({
  type: FETCH_CREDIT_DEBIT_NOTE_LOADING,
  key: 'allCreditDebitNotes',
  value: {
    inProgress: true,
  },
});

const creditDebitNoteSuccess = data => ({
  type: FETCH_CREDIT_DEBIT_NOTE_SUCCESS,
  key: 'allCreditDebitNotes',
  value: {
    inProgress: false,
    data,
  },
});

const creditDebitNoteError = error => ({
  type: FETCH_CREDIT_DEBIT_NOTE_ERROR,
  key: 'allCreditDebitNotes',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allRegChildBalanceLoading = () => ({
  type: FETCH_ALL_REG_CHILD_BALANCE_LOADING,
  key: 'allRegChildBalanceSheet',
  value: {
    inProgress: true,
  },
});

const allRegChildBalanceSuccess = data => ({
  type: FETCH_ALL_REG_CHILD_BALANCE_SUCCESS,
  key: 'allRegChildBalanceSheet',
  value: {
    inProgress: false,
    data,
  },
});

const allRegChildBalanceError = error => ({
  type: FETCH_ALL_REG_CHILD_BALANCE_ERROR,
  key: 'allRegChildBalanceSheet',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allRegTotalAdvancePaymentAmountLoading = () => ({
  type: FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING,
  key: 'allRegTotalAdvancePayments',
  value: {
    inProgress: true,
  },
});

const allRegTotalAdvancePaymentAmountSuccess = data => ({
  type: FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS,
  key: 'allRegTotalAdvancePayments',
  value: {
    inProgress: false,
    data,
  },
});

const allRegTotalAdvancePaymentAmountError = error => ({
  type: FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR,
  key: 'allRegTotalAdvancePayments',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allAdjustInvoiceAmountLoading = () => ({
  type: FETCH_ADJUST_INVOICE_AMT_LOADING,
  key: 'allAdjustInvoiceAmts',
  value: {
    inProgress: true,
  },
});

const allAdjustInvoiceAmountSuccess = data => ({
  type: FETCH_ADJUST_INVOICE_AMT_SUCCESS,
  key: 'allAdjustInvoiceAmts',
  value: {
    inProgress: false,
    data,
  },
});

const allAdjustInvoiceAmountError = error => ({
  type: FETCH_ADJUST_INVOICE_AMT_ERROR,
  key: 'allAdjustInvoiceAmts',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const tradeDebtNotificationsLoading = () => ({
  type: FETCH_TRADE_DEBT_NOTIFICATION_LOADING,
  key: 'tradeDebtNotifications',
  value: {
    inProgress: true,
  },
});

const tradeDebtNotificationsSuccess = data => ({
  type: FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS,
  key: 'tradeDebtNotifications',
  value: {
    inProgress: false,
    data,
  },
});

const tradeDebtNotificationsError = error => ({
  type: FETCH_TRADE_DEBT_NOTIFICATION_ERROR,
  key: 'tradeDebtNotifications',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const getCurrentChildRefundStatusLoading = () => ({
  type: FETCH_CHILD_REFUND_STATUS_LOADING,
  key: 'checkedRefunds',
  value: {
    inProgress: true,
  },
});

const getCurrentChildRefundStatusSuccess = data => ({
  type: FETCH_CHILD_REFUND_STATUS_SUCCESS,
  key: 'checkedRefunds',
  value: {
    inProgress: false,
    data,
  },
});

const getCurrentChildRefundStatusError = error => ({
  type: FETCH_CHILD_REFUND_STATUS_ERROR,
  key: 'checkedRefunds',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allRegChildRefundsLoading = () => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  key: 'allRegChildRefunds',
  value: {
    inProgress: true,
  },
});

const allRegChildRefundsSuccess = data => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
  key: 'allRegChildRefunds',
  value: {
    inProgress: false,
    data,
  },
});

const allRegChildRefundsError = error => ({
  type: FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  key: 'allRegChildRefunds',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const historyBillingAdjustmentLoading = () => ({
  type: FETCH_HISTORY_SUBSIDIES_LOADING,
  key: 'allHistorySubsidies',
  value: {
    inProgress: true,
  },
});

const historyBillingAdjustmentSuccess = data => ({
  type: FETCH_HISTORY_SUBSIDIES_SUCCESS,
  key: 'allHistorySubsidies',
  value: {
    inProgress: false,
    data,
  },
});

const historyBillingAdjustmentError = error => ({
  type: FETCH_HISTORY_SUBSIDIES_ERROR,
  key: 'allHistorySubsidies',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const childSubsidiesLoading = () => ({
  type: FETCH_SUBSIDIES_LOADING,
  key: 'allSubsidies',
  value: {
    inProgress: true,
  },
});

const childSubsidiesSuccess = data => ({
  type: FETCH_SUBSIDIES_SUCCESS,
  key: 'allSubsidies',
  value: {
    inProgress: false,
    data,
  },
});

const childSubsidiesError = error => ({
  type: FETCH_SUBSIDIES_ERROR,
  key: 'allSubsidies',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const dispatchGetChildCurrentBalanceSheet = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_BALANCE_SHEET,
    key: 'childBalanceInCentre',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchInvoiceByFkChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_INVOICE_BY_FKCHILD,
    key: 'allInvoiceByFkChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllFetchInvoiceByFkChild = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_INVOICES_BY_CHILD,
    key: 'allInvoicesByChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchChildBankAccounts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_BANK_ACCOUNTS,
    key: 'childBankAccounts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchFinance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FINANCE,
    key: 'invoiceSearchResult',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchInvoice = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SEARCH_INVOICE,
    key: 'invoiceSearchResult',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllBanks = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_BANKS,
    key: 'allBanks',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchInvoiceForAllCenter = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_INVOICES_FOR_ALL_CENTRES,
    key: 'allInvoicesForAllCenters',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllRefunds = (
  dispatch,
  inProgress = true,
  data = {},
  error = null,
  searchMode = false
) => {
  dispatch({
    type: FETCH_CHILD_REFUNDS,
    key: searchMode ? 'refundSearchResult' : 'allRefundData',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchEnrolledCentreList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENROLLED_CENTRES,
    key: 'allEnrolledCentres',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllConsolidatedRefund = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_REFUND,
    key: 'allInvoicesWithRefund',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllRefunds = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_CONSOLIDATED_REFUNDS,
    key: 'allConsolidatedRefunds',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllUploadedSubsidyFiles = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_UPLOADED_SUBSIDY_FILES,
    key: 'allUploadedSubsidyFiles',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetTaxRateAndAmount = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_TAX_RATE_AND_AMOUNT,
    key: 'taxRateAndAmountDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};
export const dispatchClearTaxDetails = (data = {}) => dispatch => {
  dispatch({
    type: CLEAR_TAX_RATE_AND_AMOUNT,
    key: 'taxRateAndAmountDetails',
    value: data,
  });
};

const dispatchAllDownloadedGiroFiles = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_DOWNLOADED_GIRO_FILES,
    key: 'allDownloadedGiroFiles',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllValidDownloadedGiroFiles = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_VALID_DOWNLOADED_GIRO_FILES,
    key: 'allValidDownloadedGiroFiles',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllPaymentTypes = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_PAYMENT_TYPES,
    key: 'allPaymentTypes',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchReasons = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS,
    key: 'allReasonsForChange',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSubsidyTypes = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_CUSTOM_SUBSIDY_TYPE,
    key: 'allCustomSubsidyTypes',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchActiveSubsidies = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ACTIVE_SUBSIDIES,
    key: 'allActiveSubsidies',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAdvancePaymentItemAmountForRegistration = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT,
    key: 'allAdvancePaymentItemAmount',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSubsidyDebitAmount = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SUBSIDY_DEBIT_AMOUNT,
    key: 'subsidyDebitAmount',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllAbsentChildVoidSubsidy = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_ABSENT_CHILD_VOID_SUBSIDY,
    key: 'absentChildVoidSubsidy',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchChildFinancialAssistanceStatus = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS,
    key: 'getChildFinancialAssistanceStatus',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAdvancePaymentReceipt = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADVANCE_PAYMENT_RECEIPTS,
    key: 'allAdvancePaymentReceipts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetAdvancePaymentReceiptForRegistration = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION,
    key: 'allAdvancePaymentReceiptsForRegistration',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchEmptyReason = () => ({
  type: EMPTY_REASONS_GIRO,
  value: {
    inProgress: false,
    data: [],
    error: null,
  },
});

export const fetchAllPurchaseItems = reqData => async dispatch => {
  dispatchFetchAllPurchaseItems(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_PURCHASE_ITEM,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchAllPurchaseItems(dispatch, false, data);
    } else {
      dispatchFetchAllPurchaseItems(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchAllPurchaseItems(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getChildCurrentBalance = reqData => async dispatch => {
  dispatchGetChildCurrentBalance(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_CURRENT_BALANCE,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchGetChildCurrentBalance(dispatch, false, data);
    } else {
      dispatchGetChildCurrentBalance(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchGetChildCurrentBalance(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getChildCurrentBalanceSheet = reqData => async dispatch => {
  dispatchGetChildCurrentBalanceSheet(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_BALANCE_SHEET,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchGetChildCurrentBalanceSheet(dispatch, false, data);
    } else {
      dispatchGetChildCurrentBalanceSheet(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchGetChildCurrentBalanceSheet(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchInvoiceByFkChild = reqData => async dispatch => {
  dispatchFetchInvoiceByFkChild(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: INVOICES_BY_FK_CHILD,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchInvoiceByFkChild(dispatch, false, data);
    } else {
      dispatchFetchInvoiceByFkChild(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchInvoiceByFkChild(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllInvoiceByFkChild = IDChild => async dispatch => {
  dispatchAllFetchInvoiceByFkChild(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: INVOICES_BY_FK_CHILD,
        variables: { IDChild },
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAllFetchInvoiceByFkChild(dispatch, false, data);
    } else {
      dispatchAllFetchInvoiceByFkChild(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllFetchInvoiceByFkChild(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const searchFinance = reqData => async dispatch => {
  dispatchSearchFinance(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: SEARCH_FINANCE,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchSearchFinance(dispatch, false, data);
    } else {
      dispatchSearchFinance(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchSearchFinance(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const searchInvoice = reqData => async dispatch => {
  dispatchSearchFinance(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: SEARCH_INVOICE,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchSearchInvoice(dispatch, false, data);
    } else {
      dispatchSearchInvoice(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchSearchInvoice(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const fetchInvoiceForAllCenter = reqData => async dispatch => {
  dispatchFetchInvoiceForAllCenter(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_INVOICE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchInvoiceForAllCenter(dispatch, false, data);
    } else {
      dispatchFetchInvoiceForAllCenter(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchInvoiceForAllCenter(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllRefundsList = reqData => async dispatch => {
  let searchMode = false;
  if (get(reqData, 'searchMode')) {
    searchMode = true;
  }
  dispatchFetchAllRefunds(dispatch, true, null, null, searchMode);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_CHILD_REFUNDS,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchAllRefunds(dispatch, false, data.data, null, searchMode);
    } else {
      dispatchFetchAllRefunds(dispatch, false, null, data.error, searchMode);
    }
  } catch (ex) {
    dispatchFetchAllRefunds(
      dispatch,
      false,
      null,
      {
        error: parseGraphQLError(ex),
      },
      searchMode
    );
  }
};

export const fetchAllEnrolledCentres = ({
  childID,
  includeUpComing = true,
}) => async dispatch => {
  dispatchFetchEnrolledCentreList(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_LIST_OF_ENROLLED_CENTRES,
        variables: {
          childID,
          includeUpComing,
        },
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchEnrolledCentreList(dispatch, false, data.data);
    } else {
      dispatchFetchEnrolledCentreList(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchEnrolledCentreList(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllConsolidatedRefund = reqData => async dispatch => {
  dispatchAllConsolidatedRefund(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CONSOLIDATED_REFUND,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAllConsolidatedRefund(dispatch, false, data);
    } else {
      dispatchAllConsolidatedRefund(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllConsolidatedRefund(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllConsoleRefunds = reqData => async dispatch => {
  dispatchAllRefunds(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CONSOLIDATED_REFUND,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAllRefunds(dispatch, false, data);
    } else {
      dispatchAllRefunds(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllRefunds(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllUploadedSubsidyFiles = reqData => async dispatch => {
  dispatchAllUploadedSubsidyFiles(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ALL_UPLOADED_SUBSIDY_FILES,
        variables: reqData,
      },
      type: 'query',
    });

    if (result.success) {
      dispatchAllUploadedSubsidyFiles(dispatch, false, result.data);
    } else {
      dispatchAllUploadedSubsidyFiles(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchAllUploadedSubsidyFiles(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const findAllDownloadedGiroFiles = (
  reqData,
  update = false
) => async dispatch => {
  if (!update) {
    dispatchAllDownloadedGiroFiles(dispatch);
  }
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ALL_DOWNLOADED_GIRO_FILES,
        variables: reqData,
      },
      type: 'query',
    });
    if (!update) {
      if (result.success) {
        dispatchAllDownloadedGiroFiles(dispatch, false, result.data);
      } else {
        dispatchAllDownloadedGiroFiles(dispatch, false, null, result.error);
      }
    }
    return result;
  } catch (ex) {
    dispatchAllDownloadedGiroFiles(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const findAllValidDownloadedGiroFiles = (
  reqData,
  update = false
) => async dispatch => {
  if (!update) {
    dispatchAllValidDownloadedGiroFiles(dispatch);
  }
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ALL_VALID_DOWNLOADED_GIRO_FILES,
        variables: reqData,
      },
      type: 'query',
    });
    if (!update) {
      if (result.success) {
        dispatchAllValidDownloadedGiroFiles(dispatch, false, result.data);
      } else {
        dispatchAllValidDownloadedGiroFiles(
          dispatch,
          false,
          null,
          result.error
        );
      }
    }
    return result;
  } catch (ex) {
    dispatchAllValidDownloadedGiroFiles(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getTaxRateAndAmount = reqData => async dispatch => {
  dispatchGetTaxRateAndAmount(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_TAX_RATE_AND_AMOUNT,
        variables: reqData,
      },
      type: 'query',
    });

    if (result.success) {
      dispatchGetTaxRateAndAmount(dispatch, false, result.data);
    } else {
      dispatchGetTaxRateAndAmount(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchGetTaxRateAndAmount(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getFinanceDocumentUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_FINANCE_DOCUMENT_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createAdhocChildRefund = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_ADHOC_CHILD_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const uploadSubsidyFile = (IDSchool, url, filename) => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPLOAD_SUBISDY_FILE,
        variables: { IDSchool, url, filename },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateBankAccountStatus = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPDATE_GIRO_STATUS_WITH_REASON_MODEL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const uploadGiroFile = (
  IDSchool,
  url,
  filename,
  docType,
  IDRequestFinanceDocument
) => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPLOAD_GIRO_FILE,
        variables: {
          IDSchool,
          url,
          filename,
          docType,
          IDRequestFinanceDocument,
        },
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getGiroRefundArchive = IDSchool => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: GENERATE_GIRO_REFUND_ARCHIVE,
        variables: {
          IDSchool,
        },
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getGiroDeductionArchive = IDSchool => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: GENERATE_GIRO_DEDUCTION_ARCHIVE,
        variables: {
          IDSchool,
        },
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const exportGiroApplicationPdf = ({
  bankAccountID,
  centreId,
}) => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: EXPORT_GIRO_APPLICATION_PDF,
        variables: {
          bankAccountID,
          centreId,
        },
      },
      type: 'mutation',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createOfflineInvoice = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_OFFLINE_INVOICE,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createOfflineReceipt = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_RECEIPT_FOR_OFFLINE_PAYMENT,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createAdHocCreditNote = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_ADHOC_CREDIT_NOTE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const cancelReceipt = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_RECEIPT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const unlinkReceipt = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UNLINK_RECEIPT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getChildBankAccounts = reqData => async dispatch => {
  dispatchFetchChildBankAccounts(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: BANK_ACCOUNTS_INFO_BY_CHILD_ID,
        variables: reqData,
      },
      type: 'query',
    });

    if (result.success) {
      dispatchFetchChildBankAccounts(dispatch, false, result.data);
    } else {
      dispatchFetchChildBankAccounts(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchChildBankAccounts(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getAllBanks = reqData => async dispatch => {
  dispatchFetchAllBanks(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ALL_BANK_BIC_CODE,
        variables: reqData,
      },
      type: 'query',
    });

    if (result.success) {
      dispatchFetchAllBanks(dispatch, false, result.data);
    } else {
      dispatchFetchAllBanks(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchFetchAllBanks(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const saveGiroApplication = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_GIRO_APPLICATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const saveRegGiroApplication = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_REG_GIRO_APPLICATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const saveCancelRegistrationGiroAccounts = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_CANCEL_REG_GIRO_APPLICATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getGiroDocumentUploadUrl = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_ID_DOCUMENT_TYPE_CONFIG_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getGiroFormUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_ID_DOCUMENT_TYPE_CONFIG_GIRO_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getGiroDocumentUploadUrls = reqData => async () => {
  try {
    const bulkMutationReqData = (reqData || []).map(item => ({
      queryKey: `documentUpload${item.type}`,
      key: 'getDocumentUploadURLByIDDocumentTypeConfig',
      query: '{ Url, Key, Filename }',
      variables: [
        {
          IDDocumentTypeConfig: item.IDDocumentTypeConfig,
          IDRegChild: item.IDRegChild,
          file: {
            filename: item.file.name,
            filepath: item.file.path,
            filesize: item.file.size,
            mimetype: item.file.type,
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

export const saveGiroOfflineDocument = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_DOCUMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const saveRegistrationGiroDocument = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_REGISTRATION_DOCUMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const fetchAllPaymentTypes = schoolID => async dispatch => {
  dispatchAllPaymentTypes(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: PAYMENT_TYPES,
        variables: schoolID,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchAllPaymentTypes(dispatch, false, result.data);
    } else {
      dispatchAllPaymentTypes(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchAllPaymentTypes(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getBankAccountStatusChangeReasonsByStatus = reqData => async dispatch => {
  dispatchReasons(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchReasons(dispatch, false, result.data);
    } else {
      dispatchReasons(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchReasons(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getAllCustomSubsidyType = childID => async dispatch => {
  dispatchSubsidyTypes(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ALL_CUSTOM_SUBSIDY_TYPE,
        variables: childID,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchSubsidyTypes(dispatch, false, result.data);
    } else {
      dispatchSubsidyTypes(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchSubsidyTypes(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getAllActiveSubsidies = reqData => async dispatch => {
  dispatchActiveSubsidies(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ACTIVE_SUBSIDIES,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchActiveSubsidies(dispatch, false, result.data);
    } else {
      dispatchActiveSubsidies(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchActiveSubsidies(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getHistoryBillingAdjustments = reqData => async dispatch => {
  dispatch(historyBillingAdjustmentLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_HISTORY_SUBSIDIES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(historyBillingAdjustmentSuccess(data.data));
    } else {
      dispatch(historyBillingAdjustmentError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(historyBillingAdjustmentError(ex.ex.message));
  }
};

export const getAllChildHistorySubsidies = reqData => async dispatch => {
  dispatch(childSubsidiesLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_SUBSIDIES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(childSubsidiesSuccess(data.data));
    } else {
      dispatch(childSubsidiesError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(childSubsidiesError(ex.ex.message));
  }
};

export const getAdvancePaymentItemAmountForRegistration = reqData => async dispatch => {
  dispatchAdvancePaymentItemAmountForRegistration(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ADVANCE_PAYMENT_ITEM_AMOUNT,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchAdvancePaymentItemAmountForRegistration(
        dispatch,
        false,
        result.data
      );
    } else {
      dispatchAdvancePaymentItemAmountForRegistration(
        dispatch,
        false,
        null,
        result.error
      );
    }
  } catch (ex) {
    dispatchAdvancePaymentItemAmountForRegistration(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const createCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_CUSTOM_SUBSIDY_TO_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createAdvancePaymentReceipt = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_ADVANCE_PAYMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createAdvancePaymentReceiptForRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_ADVANCE_PAYMENT_FOR_REGISTRATION,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const voidCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: VOID_CUSTOM_SUBSIDY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const approveCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: APPROVE_CUSTOM_SUBSIDY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const rejectCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REJECT_CUSTOM_SUBSIDY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const cancelCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_CUSTOM_SUBSIDY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getSubsidyDebitNoteAmount = reqData => async dispatch => {
  dispatchSubsidyDebitAmount(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_SUBSIDY_DEBIT_AMOUNT,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchSubsidyDebitAmount(dispatch, false, result.data);
    } else {
      dispatchSubsidyDebitAmount(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchSubsidyDebitAmount(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAdvancePaymentReceipts = reqData => async dispatch => {
  dispatchGetAdvancePaymentReceipt(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ADVANCE_PAYMENT_RECEIPTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchGetAdvancePaymentReceipt(dispatch, false, result.data);
    } else {
      dispatchGetAdvancePaymentReceipt(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchGetAdvancePaymentReceipt(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};
export const fetchAdvancePaymentReceiptsForRegistration = reqData => async dispatch => {
  dispatchGetAdvancePaymentReceiptForRegistration(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchGetAdvancePaymentReceiptForRegistration(
        dispatch,
        false,
        result.data
      );
    } else {
      dispatchGetAdvancePaymentReceiptForRegistration(
        dispatch,
        false,
        null,
        result.error
      );
    }
  } catch (ex) {
    dispatchGetAdvancePaymentReceiptForRegistration(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getAllAbsentChildVoidSubsidy = reqData => async dispatch => {
  dispatchAllAbsentChildVoidSubsidy(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_ALL_ABSENT_CHILD_VOID_SUBSIDY,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchAllAbsentChildVoidSubsidy(dispatch, false, result.data);
    } else {
      dispatchAllAbsentChildVoidSubsidy(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchAllAbsentChildVoidSubsidy(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getChildFinancialAssistanceStatus = reqData => async dispatch => {
  dispatchChildFinancialAssistanceStatus(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_CHILD_FINANCIAL_ASSISTANCE_STATUS,
        variables: reqData,
      },
      type: 'query',
    });
    if (result.success) {
      dispatchChildFinancialAssistanceStatus(dispatch, false, result.data);
    } else {
      dispatchChildFinancialAssistanceStatus(
        dispatch,
        false,
        null,
        result.error
      );
    }
  } catch (ex) {
    dispatchChildFinancialAssistanceStatus(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const updateChildFinancialAssistance = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CHILD_FINANCIAL_ASSISTANCE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const removeCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: REMOVE_CUSTOM_SUBSIDY_BY_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const exemptCustomSubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EXEMPT_CUSTOM_SUBSIDY_BY_CHILD,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateBankAccountBillRefNumber = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_BILL_REF_NO,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const editHistorySubsidy = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EDIT_HISTORY_SUBSIDY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getChildStatementOfAccounts = reqData => async dispatch => {
  dispatch(statementOfAccountLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_STATEMENT_OF_ACCOUNT,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(statementOfAccountSuccess(data.data));
    } else {
      dispatch(statementOfAccountError(data.error));
    }
  } catch (ex) {
    dispatch(statementOfAccountError(ex.ex.message));
  }
};

export const getChildSOAByYear = reqData => async dispatch => {
  dispatch(childSOAByYearLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_SOA_BY_YEAR,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(childSOAByYearSuccess(data.data));
    } else {
      dispatch(childSOAByYearError(data.error));
    }
  } catch (ex) {
    dispatch(childSOAByYearError(ex.ex.message));
  }
};

export const getAllBulkInvoices = reqData => async dispatch => {
  dispatch(allBulkInvoicesLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_BULK_INVOICES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allBulkInvoicesSuccess(data.data));
    } else {
      dispatch(allBulkInvoicesError(data.error));
    }
  } catch (ex) {
    dispatch(allBulkInvoicesError(ex.ex.message));
  }
};

export const getBulkInvoiceTemplate = () => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_BULK_INVOICE_TEMPLATE,
      },
      type: 'query',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const cancelBulkInvoiceRequest = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_BULK_INVOICE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const validateAndCreateBulkInvoiceRequest = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: VALIDATE_BULK_INVOICE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateBulkInvoiceScheduledTime = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_BULK_INVOICE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getBulkInvoiceUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPLOAD_INVOICE_FILE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

const debtWriteOffReasonsLoading = () => ({
  type: FETCH_DEBT_WRITE_OFF_LOADING,
  key: 'debtWriteOffReasons',
  value: {
    inProgress: true,
  },
});

const debtWriteOffReasonsSuccess = data => ({
  type: FETCH_DEBT_WRITE_OFF_SUCCESS,
  key: 'debtWriteOffReasons',
  value: {
    inProgress: false,
    data,
  },
});

const debtWriteOffReasonsError = error => ({
  type: FETCH_DEBT_WRITE_OFF_ERROR,
  key: 'debtWriteOffReasons',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const getDebtWriteOffReasons = schoolID => async dispatch => {
  dispatch(debtWriteOffReasonsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_DEBT_WRITE_OFF_REASONS,
        variables: schoolID,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(debtWriteOffReasonsSuccess(data.data));
    } else {
      dispatch(debtWriteOffReasonsError(data.error));
    }
  } catch (ex) {
    dispatch(debtWriteOffReasonsError(ex.ex.message));
  }
};

export const writeOffInvoices = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: WRITE_OFF_INVOICES,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const exportProgramBillingMasterFile = forDsp => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_PROGRAM_BILLING_MASTER_FILE,
        variables: forDsp,
      },
      type: 'query',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const exportProgramBillingUploadTemplate = () => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_PROGRAM_BILLING_UPLOAD_TEMPLATE,
        variables: { forDsp: false },
      },
      type: 'query',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getProgramBillingUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPLOAD_PROGRAM_BILLING_FILE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const validateProgramBillingRequest = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: VALIDATE_PROGRAM_BILLING_REQUEST,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const createProgramBillingRequest = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_PROGRAM_BILLING_REQUEST,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getDebtWriteOffDocUploadUrls = reqData => async () => {
  try {
    const bulkMutationReqData = (reqData || []).map((item, idx) => ({
      queryKey: `docUpload${idx + 1}`,
      key: 'getDebtWriteOffSupportingDocumentUploadURL',
      query: '{ Url, Key, Filename }',
      variables: [
        {
          file: {
            filename: item.file.name,
            filepath: item.file.path,
            filesize: item.file.size,
            mimetype: item.file.type,
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

export const getCreditWriteOffDocUploadURL = reqData => async () => {
  try {
    const bulkMutationReqData = (reqData || []).map((item, idx) => ({
      queryKey: `docUpload${idx + 1}`,
      key: 'getCreditWriteOffSupportingDocumentUploadURL',
      query: '{ Url, Key, Filename }',
      variables: [
        {
          file: {
            filename: item.file.name,
            filepath: item.file.path,
            filesize: item.file.size,
            mimetype: item.file.type,
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

export const getAllProgramBillings = reqData => async dispatch => {
  dispatch(allProgramBillingLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_PROGRAM_BILLINGS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allProgramBillingSuccess(data.data));
    } else {
      dispatch(allProgramBillingError(data.error));
    }
  } catch (ex) {
    dispatch(allProgramBillingError(ex.ex.message));
  }
};

export const fetchAllCreditDebitNotes = reqData => async dispatch => {
  dispatch(creditDebitNoteLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CREDIT_DEBIT_NOTES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(creditDebitNoteSuccess(data.data));
    } else {
      dispatch(creditDebitNoteError(data.error));
    }
  } catch (ex) {
    dispatch(creditDebitNoteError(ex.ex.message));
  }
};

export const getRegistrationChildBalanceSheet = reqData => async dispatch => {
  dispatch(allRegChildBalanceLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_REGISTRATION_CHILD_BALANCE_SHEET,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allRegChildBalanceSuccess(data.data));
    } else {
      dispatch(allRegChildBalanceError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(allRegChildBalanceError(ex.ex.message));
  }
};

export const getRegistrationChildTotalAdvancePaymentAmount = reqData => async dispatch => {
  dispatch(allRegTotalAdvancePaymentAmountLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_REGISTRATION_TOTAL_ADVANCE_PAYMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allRegTotalAdvancePaymentAmountSuccess(data.data));
    } else {
      dispatch(allRegTotalAdvancePaymentAmountError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(allRegTotalAdvancePaymentAmountError(ex.ex.message));
  }
};

export const getRegistrationRefundChildBalance = reqData => async dispatch => {
  dispatch(allRegChildRefundsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_REG_CHILD_REFUNDS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allRegChildRefundsSuccess(data.data));
    } else {
      dispatch(allRegChildRefundsError(data.error));
    }
  } catch (ex) {
    dispatch(allRegChildRefundsError(ex.ex.message));
  }
};

export const transferChildBalance = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: TRANSFER_CHILD_BALANCE,
        variables: { dto: reqData },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const transferRegistrationChildBalance = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: TRANSFER_REG_CHILD_BALANCE,
        variables: { dto: reqData },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateRefundStatus = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_REFUND_STATUS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const adjustInvoiceAmountByInvoiceItemsPreview = reqData => async dispatch => {
  dispatch(allAdjustInvoiceAmountLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: ADJUST_INVOICE_AMOUNT_ITEMS_PREVIEW,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(allAdjustInvoiceAmountSuccess(data.data));
    } else {
      dispatch(allAdjustInvoiceAmountError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(allAdjustInvoiceAmountError(ex.ex.message));
  }
};

export const adjustInvoiceAmountByInvoiceItems = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADJUST_INVOICE_AMOUNT_ITEMS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const skipGiroForRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SKIP_GIRO_REG,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const skipRegistrationPaymentStep = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SKIP_PAYMENT_REG,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const cancelAdvancePaymentReceipt = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_ADVANCE_PAYMENT_RECEIPT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const writeOffCreditBalance = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: WRITE_OFF_CREDIT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updateChildTradeDebtNotificationConfig = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CHILD_TRADE_DEBT_NOTIFY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getTradeDebtNotifications = reqData => async dispatch => {
  dispatch(tradeDebtNotificationsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: TRADE_DEBT_NOTIFICATIONS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(tradeDebtNotificationsSuccess(data.data));
    } else {
      dispatch(tradeDebtNotificationsError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(tradeDebtNotificationsError(ex.ex.message));
  }
};

export const markChildToGenerateGiroRefund = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: MARK_CHECKED_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const unmarkChildToGenerateGiroRefund = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UNMARK_CHECKED_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getCurrentChildRefundStatus = () => async dispatch => {
  dispatch(getCurrentChildRefundStatusLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: CHECKED_REFUND_COUNTS,
        // variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(getCurrentChildRefundStatusSuccess(data.data));
    } else {
      dispatch(getCurrentChildRefundStatusError(data.error));
    }
    return data;
  } catch (ex) {
    dispatch(getCurrentChildRefundStatusError(ex.ex.message));
  }
};

export const cancelExportGiroRefund = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_EXPORT_GIRO_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};
