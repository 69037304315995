import { get } from 'lodash';
import { MutationBuilder, QueryBuilder } from '../../queryBuilder';
import { DEFAULT_ERROR } from '../../../utils/constants';
import SkRequest from '../BaseRequest';
import { parseGraphQLError } from '../../../utils';
import {
  FETCH_AVAILABLE_BANKS,
  FETCH_PAYMENT_SESSION,
  FETCH_USER_ACCOUNT_LISTING,
  FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID,
  FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO,
  CLEAR_PAYMENT_SESSION,
  SET_GIRO_SETUP_STEP,
  CLEAR_GIRO_SETUP_STEP,
  FETCH_ACCOUNT_INFO_BY_REG,
  FETCH_ACCOUNT_INFO_BY_CHILD,
} from '../actionTypes';
import apolloUtils, { SkApolloRequest } from '../../../utils/apolloUtils';
import {
  FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
  FIND_ACCOUNT_INFO_BY_CHILD_ID,
  GET_ACCOUNT_LISTING,
  ADD_GIRO,
  EXPORT_GIRO_PDF,
  EMAIL_GIRO_PDF,
  GET_PAYNOW_UEN_BY_SCHOOL,
  PAY_REGISTRATION_INVOICE_BY_GIRO,
  GET_PAYMENT_ACCESS,
  INITIATE_REFUND,
  MARK_GIRO_AS_COMPLETED,
} from '../../models/Registration/paymentModels';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

const mPaymentSessionDetails = {
  Id: 'string',
  Version: 'string',
  UpdateStatus: 'string',
  SuccessIndicator: 'string',
  MerchantId: 'string',
  Order: {
    Id: 'string',
    Amount: 'number',
    Currency: 'string',
    Description: 'string',
  },
};

_mMutationBuilder.setNewMutation(
  'createPaymentSession',
  mPaymentSessionDetails
);

_mQueryBuilder.setNewQuery('getPaymentSession', mPaymentSessionDetails);

_mMutationBuilder.setNewMutation('updatePaymentSession', {});

_mMutationBuilder.setNewMutation('payRegistrationFeeByGiro', {
  Success: 'boolean',
  TransactionNo: 'string',
  ErrorMessage: 'string',
});
_mMutationBuilder.setNewMutation('markRegistrationPaymentAsSuccessful', {});

_mMutationBuilder.setNewMutation('payGiroByInvoiceID', {});

_mQueryBuilder.setNewQuery('findAllBankBicCode', {
  data: {
    ID: 'number',
    bankCode: 'string',
    bankName: 'string',
    bicCode: 'string',
    displayOrder: 'number',
    isCdaSupported: 'boolean',
  },
});

_mQueryBuilder.setNewQuery('getRegistrationInvoice', {
  ID: 'number',
  totalAmount: 'number',
  outstandingAmount: 'number',
  invoiceNo: 'string',
  invoiceDate: 'datetime',
  invoiceDueDate: 'datetime',
  status: 'string',
  invoiceType: 'string',
  invoiceItems: {
    data: {
      ID: 'number',
      label: 'string',
      totalAmount: 'number',
      displayTotalAmount: 'string',
      displayAdjustedAmount: 'string',
      billableItem: {
        sn1StrAccPacAccountCode: 'string',
      },
    },
  },
  grandTotal: 'number',
  updatedAt: 'datetime',
  receipts: {
    data: {
      receiptNo: 'string',
      paymentType: 'string',
      amount: 'number',
      amountReceived: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      documentNo: 'string',
      userByCollectedByFkStaff: {
        firstname: 'string',
        lastname: 'string',
      },
    },
  },
  sn1UpdatedBy: 'string',
  sn1VerifiedBy: 'string',
});

_mQueryBuilder.setNewQuery('getRegistrationDraftMonthlyFeeInvoice', {
  totalAmount: 'number',
  outstandingAmount: 'number',
  status: 'string',
  grandTotal: 'number',
  invoiceItems: {
    data: {
      label: 'string',
      totalAmount: 'number',
      displayTotalAmount: 'string',
      displayAdjustedAmount: 'string',
      billableItem: {
        type: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('bankAccountsByFkChild', {
  data: {
    ID: 'number',
    isCda: 'boolean',
    billReferenceNumber: 'string',
    payerAccountName: 'string',
    payerAccountNumber: 'string',
    payerBankBranch: 'string',
    status: 'string',
    active: 'bool',
    source: 'string',
    fkBankBicCode: 'string',
  },
});

_mQueryBuilder.setNewQuery('getTrialPeriodInvoice', {
  ID: 'number',
  invoiceNo: 'string',
  status: 'string',
  outstandingAmount: 'number',
  totalAmount: 'number',
});

_mQueryBuilder.setNewQuery('getPaymentAccess', {});

const dispatchFetchPaymentSession = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_PAYMENT_SESSION,
    key: 'paymentSession',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchClearPaymentSession = (data = {}) => dispatch => {
  dispatch({
    type: CLEAR_PAYMENT_SESSION,
    key: 'paymentSession',
    value: data,
  });
};

const dispatchAccountListing = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_USER_ACCOUNT_LISTING,
    key: 'accountListing',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByEnquiry = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_ENQUIRY,
    key: 'accountInfoByEnquiry',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByReg = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_REG,
    key: 'accountInfoByReg',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByEnquiryForGiro = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO,
    key: 'accountInfoByEnquiryForGiro',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAccountByChild = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_ACCOUNT_INFO_BY_CHILD,
    key: 'accountInfoByChild',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAvailableBanks = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_AVAILABLE_BANKS,
    key: 'availableBanks',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchEnquiryMonthlyPaymentDetailsById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
    key: 'enquiryMonthlyPaymentDetailById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRegistrationPaymentById = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID,
    key: 'registrationPaymentById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const getTrialInvoice = IDEnquiry => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getTrialPeriodInvoice', {
          IDEnquiry,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchAvailableBanks = () => async dispatch => {
  dispatchFetchAvailableBanks(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllBankBicCode', {
          pagination: {
            sort: ['display_order', 'bankName'],
          },
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchAvailableBanks(dispatch, false, { data: data.data });
    } else {
      dispatchFetchAvailableBanks(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchAvailableBanks(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchUserAccounts = ({ isCDA, paymentType }) => async dispatch => {
  dispatchAccountListing(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: GET_ACCOUNT_LISTING,
      variables: {
        isCDA,
        paymentType,
      },
    });

    if (!result.loading && get(result, 'data.getRegistrationAccountListing')) {
      dispatchAccountListing(dispatch, false, { data: result.data });
    } else {
      dispatchAccountListing(dispatch, false, null, result.errors);
    }

    return result;
  } catch (ex) {
    dispatchAccountListing(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const getMonthlyFeeForEnquiry = IDRegistration => async dispatch => {
  dispatchEnquiryMonthlyPaymentDetailsById(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'getRegistrationDraftMonthlyFeeInvoice',
          {
            IDRegistration,
          }
        ),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchEnquiryMonthlyPaymentDetailsById(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchEnquiryMonthlyPaymentDetailsById(
        dispatch,
        false,
        null,
        data.error
      );
    }

    return data;
  } catch (ex) {
    dispatchEnquiryMonthlyPaymentDetailsById(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getPaymentDetailsForEnquiry = registrationID => async dispatch => {
  dispatchRegistrationPaymentById(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getRegistrationInvoice', {
          IDRegistration: registrationID,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchRegistrationPaymentById(dispatch, false, {
        data: data.data,
        key: 'registrationPaymentById',
      });
    } else {
      dispatchRegistrationPaymentById(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchRegistrationPaymentById(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const getPaymentSession = paymentOrderID => async dispatch => {
  dispatchFetchPaymentSession(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getPaymentSession', {
          paymentOrderID,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchPaymentSession(dispatch, false, { data: data.data });
    } else {
      dispatchFetchPaymentSession(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchPaymentSession(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const updatePaymentSession = paymentOrderID => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('updatePaymentSession', {
          paymentOrderID,
        }),
      },
      method: 'POST',
    });
  } catch (ex) {
    return ex;
  }
};

export const createPaymentSession = InvoiceID => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('createPaymentSession', {
          InvoiceID,
        }),
      },
      method: 'POST',
    });
  } catch (ex) {
    return ex;
  }
};

export const payUserGiro = reqData => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'payGiroByInvoiceID',
          reqData
        ),
      },
      method: 'POST',
    });
  } catch (ex) {
    throw ex;
  }
};

export const completeGiroSetup = IDRegistration => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: MARK_GIRO_AS_COMPLETED,
        variables: { IDRegistration },
      },
      type: 'mutation',
    });
  } catch (ex) {
    throw ex;
  }
};

export const addUserGiro = ({ giroAccount, paymentType }) => async () => {
  const client = await apolloUtils();
  try {
    return await client.mutate({
      mutation: ADD_GIRO,
      variables: {
        giroAccount,
        paymentType,
      },
    });
  } catch (ex) {
    throw ex;
  }
};

export const getPayNowUENBySchool = schoolID => async () => {
  const client = await apolloUtils();
  try {
    return await client.query({
      query: GET_PAYNOW_UEN_BY_SCHOOL,
      variables: { schoolID },
    });
  } catch (ex) {
    throw ex;
  }
};

export const fetchAccountInfoByEnquiry = regChildId => async dispatch => {
  dispatchFetchAccountByEnquiry(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
        variables: { regChildId },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchAccountByEnquiry(dispatch, false, result.data);
    } else {
      dispatchFetchAccountByEnquiry(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchAccountByEnquiry(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAccountInfoByReg = regChildId => async dispatch => {
  dispatchFetchAccountByReg(dispatch);
  try {
    const result = await SkApolloRequest({
      params: {
        query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
        variables: { regChildId },
      },
      type: 'query',
    });
    if (result.success) {
      dispatchFetchAccountByReg(dispatch, false, result.data);
    } else {
      dispatchFetchAccountByReg(dispatch, false, null, result.error);
    }
    return result;
  } catch (ex) {
    dispatchFetchAccountByReg(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAccountInfoByChild = regChildId => async dispatch => {
  dispatchFetchAccountByChild(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FIND_ACCOUNT_INFO_BY_CHILD_ID,
      variables: {
        regChildId,
      },
    });
    const data = get(result, 'data.getBankAccountByFkRegistrationChild', []);
    dispatchFetchAccountByChild(dispatch, false, data);

    return result.data;
  } catch (ex) {
    dispatchFetchAccountByChild(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const payRegistrationInvoiceByGiro = ({
  IDRegChild,
  IDInvoice,
}) => async () => {
  const client = await apolloUtils();
  try {
    return await client.query({
      query: PAY_REGISTRATION_INVOICE_BY_GIRO,
      variables: { IDRegChild, IDInvoice },
    });
  } catch (ex) {
    throw ex;
  }
};

export const fetchAccountInfoByEnquiryForGiro = IDEnquiry => async dispatch => {
  dispatchFetchAccountByEnquiryForGiro(dispatch);
  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FIND_ACCOUNT_INFO_BY_ENQUIRY_ID,
      variables: {
        IDEnquiry,
      },
    });

    if (!result.loading && get(result, 'data.bankAccountInfoByIDEnquiry')) {
      dispatchFetchAccountByEnquiryForGiro(dispatch, false, {
        data: result.data,
      });
    } else {
      dispatchFetchAccountByEnquiryForGiro(
        dispatch,
        false,
        null,
        get(result, 'data.error', DEFAULT_ERROR)
      );
    }

    return result.data;
  } catch (ex) {
    dispatchFetchAccountByEnquiryForGiro(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const getPaymentAccess = reqData => async dispatch => {
  dispatchFetchPaymentSession(dispatch);
  const client = await apolloUtils();

  try {
    const data = await client.query({
      query: GET_PAYMENT_ACCESS,
      variables: reqData,
    });

    if (!data.loading && get(data, 'data.getRegistrationPaymentAccess')) {
      dispatchFetchPaymentSession(dispatch, false, { data: data.data });
    } else {
      dispatchFetchPaymentSession(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchPaymentSession(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const dispatchSetGiroSetupStep = data => dispatch => {
  dispatch({
    type: SET_GIRO_SETUP_STEP,
    key: 'giroSetupStepDetail',
    value: {
      inProgress: false,
      data,
      error: null,
    },
  });
};

export const dispatchClearGiroSetupStep = (data = {}) => dispatch => {
  dispatch({
    type: CLEAR_GIRO_SETUP_STEP,
    key: 'giroSetupStepDetail',
    value: {
      inProgress: false,
      data,
      error: null,
    },
  });
};

export const exportGiroPdf = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EXPORT_GIRO_PDF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const emailGiroPdf = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: EMAIL_GIRO_PDF,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const initiateRefundForCancelledRegistration = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: INITIATE_REFUND,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default getPaymentAccess;
