import { get } from 'lodash';
import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  APPROVE_FEE_CHANGE_REQUEST,
  FETCH_CURRENT_FEE_TIER,
  FETCH_FEE_DRAFT,
  FETCH_FEE_GROUP,
  FETCH_UPCOMING_FEE_TIER,
  FETCH_GROUP_CATEGORY_FEE_LIST,
  RECALL_FEE_CHANGE_REQUEST,
  REJECT_FEE_CHANGE_REQUEST,
  SUBMIT_FEE_DRAFT,
  FETCH_ALL_FEE_BULK_UPLOADS,
} from './actionTypes';
import {
  LIST_BULK_FEE_UPLOADS,
  GET_BULK_FEE_UPLOAD_URL,
  GET_BULK_FEE_UPLOAD_TEMPLATE,
  UPLOAD_BULK_FEE_FILE,
} from '../models/feeManagement';

const _mQueryBuilder = new QueryBuilder();
const _mMutationBuilder = new MutationBuilder();
const feeTierListRespObj = {
  totalCount: 'number',
  data: {
    fkCentre: 'number',
    fkGroup: 'number',
    ID: 'number',
    label: 'String',
    effectiveForRegistrationFrom: 'Datetime',
    effectiveForRegistrationTo: 'Datetime',
    isLastTierInGroup: 'Boolean',
    createdAt: 'Datetime',
    updatedAt: 'Datetime',
    active: 'String',
    pending: 'Boolean',
    centre: {
      ID: 'number',
      label: 'string',
      school: {
        code: 'string',
        country: 'NullString',
      },
    },
    feeGroup: {
      groupCode: 'string',
    },
    'feeDrafts(pagination: {perPage: 1, page: 1, sort: ["-createdAt"]}, filter: {status: ["draft", "pending"]})': {
      totalCount: 'number',
      data: {
        ID: 'number',
        fkUser: 'number',
        status: 'string',
      },
    },
    'approvedFeeDraft: feeDrafts(pagination: {perPage: 1, page: 1, sort: ["-createdAt"]}, filter: {status: ["approved"]})': {
      data: {
        ID: 'int',
        status: 'string',
        updatedAt: 'Datetime',
        effectiveFrom: 'Datetime',
        centre: {
          label: 'string',
        },
        feeTier: {
          feeGroup: {
            groupCode: 'string',
          },
          effectiveForRegistrationFrom: 'Datetime',
          effectiveForRegistrationTo: 'Datetime',
        },
        userByFkUser: {
          firstname: 'string',
          lastname: 'string',
        },
        userByFkApprover: {
          firstname: 'string',
          lastname: 'string',
        },
        feeDraftActivityLogs: {
          data: {
            status: 'string',
            remark: 'string',
            createdAt: 'Datetime',
          },
        },
      },
    },
    'savedDraft: feeDrafts(pagination: {perPage: 1, page: 1}, filter: {status: ["draft"]})': {
      totalCount: 'number',
      data: {
        ID: 'number',
        fkUser: 'number',
        status: 'string',
        'feeDraftItems(pagination: {sort: ["fk_level", "nationality", "fk_program", "effectiveFrom"]})': {
          data: {
            ID: 'number',
            nationality: 'string',
            amount: 'number',
            lastAmount: 'number',
            effectiveFrom: 'Datetime',
            program: {
              ID: 'number',
              label: 'string',
            },
            level: {
              ID: 'number',
              label: 'string',
            },
          },
        },
      },
    },
    'fees(filter: {currentActive: true}, pagination: {sort: ["fk_level", "nationality", "fk_program", "effectiveFrom"]})': {
      data: {
        ID: 'number',
        nationality: 'string',
        amount: 'number',
        effectiveFrom: 'Datetime',
        effectiveTo: 'Datetime',
        program: {
          ID: 'number',
          label: 'string',
        },
        level: {
          ID: 'number',
          label: 'string',
        },
      },
    },
    numberOfChildren: {
      totalCount: 'number',
      levels: 'Object',
    },
  },
};

_mQueryBuilder.setNewQuery('findAllCurrentFeeTier', feeTierListRespObj);
_mQueryBuilder.setNewQuery('findAllUpcomingFeeTier', feeTierListRespObj);
_mQueryBuilder.setNewQuery('findAllFeeDraft', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkCentre: 'number',
    status: 'string',
    createdAt: 'Datetime',
    effectiveFrom: 'Datetime',
    userByFkApprover: {
      firstname: 'string',
      lastname: 'string',
    },
    userByFkUser: {
      firstname: 'string',
      lastname: 'string',
    },
    centre: {
      ID: 'string',
      label: 'string',
      school: {
        code: 'string',
        country: 'NullString',
      },
    },
    'feeTier(filter: {active: [true, false]})': {
      effectiveForRegistrationFrom: 'Datetime',
      effectiveForRegistrationTo: 'Datetime',
      label: 'string',
      feeGroup: {
        groupCode: 'string',
      },
      pending: 'boolean',
    },
    feeDraftActivityLogs: {
      data: {
        status: 'string',
        remark: 'string',
        createdAt: 'Datetime',
      },
    },
    feeDraftItems: {
      data: {
        ID: 'number',
        nationality: 'string',
        amount: 'number',
        lastAmount: 'number',
        effectiveFrom: 'Datetime',
        program: {
          label: 'string',
        },
        level: {
          ID: 'number',
          label: 'string',
        },
      },
    },
  },
});
_mQueryBuilder.setNewQuery('findAllFeeGroup', {
  data: {
    ID: 'number',
    groupCode: 'string',
  },
});
_mMutationBuilder.setNewMutation('newFeeTier', {
  ID: 'string',
});
_mMutationBuilder.setNewMutation('newFeeDraft', {
  ID: 'string',
});
_mMutationBuilder.setNewMutation('updateFeeDraftItems', {
  ID: 'string',
});
_mMutationBuilder.setNewMutation('submitFeeDraft', {});
_mMutationBuilder.setNewMutation('cancelFeeChangeRequest', {});
_mMutationBuilder.setNewMutation('approveFeeChangeRequest', {});
_mMutationBuilder.setNewMutation('rejectFeeChangeRequest', {});

const dispatchCurrentFeeTier = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CURRENT_FEE_TIER,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchUpcomingFeeTier = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_UPCOMING_FEE_TIER,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFindAllFeeDraft = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FEE_DRAFT,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFindAllFeeGroup = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FEE_GROUP,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchApproveFeeChangeRequest = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: APPROVE_FEE_CHANGE_REQUEST,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRejectFeeChangeRequest = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: REJECT_FEE_CHANGE_REQUEST,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSubmitFeeDraft = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SUBMIT_FEE_DRAFT,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRecallFeeChangeRequest = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: RECALL_FEE_CHANGE_REQUEST,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchListBulkFeeUploadRequest = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_FEE_BULK_UPLOADS,
    key: 'bulkFeeUploads',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchCurrentFeeTier = reqData => async dispatch => {
  dispatchCurrentFeeTier(dispatch);

  const filter = Object.assign({}, get(reqData, 'filter', {}));
  const filterKeys = Object.keys(filter);

  filterKeys.forEach(eachFilter => {
    if (!filter[eachFilter]) {
      delete filter[eachFilter];
    }
  });

  const postData = { ...reqData, filter };
  const query = _mQueryBuilder.getQueryAsString(
    'findAllCurrentFeeTier',
    postData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchCurrentFeeTier(dispatch, false, data.data);
    } else {
      dispatchCurrentFeeTier(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchCurrentFeeTier(dispatch, false, null, { error: ex.message });
  }
};

export const fetchUpcomingFeeTier = reqData => async dispatch => {
  dispatchUpcomingFeeTier(dispatch);
  const filter = Object.assign({}, get(reqData, 'filter', {}));
  const filterKeys = Object.keys(filter);

  filterKeys.forEach(eachFilter => {
    if (!filter[eachFilter]) {
      delete filter[eachFilter];
    }
  });

  const postData = { ...reqData, filter };
  const query = _mQueryBuilder.getQueryAsString(
    'findAllUpcomingFeeTier',
    postData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchUpcomingFeeTier(dispatch, false, data.data);
    } else {
      dispatchUpcomingFeeTier(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchUpcomingFeeTier(dispatch, false, null, { error: ex.message });
  }
};

export const fetchAllFeeDraft = (reqData, key) => async dispatch => {
  dispatchFindAllFeeDraft(dispatch, true, { key });

  reqData = Object.assign({}, reqData);
  const reqKeys = Object.keys(reqData);

  reqKeys.forEach(eachKey => {
    if (!reqData[eachKey]) {
      delete reqData[eachKey];
    }
    if (eachKey === 'filter') {
      const filterData = reqData[eachKey];
      const filterDataKeys = Object.keys(filterData);
      filterDataKeys.forEach(eachFilterKey => {
        if (!filterData[eachFilterKey]) {
          delete filterData[eachFilterKey];
        }
      });
    }
  });

  const query = _mQueryBuilder.getQueryAsString('findAllFeeDraft', reqData);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFindAllFeeDraft(dispatch, false, { data: data.data, key });
    } else {
      dispatchFindAllFeeDraft(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFindAllFeeDraft(dispatch, false, null, { error: ex.message });
  }
};

export const fetchAllFeeGroup = (
  filter,
  pagination = null
) => async dispatch => {
  dispatchFindAllFeeGroup(dispatch);

  filter = Object.assign({}, filter);
  const filterKeys = Object.keys(filter);

  filterKeys.forEach(eachFilter => {
    if (!filter[eachFilter]) {
      delete filter[eachFilter];
    }
  });

  const reqData = { filter, pagination };
  if (!reqData.pagination) {
    delete reqData.pagination;
  }

  const query = _mQueryBuilder.getQueryAsString('findAllFeeGroup', reqData);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFindAllFeeGroup(dispatch, false, data.data);
    } else {
      dispatchFindAllFeeGroup(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFindAllFeeGroup(dispatch, false, null, { error: ex.message });
  }
};

export const submitFeeDraft = reqData => async dispatch => {
  dispatchSubmitFeeDraft(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'submitFeeDraft',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateFeeDraftItems = reqData => async dispatch => {
  dispatchSubmitFeeDraft(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'updateFeeDraftItems',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelFeeChangeRequest = reqData => async dispatch => {
  dispatchRecallFeeChangeRequest(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'cancelFeeChangeRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchRecallFeeChangeRequest(dispatch, false, data.data);
    } else {
      dispatchRecallFeeChangeRequest(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRecallFeeChangeRequest(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const approveFeeChangeRequest = reqData => async dispatch => {
  dispatchApproveFeeChangeRequest(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'approveFeeChangeRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchApproveFeeChangeRequest(dispatch, false, data.data);
    } else {
      dispatchApproveFeeChangeRequest(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchApproveFeeChangeRequest(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const rejectFeeChangeRequest = reqData => async dispatch => {
  dispatchRejectFeeChangeRequest(dispatch);

  const query = _mMutationBuilder.getMutationAsString(
    'rejectFeeChangeRequest',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchRejectFeeChangeRequest(dispatch, false, data.data);
    } else {
      dispatchRejectFeeChangeRequest(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRejectFeeChangeRequest(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const addNewFeeTier = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString('newFeeTier', reqData);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const addNewFeeDraft = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString('newFeeDraft', reqData);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchCategoyWiseFee = () => dispatch =>
  dispatch({
    type: FETCH_GROUP_CATEGORY_FEE_LIST,
    value: [
      {
        ID: '1',
        nationality: 'Singaporean',
        program_type: 'Half Day',
        current_fee: '$200',
        new_fee: '&300',
      },
      {
        ID: '2',
        nationality: 'Singaporean',
        program_type: 'Half Day',
        current_fee: '$200',
        new_fee: '&300',
      },
      {
        ID: '3',
        nationality: 'Singaporean',
        program_type: 'Half Day',
        current_fee: '$200',
        new_fee: '&300',
      },
      {
        ID: '4',
        nationality: 'Singaporean',
        program_type: 'Half Day',
        current_fee: '$200',
        new_fee: '&300',
      },
    ],
  });

export const listBulkFeeUploadRequest = (
  reqData,
  update = false
) => async dispatch => {
  if (!update) {
    dispatchListBulkFeeUploadRequest(dispatch);
  }
  try {
    const result = await SkApolloRequest({
      params: {
        query: LIST_BULK_FEE_UPLOADS,
        variables: reqData,
      },
      type: 'query',
    });
    if (!update) {
      if (result.success) {
        dispatchListBulkFeeUploadRequest(dispatch, false, result.data);
      } else {
        dispatchListBulkFeeUploadRequest(dispatch, false, null, result.error);
      }
    }
    return result;
  } catch (ex) {
    dispatchListBulkFeeUploadRequest(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const getBulkFeeUploadTemplate = centreIds => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        query: GET_BULK_FEE_UPLOAD_TEMPLATE,
        variables: {
          centreIds,
        },
      },
      type: 'query',
    });
    return result;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getBulkFeeUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_BULK_FEE_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const uploadBulkFeeFile = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPLOAD_BULK_FEE_FILE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export default fetchCurrentFeeTier;
