import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import {
  FETCH_WAITLISTS,
  FETCH_ALL_WAITLIST_VACANCY,
  FETCH_WAITLISTS_FILTER,
  SEARCH_CHILD_IN_WAITLIST,
} from './actionTypes';
import { GET_WAITLIST, UPDATE_ENQUIRY_TAGS } from '../models/waitlistModel';
import apolloUtils from '../../utils/apolloUtils';

const _mQueryBuilder = new QueryBuilder();
const _mMutationEnquiryBuilder = new MutationBuilder();

_mMutationEnquiryBuilder.setNewMutation('addChildActivityLog', {
  ID: 'string',
});

_mMutationEnquiryBuilder.setNewMutation('acceptVacancyForRegistration', {});

_mMutationEnquiryBuilder.setNewMutation('rejectVacancyForRegistration', {});

_mMutationEnquiryBuilder.setNewMutation('updateEnquiryDetail', {
  ID: 'string',
});

_mMutationEnquiryBuilder.setNewMutation('insertOrUpdateParentByFkUser', {
  ID: 'string',
});

_mMutationEnquiryBuilder.setNewMutation('insertOrUpdateUserAddress', {
  ID: 'string',
});
_mMutationEnquiryBuilder.setNewMutation('remoteTagFromEnquiry', {});

_mMutationEnquiryBuilder.setNewMutation('updateChildByFields', {
  ID: 'string',
});

_mQueryBuilder.setNewQuery('getWaitListFilter', {
  centres: {
    data: {
      ID: 'integer',
      label: 'string',
    },
  },
  tags: {
    data: {
      ID: 'integer',
      description: 'string',
    },
  },
  levels: {
    data: {
      ID: 'integer',
      label: 'string',
    },
  },
  programs: {
    data: {
      ID: 'integer',
      label: 'string',
    },
  },
  preferMonthOfEnrolmentOptions: {
    numberOfMonth: 'integer',
    label: 'string',
  },
  races: '[string]',
});

_mQueryBuilder.setNewQuery('getVacancyTab', {
  totalCount: 'number',
  data: {
    fkCentre: 'number',
    fkLevel: 'number',
    available: 'number',
    date: 'timestamp',
    level: {
      label: 'string',
      ID: 'number',
      active: 'bool',
    },
    centre: {
      ID: 'number',
      label: 'string',
    },
    enquiries: {
      totalCount: 'number',
      data: {
        admissionStatus: {
          label: 'string',
        },
        fkChild: 'number',
        childFirstname: 'string',
        childLastname: 'string',
        child: {
          firstname: 'string',
          lastname: 'string',
        },
      },
    },
  },
});

_mQueryBuilder.setNewQuery('searchChildInWaitList', {
  totalCount: 'number',
  data: {
    ID: 'number',
    firstname: 'string',
    lastname: 'string',
    birthCertificate: 'string',
    childRelations: {
      data: {
        user: {
          mobilePhone: 'string',
          mobilePhoneCountryCode: 'string',
        },
      },
    },
  },
});

const dispatchFetchWaitList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_WAITLISTS,
    key: 'list',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchWaitListFilter = (
  dispatch,
  inprogress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_WAITLISTS_FILTER,
    key: 'listFilter',
    value: {
      inprogress,
      data,
      error,
    },
  });
};

const dispatchFetchVacancyList = (
  dispatch,
  inprogress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_WAITLIST_VACANCY,
    key: 'vacancy',
    value: {
      inprogress,
      data,
      error,
    },
  });
};

const dispatchSearchChildInWaitList = (
  dispatch,
  inprogress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: SEARCH_CHILD_IN_WAITLIST,
    key: 'searchChild',
    value: {
      inprogress,
      data,
      error,
    },
  });
};

export const fetchWaitList = (reqData, key) => async dispatch => {
  dispatchFetchWaitList(dispatch);
  const client = await apolloUtils();
  try {
    reqData = Object.assign({}, reqData);
    const reqKeys = Object.keys(reqData);

    reqKeys.forEach(eachKey => {
      if (!reqData[eachKey]) {
        delete reqData[eachKey];
      }
      if (eachKey === 'filter') {
        const filterData = reqData[eachKey];
        const filterDataKeys = Object.keys(filterData);
        filterDataKeys.forEach(eachFilterKey => {
          if (
            eachFilterKey === 'waitlistStatus' &&
            filterData[eachFilterKey] === null
          ) {
            delete filterData[eachFilterKey];
          }
          if (
            eachFilterKey !== 'waitlistStatus' &&
            !filterData[eachFilterKey]
          ) {
            delete filterData[eachFilterKey];
          }
        });
      }
    });

    const result = await client.query({
      query: GET_WAITLIST,
      variables: reqData,
    });
    if (!result.loading && result.data && result.data.getWaitList) {
      dispatchFetchWaitList(dispatch, false, { data: result.data, key });
    } else {
      dispatchFetchWaitList(dispatch, false, null, result.data.error);
    }

    return result;
  } catch (ex) {
    dispatchFetchWaitList(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const updateEnquiryField = reqData => async () => {
  try {
    const obj = reqData;
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getBulkMutationAsString(obj, false),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const acceptVacancyForRegistration = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'acceptVacancyForRegistration',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectVacancyForRegistration = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'rejectVacancyForRegistration',
          reqData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateEnquiryTags = (IDEnquiry, IDTag) => async () => {
  const client = await apolloUtils();

  try {
    const result = await client.mutate({
      mutation: UPDATE_ENQUIRY_TAGS,
      variables: {
        IDEnquiry,
        IDTag,
      },
    });
    return result.data;
  } catch (ex) {
    return ex;
  }
};

export const addChildActivityLog = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'addChildActivityLog',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchWaitListFilter = (reqData, key) => async dispatch => {
  dispatchFetchWaitListFilter(dispatch);
  try {
    reqData = Object.assign({}, reqData);
    const reqKeys = Object.keys(reqData);

    reqKeys.forEach(eachKey => {
      if (!reqData[eachKey]) {
        delete reqData[eachKey];
      }
      if (eachKey === 'filter') {
        const filterData = reqData[eachKey];
        const filterDataKeys = Object.keys(filterData);
        filterDataKeys.forEach(eachFilterKey => {
          if (
            eachFilterKey === 'waitlistStatus' &&
            filterData[eachFilterKey] === null
          ) {
            delete filterData[eachFilterKey];
          }
          if (
            eachFilterKey !== 'waitlistStatus' &&
            !filterData[eachFilterKey]
          ) {
            delete filterData[eachFilterKey];
          }
        });
      }
    });

    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getWaitListFilter', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchWaitListFilter(dispatch, false, {
        data: data.data,
        key,
      });
    } else {
      dispatchFetchWaitListFilter(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchWaitListFilter(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchVacancyList = (reqData, key) => async dispatch => {
  dispatchFetchVacancyList(dispatch);
  try {
    reqData = Object.assign({}, reqData);
    const reqKeys = Object.keys(reqData);

    reqKeys.forEach(eachKey => {
      if (!reqData[eachKey]) {
        delete reqData[eachKey];
      }
      if (eachKey === 'filter') {
        const filterData = reqData[eachKey];
        const filterDataKeys = Object.keys(filterData);
        filterDataKeys.forEach(eachFilterKey => {
          if (
            eachFilterKey === 'waitlistStatus' &&
            filterData[eachFilterKey] === null
          ) {
            delete filterData[eachFilterKey];
          }
          if (
            eachFilterKey !== 'waitlistStatus' &&
            !filterData[eachFilterKey]
          ) {
            delete filterData[eachFilterKey];
          }
        });
      }
    });

    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getVacancyTab', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchVacancyList(dispatch, false, { data: data.data, key });
    } else {
      dispatchFetchVacancyList(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchVacancyList(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const searchChildInWaitList = q => async dispatch => {
  dispatchSearchChildInWaitList(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('searchChildInWaitList', { q }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchSearchChildInWaitList(dispatch, false, data.data);
    } else {
      dispatchSearchChildInWaitList(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchSearchChildInWaitList(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export default fetchWaitList;
