import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

const SCHOOL_ID = process.env.REACT_APP_SCHOOL_ID;

export default class SentryHandler {
  static init() {
    this.initSentry();
  }

  static initSentry() {
    // only initialise Sentry on dev, staging, and production
    if (process.env.REACT_APP_ENVIRONMENT) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        ignoreErrors: [/ChunkLoadError/],
      });
    }
  }

  static setUserContextOnRouteChange(route, userDetails) {
    const extra = {
      userRole: get(userDetails, 'userRoleRelations.data[0].role.label'),
      schoolID: SCHOOL_ID,
    };
    this.setUserContext(userDetails.ID, extra);
    this.setExtraTags({ extra });
  }

  static setUserContext(userId, extra) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userId,
        ...extra,
      });
    });
  }

  static setExtraTags({ extra }) {
    Sentry.setTags(extra);
  }

  static sendError(err, withScope = false, req) {
    if (withScope) {
      Sentry.withScope(scope => {
        scope.setExtras({ req });
        // Sentry.capturkeException(err);
      });
    } else {
      // Sentry.captureException(err);
    }
  }
}
