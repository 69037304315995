import React from 'react';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FieldWrapper from './FieldWrapper';
import Text from '../Text';

export const CheckBoxLabel = ({
  value = false,
  label,
  name,
  disabled,
  onChange,
  className = 's7t-font-500',
  classes,
  wrapperPadding,
  textProps,
  fromControlProps,
}) => (
  <FieldWrapper wrapperPadding={wrapperPadding}>
    <FormControlLabel
      className="sn2-formControlLabel"
      {...fromControlProps}
      control={
        <Checkbox
          checked={Boolean(value)}
          color="primary"
          onChange={onChange}
          disabled={disabled}
          classes={classes}
          className="sn2-checkbox"
          name={name}
        />
      }
      label={
        <Text {...textProps} className={className}>
          {label}
        </Text>
      }
    />
  </FieldWrapper>
);

export default CheckBoxLabel;
