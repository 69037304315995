import { get } from 'lodash';
import {
  SAVE_UPLOADED_REG_DOCUMENTS,
  FETCH_DOCUMENTS_TO_UPLOAD,
} from '../actions/actionTypes';

export const fetchRegistrationDocumentsToUpload = (state = {}, action) => {
  if (action.type === FETCH_DOCUMENTS_TO_UPLOAD) {
    const data = get(action, 'value.data.getRequiredRegDocuments');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const savedRegGiroDocuments = (state = {}, action) => {
  if (action.type === SAVE_UPLOADED_REG_DOCUMENTS) {
    const data = get(action, 'value.data.saveRegistrationGiroDocument');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};
