import React from 'react';
import { Grid } from '@material-ui/core';
import Text from 'components/common/Text';

import MFSLogo from './images/mfs-logo.png';
import MfSMIcon from './images/mfs-m-icon.png';
import MFSITopL from './images/mfs-top-left.png';
import MFSITopR from './images/mfs-top-right.png';
import MFSIBottomL from './images/mfs-bottom-left.png';
import MFSIBottomR from './images/mfs-bottom-right.png';

import LSHLogo from './images/lsh-logo.png';
import LSHMIcon from './images/lsh-m-icon.png';
import LSHLine from './images/lsh-line.png';
import LSHIBottomR from './images/lsh-bottom-right.png';
import LSHIBottomL from './images/lsh-bottom-left.png';
import LSHMid from './images/lsh-mid.png';

const MFSMaintenancePage = () => (
  <Grid item xs={12}>
    <Grid className="s7t-class-maintenance-main">
      <img
        src={MFSLogo}
        alt="mfs-logo"
        className="s7t-class-maintenance-logo-100"
      />
    </Grid>
    <Grid className="w3-display-middle">
      <img
        src={MfSMIcon}
        alt="mfs-m-icon"
        className="s7t-class-maintenance-logo-80"
      />
      <Grid className="s7t-class-maintenance-div">
        <Text className="s7t-class-maintenance-font-h2">
          {' '}
          Site Under Maintenance
        </Text>
        <Text className="s7t-class-maintenance-font-h3"> 网站维修中</Text>
        <Text className="w3-margin">
          Our system is currently undergoing maintenance to ensure you get the
          best experience. We will be back online shortly.
        </Text>
        <Text>Thank you for your patience!</Text>
      </Grid>
    </Grid>
    <Grid className="s7t-class-maintenance-mfsbg-top-left">
      <img src={MFSITopL} alt="mfs-top-l" style={{ width: '225px' }} />
    </Grid>
    <Grid className="s7t-class-maintenance-mfsbg-bottom-left">
      <img src={MFSIBottomL} alt="mfs-bottom-l" style={{ width: '225px' }} />
    </Grid>
    <Grid className="s7t-class-maintenance-mfsbg-top-right">
      <img
        src={MFSITopR}
        alt="mfs-top-i"
        className="s7t-class-maintenance-logo-100"
      />
    </Grid>
    <Grid className="s7t-class-maintenance-mfsbg-bottom-right">
      <img src={MFSIBottomR} alt="mfs-logo" style={{ width: '180px' }} />
    </Grid>
  </Grid>
);

const LSHMaintenancePage = () => (
  <Grid item xs={12}>
    <Grid className="s7t-class-maintenance-main">
      <img
        src={LSHLogo}
        alt="lsh-logo"
        className="s7t-class-maintenance-logo-80"
      />
    </Grid>
    <Grid className="w3-display-middle">
      <img
        src={LSHMIcon}
        alt="l-m-icon"
        className="s7t-class-maintenance-logo-80"
      />
      <Grid className="s7t-class-maintenance-div">
        <Text className="s7t-class-maintenance-font-h2 s7t-class-maintenance-font-family">
          {' '}
          Site Under Maintenance
        </Text>
        <Text className="s7t-class-maintenance-font-h3 s7t-class-maintenance-font-400">
          {' '}
          网站维修中
        </Text>
        <Text className="w3-margin">
          Our system is currently undergoing maintenance to ensure you get the
          best experience. We will be back online shortly.
        </Text>
        <Text>Thank you for your patience!</Text>
      </Grid>
    </Grid>
    <Grid className="s7t-class-maintenance-lshbg-bottom-line">
      <img
        src={LSHLine}
        alt="lsh-line"
        className="s7t-class-maintenance-logo-100p"
      />
    </Grid>
    <Grid className="s7t-class-maintenance-lshbg-bottom-left">
      <img
        src={LSHIBottomL}
        alt="lsh-bottom-l"
        className="s7t-class-maintenance-logo-400"
      />
    </Grid>
    <Grid className="s7t-class-maintenance-lshbg-bottom-right">
      <img
        src={LSHIBottomR}
        alt="lsh-bottom-r"
        className="s7t-class-maintenance-logo-300"
      />
    </Grid>
    <Grid className="s7t-class-maintenance-lsh-mid-logo">
      <img
        src={LSHMid}
        alt="mfs-logo"
        className="s7t-class-maintenance-logo-300"
      />
    </Grid>
  </Grid>
);

const Maintenance = () => {
  const SCHOOL_ID = process.env.REACT_APP_SCHOOL_ID;

  return (
    <Grid container className="s7t-class-maintenance-container w3-center">
      {SCHOOL_ID === '1' ? <MFSMaintenancePage /> : <LSHMaintenancePage />}
    </Grid>
  );
};

export default Maintenance;
