import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import { Grid, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {
  DEFAULT_ERROR,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  REGISTRATION_FEE,
} from '../../../../../utils/constants';
import {
  capitaliseFirstLetter,
  handleDisabledDates,
} from '../../../../../utils';
import { SETTINGS, DISCOUNT_SETTINGS } from '../../../../../routes/constants';
import Modal from '../../../../common/Modal';
import Text from '../../../../common/Text';
import CommonPageLayout from '../../../../common/Page';
import Button from '../../../../common/Button';
import Loader from '../../../../common/Loader';
import {
  LabelField,
  TextField,
  DateTimePicker,
  RadioGroupField,
} from '../../../../common/InputFields';
import {
  activateDiscount,
  deactivateDiscount,
  fetchDiscountGroups,
  showSnackBarMessage,
} from '../../../../../redux/actions';

function constructCenterNameString(centerRelationsObj) {
  const centresList = get(centerRelationsObj, 'data', []);
  let centerNamesSeperatedByCommas = '';
  centresList.forEach((eachCenter, idx) => {
    centerNamesSeperatedByCommas += `${get(eachCenter, 'centre.label')}${
      idx === centresList.length - 1 ? '' : ','
    }`;
  });
  return centerNamesSeperatedByCommas;
}

class DiscountDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showActivateModal: false,
      showDeactivateModal: false,
      remarks: '',
      effectiveDate: '',
      ceasedDate: '',
      ceaseExistingArrangement: 'keep',
      errors: {
        apiError: '',
        remarksError: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDiscountActivation = this.handleDiscountActivation.bind(this);
    this.handleDeactivation = this.handleDeactivation.bind(this);
    this.fetchDiscountDetailsData = this.fetchDiscountDetailsData.bind(this);
  }

  componentDidMount() {
    this.fetchDiscountDetailsData();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        get(prevProps, 'discountDetails'),
        get(this, 'props.discountDetails')
      ) &&
      !get(this, 'props.discountDetails.inProgress')
    ) {
      const effectiveDate = get(
        this,
        'props.discountDetails.data[0].effectiveDate'
      );
      const ceasedDate = get(this, 'props.discountDetails.data[0].ceasedDate');
      // eslint-disable-next-line react/no-did-update-set-state
      return this.setState({
        effectiveDate,
        ceasedDate,
      });
    }
  }

  fetchDiscountDetailsData() {
    const { fetchDiscountDetails, schoolID, match } = this.props;
    const discountID = parseInt(get(match, 'params.id'), 10);
    const reqObj = {
      schoolID,
      discountItemGroupFilter: {
        ID: discountID,
      },
    };
    fetchDiscountDetails(reqObj);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { errors } = this.state;
    let stateChanges = { [name]: value };

    if (get(errors, 'remarksError') || get(errors, 'apiError')) {
      stateChanges = {
        [name]: value,
        errors: {},
      };
    }
    this.setState(stateChanges);
  }

  handleDiscountActivation() {
    const {
      activateDiscountMethod,
      match,
      history,
      showSnackBarMessage,
      t,
    } = this.props;
    const { effectiveDate, ceasedDate } = this.state;
    const discountID = parseInt(get(match, 'params.id'), 10);
    const data = {
      IDDiscountItemGroup: discountID,
    };

    if (effectiveDate) {
      data.effectiveDate = moment(effectiveDate).format(DATE_PICKER_FORMAT);
    }
    if (ceasedDate) {
      data.ceasedDate = moment(ceasedDate).format(DATE_PICKER_FORMAT);
    } else {
      data.ceasedDate = null;
    }

    activateDiscountMethod(data)
      .then(resp => {
        if (resp.success) {
          this.setState(
            {
              showActivateModal: false,
            },
            () => {
              history.goBack();
              showSnackBarMessage(t('discount.discountActivationSuccess'));
            }
          );
        } else if (resp.error) {
          const errorMessage = get(resp, 'error[0].message') || DEFAULT_ERROR;
          showSnackBarMessage(errorMessage, 'error');
          this.setState({
            showActivateModal: false,
          });
        }
      })
      .catch(ex => {
        const errorMessage = get(ex, 'message') || DEFAULT_ERROR;
        showSnackBarMessage(errorMessage, 'error');
        this.setState({
          showActivateModal: false,
        });
      });
  }

  handleDeactivation() {
    const {
      deactivateDiscountMethod,
      match,
      history,
      showSnackBarMessage,
      discountDetails,
      t,
    } = this.props;
    const discountDetailsData = get(discountDetails, 'data[0]');
    const { remarks, errors, ceaseExistingArrangement } = this.state;
    if (!remarks) {
      errors.remarksError = t('common.requiredError');
      return this.setState({
        errors,
      });
    }
    const discountID = parseInt(get(match, 'params.id'), 10);
    const reqData = {
      IDDiscountItemGroup: discountID,
      remarks,
    };
    if (get(discountDetailsData, 'hasDiscountArrangement')) {
      reqData.ceaseExistingArrangement = ceaseExistingArrangement === 'cease';
    }
    deactivateDiscountMethod(reqData)
      .then(resp => {
        if (resp.success) {
          this.setState(
            {
              showDeactivateModal: false,
            },
            () => {
              history.goBack();
              showSnackBarMessage(t('discount.discountDeactivationSuccess'));
            }
          );
        } else if (resp.error) {
          const errorMesage = get(resp, 'error[0].message') || DEFAULT_ERROR;
          showSnackBarMessage(errorMesage, 'error');
          this.setState({
            showDeactivateModal: false,
          });
        }
      })
      .catch(ex => {
        const errorMesage = get(ex, 'message') || DEFAULT_ERROR;
        showSnackBarMessage(errorMesage, 'error');
        this.setState({
          showDeactivateModal: false,
        });
      });
  }

  render() {
    const { discountDetails, history, t } = this.props;
    const {
      showActivateModal,
      showDeactivateModal,
      remarks,
      errors,
      effectiveDate,
      ceasedDate,
      ceaseExistingArrangement,
    } = this.state;

    if (get(discountDetails, 'inProgress')) {
      return <Loader />;
    }
    const discountDetailsData = get(discountDetails, 'data[0]');
    const isActive = get(discountDetails, 'data[0].status') === 'active';

    const crumbValues = [
      {
        label: t('common.settingsLabel'),
        link: SETTINGS,
      },
      {
        label: t('discount.discountsLabel'),
        link: DISCOUNT_SETTINGS,
      },
      {
        label: `${get(discountDetailsData, 'name', '-')}`,
        link: '/',
      },
    ];

    const isRecurrent = get(discountDetailsData, 'isRecurrent');
    const hasDiscountArrangement = get(
      discountDetailsData,
      'hasDiscountArrangement'
    );
    let keepOrCeaseDiscountOptions = null;
    if (isRecurrent && hasDiscountArrangement) {
      keepOrCeaseDiscountOptions = (
        <RadioGroupField
          name="ceaseExistingArrangement"
          label={t('common.selectActionLabel')}
          onChange={this.handleChange}
          value={ceaseExistingArrangement}
          options={[
            {
              label: 'keep',
              description: t('discount.keepCurrentDiscountArrangementLabel'),
            },
            {
              label: 'cease',
              description: t('discount.ceaseCurrentDiscountArrangementLabel'),
            },
          ]}
          required
        />
      );
    }

    return (
      <CommonPageLayout
        title={t('discount.discountsLabel')}
        breadcrumbList={crumbValues}
      >
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={0} className="w3-padding-large">
              <Grid item xs={12} md={12}>
                <Text h2>{get(discountDetailsData, 'name', '-')}</Text>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('common.type')}
                    value={capitaliseFirstLetter(
                      get(discountDetailsData, 'discountItemType', '-')
                    )}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  {isActive ? (
                    <LabelField
                      label={t('discount.effectiveDate')}
                      value={
                        get(discountDetailsData, 'effectiveDate')
                          ? moment(
                              get(discountDetailsData, 'effectiveDate')
                            ).format(DATE_FORMAT)
                          : '-'
                      }
                    />
                  ) : (
                    <DateTimePicker
                      label={t('common.effectiveDate')}
                      name="effectiveDate"
                      type="date"
                      value={effectiveDate}
                      required
                      handleDateChange={this.handleChange}
                      handleDisable={date => handleDisabledDates(date, [1])}
                      isNormal
                      variant="dialog"
                    />
                  )}
                </Grid>
                <Grid item xs={3} md={3}>
                  {isActive ? (
                    <LabelField
                      label={t('discount.obsoleteDate')}
                      value={
                        get(discountDetailsData, 'ceasedDate')
                          ? moment(
                              get(discountDetailsData, 'ceasedDate')
                            ).format(DATE_FORMAT)
                          : '-'
                      }
                    />
                  ) : (
                    <DateTimePicker
                      label={t('discount.obsoleteDate')}
                      name="ceasedDate"
                      type="date"
                      value={ceasedDate}
                      handleDateChange={this.handleChange}
                      handleDisable={date =>
                        handleDisabledDates(date, [
                          Number(moment(date._d).endOf('month').format('DD')),
                        ])
                      }
                      isNormal
                      clearable
                      variant="dialog"
                    />
                  )}
                </Grid>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('discount.registrationWaiver')}
                    value={
                      get(discountDetailsData, 'applyTo', '') ===
                      REGISTRATION_FEE
                        ? t('common.yes')
                        : t('common.no')
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('common.applicationLevel')}
                    value={capitaliseFirstLetter(
                      get(discountDetailsData, 'applicationLevel', '-')
                    )}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('common.occurenceLevel')}
                    value={
                      get(discountDetailsData, 'isRecurrent')
                        ? t('discount.recurrenceDiscount')
                        : t('discount.onetimeDiscount')
                    }
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('discount.accountCode')}
                    value={get(discountDetailsData, 'accPacCode', '-')}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('discount.gstTaxable')}
                    value={get(discountDetailsData, 'taxable') ? 'Yes' : 'No'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('common.amount')}
                    value={`$${get(discountDetailsData, 'amount', 0).toFixed(
                      2
                    )}`}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <LabelField
                    label={t('discount.applicableCentre')}
                    value={constructCenterNameString(
                      get(
                        discountDetailsData,
                        'discountItemGroupCentreRelations',
                        {}
                      )
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container className="w3-margin-top w3-margin-bottom">
                <Grid item xs={12} md={12} className="w3-right-align">
                  <Button
                    className="w3-margin-right"
                    onClick={() => history.goBack()}
                    button="secondary"
                  >
                    {t('common.cancelButton').toUpperCase()}
                  </Button>
                  <Button
                    className="w3-margin-right"
                    onClick={() =>
                      this.setState({
                        [isActive
                          ? 'showDeactivateModal'
                          : 'showActivateModal']: true,
                      })
                    }
                  >
                    {isActive
                      ? t('common.deactivateButtonLabel').toUpperCase()
                      : t('common.activateButtonLabel').toUpperCase()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {showActivateModal && (
            <Modal
              isOpen={showActivateModal}
              onPrimaryClick={this.handleDiscountActivation}
              onSecondaryClick={() => {
                this.setState({
                  showActivateModal: false,
                  errors: {},
                });
              }}
              dialogTitle={t('discount.titleOfDiscountActivationPopup')}
              dialogContent={
                <>
                  <Text>{t('discount.contentOfDiscountActivationPopup')}</Text>
                </>
              }
              fullWidth
              buttonPrimaryName={t('common.confirmButton').toUpperCase()}
              buttonSecondaryName={t('common.cancelButton').toUpperCase()}
              errors={get(errors, 'apiError')}
            />
          )}
          {showDeactivateModal && (
            <Modal
              isOpen={showDeactivateModal}
              onPrimaryClick={this.handleDeactivation}
              onSecondaryClick={() => {
                this.setState({
                  showDeactivateModal: false,
                  errors: {},
                });
              }}
              dialogTitle={t('discount.titleOfDiscountDeActivationPopup')}
              dialogContent={
                <Grid>
                  <Text>
                    {t('discount.contentOfDiscountDeActivationPopup')}
                  </Text>
                  {keepOrCeaseDiscountOptions}
                  <TextField
                    label="Remarks"
                    required
                    name="remarks"
                    value={remarks}
                    onChange={this.handleChange}
                    error={get(errors, 'remarksError')}
                  />
                </Grid>
              }
              fullWidth
              buttonPrimaryName={t('common.confirmButton').toUpperCase()}
              buttonSecondaryName={t('common.cancelButton').toUpperCase()}
              errors={get(errors, 'apiError')}
            />
          )}
        </Grid>
      </CommonPageLayout>
    );
  }
}

const mapStateToProps = state => ({
  schoolID: parseInt(get(state, 'schoolID.data', 0), 10),
  discountDetails: get(state, 'discountGroups', []),
});

const mapDispatchToProps = {
  activateDiscountMethod: activateDiscount,
  deactivateDiscountMethod: deactivateDiscount,
  fetchDiscountDetails: fetchDiscountGroups,
  showSnackBarMessage,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(DiscountDetail);
