import { get } from 'lodash';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  ADD_UPDATE_SPOUSE,
  REMOVE_CHILD_SPOUSE,
  UPDATE_CHILD_PARTICULAR,
} from '../models/childDetailsModel';

export const saveParentParticulars = data => async () => {
  try {
    const reqData = [];

    if (Object.keys(data).length === 0) {
      return {
        success: true,
      };
    }

    if (get(data, 'nationalityData', false)) {
      reqData.push({
        key: 'updateParentNationality',
        query: '{ ID }',
        variables: [data.nationalityData],
      });
    }

    if (get(data, 'maritialData', false)) {
      reqData.push({
        key: 'updateParentMaritalStatus',
        query: '{ ID }',
        variables: [data.maritialData],
      });
    }

    if (get(data, 'workingStatusData', false)) {
      reqData.push({
        key: 'updateParentWorkingStatus',
        query: '{ ID }',
        variables: [data.workingStatusData],
      });
    }
    if (get(data, 'updateEmailMobile', false)) {
      reqData.push({
        key: 'updateParentEmailOrMobile',
        query: '{ ID }',
        variables: [data.updateEmailMobile],
      });
    }

    if (get(data, 'parentNameData', false)) {
      reqData.push({
        key: 'updateParentName',
        query: '{ ID }',
        variables: [data.parentNameData],
      });
    }

    if (get(data, 'parentIdentificationNoData', false)) {
      reqData.push({
        key: 'updateParentIdentificationNo',
        query: '{ ID }',
        variables: [data.parentIdentificationNoData],
      });
    }

    if (get(data, 'parentAddressData', false)) {
      reqData.push({
        key: 'addOrUpdateParentAddress',
        query: '{ ID }',
        variables: [data.parentAddressData],
      });
    }

    if (get(data, 'parentDobData', false)) {
      reqData.push({
        key: 'updateParentBirthdate',
        query: '{ ID }',
        variables: [data.parentDobData],
      });
    }

    if (get(data, 'parentRelationData', false)) {
      reqData.push({
        key: 'updateParentRelation',
        query: '{ ID }',
        variables: [data.parentRelationData],
      });
    }

    return await SkApolloRequest({
      params: reqData,
      type: 'bulkMutation',
    });
  } catch (ex) {
    return {
      success: false,
      inProgress: false,
      error: [parseGraphQLError(ex)],
    };
  }
};

export const updateChildParticularV2 = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: UPDATE_CHILD_PARTICULAR,
        variables: reqData,
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return ex;
  }
};

export const addUpdateSpouse = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: ADD_UPDATE_SPOUSE,
        variables: reqData,
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return ex;
  }
};

export const removeChildSpouse = reqData => async () => {
  try {
    const result = await SkApolloRequest({
      params: {
        mutation: REMOVE_CHILD_SPOUSE,
        variables: reqData,
      },
      type: 'mutation',
    });

    return result;
  } catch (ex) {
    return ex;
  }
};

export default saveParentParticulars;
