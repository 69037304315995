import {
  FETCH_ALL_BUSSES,
  FETCH_ALL_BUS_DRIVER,
  FETCH_ALL_CHILDREN_FOR_BUS,
  LIST_ALL_BUSES,
  SHOW_DELETE_BUS_POPUP,
  FETCH_ALL_BUS_CHILDREN,
} from './actionTypes';
import SkRequest from './BaseRequest';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  LIST_ALL_BUS_MODEL,
  LIST_ALL_BUS_CHILDREN,
  ADD_BUS_ROUTE,
  UPDATE_BUS_DETAILS,
  DELETE_BUS_DETAILS,
  FETCH_ALL_UNASSIGNED_CHILDREN,
  ASSIGN_CHILD_TO_BUS,
  UPDATE_BUS_CHILD,
  DELETE_CHILD_FROM_BUS,
} from '../models/busManagementModels';
import localstorage from '../../utils/localStorage';
import { MutationBuilder, QueryBuilder } from '../queryBuilder';
import { parseGraphQLError } from '../../utils';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

_mQueryBuilder.setNewQuery('findAllBus', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    fkCentre: 'number',
    plateNumber: 'string',
    busChildren: {
      data: {
        ID: 'number',
        child: {
          ID: 'number',
          firstname: 'string',
          lastname: 'string',
        },
      },
    },
    updatedAt: 'datetime',
  },
});

_mMutationBuilder.setNewMutation('insertOrUpdateBusForSchool', {
  ID: 'number',
  plateNumber: 'string',
  fkSchool: 'number',
  fkCentre: 'number',
});

_mMutationBuilder.setNewMutation('removeBusById', {});

_mQueryBuilder.setNewQuery('findAllBusUser', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    fkCentre: 'number',
    firstname: 'string',
    lastname: 'string',
    isAttendant: 'boolean',
    centre: {
      ID: 'number',
      label: 'string',
      code: 'string',
    },
    mobilePhoneCountryCode: 'string',
    mobilePhone: 'string',
    createdAt: 'datetime',
  },
});

_mMutationBuilder.setNewMutation('insertBusUser', {
  ID: 'number',
});

_mMutationBuilder.setNewMutation('updateBusDriverByFields', {
  ID: 'number',
});

_mMutationBuilder.setNewMutation('deleteBusDriverByID', {});

const dispatchFetchAllBusses = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_BUSSES,
    key: 'allBusses',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchChildrenForBus = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CHILDREN_FOR_BUS,
    key: 'availableChildrenForBus',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAllBusDriver = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_BUS_DRIVER,
    key: 'all_bus_driver',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllBusData = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LIST_ALL_BUSES,
    key: 'allBusData',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllBusChildren = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_BUS_CHILDREN,
    key: 'allBusChildren',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const removeBus = async IDBus => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('removeBusById', {
          IDBus: parseInt(IDBus, 10),
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const createOrUpdateBus = async (bData, busID = 0) => {
  try {
    const saveData = { data: bData, busID: parseInt(busID, 10) };

    if (!busID) {
      delete saveData.busID;
    }

    if (!saveData.data.children || !saveData.data.children.length) {
      delete saveData.data.children;
    } else {
      saveData.children = bData.children;
      delete saveData.data.children;
    }

    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'insertOrUpdateBusForSchool',
          saveData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchAllBusses = (filter, pagination) => async dispatch => {
  dispatchFetchAllBusses(dispatch);

  try {
    const reqData = {};

    if (pagination) {
      reqData.pagination = pagination;
    }

    if (filter) {
      reqData.filter = filter;
    }

    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllBus', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchAllBusses(dispatch, false, data);
    } else {
      dispatchFetchAllBusses(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchAllBusses(dispatch, false, null, { error: ex.message });
    throw ex;
  }
};

export const fetchAllChildrenForBus = reqData => async dispatch => {
  dispatchFetchChildrenForBus(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_ALL_UNASSIGNED_CHILDREN,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchChildrenForBus(dispatch, false, data);
    } else {
      dispatchFetchChildrenForBus(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchChildrenForBus(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const fetchAllBusDriver = reqData => async dispatch => {
  dispatchFetchAllBusDriver(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllBusUser', reqData),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchAllBusDriver(dispatch, false, data);
    } else {
      dispatchFetchAllBusDriver(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchAllBusDriver(dispatch, false, null, { error: ex.message });
    throw ex;
  }
};

export const insertBusDriver = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('insertBusUser', reqData),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateBusDriver = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'updateBusDriverByFields',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteBusDriver = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'deleteBusDriverByID',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const listAllBusData = reqData => async dispatch => {
  dispatchAllBusData(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_ALL_BUS_MODEL,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAllBusData(dispatch, false, data);
    } else {
      dispatchAllBusData(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllBusData(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const createBusRoute = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_BUS_ROUTE,
        variables: {
          busDetail: reqData,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateBusRoute = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_BUS_DETAILS,
        variables: {
          data: reqData,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteBusRoute = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_BUS_DETAILS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const showDeleteBusPopUp = (sourcePath, shouldShowPopUp) => dispatch => {
  dispatch({
    type: SHOW_DELETE_BUS_POPUP,
    key: 'showDeletePopUp',
    value: {
      sourcePath,
      shouldShowPopUp,
    },
  });
};

export const listAllBusChildren = reqData => async dispatch => {
  dispatchAllBusChildren(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: LIST_ALL_BUS_CHILDREN,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAllBusChildren(dispatch, false, data);
    } else {
      dispatchAllBusChildren(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchAllBusChildren(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export const assignChildToBus = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ASSIGN_CHILD_TO_BUS,
        variables: {
          data: reqData,
        },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateChildInBus = (busChildID, data) => async () => {
  try {
    const respData = await SkApolloRequest({
      params: {
        mutation: UPDATE_BUS_CHILD,
        variables: {
          busChildID,
          data,
        },
      },
      type: 'mutation',
    });
    return respData;
  } catch (ex) {
    return ex;
  }
};

export const deleteChildFromBus = id => async () => {
  try {
    const respData = await SkApolloRequest({
      params: {
        mutation: DELETE_CHILD_FROM_BUS,
        variables: { id },
      },
      type: 'mutation',
    });
    return respData;
  } catch (ex) {
    return ex;
  }
};
