import {
  FETCH_SCHEDULED_VISITS,
  CLEAR_SCHEDULED_VISITS,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getScheduledVisitsOfChild": {
 *       "totalCount": 1,
 *       "data": [
 *         {
 *           "ID": 304,
 *           "visitFrom": "2019-12-07 12:00:00",
 *           "visitTo": "2019-12-07 12:30:00",
 *           "visitType": "centre_visit",
 *           "status": "completed",
 *           "fkEnquiry": 302,
 *           "fkCentre": 2,
 *           "fkUser": 1368,
 *           "trialPeriodFee": null,
 *           "comment": "-",
 *           "trialPeriodPaymentStatus": null,
 *           "centre": {
 *             "ID": 2,
 *             "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *           }
 *         }
 *       ]
 *     }
 *   }
 * }
 *
 *
 */

export function scheduledVisits(state = {}, action) {
  if (action.type === FETCH_SCHEDULED_VISITS) {
    if (
      action.value &&
      action.value.data &&
      action.value.data.getScheduledVisitsOfChild &&
      action.value.data.getScheduledVisitsOfChild.data
    ) {
      return Object.assign({}, state, {
        ...action.value,
        data: action.value.data.getScheduledVisitsOfChild.data,
      });
    }
    return Object.assign({}, action.value);
  }

  if (action.type === CLEAR_SCHEDULED_VISITS) {
    return Object.assign({}, action.value);
  }
  return state;
}

export default scheduledVisits;
