import { get, orderBy, round } from 'lodash';
import { AVAILABLE, WAITLIST } from '../../utils/constants';
import {
  PARENT_ENQUIRY,
  SELECTED_CENTERS,
  FIND_CENTERS,
  CLEAR_CENTRES,
  CLEAR_TCC_CENTRES,
  STORE_SEARCH_DATA,
  FETCH_AVAILABLE_DATES,
  FIND_ENROLLMENT_CENTERS,
  SET_EXPANDED_ENQUIRY_ID,
} from '../actions/actionTypes';

export const applicationDetailPanel = (state = {}, action) => {
  if (action.type === SET_EXPANDED_ENQUIRY_ID) {
    return Object.assign({}, state, {
      ...action.value,
    });
  }

  return state;
};

/**
 * Example data
 * {
 *   "data": {
 *     "findAllCentreByDistance": {
 *       "totalCount": 0
 *     }
 *   }
 * }
 *
 */

export const centerSearch = (state = {}, action) => {
  if (action.type === FIND_CENTERS) {
    const data = get(action, 'value.data.findAllCentreByDistance.data', null);

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_CENTRES) {
    return Object.assign({}, state, {
      ...action.value,
      data: {},
    });
  }

  return state;
};

export const enrollmentCenterSearch = (state = {}, action) => {
  if (action.type === FIND_ENROLLMENT_CENTERS) {
    let data = get(action, 'value.data.data.findAllEnrollmentCentresForSchool');

    if (!data) {
      data = get(action, 'value.data.data.findNearestCentresByLatLong', null);

      if (get(data, 'data.length')) {
        const modeledData = data.data.map(eachCentreData => {
          let address = '-';
          let eachCentre = eachCentreData;
          if (eachCentreData.centre) {
            eachCentre = eachCentreData.centre;
          }

          const addData = get(eachCentre, 'addresses.data[0]');
          if (addData) {
            address = `${addData.address} ${addData.city} ${addData.country} ${addData.postcode}`;
          }

          const distance = get(eachCentreData, 'distance', null);
          return {
            id: eachCentre.ID,
            name: eachCentre.label,
            code: eachCentre.code,
            distance: distance ? round(distance) : distance,
            address,
            location: addData.city,
            status: eachCentre.availability ? AVAILABLE : WAITLIST,
          };
        });

        return Object.assign(
          {},
          {
            ...action.value,
            data: modeledData,
          }
        );
      }
    }

    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }

    return Object.assign({}, action.value);
  }
  if (action.type === CLEAR_TCC_CENTRES) {
    return Object.assign({}, state, {
      data: {},
    });
  }
  return state;
};

export const enquiry = (state = {}, action) => {
  if (action.type === PARENT_ENQUIRY) {
    return Object.assign({}, state, {
      ...action.value,
      data: action.value,
    });
  }
  return state;
};

/**
 *Example Data
 * {
 *  data {
 *      date: "2020-19-11 90:00:00"
 *  }
 * }
 */

export const availableDateSlots = (state = {}, action) => {
  if (action.type === FETCH_AVAILABLE_DATES) {
    const data =
      get(action, 'value.data.getAvailableDaysOfMonth.length') &&
      get(action, 'value.data.getAvailableDaysOfMonth');
    const orderedData = orderBy(data, ['date'], ['asc']);
    if (orderedData) {
      return Object.assign({}, state, {
        ...action.value,
        data: orderedData,
      });
    }
  }
  return state;
};

export const selectedCenters = (state = [], action) => {
  if (action.type === SELECTED_CENTERS) {
    return Object.assign([], action.value);
  }
  return state;
};

export const storedSearchData = (state = {}, action) => {
  if (action.type === STORE_SEARCH_DATA) {
    return Object.assign({}, action.value);
  }
  return state;
};

export default enquiry;
