import React from 'react';
import { Tooltip, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Info, InfoOutlined } from '@material-ui/icons';
import { BASE_COLOR, ICON_SIZE, SPACING } from '../../../../theme/variables';

const StatusNoteTooltip = ({ tooltip, iconColor, outlined = false }) => {
  return (
    <Box ml={1} display="flex" alignItems="center">
      <IconPinTooltip arrow placement="top" title={tooltip}>
        {outlined ? (
          <InfoOutlineIcon color={iconColor} />
        ) : (
          <InfoTooltipIcon color={iconColor} />
        )}
      </IconPinTooltip>
    </Box>
  );
};

export default StatusNoteTooltip;

StatusNoteTooltip.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  outlined: PropTypes.bool,
};

StatusNoteTooltip.defaultProps = {
  tooltip: '',
  outlined: false,
};

const InfoTooltipIcon = styled(Info)`
  color: ${props => props.color || BASE_COLOR.neutral.main};
`;

const InfoOutlineIcon = styled(InfoOutlined)`
  color: ${props => props.color || BASE_COLOR.neutral.main};
`;

const IconPinTooltip = withStyles({
  tooltip: {
    fontSize: ICON_SIZE.small,
    padding: SPACING.padding15,
  },
})(Tooltip);
