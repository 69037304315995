import gql from 'graphql-tag';

/* ==== Query ==== */
export const GET_WITHDRAWAL_DATE_LIST = gql`
  query getWithdrawalEffectiveDateList($childId: Int!) {
    getEstimatedWithdrawalEffectiveDateList(IDChild: $childId) {
      data
    }
  }
`;

export const GET_TEMP_WITHDRAWAL_REASON = gql`
  query getTempWithdrawalReason($filter: CodeFilter) {
    findAllConfigByCategory(
      searchCategory: "tmp_withdrawal_reason"
      filter: $filter
    ) {
      data {
        ID
        label
        description
      }
    }
  }
`;

export const GET_TEMP_WITHDRAWAL_EFFECTIVE_TO = gql`
  query getTempWithdrawalEffectiveToDate(
    $startDate: Datetime!
    $endDate: Datetime
  ) {
    getTemporaryWithdrawalEndDate(startDate: $startDate, endDate: $endDate)
  }
`;

/* ==== Mutation ==== */
export const SUBMIT_WITHDRAWAL_REQUEST = gql`
  mutation submitNewWithdrawalRequest(
    $childId: Int!
    $effectiveDate: Datetime!
    $submissionDate: Datetime!
    $reasonId: Int!
    $remark: String!
    $refundDeposit: Float
    $refundFee: Float
    $otherReason: String
  ) {
    submitNewWithdrawalRequest(
      IDChild: $childId
      submissionDate: $submissionDate
      effectiveDate: $effectiveDate
      reason: $reasonId
      remark: $remark
      refundDepositAmount: $refundDeposit
      refundFeeAmount: $refundFee
      otherReason: $otherReason
    ) {
      ID
    }
  }
`;

export const SUBMIT_TEMP_WITHDRAWAL = gql`
  mutation submitTempWithdrawalData(
    $tempWithdrawalRequest: TemporaryWithdrawalDraftUpdate!
  ) {
    submitTemporaryWithdrawalRequest(
      tmpWithdrawalRequest: $tempWithdrawalRequest
    ) {
      fkTemporaryWithdrawal
      submissionDate
      effectiveFrom
      effectiveTo
      reasonForChange
      withdrawalReason
      withdrawalOtherReason
      remark
    }
  }
`;

export const GET_ALL_WITHDRAWAL_DRAFTS = gql`
  query getAllWithdrawalDrafts(
    $schoolID: Int!
    $IDCentres: [Int!]
    $fkChild: Int
    $effectiveFrom: Datetime
    $effectiveTo: Datetime
    $filter: WithdrawalDraftFilter
    $pagination: Pagination
  ) {
    getAllWithdrawalDrafts(
      schoolID: $schoolID
      IDCentres: $IDCentres
      fkChild: $fkChild
      effectiveFrom: $effectiveFrom
      effectiveTo: $effectiveTo
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        child {
          firstname
          lastname
          birthCertificate
          closestLevel {
            level {
              label
            }
            program {
              label
            }
          }
        }
        status
        additionalStatus
        centre {
          label
        }
        withdrawalDraftItem {
          refundDepositAmount
          refundFeeAmount
          refundDepositAmount
          createdAt
          effectiveDate
          submissionDate
        }
      }
    }
  }
`;

export const CANCEL_COMPLETED_WITHDRAWAL = gql`
  mutation cancelCompletedWithdrawal(
    $IDChild: Int!
    $IDDraft: Int!
    $remark: String!
    $bypassExceeding: Boolean
  ) {
    cancelCompletedWithdrawal(
      IDChild: $IDChild
      IDDraft: $IDDraft
      remark: $remark
      bypassExceeding: $bypassExceeding
    )
  }
`;

export const CHANGE_WITHDRAWAL_DATE = gql`
  mutation changeWithdrawalDate(
    $IDChild: Int!
    $IDDraft: Int!
    $newEffectiveDate: Datetime!
    $bypassExceeding: Boolean
  ) {
    changeWithdrawalDate(
      IDChild: $IDChild
      IDDraft: $IDDraft
      newEffectiveDate: $newEffectiveDate
      bypassExceeding: $bypassExceeding
    )
  }
`;

export const WITHDRAWAL_MANUAL_FORM_COMPLETE = gql`
  mutation handleWithdrawalManualFormComplete($IDDraft: Int!, $IDChild: Int!) {
    handleWithdrawalManualFormComplete(IDDraft: $IDDraft, IDChild: $IDChild)
  }
`;

export const TRANSFER_MANUAL_FORM_COMPLETE = gql`
  mutation handleTransferWithdrawalManualFormComplete($IDDraft: Int!) {
    handleTransferWithdrawalManualFormComplete(IDDraft: $IDDraft)
  }
`;
