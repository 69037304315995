import React, { Fragment } from 'react';
import { get, capitalize } from 'lodash';
import moment from 'moment';
import { Grid, Divider } from '@material-ui/core';
import CommonPageLayout from '../../../common/Page';
import { LabelField } from '../../../common/InputFields';
import Text from '../../../common/Text';
import {
  CENTRE_SETTINGS_MANAGEMENT,
  BUS_SETTINGS,
} from '../../../../routes/constants';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import { RowFieldWrapper } from '../BusForm/style';
import BusChildList from '../BusChildren/Listing';
import { Can } from '../../../../utils/ability/abilityContext';

const BusDetails = ({
  busInfo,
  showDeleteModal,
  handleDeleteBus,
  swithToEditView,
  showDeletePopUp,
  closeDeletePopUp,
  sourcePath,
  history,
  t,
}) => {
  const crumbValues = [
    {
      label: t('common.centreManagementLabel'),
      link: CENTRE_SETTINGS_MANAGEMENT,
    },
    {
      label: t('bus_management.busManagementLabel'),
      link: BUS_SETTINGS,
    },
    {
      label: t('bus_management.busDetailsLabel'),
    },
  ];

  return (
    <CommonPageLayout
      title={t('bus_management.busManagementLabel')}
      breadcrumbList={crumbValues}
    >
      <Fragment>
        <Grid container justify="space-between">
          <Grid item xs={6} md={6}>
            <Text h3>{t('bus_management.busRouteLabel')}</Text>
          </Grid>
          <Can I="write" a="bus_management">
            <Grid item>
              <Button button="secondary" onClick={swithToEditView}>
                {capitalize(t('common.edit'))}
              </Button>
            </Grid>
          </Can>
        </Grid>
        <RowFieldWrapper item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <LabelField
                label={t('bus_management.busNameLabel')}
                value={get(busInfo, 'busName')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelField
                label={t('bus_management.busCompanyLabel')}
                value={get(busInfo, 'busCompany')}
              />
            </Grid>
          </Grid>
        </RowFieldWrapper>
        <RowFieldWrapper item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <LabelField
                label={t('bus_management.driverNameLabel')}
                value={get(busInfo, 'driverName', '')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.driverContactNoLabel')}
                value={`${get(busInfo, 'driverCountryCode')} ${get(
                  busInfo,
                  'driverMobile'
                )}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.busPlateNoLabel')}
                value={get(busInfo, 'plateNumber')}
              />
            </Grid>
          </Grid>
        </RowFieldWrapper>
        <RowFieldWrapper item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
              <LabelField
                label={t('bus_management.attendantNameLabel')}
                value={get(busInfo, 'attendantName', '')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.attendantContactNoLabel')}
                value={`${get(busInfo, 'attendantCountryCode')} ${get(
                  busInfo,
                  'attendantMobile'
                )}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.attendantPasscodeLabel')}
                value={get(busInfo, 'attendantPassCode')}
              />
            </Grid>
          </Grid>
        </RowFieldWrapper>
        <RowFieldWrapper item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.directionTypeLabel')}
                value={
                  get(busInfo, 'directionType') === 'arrival'
                    ? t('bus_management.directionTypeArrival')
                    : t('bus_management.directionTypeDeparture')
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.departureTimeLabel')}
                value={moment(get(busInfo, 'year')).format('HH:mm')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('bus_management.associatedCentre')}
                value={get(busInfo, 'centre')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
              <LabelField
                label={t('common.status')}
                value={
                  get(busInfo, 'status')
                    ? t('common.active')
                    : t('common.disabled')
                }
              />
            </Grid>
          </Grid>
        </RowFieldWrapper>
        <RowFieldWrapper item xs={12}>
          <Grid item xs={3} sm={3} md={3} xl={3} lg={3}>
            <LabelField
              label={t('common.year')}
              value={moment(get(busInfo, 'year')).format('YYYY')}
            />
          </Grid>
        </RowFieldWrapper>
        <Grid container className="w3-padding-24" justify="flex-end">
          <Can I="write" a="bus_management">
            <Button button="destructive" onClick={showDeletePopUp}>
              {t('bus_management.deleteRouteLabel')}
            </Button>
          </Can>
        </Grid>
        <Divider className="w3-margin-top" />
        {showDeleteModal && (
          <Modal
            isOpen={showDeleteModal}
            onPrimaryClick={handleDeleteBus}
            onSecondaryClick={() => {
              closeDeletePopUp();
              history.push({ pathname: sourcePath });
            }}
            dialogTitle={t('bus_management.deleteRouteLabel')}
            dialogContent={
              <Text body>{t('bus_management.deleteBusDialogContent')}</Text>
            }
            fullWidth
            buttonPrimaryName={t('common.confirmButton')}
            buttonSecondaryName={t('common.cancelButton')}
          />
        )}
      </Fragment>
      {get(busInfo, 'centreID', false) && (
        <Fragment>
          <BusChildList
            centreID={get(busInfo, 'centreID')}
            busID={get(busInfo, 'busID')}
          />
        </Fragment>
      )}
    </CommonPageLayout>
  );
};

export default BusDetails;
