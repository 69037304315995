import { get } from 'lodash';
import {
  FETCH_GROUP_CATEGORY_FEE_LIST,
  FETCH_CURRENT_FEE_TIER,
  FETCH_FEE_DRAFT,
  FETCH_FEE_GROUP,
  FETCH_UPCOMING_FEE_TIER,
  FETCH_ALL_FEE_BULK_UPLOADS,
} from '../actions/actionTypes';

export const fetchFeelistCategory = (state = {}, action) => {
  if (action.type === FETCH_GROUP_CATEGORY_FEE_LIST) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllCurrentFeeTier": {
 *       "totalCount": 2,
 *       "data": [
 *         {
 *           "fkCentre": 2,
 *           "fkGroup": 5,
 *           "ID": 4,
 *           "label": ".",
 *           "effectiveForRegistrationTo": null,
 *           "effectiveForRegistrationFrom": "2018-05-01 00:00:00",
 *           "isLastTierInGroup": true,
 *           "createdAt": "2019-12-01 12:07:36",
 *           "updatedAt": "2019-12-01 12:07:36",
 *           "active": true,
 *           "pending": false,
 *           "centre": {
 *             "ID": 2,
 *             "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic",
 *             "school": {
 *               "code": "TCC",
 *               "country": "Singapore"
 *             }
 *           },
 *           "feeGroup": {
 *             "groupCode": "PUBLIC"
 *           },
 *           "feeDrafts": {
 *             "totalCount": 0,
 *             "data": []
 *           },
 *           "approvedFeeDraft": {
 *             "data": [
 *               {
 *                 "ID": 256,
 *                 "status": "approved",
 *                 "updatedAt": "2020-01-29 23:14:48",
 *                 "effectiveFrom": "2018-05-01 00:00:00",
 *                 "centre": {
 *                   "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *                 },
 *                 "feeTier": {
 *                   "feeGroup": {
 *                     "groupCode": "PUBLIC"
 *                   },
 *                   "effectiveForRegistrationTo": null,
 *                   "effectiveForRegistrationFrom": "2018-05-01 00:00:00"
 *                 },
 *                 "userByFkUser": {
 *                   "firstname": "test",
 *                   "lastname": "migration_user"
 *                 },
 *                 "userByFkApprover": {
 *                   "firstname": "test",
 *                   "lastname": "migration_user"
 *                 },
 *                 "feeDraftActivityLogs": {
 *                   "data": [
 *                     {
 *                       "status": "approved",
 *                       "remark": "migrated_fee",
 *                       "createdAt": "2018-04-18 11:05:38"
 *                     }
 *                   ]
 *                 }
 *               }
 *             ]
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export function fetchCurrentFeeTier(state = {}, action) {
  if (action.type === FETCH_CURRENT_FEE_TIER) {
    const data = get(action, 'value.data.findAllCurrentFeeTier');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
}

export function fetchUpcomingFeeTier(state = {}, action) {
  if (action.type === FETCH_UPCOMING_FEE_TIER) {
    const data = get(action, 'value.data.findAllUpcomingFeeTier');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
}

export function fetchAllFeeDraft(state = {}, action) {
  if (action.type === FETCH_FEE_DRAFT) {
    const data = get(action, 'value.data.data.findAllFeeDraft');
    if (data) {
      return Object.assign({}, state, {
        [action.value.data.key]: {
          ...action.value,
          data,
        },
      });
    }
    const reqData = get(action, 'value.data');
    if (reqData) {
      return Object.assign({}, state, {
        [action.value.data.key]: action.value,
      });
    }
  }
  return state;
}

export function fetchAllFeeGroup(state = {}, action) {
  if (action.type === FETCH_FEE_GROUP) {
    const data = get(action, 'value.data.findAllFeeGroup.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
}

export const bulkFeeUploads = (state = {}, action) => {
  if (action.type === FETCH_ALL_FEE_BULK_UPLOADS) {
    const data = get(action, 'value.data.listBulkFeeUploadRequest');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default fetchFeelistCategory;
