import gql from 'graphql-tag';

export const GENERATE_REPORT = gql`
  mutation generateReport($reportCode: String!, $requestDTO: Map!) {
    generateReport(reportCode: $reportCode, requestDTO: $requestDTO)
  }
`;

export const GENERATE_REPORT_ASYNC = gql`
  mutation generateReportAsync($reportCode: String!, $requestDTO: Map!) {
    generateReportAsync(reportCode: $reportCode, requestDTO: $requestDTO)
  }
`;

export const GET_REPORT_SCHEMA = gql`
  query getReportSchema {
    getReportSchema {
      reportName
      reportCategory
      reportCode
      fields {
        name
        jsonFieldName
        type
      }
    }
  }
`;
// todo isRequired

export const GET_REPORT_LIST = gql`
  query getReportList($pagination: Pagination) {
    getReportList(pagination: $pagination) {
      totalCount
      data {
        ID
        status
        filename
        label
        uRL
        remark
        uploadedAt
        createdAt
        presignedUrl
      }
    }
  }
`;

export const GET_ALL_MIGRATED_CHECKLST_CENTRES = gql`
  query getMigratedChecklistAllCentres($filter: ChecklistReportMigratedFilter) {
    getMigratedChecklistAllCentres(filter: $filter) {
      totalCount
      data {
        ID
        label
        centreLevels {
          totalCount
          data {
            ID
            fkLevel
            fkCentre
            level {
              ID
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_MIGRATED_CHECKLIST_CLASS_BY_CENTRE = gql`
  query getMigratedChecklistClassByCentre(
    $centerId: Int!
    $year: Int
    $levelId: Int
    $filter: ChecklistReportMigratedFilter
  ) {
    getMigratedChecklistClassByCentre(
      centreId: $centerId
      year: $year
      levelId: $levelId
      filter: $filter
    ) {
      TotalCount
      Data {
        Centre
        CentreName
        Level
        LevelName
        Class
        ClassName
      }
    }
  }
`;
