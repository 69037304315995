import React from 'react';
import { get } from 'lodash';
import Select from '@material-ui/core/Select';
import { MenuItem, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import FieldWrapper from './FieldWrapper';
import Text from '../Text';

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    & svg {
      color: ${props => props.theme.palette.common.white};
    }
  }
`;

export const MultiSelectField = ({
  label,
  name,
  value,
  disabled,
  onChange,
  options,
  error,
  onBlur,
  required,
  labelStyle,
  labelTextColor,
  emptyMenu,
  emptyMenuText = '',
  emptyMenuDisabled,
  customEmptyMenu = false,
  customHintIcon = false,
  hint,
  ...props
}) => (
  <FieldWrapper
    required={required}
    label={label}
    name={name}
    error={error}
    styles={labelStyle}
    disabled={disabled}
    labelTextColor={labelTextColor}
    hint={hint}
    customHintIcon={customHintIcon}
  >
    <Select
      multiple
      id={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      displayEmpty
      name={name}
      disabled={disabled}
      renderValue={selected =>
        selected
          .flatMap(v =>
            options.filter(o => o.label === v).map(o => o.description)
          )
          .join(', ')
      }
      {...props}
    >
      {emptyMenu && (
        <MenuItem key="" value="" disabled={emptyMenuDisabled}>
          {customEmptyMenu ? (
            <Text color="gridBox">{emptyMenuText}</Text>
          ) : (
            emptyMenuText
          )}
        </MenuItem>
      )}
      {get(options, 'length') === 0 && (
        <MenuItem key="" value="" disabled>
          No options
        </MenuItem>
      )}
      {options.map(eachOption => (
        <StyledMenuItem key={eachOption.ID} value={eachOption.label}>
          <Checkbox
            color="primary"
            checked={value && value.indexOf(eachOption.label) > -1}
            disabled={disabled}
          />
          {eachOption.description}
        </StyledMenuItem>
      ))}
    </Select>
    {emptyMenu && (
      <span className="s7t-multiselect-placeholder">
        {customEmptyMenu ? (
          <Text color="gridBox">{emptyMenuText}</Text>
        ) : (
          emptyMenuText
        )}
      </span>
    )}
  </FieldWrapper>
);
export default MultiSelectField;
