import gql from 'graphql-tag';

export const GET_FORM_SIGNER = gql`
  query getFormSigner($IDRegistration: Int!) {
    getFormSigner(IDRegistration: $IDRegistration) {
      DisplayName
      Email
      Order
      ParentID
      UserId
      Status
      IsStaff
      Type
      UpdatedAt
    }
  }
`;

export const SEND_FORM = gql`
  mutation sendForm(
    $IDRegistration: Int!
    $FormParticipants: [FormSignerDto!]!
  ) {
    sendForm(
      IDRegistration: $IDRegistration
      FormParticipants: $FormParticipants
    ) {
      ID
      fkEnquiry
    }
  }
`;

export const CANCEL_CONSENT_FORM = gql`
  mutation cancelConsentForm($IDRegistration: Int!) {
    cancelForm(IDRegistration: $IDRegistration) {
      ID
      fkEnquiry
      eDocumentID
      eDocumentStatus
    }
  }
`;

export const GET_CONSENT_UPLOAD_URL = gql`
  mutation getConsentUploadURL($IDRegistration: Int!, $file: Upload!) {
    getRegistrationConsentUploadURL(
      IDRegistration: $IDRegistration
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const SAVE_CONSENT_OFFLINE_FORM = gql`
  mutation saveConsentOfflineForm(
    $IDRegistration: Int!
    $fileUrl: String!
    $fileName: String!
  ) {
    saveRegistrationConsentOfflineForm(
      IDRegistration: $IDRegistration
      fileURL: $fileUrl
      fileName: $fileName
    )
  }
`;
