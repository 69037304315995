import { getSchoolShortName, getLogoPostfix } from 'utils';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BASE_COLOR,
  FONT,
  ICON_SIZE,
  BORDER,
  SIZE,
} from './variables';

const FONT_THEME = {
  h1: {
    fontFamily: FONT.regular,
    fontSize: '42px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSizeMobile: '20px',
    color: BASE_COLOR.neutral.black,
  },
  h2: {
    fontFamily: FONT.regular,
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  h3: {
    fontFamily: FONT.regular,
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: BASE_COLOR.neutral.black,
  },
  h4: {
    fontFamily: FONT.regular,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  h5: {
    fontFamily: FONT.regular,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  body: {
    fontFamily: FONT.regular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  body1: {
    fontFamily: FONT.regular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    color: BASE_COLOR.neutral.black,
  },
  body2: {
    fontFamily: FONT.regular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: BASE_COLOR.neutral.black,
  },
  bodyCopy: {
    fontFamily: FONT.regular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: BASE_COLOR.neutral.black,
  },
  bodyCopyTitle: {
    // body2
    fontFamily: FONT.regular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  copySmallTitle: {
    fontFamily: FONT.regular,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  copySmall: {
    fontFamily: FONT.regular,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: BASE_COLOR.text.default,
  },

  smallTitle: {
    fontFamily: FONT.regular,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  smallTitleBold: {
    // same as smallTitle
    fontFamily: FONT.regular,
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 'bold',
    color: BASE_COLOR.neutral.black,
  },
  caption: {
    fontFamily: FONT.regular,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: BASE_COLOR.neutral.black,
  },
  fontSize_20: {
    fontSize: '20px',
    color: BASE_COLOR.neutral.black,
  },
  header1: {
    fontFamily: FONT.regular,
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 600,
    color: BASE_COLOR.neutral.black,
  },
  header5: {
    fontFamily: FONT.regular,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    color: BASE_COLOR.neutral.black,
  },
};
const {
  h1,
  h2,
  h3,
  h4,
  h5,
  body,
  bodyCopyTitle,
  copySmallTitle,
  copySmall,
  smallTitle,
  smallTitleBold,
  caption,
  fontSize_20,
  header1,
  header5,
  body1,
  body2,
} = FONT_THEME;
const THEME = {
  fonts: {
    h1,
    h2,
    h3,
    h4,
    h5,
    body,
    bodyCopyTitle,
    copySmallTitle,
    copySmall,
    smallTitle,
    smallTitleBold,
    caption,
    fontSize_20,
    header1,
    header5,
    body1,
    body2,
    ...FONT,
  },
  baseColor: {
    ...BASE_COLOR,
  },
  iconSize: {
    ...ICON_SIZE,
  },
  border: {
    ...BORDER,
  },

  /** Below will be used for material UI theme */
  typography: {
    useNextVariants: true,
    h1,
    h2,
    h3,
    h4,
    h5,
    caption,
    fontSize_20,
    body1: {
      fontSize: '1rem',
      fontFamily: FONT.regular,
      fontWeight: 400,
      fontStyle: 'normal',
      color: BASE_COLOR.neutral.black,
      lineHeight: 1.5,
    },
    fontFamily: ['Lato-Regular'].join(','),
  },

  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: BASE_COLOR.neutral.white,
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: BASE_COLOR.text.default,
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          // underline color when textfield is inactive
          borderBottom: `1px solid ${BASE_COLOR.neutral.mediumGrey}`,
        },
        '&:hover:not(.Mui-disabled):not(.Mui-error):not(.Mui.focused):before': {
          borderBottom: `2px solid ${BASE_COLOR.neutral.main}`, // underline color when textfield hovered
        },
        '&:after': {
          borderBottom: `2px solid ${BASE_COLOR.neutral.main}`, // underline color when textfield hovered
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: BASE_COLOR.neutral.main,
        },
        '&.Mui-selected': {
          backgroundColor: BASE_COLOR.neutral.main,
        },
        '&.Mui-selected:hover': {
          backgroundColor: BASE_COLOR.neutral.main,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: BASE_COLOR.neutral.main,
          color: BASE_COLOR.neutral.white,
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
          color: BASE_COLOR.neutral.white,
        },
      },
      root: {
        '&.Mui-selected': {
          color: BASE_COLOR.neutral.white,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$checked': {
          color: BASE_COLOR.neutral.main,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: BASE_COLOR.neutral.main,
        },
        '&$disabled': {
          color: BASE_COLOR.neutral.grey,
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        letterSpacing: '0.25px',
        fontFamily: FONT.regular,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: FONT.regular,
      },
    },
  },
  device: {
    mobileL: `(max-width: ${SIZE.mobileL})`,
  },
  logo: `${
    process.env.REACT_APP_CDN_URL
  }/${getSchoolShortName()}/${getLogoPostfix()}`,
};

export default THEME;
