import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Text from '../Text';

export const RowWrapper = styled(Grid)`
  ${props =>
    `
        margin-bottom: 0;

        @media ${props.theme.device.mobileL} {
            margin-bottom 15px;
        }
    `}
`;

export const TotalText = styled(Text)`
  ${props =>
    `
        text-align: left;

        @media ${props.theme.device.mobileL} {
            text-align: right;
        }
    `}
`;
