import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import { FETCH_WEIGHTLIST_PARAM_TAGS } from './actionTypes';

const mQueryBuilder = new QueryBuilder();
const mMutationEnquiryBuilder = new MutationBuilder();

mQueryBuilder.setNewQuery('findAllTags', {
  data: {
    ID: 'number',
    isCustom: 'Boolean',
    weight: 'number',
    description: 'string',
    centre: {
      label: 'string',
    },
  },
});

mMutationEnquiryBuilder.setNewMutation('updateTagByFields', {
  ID: 'number',
  weight: 'number',
});

mMutationEnquiryBuilder.setNewMutation('newTag', {
  description: 'string',
});

const dispatchWeightListParamTags = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_WEIGHTLIST_PARAM_TAGS,
    key: 'waitlistParamTags',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const weightListParamTags = reqData => async dispatch => {
  dispatchWeightListParamTags(dispatch);
  try {
    const query = mQueryBuilder.getQueryAsString('findAllTags', reqData);
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchWeightListParamTags(dispatch, false, data.data);
    } else {
      dispatchWeightListParamTags(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchWeightListParamTags(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const updateWeightListTags = tags => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationEnquiryBuilder.getBulkMutationAsString({
          updateTagByFields: tags,
        }),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const addnewTag = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: mMutationEnquiryBuilder.getMutationAsString('newTag', reqData),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default weightListParamTags;
