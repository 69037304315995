import { get, cloneDeep } from 'lodash';
import {
  FETCH_EVENT_MANAGEMENT_LISTING,
  CURRENT_EVENT_CATEGORY,
  FETCH_EVENT_DETAILS,
  EVENT_RSVP_LIST,
  FETCH_GET_RSVP_COUNT,
  FETCH_RSVP_DATA,
  FETCH_EVENT_PHOTOS,
  FETCH_CURRENT_EVENT,
} from '../actions/actionTypes';

/**
 *
 * Example Data
 *
 * data: {
 *  eventById: {
 *    ID: 3
 *    cancellationDate: "2019-12-17 00:00:00"
 *    childEventFee: 0
 *    description: "Attire: Cove T-Shirt, Long pants, Cove Hat, Covered shoes↵Items to bring: Water bottle with strap ↵*More details such as the itinerary plan, indemnity form and rationale for field trip will be sent separately on paper for your viewing and acknowledgement."
 *    eventImageKey: "upload/event/3/a15-jurong-lake-gardens-playground-forest-ramble-20191211-1576044943296346745-2660.jpg"
 *    eventPhotos: {
 *      data: [{
 *         ID: 1
 *          active: true
 *          fileKey: "upload/event/3/48E315BD-3229-4804-B093-677E3917194C-20200110-1578639911759236093-1566.jpg"
 *          fkEvent: 3
 *          status: "published"
 *      }],
 *      totalCount: 1
 *    eventScopes: {
 *      data: [{
 *        centre: {
 *           ID: 2
 *           label: "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *        },
 *        class: {
 *          ID: 10
 *          fkLevel: 20
 *          label: "K1 Class"
 *        },
 *        fkCentre: 2
 *        fkClass: 10,
 *        fkEvent: 3,
 *        fkSchool: 3,
 *        school: {
 *          ID: 3
 *          name: "The Caterpillar's Cove"
 *        }
 *      }]
 *    }
 *    eventType: 1133
 *    fkApprover: 890
 *    fkCreator: 890
 *    from: "2019-12-17 09:00:33"
 *    guestCountLimit: 1
 *    guestEventFee: 2.14
 *    guestsAllowed: true
 *    guestsDetailsRequired: true
 *    location: "Jurong Lake Gardens 104 Yuan Ching Rd, Singapore 618665"
 *    parentConsentRequired: true
 *    paymentRequired: true
 *    publishDate: null
 *    rsvpDeadline: "2019-12-16 00:00:00"
 *    status: "published"
 *    termsAndConditions: "Parents will not be able to sign up after the registration deadline. Transport cost will be billed on 17th December."
 *    title: "K2 Fieldtrip to Forest Ramble"
 *    to: "2019-12-17 12:00:33"
 *    }
 *  }
 * }
 */

export const getEventPhotos = (state = {}, action) => {
  if (action.type === FETCH_EVENT_PHOTOS) {
    const data = get(action, 'value.data.eventById.eventPhotos');
    let newState = cloneDeep(state);
    newState = { ...action.value };
    if (data) {
      newState.data = data;
    }
    return newState;
  }
  return state;
};

/**
 *
 * Example Data
 *
 * data: {
 *  getEventRsvpChildList: {
 *    data: [{
 *      ID: 761,
 *      child: {
 *        ID: 29,
 *        childRelations: {
 *          data: [{
 *              ID: 974
 *              parent: {
 *              firstname: ""
 *              lastname: "Zhang Xuxiao"
 *              }
 *          }]
 *        }
 *        currentClass: {
 *          class: {
 *            label: "Nursery new class"
 *          }
 *        }
 *        firstname: ""
 *        lastname: "Melody Han Ziying"
 *      },
 *      eventRsvpGuests: {
 *        data: [{
 *          ID: 34
 *          fkRelation: 984
 *          mobilePhone: "98052453"
 *          name: "Test"
 *        }],
 *        totalCount: 2
 *      }
 *      rsvpAckFkUser: 739
 *      rsvpRemoveFkUser: null
 *      userByRsvpAckFkUser: {
 *        ID: 739
 *        firstname: ""
 *        lastname: "Zhang Xuxiao"
 *      }
 *      userByRsvpRemoveFkUser: null
 *    }]
 *    totalCount: 1
 *  }
 *  getRsvpAttendeesClassAndCentreCountByStatus: {
 *    CentreCount: 1
 *    ChildCount: 1
 *    ClassCount: 1
 *    GuestCount: 2
 *  }
 * }
 *
 */

export const getRsvpData = (state = {}, action) => {
  if (action.type === FETCH_RSVP_DATA) {
    const data = get(action, 'value.data');
    if (data) {
      const resData = {
        rsvpCount: data.getRsvpAttendeesClassAndCentreCountByStatus,
        rsvpData: data.getEventRsvpChildList,
      };
      return Object.assign({}, state, {
        ...action.value,
        data: resData,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example Data
 *
 * {
 *  data: {
 *    getDraftEvents: {
 *      data: [{
 *        ID: 25,
 *        attendeesCount: {
 *          CountMapping: null
 *        }
 *        description: "this is a test event",
 *        eventImageKey: "",
 *        eventScopes: {
 *          data: {
 *            centre: {
 *              label: "The Caterpillar's Cove @ Jurong East"
 *            }
 *            class: {
 *              label: "K1 Class"
 *            }
 *            school: {
 *               name: "The Caterpillar's Cove"
 *            }
 *          }
 *        },
 *        eventType: 1133,
 *        fkApprover: null,
 *        fkCreator: 32
 *        from: "2020-11-27 21:53:15"
 *        guestCountLimit: null
 *        parentConsentRequired: true
 *        status: "draft"
 *        title: "Pending Publish Test"
 *        to: "2020-11-27 22:53:15"
 *      }]
 *      totalCount: 1
 *    }
 *    getPastEvents: {}
 *    getPendingEvents: {}
 *    getUpcomingEvents: {}
 *  }
 * }
 */

export const getRsvpCount = (state = {}, action) => {
  if (action.type === FETCH_GET_RSVP_COUNT) {
    const data = get(
      action,
      'value.data.getRsvpStatusCountDetails.CountMapping'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const getRsvpList = (state = {}, action) => {
  if (action.type === EVENT_RSVP_LIST) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const getEventDetails = (state = {}, action) => {
  if (action.type === FETCH_EVENT_DETAILS) {
    const data = get(action, 'value.data.getAllEvents');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const fetchAllEvents = (state = {}, action) => {
  if (action.type === FETCH_EVENT_MANAGEMENT_LISTING) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const currentEvent = (state = {}, action) => {
  if (action.type === FETCH_CURRENT_EVENT) {
    const data = get(action, 'value.data.getAllEvents');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example Data
 *
 * data: {
 *  data: {
 *    getPastEvents: {
 *      data: [{
 *        ID: 25,
 *        attendeesCount: {
 *          CountMapping: null
 *        }
 *        description: "this is a test event",
 *        eventImageKey: "",
 *        eventScopes: {
 *          data: {
 *            centre: {
 *              label: "The Caterpillar's Cove @ Jurong East"
 *            }
 *            class: {
 *              label: "K1 Class"
 *            }
 *            school: {
 *               name: "The Caterpillar's Cove"
 *            }
 *          }
 *        },
 *        eventType: 1133,
 *        fkApprover: null,
 *        fkCreator: 32
 *        from: "2020-11-27 21:53:15"
 *        guestCountLimit: null
 *        parentConsentRequired: true
 *        status: "draft"
 *        title: "Pending Publish Test"
 *        to: "2020-11-27 22:53:15"
 *      }]
 *      totalCount: 1
 *    }
 *  }
 * }
 *
 */

export const fetchCurrentEvents = (state = {}, action) => {
  if (action.type === CURRENT_EVENT_CATEGORY) {
    const data = get(action, 'value.data');
    if (data.data && data.key) {
      const keyValue = get(data, 'key');
      const reqData = keyValue && data.data[keyValue];
      return Object.assign({}, state, {
        ...action.value,
        data: reqData,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default fetchAllEvents;
