import apolloUtils from '../../utils/apolloUtils';
import { FETCH_SURVEY_DETAILS_PARENT } from './actionTypes';
import {
  GET_PSS_QUESTIONS,
  GET_SURVEY_FOR_PARENT,
  SAVE_SURVEY_RESPONSE,
  PSS_SURVEY_CONFIG,
  PSS_SURVEY_NOTIFICATION_CONFIG,
} from '../models/pssModel';

const dispatchParentSurveyDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SURVEY_DETAILS_PARENT,
    key: 'pssSurveyDetailsParent',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export const fetchPssQuestions = reqData => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: GET_PSS_QUESTIONS,
      variables: {
        surveyID: reqData?.surveyID,
        childID: reqData?.childID,
      },
    });
    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const fetchSurveyDetailsForParent = reqData => async dispatch => {
  dispatchParentSurveyDetails(dispatch);
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: GET_SURVEY_FOR_PARENT,
      variables: reqData,
    });

    dispatchParentSurveyDetails(dispatch, false, result);
    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchParentSurveyDetails(dispatch, false, null, { error: ex.message });
    return { error: errorMessage };
  }
};

export const savePssSurveyResponse = reqData => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.mutate({
      mutation: SAVE_SURVEY_RESPONSE,
      variables: {
        childID: reqData?.childID,
        parentID: reqData?.parentID,
        surveyID: reqData?.surveyID,
        hashKey: reqData?.hashKey,
        surveyResponse: reqData?.surveyResponse,
        isCompleted: reqData?.isCompleted,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getPssSurveyConfig = reqData => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: PSS_SURVEY_CONFIG,
      variables: {
        childID: reqData?.childID,
        surveyID: reqData?.surveyID,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const getPssNotificationSurveyConfig = () => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: PSS_SURVEY_NOTIFICATION_CONFIG,
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};
