import {
  FETCH_ALL_BUSINESS_ENTITIES_ERROR,
  FETCH_ALL_BUSINESS_ENTITIES,
  FETCH_ALL_BUSINESS_ENTITIES_SUCCESS,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

const allBusinessEntities = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_BUSINESS_ENTITIES:
      return {
        ...state,
        inProgress: true,
        error: null,
        data: [],
      };
    case FETCH_ALL_BUSINESS_ENTITIES_ERROR:
      return {
        ...state,
        inProgress: false,
        ...action.value,
      };
    case FETCH_ALL_BUSINESS_ENTITIES_SUCCESS:
      return {
        ...state,
        inProgress: false,
        ...action.value,
      };
    default:
      return state;
  }
};

export default allBusinessEntities;
