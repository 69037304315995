import styled from 'styled-components';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Button,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

export const StyledUploadIcon = styled(PublishIcon)`
  color: ${props => props.theme.baseColor.text.label};
`;
export const StyledCard = styled(Card)`
  box-shadow: none;
`;
export const StyledButton = styled(Button)`
  text-transform: capitalize;
  color: ${props => props.theme.baseColor.text.label};
`;
export const StyledCardContent = styled(CardContent)`
  padding: 0px;
`;
export const StyledCardHeader = styled(CardHeader)`
  padding: 0px;
`;
export const StyledCardMedia = styled(CardMedia)`
  height: 190px;
`;
