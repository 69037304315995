/* eslint-disable import/prefer-default-export */
import { SHOW_CONNECT_TO_LINKPASS_MODAL } from './actionTypes';

export const showConnectToLinkpassModal = () => dispatch => {
  dispatch({
    type: SHOW_CONNECT_TO_LINKPASS_MODAL,
    value: {
      alreadyShowConnectModal: true,
    },
  });
};
