import React from 'react';
import { Grid, FormControl, InputLabel, Input } from '@material-ui/core';

const PasswordField = ({
  fieldName,
  name,
  passType,
  placeHolder,
  id,
  value,
  adornment,
  errmsg,
  passClass,
  onChange,
  disabled,
}) => (
  <Grid container justify="center" className={passClass}>
    <Grid item xs={12} className="w3-center">
      <FormControl className="s7t-margin">
        <InputLabel htmlFor="password" className="s7t-font-16">
          {fieldName}
        </InputLabel>
        <Input
          name={name}
          type={passType}
          id={id}
          value={value}
          onChange={onChange}
          endAdornment={adornment}
          placeholder={placeHolder}
          disabled={disabled}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} className="w3-center">
      <span className="s7t-error">{errmsg}</span>
    </Grid>
  </Grid>
);

export default PasswordField;
