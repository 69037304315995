import gql from 'graphql-tag';

/*= == Program Fragment == = */
export const PROGRAM_FRAGMENT = gql`
  fragment ProgramData on Program {
    ID
    label
  }
`;

/*= == Level Fragments == = */
export const LEVEL_FRAGMENT = gql`
  fragment LevelData on Level {
    ID
    label
    code
    nextLevel {
      ID
      label
    }
  }
`;
/*= == End Level Fragments == = */

/*= == User & Role Management Fragments == = */
export const ROLE_FRAGMENT = gql`
  fragment roleDetails on ListRole {
    totalCount
    data {
      ID
      label
      name
      active
      numberOfUsers
      createdAt
      updatedAt
      lastModifiedUser
      isRemovable
      user {
        firstname
        lastname
      }
      roleTemplates {
        data {
          ID
          label
          access
          moduleCategory {
            ID
            label
            moduleCategoryCode
          }
        }
      }
    }
  }
`;
/*= ==  User & Role Management Fragments  == = */

/*= == Enquiries Fragments == = */
export const ENQUIRY_FRAGMENT = gql`
  fragment EnquiryData on Enquiry {
    ID
    enrollmentPlan
    fkEnquiryState
    centre {
      ID
      label
    }
  }
`;
/*= == End Enquiries Fragments == = */

/*= == Class Management Fragments == = */
export const CHILD_CLASS_FRAGMENT = gql`
  fragment ChildClass on ChildClass {
    class {
      ID
      label
      level {
        label
      }
      centre {
        ID
        label
      }
    }
    from
  }
`;

export const CHILD_LEVEL_FRAGMENT = gql`
  fragment ChildLevel on ChildLevel {
    nextLevel {
      ID
      label
    }
    centre {
      ID
      label
      code
    }
    level {
      ...LevelData
    }
    from
  }
  ${LEVEL_FRAGMENT}
`;

// Never fetch direct enrolmentDate anymore. Fetch firstEnrolmentDate instead
export const CHILD_FRAGMENT = gql`
  fragment ChildData on Child {
    ID
    firstname
    lastname
    dateOfBirth
    birthCertificate
    enrolmentDate
    firstEnrolmentDate
    isRetained
    childCurrentEstimatedPromotionDate
    imageKey
    enrolmentStatus {
      status
      label
    }
    enquiries {
      data {
        ...EnquiryData
      }
    }
    currentClass {
      ...ChildClass
    }
    upcomingClass {
      ...ChildClass
    }
    currentLevel {
      ...ChildLevel
      program {
        ...ProgramData
      }
      to
      moveReason
    }
    upcomingLevel {
      ...ChildLevel
      program {
        ...ProgramData
      }
    }
  }
  ${CHILD_CLASS_FRAGMENT}
  ${CHILD_LEVEL_FRAGMENT}
  ${ENQUIRY_FRAGMENT}
  ${PROGRAM_FRAGMENT}
`;

export const BASIC_CHILD_FRAGMENT = gql`
  fragment BasicChildData on Child {
    ID
    firstname
    lastname
    dateOfBirth
    birthCertificate
    enrolmentDate
    firstEnrolmentDate
    imageKey
    upcomingClass {
      ...ChildClass
    }
  }
  ${CHILD_CLASS_FRAGMENT}
`;

export const CLASS_FRAGMENT = gql`
  fragment ClassData on Class {
    ID
    label
    description
    isHidden
    isDefault
    level {
      ...LevelData
    }
    centre {
      ID
      label
      code
    }
    profilePhotoKey
  }
  ${LEVEL_FRAGMENT}
`;

export const TEACHER_FRAGMENT = gql`
  fragment TeacherData on ClassTeacher {
    ID
    user {
      ID
      firstname
      lastname
    }
  }
`;
/*= == End Class Management Fragments == = */

/*= == Attendance Management Fragments == = */
export const HEALTH_CHECK_FRAGMENT = gql`
  fragment HealthCheckUps on HealthCheck {
    ID
    time
    pointX
    pointY
    updatedAt
    fkAttendance
    healthCheckType {
      type
      description
    }
    temperature
    remarks
    user {
      ID
      firstname
      lastname
    }
  }
`;

export const ATTENDANCE_FRAGMENT = gql`
  fragment Attendance on AttendanceSummary {
    totalAttendance
    totalPresent
    holiday {
      title
      description
    }
  }
`;
/*= == End Attendance Management Fragments == = */

/*= == Config Fragments == = */
export const CONFIG_FRAGMENT = gql`
  fragment Config on Code {
    ID
    fkSchool
    label
    description
    active
    createdAt
    updatedAt
    display
  }
`;
/*= == End Config Fragments == = */
/*= == Finance Management Fragments == = */
export const NOTE = gql`
  fragment note on CreditDebitNote {
    ID
    creditDebitNoteNo
    createdAt
    creditDebitNoteDate
    createdAt
    remarks
    totalAmount
    pdfUrl
  }
`;

export const BILLABLE_ITEM = gql`
  fragment billableItem on BillableItem {
    ID
    label
    unitPrice
    taxable
    type
    cdaDeductible
    displayOrder
  }
`;

export const INVOICE_ITEM = gql`
  fragment invoiceItem on InvoiceItem {
    ID
    fkInvoice
    totalAmount
    displayTotalAmount
    quantity
    taxAmount
    unitPrice
    billableItem {
      ...billableItem
    }
  }
  ${BILLABLE_ITEM}
`;

export const RECEIPT = gql`
  fragment receipt on Receipt {
    ID
    receiptNo
    amount
    amountReceived
    creditAmountUsed
    createdAt
    paymentType
    pdfUrl
    documentNo
    cancelationReason
    status
    remarks
    creditAmountUsed
    cancelledDate
    unlinkedReason
    type
  }
`;

export const INVOICE_VOID_NOTE = gql`
  fragment voidNote on InvoiceVoidNote {
    ID
    voidNoteNo
    voidRemark
    voidAt
    invoice {
      grandTotal
      invoiceType
      status
    }
  }
`;

export const INVOICE = gql`
  fragment invoiceData on Invoice {
    ID
    fkChild
    fkRegistrationChild
    invoiceNo
    totalTaxAmount
    totalAmount
    outstandingAmount
    grandTotal
    label
    remark
    status
    invoiceType
    invoiceDate
    pdfUrl
    createdAt
    invoiceItems {
      totalCount
      data {
        ...invoiceItem
      }
    }
    receipts(pagination: { sort: ["-createdAt"] }) {
      totalCount
      data {
        ...receipt
      }
    }
    creditNotes(pagination: { sort: ["-createdAt"] }) {
      totalCount
      data {
        ...note
      }
    }
    debitNotes(pagination: { sort: ["-createdAt"] }) {
      totalCount
      data {
        ...note
      }
    }
    invoiceVoidNotes(pagination: { sort: ["-createdAt"] }) {
      totalCount
      data {
        ...voidNote
      }
    }
  }
  ${INVOICE_ITEM}
  ${RECEIPT}
  ${NOTE}
  ${NOTE}
  ${INVOICE_VOID_NOTE}
`;

export const DISCOUNT_ITEM_GROUP = gql`
  fragment discountItemGroup on DiscountItemGroup {
    ID
    name
    code
    discountItemType
    effectiveDate
    ceasedDate
    isRecurrent
    amount
    taxable
    status
    applicationLevel
    discountItemGroupCentreRelations {
      data {
        centre {
          ID
          label
        }
      }
    }
  }
`;

export const DISCOUNT_FRAGMENT = gql`
  fragment Discount on DiscountArrangement {
    ID
    fkCentre
    fkChild
    fkDiscountItem
    amount
    from
    to
    isRecurrent
    active
    createdAt
    updatedAt
    discountItem {
      name
      type
      discountItemType
      startDate
      isRecurrent
      ceasedDate
      amount
      billableItem {
        taxable
      }
      discountItemGroup {
        accPacCode
        applyTo
        applicationLevel
        code
        taxable
      }
    }
  }
`;
/*= == End Finance Management Fragments == = */

/*= == Upload Info Fragment == = */
export const UPLOAD_INFO = gql`
  fragment uploadInfo on UploadInfo {
    Url
    Key
    Filename
  }
`;
/*= == End Upload Info Fragment == = */

/*= ==  Subsidy Fragments == = */
export const VOID_SUBSIDY_FRAGMENT = gql`
  fragment subsidyData on CustomSubsidyListDTO {
    totalCount
    data {
      label
      creator
      creatorID
      fkChild
      fkCentre
      idCustomSubsidy
      fkSubsidyArrangement
      fkBillableItem
      disburseMode
      mode
      amount
      editable
      voidable
      customSubsidyStatus
      status
      from
      to
      createdAt
      remarks
    }
  }
`;
/*= == End Subsidy Fragments == = */

export const APPLICANT_ENROLMENT_FRAGMENT = gql`
  fragment applicantFragment on RegistrationParentInformationDTO {
    registrationParent {
      ID
      maritalStatus
      typeOfSeparation
      firstname
      lastname
      dateOfBirth
      identificationNo
      nationality
      email
      workingStatus
      workplaceStaff
      occupationalTitle
      hasCpf
      hasNoa
      isRecentlyEmployed
      commencementDate
      salariedMonthlyIncome
      selfEmployedMonthlyIncome
      mobilePhone
      mobilePhoneCountryCode
      reasonOfUnemployment
      referredToWsg
      permanentResidenceStartDate
      expectedDeliveryDate
      siblingFullName
      siblingNationality
      siblingBc
    }
    registrationChildParent {
      fkRelationship
      isNameOnBc
    }
    registrationParentGuardian {
      firstname
      lastname
      relationship
      dateOfBirth
      nationality
      identificationNo
    }
    address {
      address
      building
      floor
      unit
      postcode
    }
  }
`;

export const SPOUSE_ENROLMENT_FRAGMENT = gql`
  fragment spouseFragment on RegistrationParentInformationDTO {
    registrationParent {
      lastname
      dateOfBirth
      identificationNo
      nationality
      workingStatus
      workplaceStaff
      occupationalTitle
      hasCpf
      hasNoa
      isRecentlyEmployed
      commencementDate
      salariedMonthlyIncome
      selfEmployedMonthlyIncome
      mobilePhone
      mobilePhoneCountryCode
      email
      permanentResidenceStartDate
    }
    registrationParentGuardian {
      firstname
      lastname
      relationship
      dateOfBirth
      nationality
      identificationNo
    }
    registrationChildParent {
      fkRelationship
    }
    address {
      address
      building
      floor
      unit
      postcode
    }
  }
`;

export const CHILD_HEALTH_FRAGMENT = gql`
  fragment ChildHealthFragment on ChildHealthInformationDto {
    immunizations {
      isImmunized
      immunizationDetails {
        disease
        exists
        remarks
      }
    }
    familyDoctorDetails {
      name
      email
      contactNumber
      countryCode
      clinicName
      clinicPostalCode
      clinicBuilding
      clinicFloor
      clinicBlockNo
      clinicUnit
    }
    physicalConditions {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    nonFoodAllergies {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    foodAllergies {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    specialNeeds {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    updatedAt
    userByUpdatedBy {
      ID
      firstname
      lastname
    }
  }
`;

export const ENRICHMENT_CLASS_SCHEDULE_FRAGMENT = gql`
  fragment EnrichmentClassScheduleFragment on EnrichmentClassSchedule {
    ID
    day
    effectiveFrom
    effectiveTo
    startTime
    endTime
    fkEnrichmentClass
  }
`;

export const ENRICHMENT_CLASS_INFO_FRAGMENT = gql`
  fragment EnrichmentClassInfoFragment on EnrichmentClass {
    ID
    fkSchool
    fkEnrichment
    fkCentre
    name
    recommendation
    status
    displayStatus
    remark
    active
    totalChildren
    childRegistrationStatus
    currentFee
    maxClassSize
    enrichment {
      vendor {
        name
      }
      enrichmentAttachments {
        totalCount
        data {
          ID
          mime
          type
          fileKey
          fileName
          fileSize
          fileURL
        }
      }
      cover
      ID
      fkVendor
      title
      type
      description
      gst
      cda
      sessionDuration
      sessionPerWeek
      minClassSize
      maxClassSize
      sessionPerMonth
      status
      remark
      enrichmentLevels {
        totalCount
        data {
          ID
          fkLevel
          fkEnrichment
          level {
            label
          }
        }
      }
      futurePrices {
        data {
          amount
          effectiveFrom
        }
      }
      currentPrice {
        amount
      }
    }
  }
`;

export const HOLIDAY_FRAGMENT = gql`
  fragment Holiday_Fragment on Holiday {
    ID
    title
    description
    holidayImageKey
    from
    to
    isPublic
    centreHolidays {
      totalCount
      data {
        centre {
          ID
        }
      }
    }
  }
`;
