import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import {
  fetchAccessibleCentreForModule,
  listAllBusData,
  deleteBusRoute,
  showSnackBarMessage,
  showDeleteBusPopUp,
} from '../../../../redux/actions';
import {
  getQueryParams,
  parseObjFields,
  replaceUrlParam,
  constructFullName,
} from '../../../../utils';
import UrlPersistenceHOC from '../../HOC/UrlPersistenceHOC';
import BusDetails from './BusDetailsView';
import { EDIT_BUS, BUS_SETTINGS } from '../../../../routes/constants';
import { DEFAULT_ERROR } from '../../../../utils/constants';
import Loader from '../../../common/Loader';

const parseQuery = () => {
  const fields = {
    centreID: 'int',
    page: 'int',
    perPage: 'int',
  };
  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      centreID: '',
      direction: '',
      status: '',
      year: '',
      page: 1,
      perPage: 10,
    },
    obj
  );
};

class BusDetailsMainComponent extends Component {
  constructor(props) {
    super(props);
    this.swithToEditView = this.swithToEditView.bind(this);
    this.showDeletePopUp = this.showDeletePopUp.bind(this);
    this.handleDeleteBus = this.handleDeleteBus.bind(this);
    this.closeDeletePopUp = this.closeDeletePopUp.bind(this);
  }

  componentDidMount() {
    const { fetchSelectedBusData, match, fetchCentres } = this.props;
    fetchCentres('bus_user');
    fetchSelectedBusData({ busEntryID: match.params.id });
  }

  componentWillUnmount() {
    const { setDeletePopUpClose } = this.props;
    setDeletePopUpClose('', false);
  }

  swithToEditView() {
    const { history, match } = this.props;
    history.push({
      pathname: replaceUrlParam(EDIT_BUS, { id: match.params.id }),
    });
  }

  showDeletePopUp() {
    const { setDeletePopUpOpen, history } = this.props;
    setDeletePopUpOpen(`${history.location.pathname}`, true);
  }

  closeDeletePopUp() {
    const { setDeletePopUpClose, history } = this.props;
    setDeletePopUpClose(`${history.location.pathname}`, false);
  }

  handleDeleteBus() {
    const {
      deleteBusDetails,
      match,
      history,
      showSnackBarMessage,
      sourcePath,
      t,
    } = this.props;
    const busEntryID = parseInt(get(match, 'params.id'), 10);
    const reqData = {
      ID: busEntryID,
    };
    deleteBusDetails(reqData)
      .then(resp => {
        if (resp.success) {
          this.closeDeletePopUp();
          const busDisabledText = t('bus_management.busEntryDeletedText');
          showSnackBarMessage(busDisabledText);
          history.push({ pathname: BUS_SETTINGS });
        } else if (resp.error) {
          const errorMesage = DEFAULT_ERROR;
          history.push({ pathname: sourcePath });
          this.closeDeletePopUp();
          showSnackBarMessage(errorMesage, 'error');
        }
      })
      .catch(() => {
        const errorMesage = DEFAULT_ERROR;
        history.push({ pathname: sourcePath });
        this.closeDeletePopUp();
        showSnackBarMessage(errorMesage, 'error');
      });
  }

  render() {
    const {
      busData,
      showDeleteModal,
      sourcePath,
      history,
      t,
      busDataIsLoading,
    } = this.props;
    const busInfo = {
      driverName: constructFullName(
        get(busData, 'busUserByFkBusDriver.firstname', ''),
        get(busData, 'busUserByFkBusDriver.lastname', '')
      ),
      driverCountryCode: get(
        busData,
        'busUserByFkBusDriver.mobilePhoneCountryCode'
      ),
      driverMobile: get(busData, 'busUserByFkBusDriver.mobilePhone'),
      driverPassCode: get(busData, 'busUserByFkBusDriver.passcode'),
      attendantName: constructFullName(
        get(busData, 'busUserByFkBusAttendant.firstname', ''),
        get(busData, 'busUserByFkBusAttendant.lastname', '')
      ),
      attendantCountryCode: get(
        busData,
        'busUserByFkBusAttendant.mobilePhoneCountryCode'
      ),
      attendantMobile: get(busData, 'busUserByFkBusAttendant.mobilePhone'),
      attendantPassCode: get(busData, 'busUserByFkBusAttendant.passcode'),
      plateNumber: get(busData, 'bus.plateNumber'),
      directionType: get(busData, 'direction'),
      year: get(busData, 'yearTime'),
      centre: get(busData, 'bus.centre.label'),
      status: get(busData, 'status'),
      busName: get(busData, 'label'),
      busCompany: get(busData, 'busCompany'),
      busID: get(busData, 'fkBus'),
      centreID: get(busData, 'bus.fkCentre'),
    };

    let renderFrag = (
      <BusDetails
        busInfo={busInfo}
        swithToEditView={this.swithToEditView}
        showDeletePopUp={this.showDeletePopUp}
        closeDeletePopUp={this.closeDeletePopUp}
        showDeleteModal={showDeleteModal}
        sourcePath={sourcePath}
        handleDeleteBus={this.handleDeleteBus}
        history={history}
        t={t}
      />
    );
    if (busDataIsLoading) {
      renderFrag = <Loader />;
    }

    return renderFrag;
  }
}

const mapStateToProps = state => ({
  busData: get(state, 'allBusData.data.data[0]'),
  centers: get(state, 'accessibleCentres', []),
  showDeleteModal: get(state, 'showDeleteBusPopUp.data.shouldShowPopUp'),
  sourcePath: get(state, 'showDeleteBusPopUp.data.sourcePath'),
  busDataIsLoading: get(state, 'allBusData.inProgress'),
});

const mapDispatchToProps = {
  fetchSelectedBusData: listAllBusData,
  fetchCentres: fetchAccessibleCentreForModule,
  deleteBusDetails: deleteBusRoute,
  showSnackBarMessage,
  setDeletePopUpClose: showDeleteBusPopUp,
  setDeletePopUpOpen: showDeleteBusPopUp,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UrlPersistenceHOC(withRouter(BusDetailsMainComponent), parseQuery));
