import moment from 'moment';
import { get } from 'lodash';
import {
  FETCH_CENTER_VACANCIES,
  FETCH_VACANCY_HISTORY,
  FETCH_MAKER_PENDING,
  STORE_VACANCY_CAP_BY_YEARS,
  RECALL_MODAL_DATA,
  REJECT_MODAL_DATA,
  UPDATE_MODAL_DATA,
  STORE_EDITED_PLANS,
  SUBMIT_PLAN_FOR_REVIEW,
} from '../actions/actionTypes';

export const rejectModalData = (state = {}, action) => {
  if (action.type === REJECT_MODAL_DATA) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const updateModalData = (state = {}, action) => {
  if (action.type === UPDATE_MODAL_DATA) {
    if (action && action.value) {
      return Object.assign({}, state, {
        data: action.value,
      });
    }
  }

  return state;
};

export const editedPlans = (state = {}, action) => {
  if (action.type === STORE_EDITED_PLANS) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const recallData = (state = {}, action) => {
  if (action.type === RECALL_MODAL_DATA) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const submitPlan = (state = {}, action) => {
  if (action.type === SUBMIT_PLAN_FOR_REVIEW) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const pendingRequest = (state = {}, action) => {
  if (action.type === FETCH_MAKER_PENDING) {
    const data = get(action, 'value.data.data.capacityDraftsByFkSchool.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, {
      ...action.value,
    });
  }

  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "capacityDraftsByFkSchool": {
 *      "data": [
 *        {
 *          "ID": 1,
 *          "fkSchool": 3,
 *          "fkCentre": 2,
 *          "status": "approved",
 *          "year": 2020,
 *          "updatedAt": "2019-12-06 11:17:18",
 *          "userByFkApprover": {
 *            "firstname": "Lian Choo Geraldine",
 *            "lastname": "Zuzarte-Teo"
 *          },
 *          "userByFkUser": {
 *            "firstname": "Hui Qin Elizabeth",
 *            "lastname": "Chua"
 *          },
 *          "capacityDraftItems": {
 *            "data": [
 *              {
 *                "fkLevel": 16,
 *                "capacity": 13,
 *                "date": "2020-01-01 00:00:00",
 *                "level": {
 *                  "label": "Infant"
 *                }
 *              },
 *            ]
 *          },
 *          "capacityDraftActivityLogs": {
 *            "data": [
 *              {
 *                "ID": 1,
 *                "remark": "Hello Geri, we input the numbers into the enrolment planning.  :)",
 *                "status": "pending",
 *                "createdAt": "2019-12-06 10:23:19"
 *              },
 *            ]
 *          }
 *        },
 *      ]
 *    }
 *  }
 *}
 *
 */

export const vacancyHistories = (state = {}, action) => {
  if (action.type === FETCH_VACANCY_HISTORY) {
    if (
      action.value.data &&
      action.value.data.data &&
      action.value.data.data.capacityDraftsByFkSchool &&
      action.value.data.data.capacityDraftsByFkSchool.data
    ) {
      const hisArr = action.value.data.data.capacityDraftsByFkSchool.data;
      const retData = [];

      hisArr.forEach(eachHisData => {
        const serviceData = [];

        eachHisData.capacityDraftItems.data.forEach(eachService => {
          serviceData.push(eachService.level.label);
        });

        retData.push({
          id: eachHisData.ID,
          fkCenter: eachHisData.fkCentre,
          service: serviceData,
          year: eachHisData.year,
          dateOfChange: eachHisData.updatedAt,
          status: eachHisData.status.toUpperCase(),
          detail: [eachHisData],
        });
      });

      return Object.assign({}, state, {
        data: {
          inProgress: action.value.inProgress,
          data: retData,
          error: null,
        },
      });
    }

    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

const modelVacancyData = vacData => {
  const vacTableData = {};
  const respKeys = Object.keys(vacData);
  const months = [];

  const vacMap = {
    enrolled: 'existingEnrolment',
    transfer_in: 'transferIn',
    transfer_out: 'transferOut',
    promotion: 'promotion',
    withdraw: 'withdraw',
    pending_registration: 'pendingRegistration',
    registered: 'enrolled',
    waiting_list: 'onWaitlist',
    available_vacancy: 'availableVacancy',
    current_capacity: 'currentCapacity',
  };

  const thisYear = new Date().getFullYear();
  const start = new Date(`1/1/${thisYear}`);

  // prep the months map values
  for (let idx = 0; idx < 12; idx++) {
    months.push(
      moment(start.valueOf()).add(idx, 'months').format('MMMM').substr(0, 3)
    );
  }

  // Just add the level key maps with months and data keys
  respKeys.forEach(eachKey => {
    const keyParts = eachKey.split('_');
    const levelName = keyParts[1];

    if (!vacTableData[levelName]) {
      vacTableData[levelName] = {};
      months.forEach(eachMonth => {
        vacTableData[levelName][eachMonth] = {
          targetEnrolmentNumber: 0,
          existingEnrolment: 0,
          transferIn: 0,
          transferOut: 0,
          promotion: 0,
          withdraw: 0,
          pendingRegistration: 0,
          enrolled: 0,
          onWaitlist: 0,
          availableVacancy: 0,
        };
      });
    }
  });

  respKeys.forEach(eachKey => {
    const keyParts = eachKey.split('_');
    const levelName = keyParts[1];
    let dataTypeKey = 'capacity';

    if (keyParts[0].indexOf('findAllVacancies') > -1) {
      dataTypeKey = 'vacancy';
    }

    if (!vacTableData[levelName]) {
      vacTableData[levelName] = {};
    }

    if (dataTypeKey === 'capacity') {
      vacData[eachKey].data.forEach(eachCapData => {
        vacTableData[levelName][
          months[eachCapData.month - 1]
        ].targetEnrolmentNumber = eachCapData.capacity;
      });
    } else {
      vacData[eachKey].Data.forEach(eachVacData => {
        vacTableData[levelName][months[eachVacData.Month - 1]][
          vacMap[eachVacData.Status]
        ] = eachVacData.Count;
      });
    }
  });

  return vacTableData;
};

/**
 *
 *
 * Example data
 *
 * {
 *  data {
 *    capacitiesByFkCentreFkLevelYear_16 {
 *      data [{
 *        ID: 85
 *        active: true
 *        capacity: 13
 *        date: "2020-01-01 00:00:00"
 *        fkCentre: 2
 *        month: 1
 *        year: 2020
 *      }]
 *    }
 *    findAllVacancies_16 {
 *      Data: [{
 *         Count: 12
 *          Month: 1
 *          Status: "current_capacity"
 *      }]
 *    }
 *  }
 * }
 *
 */

export const vacanciesByYear = (state = {}, action) => {
  if (action.type === STORE_VACANCY_CAP_BY_YEARS) {
    if (action.value && action.value.data) {
      return Object.assign({}, state, {
        inProgress: action.value.inProgress,
        [action.value.year]: modelVacancyData(action.value.data),
      });
    }

    return Object.assign({}, state, {
      inProgress: action.value.inProgress,
    });
  }

  return state;
};

export const vacancies = (state = {}, action) => {
  if (action.type === FETCH_CENTER_VACANCIES) {
    if (action.value.data && action.value.data.data) {
      return Object.assign({}, state, {
        data: {
          inProgress: action.value.inProgress,
          error: action.value.error,
          data: modelVacancyData(action.value.data.data),
        },
      });
    }

    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export default vacancies;
