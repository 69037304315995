/* eslint-disable max-len */
import { get, isEmpty, sum, set } from 'lodash';
import DatadogHandler from 'utils/datadog';
import { ApolloRequest, AxiosRequest } from 'utils/ms-utils';
import { BROADCAST_ENDPOINT } from 'utils/msEndpointConstants';
import { checkMSEnabled, isObjectId } from 'utils';
import { DEFAULT_ERROR, DEFAULT_ITEMS_PER_PAGE } from 'utils/constants';
import {
  FETCH_BROADCASTS,
  SEARCH_BROADCASTS,
  FETCH_BROADCAST_BY_ID,
  MARK_BROADCAST_AS_READ,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  GET_BROADCASTING,
  SEARCH_BROADCASTING_SCOPE,
  DELETE_BROADCASTING_BY_ID,
  MARK_BROADCAST_READ,
  GET_BROADCASTING_PHOTO_UPLOAD_URL,
  SAVE_BROADCASTING,
} from '../models/broadastModel';

const dispatchFetchBroadcasts = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchBroadcastById = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_BROADCAST_BY_ID,
    key: 'broadcastById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchBroadcasts = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchMarkBroadcastAsRead = (dispatch, broadCastID) => {
  dispatch({
    type: MARK_BROADCAST_AS_READ,
    key: 'markBroadcast',
    value: broadCastID,
  });
};

/**
 * Transform MS data to SN2 object structure
 * @param {*} response as object {
 * totalCount
 * data
 * }
 * @returns
 */
export const mapBroadcastFromMS = (response = {}) => {
  const transformScope = (broadcast, property) => {
    return Object.entries(get(broadcast, property, {})).map(([ID, label]) => ({
      [property]: Object.assign(
        {
          ID,
          label,
        },
        property === 'child' && {
          firstname: label,
        }
      ),
    }));
  };

  const result = {
    getBroadcasting: {
      totalCount: get(response, 'totalCount', 0),
      data: (get(response, 'data', []) || []).map(broadcast => ({
        ...broadcast,
        ID: broadcast.id,
        broadcastingAttachments: {
          data: get(broadcast, 'attachments', []),
        },
        broadcastingScopes: {
          data: [
            ...transformScope(broadcast, 'centre'),
            ...transformScope(broadcast, 'class'),
            ...transformScope(broadcast, 'child'),
          ],
        },
        user: {
          ...get(broadcast, 'creatorFkUser', {}),
          ID: get(broadcast, 'creatorFkUser.id'),
          userRoleRelations: {
            data: [
              {
                role: {
                  name: get(broadcast, 'creatorFkUser.role_name'),
                  label: get(broadcast, 'creatorFkUser.role_label'),
                },
              },
            ],
          },
        },
        creator: {
          ...get(broadcast, 'creatorFkUser', {}),
          ID: get(broadcast, 'creatorFkUser.id'),
        },
      })),
    },
  };
  return result;
};

/**
 * get total count data from SN2 for broadcasting
 * @param {*} reqData request params as object
 * @returns total count
 */
async function getBroadcastTotalCountFromSN2(reqData) {
  try {
    const response = await ApolloRequest({
      params: {
        query: GET_BROADCASTING,
        variables: {
          ...reqData,
          pagination: {
            ...reqData.pagination,
            perPage: 1,
          },
        },
      },
      type: 'query',
    });
    return get(response, 'data.getBroadcasting.totalCount', 0);
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return 0;
  }
}

/**
 * Fetch broadcasting list based on the filter params, combine between microservice and SN2
 * @param {*} reqData request params as object
 * @returns response
 */
export const fetchBroadcasts = reqData => async (dispatch, getState) => {
  // get MS flag config from store
  const isMSEnabled = checkMSEnabled(getState());
  dispatchFetchBroadcasts(dispatch);

  try {
    let data = {};
    let msResponse = {};
    let msResponseData = 0;
    let sn2TotalCount = 0;

    const { page = 1, perPage = DEFAULT_ITEMS_PER_PAGE, sort: sorting } = get(
      reqData,
      'pagination',
      {}
    );

    // Only fetch MS data if MS isMSEnabled flag is enable
    if (isMSEnabled) {
      const status = get(reqData, 'filter.status');
      let sort = get(sorting, '[0]', null);
      let customSort = null;

      // Transform filter for ms request
      if (get(sorting, '[0]').startsWith('-')) {
        sort = sort?.replace('-', '');
        customSort = 'desc';
      }

      // Getting the total count from SN2 for combining data & total count with ms for purpose
      sn2TotalCount = await getBroadcastTotalCountFromSN2(reqData);

      try {
        // Calling the ms to get the data based on the filter params
        msResponse = await AxiosRequest.get(BROADCAST_ENDPOINT.LIST_ALL, {
          status: Array.isArray(status) ? status.join(',') : status,
          page,
          perPage,
          sort,
          customSort,
          centre:
            get(reqData, 'centreID') || get(reqData, 'centreIDs', []).join(','),
        });
      } catch (ex) {
        msResponse = {};
        DatadogHandler.addError(ex);
        DatadogHandler.sendLog(ex, {}, 'error');
      }

      msResponseData = get(msResponse, 'data.data.length', 0);
    }

    // Combining data from SN2 incase the ms's response doesn't have data or less than perPage items
    // otherwise we'll use the data from ms
    if (msResponseData < perPage) {
      // Calling request to get data from SN2
      const graphQLResponse = await ApolloRequest({
        params: {
          query: GET_BROADCASTING,
          variables: reqData,
        },
        type: 'query',
      });

      data = (broadcasts => {
        const msData = mapBroadcastFromMS(msResponse.data);
        set(broadcasts, 'getBroadcasting.data', [
          ...get(msData, 'getBroadcasting.data', []),
          ...get(broadcasts, 'getBroadcasting.data', []).map(br => ({
            ...br,
            fromSN2: true,
          })),
        ]);
        return broadcasts;
      })(get(graphQLResponse, 'data', {}));
    } else {
      // Transforming ms's data to SN2 structure
      data = mapBroadcastFromMS(msResponse.data);
    }

    if (isMSEnabled) {
      set(
        data,
        'getBroadcasting.totalCount',
        sn2TotalCount + get(msResponse.data, 'totalCount', 0)
      );
    }

    dispatchFetchBroadcasts(dispatch, false, data);
    return data;
  } catch (ex) {
    dispatchFetchBroadcasts(dispatch, false, null, { error: ex.message });
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export const clearBroadcastSearch = () => dispatch => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress: false,
      data: [],
      error: null,
      reachedEnd: false,
    },
  });
};

/**
 * get broadcast by id, using both ms and sn2 request
 * @param {*} reqData
 * @returns
 */
export const fetchBroadcastById = reqData => async (dispatch, getState) => {
  const isMSEnabled = checkMSEnabled(getState());
  dispatchFetchBroadcastById(dispatch);
  try {
    // getting broadcastId from reqData
    const broadcastId = get(reqData, 'filter.ID');

    // isObjectId is the util function to check if the data of MS or SN2
    // incase MS record will return with id of mongodb (objectId), id of SN2 data would be an integer
    let msResponse = isObjectId(broadcastId) && isMSEnabled;

    // if it's objectId then we'll call the MS api, otherwise we'll call GraphQL api
    if (isMSEnabled && isObjectId(broadcastId)) {
      try {
        const response = await AxiosRequest.get(
          BROADCAST_ENDPOINT.BY_ID(broadcastId)
        );
        const broadcast = get(response, 'data');
        const ID = get(broadcast, 'id');
        msResponse = !!ID;
        if (ID) {
          const data = mapBroadcastFromMS({
            data: [broadcast],
            totalCount: 1,
          });
          dispatchFetchBroadcastById(dispatch, false, data);
        }
      } catch (ex) {
        msResponse = false;
        DatadogHandler.addError(ex);
        DatadogHandler.sendLog(ex, {}, 'error');
      }
    }

    if (!msResponse && !isObjectId(broadcastId)) {
      const data = await SkApolloRequest({
        params: {
          query: GET_BROADCASTING,
          variables: reqData,
        },
        type: 'query',
      });

      if (data.success) {
        set(data, 'data.getBroadcasting.data[0].fromSN2', true);
        dispatchFetchBroadcastById(dispatch, false, data.data);
      } else {
        dispatchFetchBroadcastById(dispatch, false, null, data.error);
      }
    }
  } catch (ex) {
    dispatchFetchBroadcastById(dispatch, false, null, {
      error: ex.message,
    });
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
  }
};

export const updateSearchBroadcastingEnd = (reachedEnd = false) => dispatch => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      reachedEnd,
    },
  });
};

export const searchBroadcastingScope = reqData => async dispatch => {
  const page = get(reqData, 'pagination.page', 1);
  const perPage = get(reqData, 'pagination.perPage', 10);
  if (page === 1) updateSearchBroadcastingEnd(false)(dispatch);

  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress: true,
      error: null,
    },
  });
  try {
    const data = await SkApolloRequest({
      params: {
        query: SEARCH_BROADCASTING_SCOPE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      const isLoadedMore = page > 1;
      const broadcastingScopeData = get(
        data,
        'data.searchBroadcastingScope',
        {}
      );
      const listChild = get(broadcastingScopeData, 'listChild.data', []);
      const listCentre = get(broadcastingScopeData, 'listCentre.data', []);
      const listClass = get(broadcastingScopeData, 'listClass.data', []);
      const totalBroadcastingScope = sum([
        get(broadcastingScopeData, 'listChild.totalCount', 0),
        get(broadcastingScopeData, 'listCentre.totalCount', 0),
        get(broadcastingScopeData, 'listClass.totalCount', 0),
      ]);

      if (
        isLoadedMore &&
        isEmpty(listChild) &&
        isEmpty(listClass) &&
        isEmpty(listCentre)
      ) {
        dispatch({
          type: SEARCH_BROADCASTS,
          key: 'broadcasts',
          value: {
            inProgress: false,
            error: null,
            reachedEnd: true,
          },
        });
        return;
      }
      clearBroadcastSearch(dispatch);
      if (totalBroadcastingScope < perPage && page === 1)
        updateSearchBroadcastingEnd(true)(dispatch);

      dispatchSearchBroadcasts(dispatch, false, data.data);
    } else {
      dispatchSearchBroadcasts(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchSearchBroadcasts(dispatch, false, null, { error: ex.message });
  }
};

export const saveBroadcast = reqData => async (_, getState) => {
  try {
    const isMSEnabled = checkMSEnabled(getState());
    // Transform reqBody for saving broadcast with MS
    const broadcastId = get(reqData, 'dto.IDBroadcasting');
    const reqBody = {
      id: broadcastId || null,
      attachments: (get(reqData, 'dto.attachment') || []).map(f => ({
        fileKey: f.fileKey,
        fileSize: f.fileSize,
      })),
      centreIds: (get(reqData, 'dto.scope.centreIDs') || []).map(x =>
        parseInt(x, 10)
      ),
      childIds: (get(reqData, 'dto.scope.childIDs') || []).map(x =>
        parseInt(x, 10)
      ),
      classIds: (get(reqData, 'dto.scope.classIDs') || []).map(x =>
        parseInt(x, 10)
      ),
      description: get(reqData, 'dto.description'),
      isSendEmail: get(reqData, 'dto.isSendEmail', false) || false,
      publish: get(reqData, 'publish', false) || false,
      subject: get(reqData, 'dto.subject') || '',
      from: get(reqData, 'dto.from') || null,
      to: get(reqData, 'dto.to') || null,
    };
    let data = {};

    // new broadcast would be saving with MS API then
    // if the broadcastId null or it's an objectId then we'll call the MS api, otherwise we'll call GraphQL api
    if (
      isMSEnabled &&
      (!broadcastId || (broadcastId && isObjectId(broadcastId)))
    ) {
      try {
        await AxiosRequest.post(BROADCAST_ENDPOINT.SAVE, reqBody);
        data.success = true;
      } catch (ex) {
        data.success = false;
        set(data, 'error[0]', {
          message: get(ex, 'response.data.error') || DEFAULT_ERROR,
        });
        return data;
      }
    } else {
      set(
        reqData,
        'dto.attachment',
        (get(reqData, 'dto.attachment') || []).map(item => get(item, 'fileKey'))
      );
      data = await SkApolloRequest({
        params: {
          mutation: SAVE_BROADCASTING,
          variables: reqData,
        },
        type: 'mutation',
      });
    }
    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export const deleteBroadcast = reqData => async () => {
  try {
    let data = {};
    const broadcastId = get(reqData, 'ID');
    if (broadcastId) {
      // if the broadcastId null or it's an objectId then we'll call the MS api, otherwise we'll call GraphQL api
      if (isObjectId(broadcastId)) {
        try {
          await AxiosRequest.delete(BROADCAST_ENDPOINT.DELETE(broadcastId));
          data.success = true;
          return data;
        } catch (ex) {
          data.success = false;
          set(data, 'error[0]', {
            message: get(ex, 'response.data.error') || DEFAULT_ERROR,
          });
          DatadogHandler.addError(ex);
          DatadogHandler.sendLog(ex, {}, 'error');
          return data;
        }
      } else {
        data = await SkApolloRequest({
          params: {
            mutation: DELETE_BROADCASTING_BY_ID,
            variables: reqData,
          },
          type: 'mutation',
        });
      }
    }
    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export const getBroadcastPhotoUploadURL = reqData => async (_, getState) => {
  try {
    const isMSEnabled = checkMSEnabled(getState(), 'is_ms_disabled');
    // Handle and transform reqData for MS / GraphQL
    const schoolId = get(reqData, 'IDSchool');
    const broadcastId = get(reqData, 'broadcastId');
    const reqBody = { ...reqData };
    delete reqBody.broadcastId;

    // if the broadcastId null or it's an objectId then we'll call the MS api, otherwise we'll call GraphQL api
    // to get the photo upload URL
    if (
      isMSEnabled &&
      (!broadcastId || (broadcastId && isObjectId(broadcastId)))
    ) {
      const resp = await AxiosRequest.post(
        BROADCAST_ENDPOINT.UPLOAD_ATTACHMENT(schoolId),
        get(reqData, 'file') || {}
      );
      return {
        success: true,
        data: {
          getBroadcastingPhotoUploadUrl: {
            ...resp.data,
          },
        },
      };
    }

    const data = await SkApolloRequest({
      params: {
        mutation: GET_BROADCASTING_PHOTO_UPLOAD_URL,
        variables: reqBody,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export const markBroadcastingAsRead = reqData => async dispatch => {
  try {
    const broadcastId = get(reqData, 'IDBroadcastings[0]');
    if (broadcastId) {
      // if the broadcastId's an objectId then we'll call the MS api, otherwise we'll call GraphQL api
      if (isObjectId(broadcastId)) {
        await AxiosRequest.post(BROADCAST_ENDPOINT.MARK_READ(broadcastId));
        dispatchMarkBroadcastAsRead(dispatch, broadcastId);
      } else {
        const data = await SkApolloRequest({
          params: {
            mutation: MARK_BROADCAST_READ,
            variables: reqData,
          },
          type: 'mutation',
        });

        if (data.success) {
          dispatchMarkBroadcastAsRead(dispatch, reqData.IDBroadcastings[0]);
        }
      }
    }
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};
