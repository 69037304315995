import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const RowFieldWrapper = styled(Grid)`
  margin-bottom: 2em;

  &:first-child {
    margin-top: 1.5em;
  }

  & [data-field='not-required'] {
    & .MuiFormLabel-root {
      & span {
        &:after {
          content: '*';
          padding-left: 3px;
        }
      }
    }
  }
`;

export const ActionWrapper = styled(Grid)`
  button {
    margin-left: 1.5em;
  }
`;
