import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { get } from 'lodash';
import { ListItem, Grid } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import Text from '../Text';
import {
  SVG_ICONS,
  SGT_TIME_OFFSET,
  SAVE_DATE_FORMAT,
  DATE_FORMAT_SHORT,
} from '../../../utils/constants';
import {
  TitleGrid,
  StyledDivider,
  UnreadNotificationBubble,
  NavigationArrow,
  StyledListItem,
  ListItemContainer,
  NotificationText,
  StyledLink,
} from './style';
import {
  AlertIcon,
  AnnouncementIcon,
  ApprovedIcon,
  BusIcon,
  CancelIcon,
  ClosureIcon,
  DocumentIcon,
  EventIcon,
  HealthIcon,
  PendingIcon,
  PhotoIcon,
  PromotionIcon,
  RejectedIcon,
  ReturnIcon,
  ScheduledIcon,
} from '../Icons';

const getIcon = iconName => {
  switch (iconName) {
    case SVG_ICONS.APPROVED:
      return <ApprovedIcon />;
    case SVG_ICONS.REJECTED:
      return <RejectedIcon />;
    case SVG_ICONS.ALERT:
      return <AlertIcon />;
    case SVG_ICONS.CANCEL:
      return <CancelIcon />;
    case SVG_ICONS.PENDING:
      return <PendingIcon />;
    case SVG_ICONS.RETURN:
      return <ReturnIcon />;
    case SVG_ICONS.EVENT:
      return <EventIcon />;
    case SVG_ICONS.SCHEDULED:
      return <ScheduledIcon />;
    case SVG_ICONS.CLOSURE:
      return <ClosureIcon />;
    case SVG_ICONS.BUS:
      return <BusIcon />;
    case SVG_ICONS.PROMOTION:
      return <PromotionIcon />;
    case SVG_ICONS.DOCUMENTT:
      return <DocumentIcon />;
    case SVG_ICONS.HEALTH:
      return <HealthIcon />;
    case SVG_ICONS.ANNOUNCEMENT:
      return <AnnouncementIcon />;
    case SVG_ICONS.PHOTO:
      return <PhotoIcon />;
    default:
      return <AlertIcon />;
  }
};

const getLink = link => {
  const { pathname } = window.location;
  if (pathname.includes('classmanagement/details/')) {
    return link.replace('classmanagement/details/', '');
  }
  return link;
};

const getNotificationTime = createdAt => {
  /**
   * SGT_TIME_OFFSET is SGT time using for convert client's time to server's time
   * then compare between client's time and server's time for displaying the correct fromNow's time of the notification
   */
  const currentTime = moment().utcOffset(SGT_TIME_OFFSET);
  const diffDays = moment(currentTime.format(SAVE_DATE_FORMAT)).diff(
    moment(createdAt, SAVE_DATE_FORMAT),
    'days',
    true
  );

  if (diffDays > 3)
    return moment(createdAt, SAVE_DATE_FORMAT).format(DATE_FORMAT_SHORT);

  let retTime = moment(createdAt).from(
    moment().utcOffset(SGT_TIME_OFFSET).format(SAVE_DATE_FORMAT),
    true
  );

  retTime = retTime.toLowerCase().replace(' hours', 'h');
  retTime = retTime.toLowerCase().replace(' hour', 'h');
  retTime = retTime.toLowerCase().replace(' minutes', 'm');
  retTime = retTime.toLowerCase().replace(' minute', 'm');
  retTime = retTime.toLowerCase().replace(' days', 'd');
  retTime = retTime.toLowerCase().replace('anh', 'an hr');
  retTime = retTime.toLowerCase().replace('ad', 'a day');
  retTime = retTime.toLowerCase().replace('am', 'a min');

  return retTime;
};

const getNotificationItemFrags = (data, idx) => {
  const showArrow =
    Boolean(get(data, 'meta.webLink', false)) &&
    data.meta.webLink.indexOf('%') === -1;

  return (
    <ListItemContainer isRead={data.read}>
      <StyledDivider variant="fullWidth" />
      <StyledLink to={getLink(get(data, 'meta.webLink')) || undefined}>
        <Grid item xs={2}>
          {getIcon(data.meta.iconType)}
        </Grid>
        <Grid item xs={10}>
          <StyledListItem
            key={`${idx}-${data.content.title}`}
            alignItems="flex-start"
          >
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <NotificationText bold>{data.content.title}</NotificationText>
              </Grid>
              <Grid item xs={2}>
                {!data.read && <UnreadNotificationBubble />}
              </Grid>
              <Grid item xs={12}>
                <NotificationText label>{data.content.body}</NotificationText>
              </Grid>
              <Grid item xs={12} container justify="space-between">
                <NotificationText label bold>
                  {getNotificationTime(data.createdAt)}
                </NotificationText>
                {showArrow && <NavigationArrow />}
              </Grid>
            </Grid>
          </StyledListItem>
        </Grid>
      </StyledLink>
    </ListItemContainer>
  );
};

const Notification = props => {
  const { notificationData } = props;

  if (!notificationData.length) {
    return (
      <ListItem justifyContent="center">
        <Text
          className="w3-padding"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Trans i18nKey="common.noNotifications" />
        </Text>
      </ListItem>
    );
  }

  const today = moment();
  const recentNotifications = [];
  const earlierNotifications = [];

  notificationData.forEach(item => {
    const diffDays = today.diff(item.createdAt, 'days');

    if (diffDays > 1) {
      earlierNotifications.push(item);
    } else {
      recentNotifications.push(item);
    }
  });

  const recentNotificationsLength = recentNotifications.length;
  const earlierNorificationLength = earlierNotifications.length;
  return (
    <Grid container spacing={1}>
      {Boolean(recentNotificationsLength) && (
        <>
          <TitleGrid item xs={12}>
            <Text bold>
              <Trans i18nKey="common.recent" />
            </Text>
          </TitleGrid>
          {recentNotifications.map(getNotificationItemFrags)}
        </>
      )}
      {Boolean(earlierNorificationLength) && (
        <>
          <TitleGrid item xs={12}>
            <Text bold>
              <Trans i18nKey="common.earlier" />
            </Text>
          </TitleGrid>
          {earlierNotifications.map(getNotificationItemFrags)}
        </>
      )}
    </Grid>
  );
};

export default withTheme(Notification);
