import React, { lazy, Suspense } from 'react';

const Image = lazy(() => import('../Image'));

const ImageLazyLoader = props => (
  <Suspense fallback={() => <div />}>
    <Image {...props} />
  </Suspense>
);
export default ImageLazyLoader;
