import { get } from 'lodash';
import {
  FETCH_USER_ENQUIRIES,
  CANCEL_USER_ENQUIRY,
  FETCH_ENQUIRY_STATES,
  DISPATCH_SCHEDULE_DATA,
  FETCH_AVAILABLE_TIMES,
  BOOK_VISIT,
  FETCH_CHILDREN_BY_PARENT,
  FIND_CHILDREN,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllEnquiry": {
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkCentre": 1,
 *           "fkUser": 928,
 *           "fkChild": 584,
 *           "firstname": "",
 *           "lastname": "Koo Yi Jie",
 *           "fkEnquiryState": "enrolled",
 *           "active": true,
 *           "childFirstname": "",
 *           "enrollmentPlan": "2020-06-01 00:00:00",
 *           "isTransferEnquiry": false,
 *           "documentStatus": "complete",
 *           "documentNotificationSent": false,
 *           "applicationSubmitted": false,
 *           "isTransferEnquiryReadyForEnrolment": false,
 *           "centre": {
 *             "ID": 1,
 *             "label": "The Caterpillar's Cove @ Jurong East",
 *             "fkSchool": 3
 *           },
 *           "user": {
 *             "email": "sn2dummy+4001928@gmail.com",
 *             "mobilePhone": "40000928"
 *           },
 *           "visits": {
 *             "totalCount": 2,
 *             "data": [
 *               {
 *                 "ID": 1,
 *                 "status": "completed",
 *                 "visitTo": "2019-11-01 12:30:00",
 *                 "visitFrom": "2019-11-01 12:00:00",
 *                 "visitType": "centre_visit",
 *                 "centre": {
 *                   "label": "The Caterpillar's Cove @ Jurong East"
 *                 }
 *               },
 *             ]
 *           },
 *           "enrolmentStep": "payment_1",
 *           "skipCentreVisit": false,
 *           "skipTrial": true,
 *           "vacancyAvailable": true,
 *           "vacancyStatus": "available"
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export default function (state = {}, action) {
  if (action.type === FETCH_USER_ENQUIRIES) {
    const data = get(action, 'value.data.data.findAllEnquiry.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
}

export const cancelEnquiry = (state = {}, action) => {
  if (action.type === CANCEL_USER_ENQUIRY) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const scheduleTimings = (state = {}, action) => {
  if (action.type === DISPATCH_SCHEDULE_DATA) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

export const bookVisit = (state = {}, action) => {
  if (action.type === BOOK_VISIT) {
    return Object.assign({}, state, {
      data: action.value,
    });
  }

  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getVisitTimeSlot": []
 *   }
 * }
 *
 */

export const availableEnquiryTimes = (state = {}, action) => {
  if (action.type === FETCH_AVAILABLE_TIMES) {
    return Object.assign({}, state, {
      data: action.value.data,
    });
  }

  return state;
};

/**
 *
 * {
 *  data: [{
 *     "ID": 1,,
 *     "fkUser": 928,
 *     "fkChild": 584,
 *    "firstname": "",
 *    "lastname": "Koo Yi Jie",
 *    "childFirstname": "",
 *    "childLastname": "Koo Yi Jie",
 *  }]
 * }
 *
 */

export const fetchChildrenBySchool = (state = {}, action) => {
  if (action.type === FETCH_CHILDREN_BY_PARENT) {
    return Object.assign({}, state, {
      filteredChild: action.value.data,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllChildrenByParent": {
 *       "totalCount": 1279,
 *       "data": [
 *         {
 *           "ID": 1,
 *           "firstname": "",
 *           "lastname": "Law Kar Ian, Ruth",
 *           "currentLevel": null
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const allChildrenByParent = (state = {}, action) => {
  if (action.type === FIND_CHILDREN) {
    const data = get(action, 'value.data.findAllChildrenByParent.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const enquiryStates = (state = {}, action) => {
  if (action.type === FETCH_ENQUIRY_STATES) {
    const reqData = get(action, 'value.data.enquiryData');
    if (reqData) {
      const { enquiryData, data } = action.value.data;
      const retData = enquiryData.map((item, idx) => ({
        ...data[`getEnquiryFrontendStates${idx + 1}`],
        ...item,
      }));

      return Object.assign({}, state, {
        ...action.value,
        data: retData,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};
