import moment from 'moment';
import { get } from 'lodash';
import { SAVE_DATE_FORMAT } from '../../utils/constants';
import {
  FETCH_STAFF_ATTENDANCE,
  FETCH_STAFF_ATTENDANCE_FILTERS,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getStaffAttendancesByCentre": {
 *       "total": 34,
 *       "staffAttendances": [
 *         {
 *           "staff": {
 *             "ID": 898,
 *             "firstname": "Ah Kiat",
 *             "lastname": "Lee"
 *           },
 *           "checkInOut": [
 *             {
 *               "id": 128446,
 *               "status": "verified",
 *               "source": "kiosk",
 *               "type": "check_in",
 *               "remarks": "",
 *               "time": "2020-09-09 11:50:48",
 *               "session": null,
 *               "active": true,
 *               "temperature": 0
 *             },
 *           ],
 *           "sessions": [],
 *           "date": "2020-09-09 00:00:00",
 *           "present": true,
 *           "status": "present",
 *           "remarks": "",
 *           "healthChecks": []
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const staffAttendance = (state = {}, action) => {
  if (action.type === FETCH_STAFF_ATTENDANCE) {
    const data = get(action, 'value.data.getStaffAttendancesByCentre');
    if (data) {
      const modeledData = Object.assign({}, data);
      if (get(data, 'staffAttendances.length', false)) {
        data.staffAttendances.forEach((eachStaffAttendance, idx) => {
          if (
            get(eachStaffAttendance, 'healthChecks.length', false) &&
            get(eachStaffAttendance, 'checkInOut.length', false)
          ) {
            eachStaffAttendance.checkInOut.forEach((eachCheckInOut, cioIdx) => {
              eachStaffAttendance.healthChecks.forEach(eachHealthCheck => {
                const checkInOutTime = moment(
                  modeledData.staffAttendances[idx].checkInOut[cioIdx].time,
                  SAVE_DATE_FORMAT
                );
                const healthCheckTime = moment(
                  eachHealthCheck.time,
                  SAVE_DATE_FORMAT
                );
                const delta = Math.abs(
                  checkInOutTime.diff(healthCheckTime, 'seconds')
                );
                if (delta < 6 || delta === 0) {
                  modeledData.staffAttendances[idx].checkInOut[
                    cioIdx
                  ].temperature = eachHealthCheck.temperature;
                  modeledData.staffAttendances[idx].checkInOut[cioIdx].remarks =
                    get(eachHealthCheck, 'remarks') || '-';
                }
              });
            });
          }
        });
      }

      return Object.assign(
        {},
        {
          ...action.value,
          data: modeledData,
        }
      );
    }
    return Object.assign({}, action.value);
  }

  return state;
};

export const staffAttendanceFilters = (state = {}, action) => {
  if (action.type === FETCH_STAFF_ATTENDANCE_FILTERS) {
    return Object.assign({}, action.value);
  }

  return state;
};

export default staffAttendance;
