import { get } from 'lodash';

import {
  FETCH_CENTRES_FOR_MANAGEMENT,
  FETCH_CENTRES_DRAFT_FOR_MANAGEMENT,
  FETCH_CENTRE_DOCS,
  FETCH_CENTRE_DOC_CATEGORIES,
  GET_CENTRE_LICENSE_DOCS,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "findAllCentreForSchool": {
 *      "totalCount": 2,
 *      "data": [
 *        {
 *          "ID": 1,
 *          "code": "TC2",
 *          "ecdaCode": "WP1109",
 *          "fkPrimaryEmailContact": 891,
 *          "label": "The Caterpillar's Cove @ Jurong East",
 *          "fkSchool": 3,
 *          "fkGovtScheme": null,
 *          "fkCertification": null,
 *          "teacherRatio": null,
 *          "createdAt": "2014-07-25 00:00:00",
 *          "updatedAt": "2020-10-28 09:50:30",
 *          "linkBranchID": null,
 *          "sparkExpirationDate": null,
 *          "firstLicenseDate": null,
 *          "licenseDuration": null,
 *          "firstOperationDate": null,
 *          "licenseRenewalDate": null,
 *          "licenseRenewalDuration": null,
 *          "isPublic": true,
 *          "centreWorkplaces": {
 *            "totalCount": 0,
 *            "data": []
 *          },
 *          "addresses": {
 *            "totalCount": 1,
 *            "data": [
 *              {
 *                "ID": 33,
 *                "name": "Cove @ Jurong East",
 *                "floor": "03",
 *                "address": "80, Jurong East Street 21",
 *                "building": "",
 *                "postcode": "609607",
 *                "city": "Singapore",
 *                "country": "Singapore",
 *                "lat": 1.3358266,
 *                "lng": 103.7428715
 *              }
 *            ]
 *          },
 *          "centreLevels": {
 *            "totalCount": 6,
 *            "data": [
 *              {
 *                "ID": 1,
 *                "fkLevel": 18,
 *                "createdAt": "2014-07-25 00:00:00",
 *                "updatedAt": "2019-12-01 12:07:35"
 *              },
 *            ]
 *          },
 *          "centreNearbyPostcodes": {
 *            "totalCount": 0,
 *            "data": []
 *          }
 *        },
 *      ]
 *    }
 *  }
 *}
 */
export const managementCentres = (state = {}, action) => {
  if (action.type === FETCH_CENTRES_FOR_MANAGEMENT) {
    const data = get(action, 'value.data.data.findAllCentreForSchool.data');
    const totalCount = get(
      action,
      'value.data.data.findAllCentreForSchool.totalCount',
      0
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
        totalCount,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const centreDocuments = (state = {}, action) => {
  if (action.type === FETCH_CENTRE_DOCS) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export const centreLicenseDocuments = (state = {}, action) => {
  if (action.type === GET_CENTRE_LICENSE_DOCS) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export const centreDocumentCategories = (state = {}, action) => {
  if (action.type === FETCH_CENTRE_DOC_CATEGORIES) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export const managementCentresDraft = (state = {}, action) => {
  if (action.type === FETCH_CENTRES_DRAFT_FOR_MANAGEMENT) {
    const data = get(
      action,
      'value.data.data.findAllCentreDraftForSchool.data'
    );
    const totalCount = get(
      action,
      'value.data.data.findAllCentreDraftForSchool.totalCount',
      0
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
        totalCount,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};
