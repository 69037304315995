import gql from 'graphql-tag';
import {
  CONFIG_FRAGMENT,
  DISCOUNT_ITEM_GROUP,
  DISCOUNT_FRAGMENT,
} from './commonFragments';

/** Query */
export const FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS_ITEMS = gql`
  query findAllApplicableChildDiscounts($childID: Int!) {
    findAllApplicableChildDiscounts(IDChild: $childID) {
      totalCount
      data {
        ID
        name
        fkDiscountItemGroup
        discountItemType
        type
        amount
        startDate
        ceasedDate
        applicationLevel
        isRecurrent
        active
        discountItemGroup {
          name
          code
          taxable
          applyTo
          effectiveDate
          accPacCode
        }
        centre {
          ID
          label
        }
      }
    }
  }
`;

export const FIND_ALL_CHILD_DISCOUNTS = gql`
  query getAllChildDiscounts($childID: Int!) {
    getAllChildDiscounts(IDChild: $childID) {
      totalCount
      data {
        ...Discount
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const FIND_ALL_CHILD_DISCOUNTS_REGISTRATION = gql`
  query getAllRegistrationChildDiscounts($registrationChildID: Int!) {
    getAllRegistrationChildDiscounts(
      registrationChildID: $registrationChildID
    ) {
      totalCount
      data {
        ...Discount
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS_ITEMS = gql`
  query findAllApplicableRegistrationChildDiscounts(
    $registrationChildID: Int!
  ) {
    findAllApplicableRegistrationChildDiscounts(
      registrationChildID: $registrationChildID
    ) {
      totalCount
      data {
        ID
        name
        fkDiscountItemGroup
        discountItemType
        type
        amount
        startDate
        ceasedDate
        applicationLevel
        isRecurrent
        active
        discountItemGroup {
          name
          code
          taxable
          applyTo
          effectiveDate
          accPacCode
        }
        centre {
          ID
          label
        }
      }
    }
  }
`;

export const GET_ALL_DISCOUNT_GROUPS = gql`
  query getAllDiscountItemGroup(
    $schoolID: Int!
    $name: String
    $discountItemGroupFilter: DiscountItemGroupFilter
    $pagination: Pagination
  ) {
    getAllDiscountItemGroup(
      IDSchool: $schoolID
      discountItemGroupFilter: $discountItemGroupFilter
      name: $name
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkSchool
        name
        discountItemType
        effectiveDate
        ceasedDate
        createdAt
        taxable
        amount
        status
        applicationLevel
        isRecurrent
        accPacCode
        hasDiscountArrangement
        applyTo
        discountItemGroupCentreRelations {
          data {
            centre {
              label
            }
          }
        }
      }
    }
  }
`;

export const GET_OPTIONS_CONFIG_FOR_DISCOUNT = gql`
  query getOptionsConfigForDiscount($filter: CodeFilter) {
    typeOptions: findAllConfigByCategory(
      searchCategory: "Discount_Item_Type"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    levelOptions: findAllConfigByCategory(
      searchCategory: "Discount_Application_Level"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
  }
  ${CONFIG_FRAGMENT}
`;
/** ======== */

/** Mutation */

export const ADD_DISCOUNT_ITEM_TO_CHILD = gql`
  mutation addDiscountToChild(
    $childID: Int!
    $discountItemGroupID: Int!
    $from: Datetime
    $to: Datetime
  ) {
    addDiscountToChild(
      IDChild: $childID
      IDDiscountItemGroup: $discountItemGroupID
      from: $from
      to: $to
    ) {
      ID
    }
  }
`;

export const ADD_DISCOUNT_ITEM_TO_REGISTRATION = gql`
  mutation addDiscountToRegistrationChild(
    $registrationID: Int!
    $registrationChildID: Int!
    $discountItemGroupID: Int!
    $from: Datetime
    $to: Datetime
  ) {
    addDiscountToRegistrationChild(
      registrationID: $registrationID
      registrationChildID: $registrationChildID
      IDDiscountItemGroup: $discountItemGroupID
      from: $from
      to: $to
    ) {
      ID
    }
  }
`;

export const ACTIVATE_DISCOUNT = gql`
  mutation activateDiscountItemGroup(
    $IDDiscountItemGroup: Int!
    $effectiveDate: Datetime
    $ceasedDate: Datetime
  ) {
    activateDiscountItemGroup(
      IDDiscountItemGroup: $IDDiscountItemGroup
      effectiveDate: $effectiveDate
      ceasedDate: $ceasedDate
    ) {
      ID
      name
      amount
      status
    }
  }
`;

export const DEACTIVATE_DISCOUNT = gql`
  mutation deactivateDiscountItemGroup(
    $IDDiscountItemGroup: Int!
    $remarks: String!
    $ceaseExistingArrangement: Boolean
  ) {
    deactivateDiscountItemGroup(
      IDDiscountItemGroup: $IDDiscountItemGroup
      remarks: $remarks
      ceaseExistingArrangement: $ceaseExistingArrangement
    ) {
      ID
      name
      amount
      status
    }
  }
`;

export const ADD_NEW_DISCOUNT_GROUP = gql`
  mutation addDiscountItemGroup($discountItemGroupData: DiscountItemGroupDto!) {
    addDiscountItemGroup(discountItemGroupDto: $discountItemGroupData) {
      ...discountItemGroup
    }
  }
  ${DISCOUNT_ITEM_GROUP}
`;

export const REMOVE_CHILD_DISCOUNT = gql`
  mutation removeDiscountFromChild(
    $childID: Int!
    $discountArrangementId: Int!
    $remarks: String
  ) {
    removeDiscountFromChild(
      IDChild: $childID
      IDDiscountArrangement: $discountArrangementId
      remarks: $remarks
    )
  }
`;

export const REMOVE_CHILD_DISCOUNT_FOR_REGISTRATION = gql`
  mutation removeDiscountFromRegistrationChild(
    $registrationChildID: Int!
    $discountArrangementId: Int!
    $remarks: String
  ) {
    removeDiscountFromRegistrationChild(
      registrationChildID: $registrationChildID
      IDDiscountArrangement: $discountArrangementId
      remarks: $remarks
    )
  }
`;

export const DEACTIVATE_CHILD_DISCOUNT = gql`
  mutation deactivateChildDiscount(
    $childID: Int!
    $discountArrangementId: Int!
    $remarks: String
  ) {
    deactivateChildDiscount(
      IDChild: $childID
      IDDiscountArrangement: $discountArrangementId
      remarks: $remarks
    )
  }
`;

export const DEACTIVATE_CHILD_DISCOUNT_FOR_REGISTRATION = gql`
  mutation deactivateRegistrationChildDiscount(
    $registrationChildID: Int!
    $discountArrangementId: Int!
    $remarks: String
  ) {
    deactivateRegistrationChildDiscount(
      registrationChildID: $registrationChildID
      IDDiscountArrangement: $discountArrangementId
      remarks: $remarks
    )
  }
`;

export const CHANGE_OBSELETE_DATE_CHILD_DISCOUNT = gql`
  mutation changeObsoleteDateChildDiscount(
    $IDChild: Int!
    $IDDiscountArrangement: Int!
    $to: Datetime
    $remark: String!
  ) {
    changeObsoleteDateChildDiscount(
      IDChild: $IDChild
      IDDiscountArrangement: $IDDiscountArrangement
      to: $to
      remark: $remark
    )
  }
`;

/** ======== */
