import get from 'lodash/get';
import {
  FETCH_ENRICHMENT_CLASS_DETAIL,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR,
  CLEAR_ENRICHMENT_PROGRAMME_DRAFT,
  FETCH_ENRICHMENT_CHILD_INVOICES,
  FETCH_ENRICHMENT_VENDOR_INVOICES,
  GET_ALL_CHILD_REFUND_LIST,
  FETCH_ENRICHMENT_CLASS_LIST,
  FETCH_VENDOR_PAYABLE_LIST,
  FETCH_VENDOR_PAYABLE_LIST_SUCCESS,
  FETCH_VENDOR_PAYABLE_LIST_ERROR,
  FETCH_PARENT_CLASS_INFO,
  FETCH_PARENT_CLASS_INFO_SUCCESS,
  FETCH_PARENT_CLASS_INFO_ERROR,
  FETCH_ATTACHMENT,
  FETCH_ATTACHMENT_DOCUMENT_SUCCESS,
  FETCH_ATTACHMENT_VIDEO_SUCCESS,
  FETCH_ATTACHMENT_PHOTO_SUCCESS,
  FETCH_ATTACHMENT_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

// eslint-disable-next-line import/prefer-default-export
export const enrichmentClassDetail = (state = {}, action) => {
  if (action.type === FETCH_ENRICHMENT_CLASS_DETAIL) {
    const data = get(action, 'value.data.enrichmentClassByID');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const enrichmentClassList = (state = {}, action) => {
  if (action.type === FETCH_ENRICHMENT_CLASS_LIST) {
    const data = get(action, 'value.data.getAllEnrichmentClass');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const getAllRefundChild = (state = {}, action) => {
  if (action.type === GET_ALL_CHILD_REFUND_LIST) {
    const data = get(action, 'value.data.getEnrichmentChildRefundList');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const enrichmentProgrammeDraft = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ENRICHMENT_PROGRAMME_DRAFT:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.enrichmentDraftByID'),
      };
    case FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case CLEAR_ENRICHMENT_PROGRAMME_DRAFT:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
};

export const enrichmentChildInvoices = (state = {}, action) => {
  if (action.type === FETCH_ENRICHMENT_CHILD_INVOICES) {
    const data = get(action, 'value.data.getEnrichmentChildInvoiceList');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const enrichmentVendorInvoices = (state = {}, action) => {
  if (action.type === FETCH_ENRICHMENT_VENDOR_INVOICES) {
    const data = get(action, 'value.data.getEnrichmentVendorInvoiceList');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const enrichmentVendorPayable = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_VENDOR_PAYABLE_LIST:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_VENDOR_PAYABLE_LIST_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEnrichmentVendorPaymentList'),
      };
    case FETCH_VENDOR_PAYABLE_LIST_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const fetchParentClassInfo = (state = DEFAULT_STATE, action) => {
  const data = get(action, 'value.data.getEnrichmentClassByIDAndChildID')
    ? get(action, 'value.data.getEnrichmentClassByIDAndChildID')
    : get(action, 'value.data.getEnrichmentClassesForChild');

  switch (action.type) {
    case FETCH_PARENT_CLASS_INFO:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_PARENT_CLASS_INFO_SUCCESS:
      return {
        ...state,
        ...action.value,
        data,
      };
    case FETCH_PARENT_CLASS_INFO_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const fetchEnrichmentPhoto = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ATTACHMENT:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ATTACHMENT_PHOTO_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEnrichmentAttachments'),
      };
    case FETCH_ATTACHMENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const fetchEnrichmentVideo = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ATTACHMENT:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ATTACHMENT_VIDEO_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEnrichmentAttachments'),
      };
    case FETCH_ATTACHMENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const fetchEnrichmentDocument = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ATTACHMENT:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ATTACHMENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEnrichmentAttachments'),
      };
    case FETCH_ATTACHMENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
