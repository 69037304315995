import React from 'react';
import { Grid, Avatar } from '@material-ui/core';
import get from 'lodash/get';
import Text from '../../../common/Text';

const SearchResultComponent = ({ bc, name, cdnUrl, imageKey, childClass }) => (
  <Grid container spacing={1}>
    <Grid item xs={2} className="w3-center s7t-auto-margin">
      <Avatar
        alt="child"
        src={
          imageKey
            ? `${cdnUrl}/${imageKey}`
            : `${cdnUrl}/ChildProfile/profile_pic_default.png`
        }
        width="40"
        style={{ borderRadius: '50%' }}
      >
        {get(name, '[0]')}
      </Avatar>
    </Grid>
    <Grid item xs={10}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Text body bold className="white-hover">
            {name}
          </Text>
          <Text label className="white-hover">
            {childClass}
          </Text>
        </Grid>
        <Grid item xs={12} md={6} className="s7t-auto-margin">
          <Text body className="white-hover">
            {bc}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default SearchResultComponent;
