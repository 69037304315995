import styled from 'styled-components';
import { Chip, Grid } from '@material-ui/core';

export const InputWrapper = styled('div')`
  width: auto;
  background-color: ${props => (props.noBackground ? '' : '#fff')};
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & .MuiInput-input::placeholder {
    color: ${props => props.theme.baseColor.status.draft};
  }
`;

export const CustomWrapper = styled(Grid)`
  flex: 1;
  minwidth: '200px';
`;

export const StyledChip = styled(Chip)`
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 10px;
  margin-bottom: 2px;
  padding-right: 20px;
  position: relative;
  overflow: auto;
  background-color: ${props => props.theme.baseColor.filter.tab};
  & .MuiChip-labelSmall {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
  & .MuiChip-deleteIcon {
    color: white;
    position: absolute;
    right: 0;
  }
`;

export const Listbox = styled('ul')`
  width: auto;
  overflow: auto;
  min-width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  max-height: 400px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: block;

  & li {
    width: 100%;
    position: relative;
    padding: 10px 20px;
    display: flex;
    & div {
      white-space: normal !important;
    }
    & span {
      flex-grow: 1;
    }
    & svg[data-checked='true'] {
      display: none;
    }
    & svg[data-checked='false'] {
      display: inline-block;
      color: ${props => props.theme.baseColor.neutral.mediumGrey};
      margin-right: 10px;
    }
  }

  & li[aria-selected='true'] {
    & svg[data-checked='false'] {
      display: none;
    }
    & svg[data-checked='true'] {
      display: inline-block;
      color: ${props => props.theme.baseColor.neutral.main};
      margin-right: 10px;
    }
  }
`;

export const StyledGrid = styled(Grid)` 
  border-bottom: ${props =>
    props.inputActive
      ? `2px solid ${props.theme.baseColor.text.default}`
      : `1px solid ${props.theme.baseColor.neutral.mediumGrey}`}; 
  :hover {
    border-bottom: 2px solid ${props =>
      props.inputActive
        ? props.theme.baseColor.text.default
        : props.theme.palette.primary.main};
  }
}
`;

export const StyledChipMultiSearch = styled(Chip)`
  background: ${props => props.theme.baseColor.neutral.main} !important;
`;
