import {
  FETCH_ENRICHMENT_CLASS_DETAIL,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS,
  FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR,
  CLEAR_ENRICHMENT_PROGRAMME_DRAFT,
  FETCH_ENRICHMENT_CHILD_INVOICES,
  FETCH_ENRICHMENT_VENDOR_INVOICES,
  GET_ALL_CHILD_REFUND_LIST,
  FETCH_VENDOR_PAYABLE_LIST,
  FETCH_VENDOR_PAYABLE_LIST_SUCCESS,
  FETCH_VENDOR_PAYABLE_LIST_ERROR,
  FETCH_PARENT_CLASS_INFO,
  FETCH_PARENT_CLASS_INFO_SUCCESS,
  FETCH_PARENT_CLASS_INFO_ERROR,
  FETCH_ATTACHMENT,
  FETCH_ATTACHMENT_ERROR,
  FETCH_ATTACHMENT_PHOTO_SUCCESS,
  FETCH_ATTACHMENT_VIDEO_SUCCESS,
  FETCH_ATTACHMENT_DOCUMENT_SUCCESS,
} from './actionTypes';
import {
  GET_ENRICHMENT_CLASS_DETAIL,
  GET_ENRICHMENT_PROGRAMME_DRAFT_BY_ID,
  GET_ENRICHMENT_CHILD_INVOICES,
  GET_ENRICHMENT_VENDOR_INVOICES,
  GET_ENRICHMENT_CHILD_REFUND_LIST,
  GET_VENDOR_PAYABLE_LIST,
  GET_ENRICHMENT_ATTACHMENTS,
  GET_PARENT_CLASS_DETAIL,
  GET_CLASS_INFO,
} from '../models/enrichmentProgrammeModels';
import { SkApolloRequest } from '../../utils/apolloUtils';

const dispatchClassDetail = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENRICHMENT_CLASS_DETAIL,
    key: 'enrichmentClassDetail',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const enrichmentDraftLoading = () => ({
  type: FETCH_ENRICHMENT_PROGRAMME_DRAFT,
  key: 'enrichmentProgrammeDraft',
  value: {
    inProgress: true,
  },
});

const enrichmentDraftFetchedSuccess = draftDetail => ({
  type: FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS,
  key: 'enrichmentProgrammeDraft',
  value: {
    inProgress: false,
    data: draftDetail,
  },
});

const enrichmentDraftFetchedError = error => ({
  type: FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR,
  key: 'enrichmentProgrammeDraft',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const allChildRefundList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_ALL_CHILD_REFUND_LIST,
    key: 'getAllChildRefundList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const clearEnrichmentDraft = () => dispatch => {
  dispatch({
    type: CLEAR_ENRICHMENT_PROGRAMME_DRAFT,
    key: 'enrichmentProgrammeDraft',
    value: null,
  });
};

export const fetchAllChildRefundList = reqData => async dispatch => {
  allChildRefundList(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_CHILD_REFUND_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      allChildRefundList(dispatch, false, data.data);
    } else {
      allChildRefundList(dispatch, false, data.error);
    }
    return data;
  } catch (ex) {
    allChildRefundList(dispatch, false, { error: ex.message });
    return ex;
  }
};

const dispatchChildInvoices = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENRICHMENT_CHILD_INVOICES,
    key: 'enrichmentChildInvoices',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchVendorInvoices = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ENRICHMENT_VENDOR_INVOICES,
    key: 'enrichmentVendorInvoices',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchEnrichmentChildInvoices = reqData => async dispatch => {
  dispatchChildInvoices(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_CHILD_INVOICES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchChildInvoices(dispatch, false, data.data);
    } else {
      dispatchChildInvoices(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchChildInvoices(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchEnrichmentVendorInvoices = reqData => async dispatch => {
  dispatchVendorInvoices(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_VENDOR_INVOICES,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchVendorInvoices(dispatch, false, data.data);
    } else {
      dispatchVendorInvoices(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchVendorInvoices(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchEnrichmentClassDetail = reqData => async dispatch => {
  dispatchClassDetail(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_CLASS_DETAIL,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassDetail(dispatch, false, data.data);
    } else {
      dispatchClassDetail(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchClassDetail(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchEnrichmentDraft = draftID => async dispatch => {
  dispatch(enrichmentDraftLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_PROGRAMME_DRAFT_BY_ID,
        variables: {
          draftID,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(enrichmentDraftFetchedSuccess(data.data));
    } else {
      dispatch(enrichmentDraftFetchedError(data.error));
    }
  } catch (ex) {
    dispatch(enrichmentDraftFetchedError(ex.message));
  }
};

export const handleEnrichmentUpload = (files, id) => async () => {
  try {
    const bulkMutationReqData = (files || []).map((item, index) => ({
      queryKey: `enrichmentUpload${index + 1}`,
      key: 'getEnrichmentAttachmentUploadURL',
      query: '{ Url, Key, Filename }',
      variables: [
        {
          IDVendor: id,
          file: {
            filename: item.file.name,
            filesize: item.file.size,
            mimetype: item.file.type,
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

export const handleEnrichmentVendorUpload = (
  files,
  userId,
  codeId
) => async () => {
  try {
    const bulkMutationReqData = (files || []).map((item, index) => ({
      queryKey: `enrichmentUpload${index + 1}`,
      key: 'getVendorDocumentUploadURL',
      query: '{ Url, Key, Filename }',
      variables: [
        {
          userId,
          codeId,
          file: {
            filename: item.file.name,
            filesize: item.file.size,
            mimetype: item.file.type,
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

const enrichmentVendorPayableLoading = () => ({
  type: FETCH_VENDOR_PAYABLE_LIST,
  key: 'enrichmentVendorPayable',
  value: {
    inProgress: true,
  },
});

const enrichmentVendorPayableFetchedSuccess = draftDetail => ({
  type: FETCH_VENDOR_PAYABLE_LIST_SUCCESS,
  key: 'enrichmentVendorPayable',
  value: {
    inProgress: false,
    data: draftDetail,
  },
});

const enrichmentVendorPayableFetchedError = error => ({
  type: FETCH_VENDOR_PAYABLE_LIST_ERROR,
  key: 'enrichmentVendorPayable',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const fetchEnrichmentVendorPayableList = reqData => async dispatch => {
  dispatch(enrichmentVendorPayableLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_VENDOR_PAYABLE_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(enrichmentVendorPayableFetchedSuccess(data.data));
    } else {
      dispatch(enrichmentVendorPayableFetchedError(data.error));
    }
  } catch (ex) {
    dispatch(enrichmentVendorPayableFetchedError(ex.message));
  }
};

const parentClassInfoLoading = () => ({
  type: FETCH_PARENT_CLASS_INFO,
  key: 'parentClassInfo',
  value: {
    inProgress: true,
  },
});

const parentClassInfoSuccess = classInfo => ({
  type: FETCH_PARENT_CLASS_INFO_SUCCESS,
  key: 'parentClassInfo',
  value: {
    inProgress: false,
    data: classInfo,
  },
});

const parentClassInfoError = error => ({
  type: FETCH_PARENT_CLASS_INFO_ERROR,
  key: 'parentClassInfo',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const fetchParentClassInfo = (
  reqData,
  isMyClassPage = false
) => async dispatch => {
  dispatch(parentClassInfoLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: isMyClassPage ? GET_PARENT_CLASS_DETAIL : GET_CLASS_INFO,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(parentClassInfoSuccess(data.data));
    } else {
      dispatch(parentClassInfoError(data.error));
    }
  } catch (ex) {
    dispatch(parentClassInfoError(ex.message));
  }
};

const enrichmentAttachmentsLoading = () => ({
  type: FETCH_ATTACHMENT,
  key: 'enrichmentAttachments',
  value: {
    inProgress: true,
  },
});

const enrichmentAttachmentsPhotoSuccess = attachments => ({
  type: FETCH_ATTACHMENT_PHOTO_SUCCESS,
  key: 'enrichmentAttachments',
  value: {
    inProgress: false,
    data: attachments,
  },
});

const enrichmentAttachmentsVideoSuccess = attachments => ({
  type: FETCH_ATTACHMENT_VIDEO_SUCCESS,
  key: 'enrichmentAttachments',
  value: {
    inProgress: false,
    data: attachments,
  },
});

const enrichmentAttachmentsDocumentSuccess = attachments => ({
  type: FETCH_ATTACHMENT_DOCUMENT_SUCCESS,
  key: 'enrichmentAttachments',
  value: {
    inProgress: false,
    data: attachments,
  },
});

const enrichmentAttachmentsError = error => ({
  type: FETCH_ATTACHMENT_ERROR,
  key: 'enrichmentAttachments',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const fetchAttachments = (reqData, type) => async dispatch => {
  dispatch(enrichmentAttachmentsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ENRICHMENT_ATTACHMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      switch (type) {
        case 'photo':
          dispatch(enrichmentAttachmentsPhotoSuccess(data.data));
          break;
        case 'video':
          dispatch(enrichmentAttachmentsVideoSuccess(data.data));
          break;
        case 'document':
          dispatch(enrichmentAttachmentsDocumentSuccess(data.data));
          break;
        default:
          dispatch(enrichmentAttachmentsPhotoSuccess(data.data));
          break;
      }
    } else {
      dispatch(enrichmentAttachmentsError(data.error));
    }
  } catch (ex) {
    dispatch(enrichmentAttachmentsError(ex.message));
  }
};
