import React from 'react';
import CloseIcon from '@material-ui/icons/Cancel';
import Text from '../Text';
import {
  StyledSnackBar,
  StyledCheckCircle,
  StyledErrorIcon,
  StyledMoreHorizIcon,
} from './style';
import IconButton from './iconButton';

const getMessage = (variant, message, closeAction, icon) => {
  const textMessage = <Text body>{message}</Text>;

  if (icon) {
    return (
      <>
        {icon}
        {textMessage}
        {closeAction}
      </>
    );
  }

  if (variant === 'success') {
    return (
      <>
        <div className="w3-margin-right sn2-icon-class">
          <StyledCheckCircle />
        </div>
        {textMessage}
        {closeAction}
      </>
    );
  }

  if (variant === 'error') {
    return (
      <>
        <div className="w3-margin-right sn2-icon-class">
          <StyledErrorIcon />
        </div>
        {textMessage}
        {closeAction}
      </>
    );
  }

  if (variant === 'info') {
    return (
      <>
        <div className="w3-margin-right sn2-icon-class background-transparent">
          <StyledErrorIcon />
        </div>
        {textMessage}
        {closeAction}
      </>
    );
  }

  if (variant === 'warning') {
    return (
      <>
        <div className="w3-margin-right sn2-icon-class background-transparent">
          <StyledMoreHorizIcon />
        </div>
        {textMessage}
        {closeAction}
      </>
    );
  }

  return (
    <>
      {textMessage}
      {closeAction}
    </>
  );
};

/**
 * @desc Used to show informative message to the end user.
 *
 *
 * @param boolean $open: required - boolean to show component or not
 * @param function $onClose: required - action to be performed onClose
 * @param function $onAction: required if high priority - action to be performed when clicking high priority action
 * @param string $message: required - Text that is to be shown in the Snackbar
 * @param string $variant: optional - "success"|"error"|"info"
 * @param string $priority: optional - "high"|"medium"|"low"
 * @param string $actionButtonText: required if priority is high - text to show in UI
 *
 * @return Component SnackBar
 */
export default ({
  icon,
  message,
  variant,
  priority,
  onClose,
  onAction,
  actionButtonText,
  autoHide = true,
  ...props
}) => {
  const defaultProps = {
    variant,
  };

  if (priority === 'high' && !actionButtonText) {
    throw new Error(
      'actionButtonText prop for high priority SnackBar is mandatory'
    );
  }

  if (priority === 'high' && !onAction) {
    throw new Error('onAction prop for high priority SnackBar is mandatory');
  }

  let hideDuration = 6000;
  let actionButton = null;
  let closeAction = null;

  if (priority === 'medium') {
    hideDuration = 12000;
    closeAction = (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        priority={priority}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    );
  }

  if (priority === 'high') {
    hideDuration = null;
    actionButton = (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        priority={priority}
        onClick={onAction}
      >
        {actionButtonText}
      </IconButton>
    );
  }

  if (autoHide) {
    defaultProps.autoHideDuration = hideDuration;
    defaultProps.onClose = onClose;
    defaultProps.action = actionButton;
  }

  return (
    <StyledSnackBar
      data-testid="snack-bar"
      priority={priority}
      {...props}
      {...defaultProps}
      message={getMessage(variant, message, closeAction, icon)}
    />
  );
};
