import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Text from '../Text';
import { PageContent, BreadcrumbComponent, GridWrapper } from './style';

export default ({
  title,
  pageInfoFrag,
  breadcrumbList,
  children,
  buttonsFrag = null,
  whiteBackground = true,
  className,
  snackbar,
  pageHeadFrag = null,
  customPadding,
  pageHeadFragClass,
  pageStyle,
}) => (
  <>
    <Grid container>
      {pageInfoFrag && (
        <>
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <Text h2>{title}</Text>
          </Grid>
          <GridWrapper item lg={3} md={3} sm={3} xs={12}>
            {pageInfoFrag}
          </GridWrapper>
        </>
      )}
      <Grid item xs={12}>
        {!pageInfoFrag && <Text h2>{title}</Text>}
        {breadcrumbList && (
          <BreadcrumbComponent separator=" > " aria-label="breadcrumb">
            {breadcrumbList.map((crumb, idx) =>
              idx === breadcrumbList.length - 1 ? (
                <Text label bold key={crumb.label} color="label">
                  {crumb.label}
                </Text>
              ) : (
                <Link
                  key={crumb.label}
                  className="s7t-text-decoration-none"
                  to={crumb.link}
                >
                  <Text label key={crumb.label} color="primary">
                    {crumb.label}
                  </Text>
                </Link>
              )
            )}
          </BreadcrumbComponent>
        )}
      </Grid>
    </Grid>
    {snackbar && (
      <Grid item xs={12} md={12}>
        {snackbar}
      </Grid>
    )}
    {pageHeadFrag && (
      <Grid container className={pageHeadFragClass}>
        <Grid item xs={12}>
          {pageHeadFrag}
        </Grid>
      </Grid>
    )}
    <Grid container className={className}>
      <PageContent
        whiteBG={whiteBackground}
        customPadding={customPadding}
        style={pageStyle}
      >
        <div>{children}</div>
      </PageContent>
    </Grid>
    {buttonsFrag}
  </>
);
