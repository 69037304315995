import { FETCH_APPOINTMENTS, FETCH_ALL_CALENDAR_DATA } from './actionTypes';
import { MutationBuilder } from '../queryBuilder';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import { FIND_ALL_APPOINTMENTS } from '../models/calenderModel';
import SkRequest from './BaseRequest';

const _mMutationEnquiryBuilder = new MutationBuilder();

_mMutationEnquiryBuilder.setNewMutation('addCalendarEvent', {
  ID: 'number',
});

_mMutationEnquiryBuilder.setNewMutation('cancelAvailability', {});

const dispatchCalendarData = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CALENDAR_DATA,
    key: 'allCalendarData',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAppointments = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_APPOINTMENTS,
    key: 'appointments',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchAppointments = reqData => async dispatch => {
  dispatchFetchAppointments(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_APPOINTMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchAppointments(dispatch, false, { data: data.data });
    } else {
      dispatchFetchAppointments(dispatch, false, null, data.error);
    }

    return data.data;
  } catch (ex) {
    dispatchFetchAppointments(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};

export const createAppointment = reqData => async () => {
  const query = _mMutationEnquiryBuilder.getMutationAsString(
    'addCalendarEvent',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelAvailibility = reqData => async () => {
  const query = _mMutationEnquiryBuilder.getMutationAsString(
    'cancelAvailability',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchAllCalendarData = reqData => async dispatch => {
  dispatchCalendarData(dispatch);
  try {
    const data = await SkApolloRequest({
      params: reqData,
      type: 'bulkQuery',
    });
    if (data.success) {
      dispatchCalendarData(dispatch, false, { data: data.data });
    } else {
      dispatchCalendarData(dispatch, false, null, data.error);
    }

    return data.data;
  } catch (ex) {
    dispatchCalendarData(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
    return parseGraphQLError(ex);
  }
};
