import { get } from 'lodash';
import { FETCH_SURVEY_DETAILS_PARENT } from '../actions/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const parentSatisfactionSurveyDetails = (state = {}, action) => {
  if (action.type === FETCH_SURVEY_DETAILS_PARENT) {
    const data = get(action, 'value.data.getAllRegistrationChildDiscounts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};
