import { AxiosHelper } from './ms-utils';

const BROADCAST = {
  LIST_ALL: '',
  BY_ID: id => `/id/${id}`,
  LIST_BY_CHILD_ID: childId => `/child/${childId}`,
  RECALL: id => `/recall/${id}`,
  SAVE: `/save`,
  MARK_READ: id => `/read/${id}`,
  DELETE: id => `/id/${id}`,
  UPLOAD_ATTACHMENT: schoolId =>
    `/attachment/get-signed-upload-url/${schoolId}`,
};

const NOTIFICATION = {
  LIST: '',
  MARK_READ: '/mark-as-read',
};

export const BROADCAST_ENDPOINT = BROADCAST;
export const NOTIFICATION_ENDPOINT = NOTIFICATION;

// Axios instance for notification service, will update when MS supports API Gateway
export const notificationMsInstance = AxiosHelper({
  baseURL: process.env.REACT_APP_MS_NOTIFICATION_URL,
});
