/* eslint-disable import/no-unresolved */

const getConfig = () => {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page,
  //  if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const schoolID = parseInt(process.env.REACT_APP_SCHOOL_ID, 10);

  return {
    domain: process.env.REACT_APP_LINKPASS_DOMAIN,
    clientId:
      schoolID === 1
        ? process.env.REACT_APP_LINKPASS_MFS_CLIENT_ID
        : process.env.REACT_APP_LINKPASS_LSH_CLIENT_ID,
    appOrigin: process.env.REACT_APP_PARENT_PORTAL,
    redirectUri: `${process.env.REACT_APP_PARENT_PORTAL}/rpc/lp-sso-callback`,
  };
};

export default getConfig;
