import { get } from 'lodash';
import { FETCH_ALL_MANAGEMENT_LEVELS } from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getLevelsForSchool": {
 *       "data": [
 *         {
 *           "ID": 16,
 *           "label": "Infant",
 *           "fromMonth": 2,
 *           "toMonth": 18,
 *           "promotionDate": null,
 *           "updatedAt": "2019-12-01 12:07:35",
 *           "offOnSaturday": true,
 *           "isInfant": true
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const managementLevels = (state = {}, action) => {
  if (action.type === FETCH_ALL_MANAGEMENT_LEVELS) {
    const data = get(action, 'value.data.data.getLevelsForSchool.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export default managementLevels;
