import { get } from 'lodash';
import {
  FETCH_NOTIFICATIONS_HISTORY,
  SHOW_SNACKBAR_MESSAGE,
  HIDE_SNACKBAR_MESSAGE,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getNotifications": {
 *       "totalCount": 10,
 *       "data": [
 *         {
 *           "ID": 243809,
 *           "navigate": "Blacklist",
 *           "channel": "app",
 *           "content": {
 *             "title": "Dear principal",
 *             "body": "Your request to add Hannah Felise Lim to special handling is approved"
 *           },
 *           "meta": {
 *             "iconType": "default",
 *             "webLink": ""
 *           },
 *           "read": true,
 *           "createdAt": "2020-12-02 09:24:48"
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const getNotificationHistory = (state = {}, action) => {
  if (action.type === FETCH_NOTIFICATIONS_HISTORY) {
    const data = get(action, 'value.data.data.getNotifications');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

const DEFAULT_SNACKBAR_MSG = {
  data: {
    visibility: false,
    snackBarInfo: null,
  },
};

export const snackBarMessage = (state = DEFAULT_SNACKBAR_MSG, action) => {
  const data = get(action, 'value');

  switch (action.type) {
    case SHOW_SNACKBAR_MESSAGE:
      return {
        ...state,
        data: {
          visibility: true,
          snackBarInfo: {
            ...data,
          },
        },
      };
    case HIDE_SNACKBAR_MESSAGE:
      return DEFAULT_SNACKBAR_MSG;
    default:
      return state;
  }
};

export default getNotificationHistory;
