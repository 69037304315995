import { get } from 'lodash';
import {
  FETCH_ALL_REPORT_SCHEMA,
  FETCH_ALL_REPORT_LIST,
  FETCH_ALL_QQL_CHECKLIST_REPORT,
  FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { parseGraphQLError } from '../../utils';
import {
  GET_REPORT_SCHEMA,
  GENERATE_REPORT,
  GET_REPORT_LIST,
  GENERATE_REPORT_ASYNC,
  GET_ALL_MIGRATED_CHECKLST_CENTRES,
  GET_MIGRATED_CHECKLIST_CLASS_BY_CENTRE,
} from '../models/reportModel';

const dispatchReportSchema = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_REPORT_SCHEMA,
    key: 'reportSchema',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchReportList = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_REPORT_LIST,
    key: 'reportList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllCenterQQLChecklist = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_QQL_CHECKLIST_REPORT,
    key: 'qQLCheckListReportByCenter',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllQQLChecklistClassByCenter = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER,
    key: 'qQLCheckListReportClassByCenter',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchAllReportSchema = () => {
  return async dispatch => {
    dispatchReportSchema(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_REPORT_SCHEMA,
        },
        type: 'query',
      });

      if (result.success) {
        dispatchReportSchema(dispatch, false, result.data);
      } else {
        dispatchReportSchema(dispatch, false, null, result.error);
      }
    } catch (ex) {
      dispatchReportSchema(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };
};

export const getReportList = (reqData, update = false) => {
  return async dispatch => {
    if (!update) {
      dispatchReportList(dispatch);
    }
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_REPORT_LIST,
          variables: reqData,
        },
        type: 'query',
      });
      if (!update) {
        if (result.success) {
          dispatchReportList(dispatch, false, result.data);
        } else {
          dispatchReportList(dispatch, false, null, result.error);
        }
      }
      return result;
    } catch (ex) {
      dispatchReportList(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };
};

export const generateReport = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GENERATE_REPORT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const generateReportAsync = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GENERATE_REPORT_ASYNC,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const getAllQQLChecklistByCenter = () => {
  return async dispatch => {
    dispatchAllCenterQQLChecklist(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_ALL_MIGRATED_CHECKLST_CENTRES,
          variables: {
            filter: {},
          },
        },
        type: 'query',
      });
      if (result.success) {
        dispatchAllCenterQQLChecklist(dispatch, false, result.data);
      } else {
        dispatchAllCenterQQLChecklist(dispatch, false, null, result.error);
      }
    } catch (ex) {
      dispatchAllCenterQQLChecklist(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };
};

export const getAllQQLChecklistClassByCenter = reqData => {
  return async dispatch => {
    dispatchAllQQLChecklistClassByCenter(dispatch);
    try {
      const result = await SkApolloRequest({
        params: {
          query: GET_MIGRATED_CHECKLIST_CLASS_BY_CENTRE,
          variables: {
            centerId: get(reqData, 'centreID'),
            ...((reqData, 'year') && {
              year: get(reqData, 'year'),
            }),
            ...(get(reqData, 'levelId') && {
              levelId: get(reqData, 'levelId'),
            }),
            filter: {},
          },
        },
        type: 'query',
      });

      if (result.success) {
        dispatchAllQQLChecklistClassByCenter(dispatch, false, result.data);
      } else {
        dispatchAllQQLChecklistClassByCenter(
          dispatch,
          false,
          null,
          result.error
        );
      }
    } catch (ex) {
      dispatchAllQQLChecklistClassByCenter(dispatch, false, null, {
        error: parseGraphQLError(ex),
      });
    }
  };
};
