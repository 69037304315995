import React from 'react';
import { Trans } from 'react-i18next';
import {
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  StyledCardMedia,
  StyledButton,
  StyledUploadIcon,
} from './style';
import Text from '../../Text';

export const PhotoField = ({
  label,
  value,
  error,
  onReupload = () => {},
  showReupload = false,
  defaultImage = '',
}) => (
  <StyledCard>
    <StyledCardHeader
      disableTypography
      title={<Text label>{label}</Text>}
      action={
        showReupload ? (
          <StyledButton onClick={onReupload} endIcon={<StyledUploadIcon />}>
            <Trans i18nKey="childprofile.reUploadText" />
          </StyledButton>
        ) : null
      }
    />
    <StyledCardMedia
      component="img"
      src={value}
      defaultImage={defaultImage}
      onError={e => {
        e.target.src = defaultImage;
      }}
    />
    <StyledCardContent>{error}</StyledCardContent>
  </StyledCard>
);

export default PhotoField;
