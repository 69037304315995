import { get } from 'lodash';
import { SHOW_CONNECT_TO_LINKPASS_MODAL } from '../actions/actionTypes';

export const linkpass = (state = {}, action) => {
  if (action.type === SHOW_CONNECT_TO_LINKPASS_MODAL) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export default linkpass;
