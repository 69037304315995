import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { get, flatten, filter } from 'lodash';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { ContentWrapper, RouteContent } from './common/Sidemenu/style';
import localStore from '../utils/localStorage';
import { getQueryParams, replaceUrlParam, isStaffPortal } from '../utils';
import Header from './common/Header';
import Footer from './common/Footer';
import Sidemenu from './common/Sidemenu/index';
import SnackBar from './common/SnackBar';
import WithUser from './WithUser';
import AppTheme from './MainTheme';
import {
  OVERVIEW,
  PARENT_SEARCH,
  PARENT_INFO,
  HOMEPAGE,
  ENQUIRY,
  LOGIN,
  INTRO_PAGE,
  REGISTRATION_FORM_PREVIEW,
  ENRICHMENT_OVERVIEW,
  LP_SSO_CALLBACK,
  PSS_URL,
} from '../routes/constants';
import protectedRoutes from '../routes/protected';
import publicRoutes from '../routes/public';
import parentRoutes from '../routes/protected/parent';
import staffRoutes from '../routes/protected/staff';
import {
  hideSnackBarMessage,
  getCurrentSystemAnnouncement,
} from '../redux/actions';
import {
  PRODUCTION,
  PROD,
  VENDOR_ADMIN,
  HIDE_MODULES_BY_ROLES,
} from '../utils/constants';
import Text from './common/Text';
import SessionTimeout from './views/SessionTimeout';

const NotFound = lazy(() => import('./common/NotFound'));
const ErrorBoundary = lazy(() => import('./common/ErrorBoundary'));
const userToken = localStore.getValue('userToken');

const filterValidPathsForRoleBySideMenu = (
  allRoutes,
  sidemenu,
  isVendorAdmin = false
) => {
  let validPaths = sidemenu.map(menu => {
    if (menu.submenu) {
      return menu.submenu.map(sub => sub.path);
    }

    if (menu.path) return menu.path;
  });

  validPaths = flatten(validPaths);

  return filter(allRoutes, route => {
    let isValid = false;
    validPaths.forEach(validRoute => {
      if (
        route.url.includes('vendor') &&
        isVendorAdmin &&
        route.url.includes(validRoute)
      ) {
        isValid = true;
      } else if (
        !route.url.includes('vendor') &&
        route.url.includes(validRoute)
      ) {
        isValid = true;
      } else if (route.url.includes('enrichment/vendor')) {
        isValid = true;
      }
    });

    return isValid || route.url === OVERVIEW;
  });
};

const App = ({
  history,
  location: { pathname, search },
  schoolID,
  userRole,
  snackBarMessage,
  hideSnackBarMessage,
  sidemenuData,
  getCurrentSystemAnnouncement,
  currentSystemAnnouncement,
}) => {
  const isStaffDomain = isStaffPortal();
  const currentPath = get(history, 'location.pathname');
  let authUserRoutes = [];
  const [announcementAlert, setAnnouncementAlert] = useState(false);

  if (userRole && get(sidemenuData, 'length', 0)) {
    const hideMenusByRole = HIDE_MODULES_BY_ROLES[userRole.name] || [];
    const menus = sidemenuData.map(item => {
      const result = {
        ...item,
      };
      if (item.submenu && item.submenu.length > 0) {
        result.submenu = (item.submenu || []).filter(
          subItem => !hideMenusByRole.includes(subItem.label)
        );
      }
      return result;
    });

    authUserRoutes = filterValidPathsForRoleBySideMenu(
      userRole.isStaff ? staffRoutes : parentRoutes,
      menus,
      userRole.label === VENDOR_ADMIN
    );
  }

  const isUserSessionActive = Boolean(localStore.getValue('userToken'));
  let showLostError = false;

  const queryParams = getQueryParams();
  if (
    pathname.indexOf(PARENT_SEARCH) === -1 &&
    pathname.indexOf(PARENT_INFO) === -1 &&
    pathname.indexOf(INTRO_PAGE) === -1 &&
    pathname.indexOf(ENQUIRY) === -1 &&
    pathname.indexOf(PSS_URL) === -1
  ) {
    let routeType = 0;
    if (pathname !== HOMEPAGE) {
      protectedRoutes.forEach(item => {
        if (item.url.indexOf(pathname.split('/')[1]) > -1) {
          showLostError = true;
          routeType = 1;
        }
      });
    }

    publicRoutes.forEach(item => {
      if (item.url.indexOf(pathname.split('/')[1]) > -1) {
        routeType = 2;
      }
    });

    if (routeType === 1 && !isUserSessionActive) {
      const uriComponent = encodeURIComponent(`${pathname}${search}`);
      history.push(`${LOGIN}?redirect=${uriComponent}`);
    } else if (routeType === 2 && isUserSessionActive) {
      if (
        !get(userRole, 'isStaff') &&
        process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true'
      ) {
        history.push(ENRICHMENT_OVERVIEW);
      } else if (!pathname.includes(LP_SSO_CALLBACK)) {
        history.push(OVERVIEW);
      }
    } else if (routeType === 0 && isUserSessionActive) {
      showLostError = true;
    }
  }

  let showHeaderAndMenu = true;
  const urlArr = pathname.split('/');
  if (
    pathname ===
    replaceUrlParam(REGISTRATION_FORM_PREVIEW, {
      enquiryid: urlArr[urlArr.length - 1],
    })
  ) {
    showHeaderAndMenu = false;
  }

  if (userToken && window.location.search.includes('redirect')) {
    const uriComponent = decodeURIComponent(queryParams.redirect);
    history.push(uriComponent);
  }

  useEffect(() => {
    getCurrentSystemAnnouncement();
  }, []);

  useEffect(() => {
    const systemAnnouncementAlert = get(currentSystemAnnouncement, 'data');
    if (get(systemAnnouncementAlert, 'ID')) {
      const currentDate = moment().format('YYYY-MM-DD');

      let storedData = localStore.getValue('systemAnnouncementData');
      if (
        !storedData ||
        storedData.dismissedDate !== currentDate ||
        storedData.lastDismissedID !== systemAnnouncementAlert.ID
      ) {
        storedData = {
          lastDismissedID: systemAnnouncementAlert.ID,
          dismissedDate: currentDate,
          dismissed: false,
        };
        localStore.setValue('systemAnnouncementData', storedData);
      }
      if (!storedData.dismissed) {
        setAnnouncementAlert(true);
      }
    }
  }, [currentSystemAnnouncement]);

  const handleDismiss = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const systemAnnouncementData =
      localStore.getValue('systemAnnouncementData') || {};
    if (
      !systemAnnouncementData.dismissedDate ||
      systemAnnouncementData.dismissedDate !== currentDate
    ) {
      systemAnnouncementData.dismissedDate = currentDate;
    }
    systemAnnouncementData.dismissed = true;
    systemAnnouncementData.lastDismissedID = get(
      currentSystemAnnouncement,
      'data.ID'
    );
    localStore.setValue('systemAnnouncementData', systemAnnouncementData);
    setAnnouncementAlert(false);
  };

  const fkSchool = schoolID;
  const bannerAlert = get(process.env, 'REACT_APP_ENVIRONMENT', 'production');

  return (
    <>
      {bannerAlert !== PRODUCTION && bannerAlert !== PROD && (
        <div className="s7t-banner-alert w3-bar">
          <ErrorIcon className="s7t-error-icon" />
          <div className="sn2-banner-message">
            <Trans i18nKey="common.bannerAlertMsg" />
          </div>
        </div>
      )}
      <AppTheme fkSchool={fkSchool}>
        <div
          className={
            currentPath === '/login'
              ? 'sn2-system-alert'
              : 'sn2-universal-alert'
          }
        >
          {isStaffDomain && <SessionTimeout />}
          {get(currentSystemAnnouncement, 'data.ID') && (
            <SnackBar
              open={announcementAlert}
              variant="info"
              priority="high"
              message={
                <Grid>
                  <Text bold>
                    {get(currentSystemAnnouncement, 'data.title')}
                  </Text>
                  <br />
                  <Text>{get(currentSystemAnnouncement, 'data.message')}</Text>
                </Grid>
              }
              autoHide
              actionButtonText="Dismiss"
              onAction={handleDismiss}
            />
          )}
        </div>
        {get(snackBarMessage, 'visibility', false) && (
          <div
            className={
              get(snackBarMessage, 'snackBarInfo.anchor') === 'top'
                ? 'sn2-universal-alert'
                : 'sn2-universal-toast'
            }
          >
            <SnackBar
              open={get(snackBarMessage, 'visibility', false)}
              onClose={() => hideSnackBarMessage()}
              icon={get(snackBarMessage, 'snackBarInfo.icon')}
              message={get(snackBarMessage, 'snackBarInfo.message')}
              variant={get(snackBarMessage, 'snackBarInfo.variant', 'error')}
            />
          </div>
        )}
        {showLostError ? (
          <>
            <div
              className="s7t-sidemenu"
              style={{ display: showHeaderAndMenu ? '' : 'none' }}
            >
              {WithUser({ default: Sidemenu }, null, true)}
            </div>
            <ContentWrapper
              className={showHeaderAndMenu ? 's7t-logged-content' : ''}
            >
              <div
                style={{
                  display: showHeaderAndMenu ? '' : 'none',
                }}
              >
                {WithUser({ default: Header })}
              </div>

              <RouteContent className="s7t-content">
                <Suspense fallback={<div />}>
                  <ErrorBoundary>
                    <Switch>
                      {authUserRoutes.map(eachItem => (
                        <Route
                          key={`rout-key-${eachItem.url}`}
                          path={eachItem.url}
                          exact
                          render={props =>
                            WithUser(eachItem.component, {
                              ...props,
                              fkSchool,
                            })
                          }
                        />
                      ))}
                      {authUserRoutes.length && (
                        <Route
                          render={props => (
                            <NotFound {...props} activeUserSession />
                          )}
                        />
                      )}
                    </Switch>
                  </ErrorBoundary>
                </Suspense>
              </RouteContent>
              {WithUser({ default: Footer })}
            </ContentWrapper>
          </>
        ) : (
          <Suspense fallback={<div />}>
            <ErrorBoundary>
              <Switch>
                {publicRoutes.map(eachRoute => (
                  <Route
                    key={eachRoute.url}
                    path={eachRoute.url}
                    exact
                    render={props => (
                      <eachRoute.component {...props} fkSchool={fkSchool} />
                    )}
                  />
                ))}
                <Route
                  render={props => (
                    <NotFound {...props} activeUserSession={false} />
                  )}
                />
              </Switch>
            </ErrorBoundary>
          </Suspense>
        )}
      </AppTheme>
    </>
  );
};

const mapStateToProps = state => ({
  schoolID: get(state, 'schoolID.data'),
  userRole: get(state, 'userDetails.data.userRoleRelations.data[0].role'),
  snackBarMessage: get(state, 'snackBarMessage.data'),
  sidemenuData: get(state, 'sidemenu.data'),
  currentSystemAnnouncement: get(state, 'currentSystemAnnouncement', []),
});

const mapDispatchToProps = {
  hideSnackBarMessage,
  getCurrentSystemAnnouncement,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
