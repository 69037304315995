import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from 'react-router-dom';

import Text from './Text';

export const StyledStepLabel = styled(StepLabel)`
  & .MuiStepIcon-root {
    border: ${props => `3px solid ${props.theme.baseColor.status.draft}`};
    color: transparent;
    border-radius: 30px;
    text {
      fill: ${props => props.theme.baseColor.status.draft};
    }
  }
  & .MuiStepIcon-active {
    border: ${props => `3px solid ${props.theme.baseColor.neutral.main}`};
    color: transparent;
    border-radius: 30px;
    text {
      fill: ${props => props.theme.baseColor.neutral.main};
    }
  }
  & .MuiStepIcon-completed {
    border: none;
    color: ${props => props.theme.palette.primary.main};
  }
`;

export const StyledText = styled(Text)`
  text-decoration: underline;
  color: #38576b;
  cursor: pointer;
`;

export const StyledHyperLink = styled(Link)`
  text-decoration: underline;
  color: ${props => props.theme.palette.primary.main}};
  &:hover {
    color: ${props => props.theme.baseColor.text.label};
  }
`;

export const useStyles = makeStyles({
  contentStyle: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      background: '#BDBDBD',
      borderRadius: '20px',
    },
  },
});

export default StyledStepLabel;
