import React, { useRef, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CheckedIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';
import FieldWrapper from '../FieldWrapper';
import {
  InputWrapper,
  Listbox,
  StyledChip,
  CustomWrapper,
  StyledGrid,
} from './style';

const handleChangeValue = (
  event,
  onChange,
  selectedOptions,
  hasAllOption,
  options
) => {
  if (hasAllOption) {
    const hasClickedOnAllOption =
      Number(event.currentTarget.getAttribute('data-option-index')) === 0;
    const hasAllInSelectedOptions = selectedOptions.some(
      option => option.value === 'all'
    );
    if (hasAllInSelectedOptions && hasClickedOnAllOption) {
      onChange(options);
    } else if (
      !hasAllInSelectedOptions &&
      selectedOptions.length === options.length
    ) {
      if (hasClickedOnAllOption) {
        // "All" option has been removed so the value is reset
        onChange([]);
      } else {
        onChange(selectedOptions);
      }
    } else {
      if (get(selectedOptions, '[0].value') === 'all') {
        selectedOptions.splice(0, 1);
      }
      onChange(selectedOptions);
    }
  } else {
    onChange(selectedOptions);
  }
};

/**
 * @desc Used to select multiple tags with search
 *
 *
 * @param string $label: required - label for the field
 * @param string $name: optional - name of the field
 * @param boolean $required: optional - * to denote whether the field is required or not
 * @param string $error: optional - errors for the field
 * @param array $fieldValue: required - array of selected option in format: [{ value: 1, description: abcd }]
 * @param object $options: {value, description} : required - options to list out in the field
 * @param function $onChange: required - function triggered to handle the change in options
 * @param boolean $hasAllOption: optional - boolean to have/ not have the 'All' option
 * @param boolean $allowNew: optional - boolean used to have/not have the create option on the list
 * @param function $handleAddNewItem: optional - function to trigger on create onclick on the list
 * @param function $handleChange: optional - function to get the field change value
 * @param boolean $customColor - custom color for the checkbox in the dropdown
 *
 * @return Component SearchWithMultiSelectField
 */

const LIST_BOX_MAX_HEIGHT = 400;
export const SearchWithMultiSelectFieldOld = ({
  label,
  name,
  required,
  error,
  fieldValue,
  options,
  onChange,
  hasAllOption,
  allowNew,
  handleAddNewItem,
  handleChange,
  disabled,
  noBackground = false,
  placeholder = 'Search...',
  labelTextColor,
  customColor,
  customStyle,
  hideUnderline = false,
  fieldName = '',
  onClose,
  onClick = () => {},
  notChangeTextField = false,
}) => {
  const {
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    value,
    inputValue,
  } = useAutocomplete({
    multiple: true,
    options: hasAllOption
      ? [
          {
            value: 'all',
            description: 'All',
          },
          ...options,
        ]
      : options,
    value:
      hasAllOption && fieldValue.length === options.length
        ? [
            {
              value: 'all',
              description: 'All',
            },
            ...fieldValue,
          ]
        : fieldValue,
    onChange: (event, selectedOptions) => {
      handleChangeValue(
        event,
        onChange,
        selectedOptions,
        hasAllOption,
        options
      );
    },
    getOptionSelected: (option, value) => value && option.value === value.value,
    getOptionLabel: option => option.description,
    onInputChange: handleChange,
    onClose: (e, reason) => {
      document.body.style.overflow = 'unset';
      if (onClose) {
        onClose(e, reason);
      }
    },
  });
  const [maxHeight, setMaxHeight] = useState(LIST_BOX_MAX_HEIGHT);
  const [inputType, setInputType] = useState('submit');
  const inputRef = useRef(null);

  const isExistingInOption = (groupedOptions || []).some(
    option => option.description.toLowerCase() === inputValue.toLowerCase()
  );

  const optionElement =
    get(value, '[0].value') === 'all' ? (
      <StyledChip
        disabled={disabled}
        key="tag-all"
        label="All"
        {...getTagProps(0)}
      />
    ) : (
      value.map((option, index) => (
        <StyledChip
          disabled={disabled}
          key={`tag-${index}`}
          label={option.description}
          {...getTagProps({ index })}
        />
      ))
    );

  let inputActive = false;
  if (document.activeElement.name === fieldName) {
    inputActive = true;
  }

  const getListBoxMaxHeight = () => {
    if (inputRef && inputRef.current) {
      const { top } = inputRef.current.getBoundingClientRect();
      const maxHeightLB = window.innerHeight - top - 50;
      return maxHeightLB > LIST_BOX_MAX_HEIGHT
        ? LIST_BOX_MAX_HEIGHT
        : maxHeightLB;
    }
    return LIST_BOX_MAX_HEIGHT;
  };
  const onClickInput = () => {
    if (!onClick) {
      setInputType('text');
      document.body.style.overflow = 'hidden';
      setMaxHeight(getListBoxMaxHeight());
    } else {
      onClick();
    }
  };

  const inputElement = (
    <Input
      type={inputType}
      name={fieldName}
      className={disabled ? 'sn2-hide-input' : ''}
      placeholder={placeholder}
      {...getInputProps()}
      onClick={onClickInput}
      disableUnderline={hideUnderline}
      onChange={e =>
        notChangeTextField ? onChange() : getInputProps().onChange(e)
      }
      value={notChangeTextField ? '' : getInputProps().value}
    />
  );

  return (
    <FieldWrapper
      required={required}
      label={label}
      name={name}
      error={error}
      disabled={disabled}
      labelTextColor={labelTextColor}
    >
      <InputWrapper
        noBackground={noBackground}
        ref={setAnchorEl}
        onClick={() => onClick && onClick()}
      >
        {customStyle ? (
          <StyledGrid container xs={12} inputActive={inputActive}>
            <Grid item style={{ display: 'contents' }}>
              {optionElement}
            </Grid>
            <CustomWrapper
              item
              style={{
                minWidth: fieldName === 'tagSearchField' ? '200px' : '400px',
              }}
            >
              {inputElement}
            </CustomWrapper>
          </StyledGrid>
        ) : (
          <>
            {optionElement}
            {inputElement}
          </>
        )}
      </InputWrapper>

      {!notChangeTextField && (
        <Listbox
          {...getListboxProps()}
          style={{ maxHeight }}
          ref={inputRef}
          customColor={customColor}
        >
          {!disabled &&
            (groupedOptions || []).map((option, index) => (
              <MenuItem
                key={`menu-item-${index}`}
                {...getOptionProps({ option, index })}
              >
                <CheckedIcon data-checked="true" />
                <UncheckedIcon data-checked="false" />
                <span>{option.description}</span>
              </MenuItem>
            ))}
          {allowNew && inputValue && !isExistingInOption && (
            <MenuItem>
              <span
                onClick={() => handleAddNewItem(inputValue)}
              >{`+ Create ${inputValue}`}</span>
            </MenuItem>
          )}
        </Listbox>
      )}
    </FieldWrapper>
  );
};

SearchWithMultiSelectFieldOld.defaultProps = {
  handleAddNewItem: () => {},
  fieldValue: [],
  name: '',
  handleChange: () => {},
  required: false,
  allowNew: false,
  hasAllOption: false,
  error: '',
};
SearchWithMultiSelectFieldOld.propTypes = {
  handleAddNewItem: PropTypes.func,
  fieldValue: PropTypes.arrayOf(PropTypes.shape),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  hasAllOption: PropTypes.bool,
  allowNew: PropTypes.bool,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchWithMultiSelectFieldOld;
