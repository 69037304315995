import { get } from 'lodash';
import {
  FETCH_ALL_LEADS,
  FETCH_ALL_LEADS_SUCCESS,
  FETCH_ALL_LEADS_ERROR,
  FETCH_LEAD_BY_ID,
  FETCH_LEAD_BY_ID_SUCCESS,
  FETCH_LEAD_BY_ID_ERROR,
  FETCH_ALL_POC_USER,
  FETCH_ALL_POC_USER_SUCCESS,
  FETCH_ALL_POC_USER_ERROR,
  SAVE_LEAD_FILTERS,
} from './actionTypes';
import {
  FETCH_ALL_CENTRE_WAITLIST,
  FETCH_DETAIL_ENQUIRY,
  FIND_ALL_POC_USER,
} from '../models/enquiriesManagementModels';
import { SkApolloRequest } from '../../utils/apolloUtils';

export const saveLeadFilters = filter => async dispatch => {
  dispatch({ type: SAVE_LEAD_FILTERS, value: { filter } });
};

export const fetchCentreWaitlistAction = reqData => async dispatch => {
  dispatch({ type: FETCH_ALL_LEADS });
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_ALL_CENTRE_WAITLIST,
        variables: { ...reqData },
      },
      type: 'query',
    });
    const success = get(data, 'success', false);
    const { findAllLeads } = get(data, 'data', {});
    if (success) {
      dispatch({
        type: FETCH_ALL_LEADS_SUCCESS,
        value: { findAllLeads },
      });
    } else {
      dispatch({
        type: FETCH_ALL_LEADS_ERROR,
        value: get(data, 'error'),
      });
    }
    return data;
  } catch (ex) {
    dispatch({
      type: FETCH_ALL_LEADS_ERROR,
      value: { error: get(ex, 'message') },
    });
    return ex;
  }
};

export const fetchEnquiryDetailAction = reqData => async dispatch => {
  dispatch({ type: FETCH_LEAD_BY_ID });
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_DETAIL_ENQUIRY,
        variables: { ...reqData },
      },
      type: 'query',
    });
    const success = get(data, 'success', false);
    const { leadByID } = get(data, 'data', {});
    if (success) {
      dispatch({
        type: FETCH_LEAD_BY_ID_SUCCESS,
        value: { leadByID },
      });
    } else {
      dispatch({
        type: FETCH_LEAD_BY_ID_ERROR,
        value: get(data, 'error'),
      });
    }
    return data;
  } catch (ex) {
    dispatch({
      type: FETCH_LEAD_BY_ID_ERROR,
      value: { error: get(ex, 'message') },
    });
    return ex;
  }
};

export const fetchAllPOCUser = reqData => async dispatch => {
  dispatch({ type: FETCH_ALL_POC_USER });
  try {
    const res = await SkApolloRequest({
      params: {
        query: FIND_ALL_POC_USER,
        variables: { ...reqData },
      },
      type: 'query',
    });
    if (res.success) {
      dispatch({
        type: FETCH_ALL_POC_USER_SUCCESS,
        value: {
          data: get(res, 'data.findAllPOCUser.data'),
        },
      });
    } else {
      dispatch({
        type: FETCH_ALL_POC_USER_ERROR,
        value: {
          error: get(res, 'error.0.message'),
        },
      });
    }
  } catch (ex) {
    dispatch({
      type: FETCH_ALL_POC_USER_ERROR,
      value: { error: get(ex, 'message') },
    });
    return ex;
  }
};
