import gql from 'graphql-tag';

export const ADD_OR_UPDATE_SECTION_RESOURCES = gql`
  mutation createOrUpdateClassResource(
    $classResourceID: Int
    $dto: ClassResourceCreateUpdateDto!
  ) {
    createOrUpdateClassResource(IDClassResource: $classResourceID, dto: $dto) {
      ID
      title
      fkClassResource
      filename
    }
  }
`;

export const DELETE_SECTION_RESOURCES = gql`
  mutation deleteClassSectionOrResource($classResourceID: Int!) {
    deleteClassSectionOrResource(IDClassResource: $classResourceID)
  }
`;

export const UPDATE_SECTION_RESOURCE_ORDER = gql`
  mutation updateClassResourceOrder(
    $classID: Int!
    $data: [ClassResourceSortingSectionDto!]!
  ) {
    updateClassResourceOrder(IDClass: $classID, data: $data)
  }
`;
