import { useState, useEffect, useRef } from 'react';
import localStore from '../../../utils/localStorage';
import { logoutUser } from '../../../utils/authUtil';
import { SECONDS } from '../../../utils/constants';

const SessionTimeoutHandler = props => {
  const { sessionTimeout, sessionTimeoutCountdown, t } = props;
  const userToken = localStore.getValue('userToken');
  const [time, setTime] = useState(sessionTimeout * SECONDS);
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);
  const isActiveRef = useRef(isActive);

  const resetTimer = (clear = false) => {
    if (!isActiveRef.current) {
      setTime(sessionTimeout * SECONDS);
    }
    if (clear) {
      setTime(sessionTimeout * SECONDS);
    }
  };

  const dismissModalAndResetTimer = () => {
    setIsActive(false);
    resetTimer(true);
  };

  const formatTime = time => {
    const minutes = Math.floor(time / SECONDS);
    const seconds = time % SECONDS;
    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);

  useEffect(() => {
    if (Number.isNaN(time) && sessionTimeout) {
      setTime(sessionTimeout * SECONDS);
    }
    if (time <= 0) {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      logoutUser(false);
      window.addEventListener('beforeunload');
    } else if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    }

    return () => {
      window.removeEventListener('beforeunload');
    };
  }, [time, sessionTimeout]);

  useEffect(() => {
    if (time <= sessionTimeoutCountdown * SECONDS) {
      setIsActive(true);
    }
  }, [time]);

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchmove'];
    const resetTimerHandler = () => resetTimer();

    events.forEach(event => {
      window.addEventListener(event, resetTimerHandler);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimerHandler);
      });
    };
  }, []);

  return {
    time,
    formatTime,
    userToken,
    isActive,
    dismissModalAndResetTimer,
    logoutUser,
    sessionTimeoutCountdown,
    sessionTimeout,
    t,
  };
};

export default SessionTimeoutHandler;
