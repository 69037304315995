import get from 'lodash/get';
import {
  FETCH_WAITLISTS,
  FETCH_ALL_WAITLIST_VACANCY,
  FETCH_WAITLISTS_FILTER,
  SEARCH_CHILD_IN_WAITLIST,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 * "data": {
 *   "getWaitList": {
 *     "totalCount": 746,
 *     "data": [
 *       {
 *         "ID": 231,
 *         "isTransferEnquiry": false,
 *         "fkUser": 662,
 *         "fkChild": 809,
 *         "fkCentre": 2,
 *         "fkLevel": 17,
 *         "fkProgram": 10,
 *         "firstname": "",
 *         "lastname": "Toh Soo Ting",
 *         "fkEnquiryState": "waiting_list",
 *         "householdIncomeRange": null,
 *         "vacancyAvailable": true,
 *         "createdAt": "2018-12-04 00:00:00",
 *         "active": true,
 *         "waitlistStatus": true,
 *         "paymentStatus": "pending",
 *         "giroStatus": "pending",
 *         "enrollmentPlan": "2021-01-01 00:00:00",
 *         "centre": {
 *           "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *         },
 *         "child": {
 *           "firstname": "",
 *           "lastname": "Koh Rae Anne",
 *           "dateOfBirth": "2019-02-20 00:00:00",
 *           "birthCertificate": "T1905052H",
 *           "nationality": "citizen",
 *           "race": null
 *         },
 *         "level": {
 *           "label": "Junior Toddlers",
 *           "fromMonth": 18,
 *           "toMonth": 24
 *         },
 *         "program": {
 *           "label": "Full Day",
 *           "from": "2001-01-01 00:00:00",
 *           "to": null
 *         },
 *         "user": {
 *           "firstname": "",
 *           "lastname": "Toh Soo Ting",
 *           "email": "sn2dummy+40000662@gmail.com",
 *           "mobilePhoneCountryCode": "+65000",
 *           "mobilePhone": "40000662",
 *           "addresses": {
 *             "data": [
 *               {
 *                 "address": "Toh Guan Road",
 *                 "postcode": "600269"
 *               }
 *             ]
 *           },
 *           "parent": {
 *             "identificationNo": "S8475485D",
 *             "monthlyIncome": null
 *           }
 *         },
 *         "enquiryStateHistories": {
 *           "data": [
 *             {
 *               "ID": 462,
 *               "fkEnquiryState": "waiting_list"
 *             }
 *           ]
 *         },
 *         "waitlistTags": {
 *           "data": [
 *             {
 *               "tag": {
 *                 "description": "Siblings of Currently Enrolled Children",
 *                 "weight": 500,
 *                 "ID": 17
 *               }
 *             },
 *             {
 *               "tag": {
 *                 "description": "NP Parent",
 *                 "weight": 80,
 *                 "ID": 26
 *               }
 *             }
 *           ]
 *         }
 *       },
 *     ]
 *   }
 * }
 *
 */

export const fetchWaitList = (state = {}, action) => {
  if (action.type === FETCH_WAITLISTS) {
    const data = get(action, 'value');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: get(action, 'value.data.data.getWaitList', {}),
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getWaitListFilter": {
 *       "centres": {
 *         "data": [
 *           {
 *             "ID": 1,
 *             "label": "The Caterpillar's Cove @ Jurong East"
 *           },
 *         ]
 *       },
 *       "tags": {
 *         "data": [
 *           {
 *             "ID": 13,
 *             "description": "Internal Transfer"
 *           },
 *         ]
 *       },
 *       "levels": {
 *         "data": [
 *           {
 *             "ID": 16,
 *             "label": "Infant"
 *           },
 *         ]
 *       },
 *       "programs": {
 *         "data": [
 *           {
 *             "ID": 9,
 *             "label": "Half Day (AM)"
 *           },
 *         ]
 *       },
 *       "preferMonthOfEnrolmentOptions": [
 *         {
 *           "numberOfMonth": 1,
 *           "label": "Next 1 month"
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const fetchWaitListFilter = (state = {}, action) => {
  if (action.type === FETCH_WAITLISTS_FILTER) {
    if (
      action.value.data &&
      action.value.data.data &&
      action.value.data.data.getWaitListFilter
    ) {
      return Object.assign({}, state, {
        [action.value.data.key]: action.value.data.data,
      });
    }
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getVacancyTab": {
 *       "totalCount": 13,
 *       "data": [
 *         {
 *           "fkCentre": 1,
 *           "fkLevel": 16,
 *           "available": 30,
 *           "date": "2020-12-01 00:00:00",
 *           "level": {
 *             "label": "Infant",
 *             "ID": 16,
 *             "active": true
 *           },
 *           "centre": {
 *             "ID": 1,
 *             "label": "The Caterpillar's Cove @ Jurong East"
 *           },
 *           "enquiries": {
 *             "totalCount": 6,
 *             "data": [
 *               {
 *                 "admissionStatus": {
 *                   "label": "Pending E-consent"
 *                 },
 *                 "fkChild": 1284,
 *                 "childFirstname": "ChildWith1Parent3ib1i",
 *                 "childLastname": "Lee",
 *                 "child": {
 *                   "firstname": "ChildWith1Parent3ib1i",
 *                   "lastname": "Lee"
 *                 }
 *               },
 *             ]
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const fetchVacancyList = (state = {}, action) => {
  if (action.type === FETCH_ALL_WAITLIST_VACANCY) {
    const data = get(action, 'value');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: get(action, 'value.data.data.getVacancyTab', {}),
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 *
 * Example data
 *
 * {
 *   "data": {
 *     "searchChildInWaitList": {
 *       "totalCount": 156,
 *       "data": [
 *         {
 *           "ID": 1316,
 *           "firstname": "Driya",
 *           "lastname": "",
 *           "birthCertificate": "T1238234J",
 *           "childRelations": {
 *             "data": [
 *               {
 *                 "user": {
 *                   "mobilePhone": "7356700726",
 *                   "mobilePhoneCountryCode": "+91"
 *                 }
 *               }
 *             ]
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const searchChildInWaitListResult = (state = {}, action) => {
  if (action.type === SEARCH_CHILD_IN_WAITLIST) {
    if (
      action.value.data &&
      action.value.data.searchChildInWaitList &&
      action.value.data.searchChildInWaitList.data
    ) {
      return Object.assign({}, state, {
        data: {
          data: action.value.data.searchChildInWaitList,
          inProgress: action.value.inprogress,
          errors: action.value.error,
        },
      });
    }
  }
  return state;
};

export default fetchWaitList;
