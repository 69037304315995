import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';

export const ToggleSwitch = styled(Switch)`
  &.MuiSwitch-root {
    width: 45px;
    height: 24px;
    padding: 0px;
    display: flex;
    border-radius: 20px;
    background-color: ${props =>
      props.checked
        ? props.theme.baseColor.neutral.green
        : props.theme.baseColor.neutral.lightGrey};

    & span {
      padding: 1px;
    }
  }

  &.MuiSwitch-switchBase {
    color: white;

    &.MuiSwitch-checked {
      transform: 'translateX(20px)';
      color: ${props => props.theme.baseColor.neutral.white};
      &.MuiSwitch-track {
        opacity: 1;
        background-color: ${props => props.theme.baseColor.status.success};
        border-color: ${props => props.theme.baseColor.status.success};
      }
    }
  }

  &.MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    box-shadow: 'none';
  }

  &.MuiSwitch-track {
    border: ${props => `1px solid ${props.theme.baseColor.neutral.lightGrey}`};
    border-radius: 20px;
    opacity: 1;
    background-color: ${props => props.theme.baseColor.neutral.white};
  }

  & .MuiSwitch-colorSecondary {
    color: ${props => props.theme.baseColor.neutral.white};
  }
`;

export default ToggleSwitch;
