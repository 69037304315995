import { FETCH_WEIGHTLIST_PARAM_TAGS } from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllTags": {
 *       "data": [
 *         {
 *           "ID": 13,
 *           "isCustom": false,
 *           "weight": 40,
 *           "description": "Internal Transfer",
 *           "centre": {
 *             "label": "The Caterpillar's Cove @ Jurong East"
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const waitlistParamTags = (state = {}, action) => {
  if (action.type === FETCH_WEIGHTLIST_PARAM_TAGS) {
    if (
      action.value.data &&
      action.value.data.findAllTags &&
      action.value.data.findAllTags.data
    ) {
      return Object.assign({}, state, {
        inProgress: action.value.inProgress,
        data: action.value.data.findAllTags.data,
        error: action.value.error,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export default waitlistParamTags;
