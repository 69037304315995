import { get } from 'lodash';
import { capitaliseFirstLetter } from '../../utils';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { GET_WAITLIST_PARAMETERS_GQL } from '../models/waitlistModel';
import {
  GET_WAITLIST_PARAMETERS,
  GET_WAITLIST_PARAMETERS_SUCCESS,
  GET_WAITLIST_PARAMETERS_ERROR,
} from './actionTypes';

const getWaitlistParameters = () => ({
  type: GET_WAITLIST_PARAMETERS,
  value: {
    isLoading: true,
  },
});

const getWaitlistParametersSuccess = ({ data }) => ({
  type: GET_WAITLIST_PARAMETERS_SUCCESS,
  value: {
    isLoading: false,
    data,
  },
});

const getWaitlistParametersError = error => ({
  type: GET_WAITLIST_PARAMETERS_ERROR,
  value: {
    isLoading: false,
    data: null,
    error,
  },
});

export const fetchWaitlistParameters = reqData => async dispatch => {
  dispatch(getWaitlistParameters());
  try {
    const newReqData = {
      IDSchool: reqData.fkSchool,
      pagination: {
        ...reqData.pagination,
      },
    };
    const result = await SkApolloRequest({
      type: 'query',
      params: {
        query: GET_WAITLIST_PARAMETERS_GQL,
        variables: newReqData,
      },
    });
    if (get(result, 'data.findAllTags.data')) {
      result.data.findAllTags.data = result.data.findAllTags.data.map(item => ({
        ...item,
        type: capitaliseFirstLetter(item.type),
      }));
    }
    dispatch(getWaitlistParametersSuccess({ data: result }));
  } catch (error) {
    dispatch(getWaitlistParametersError({ error: error.message }));
  }
};

export default fetchWaitlistParameters;
