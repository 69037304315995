import { get } from 'lodash';
import moment from 'moment';
import { DATE_PICKER_FORMAT } from '../../utils/constants';
import { FETCH_ALL_HOLIDAYS, FETCH_CLOSURE_DAYS } from '../actions/actionTypes';

/**
 *
 * Example Data
 *
 * data: {
 *   data: {
 *      getCentreHolidaysOfYear: {
 *         totalCount: 34,
 *          data: [{
 *            ID: 2
 *            centreHolidays: {
 *              totalCount: 0,
 *              data: []
 *            }
 *            description: "New Year Day"
 *            from: "2020-01-01 00:00:00"
 *            holidayImageKey: ""
 *            isPublic: true
 *            title: "New Year Day"
 *            to: "2020-01-01 23:59:59"
 *          }]
 *      }
 *    }
 * }
 * error: null
 * inProgress: false
 *
 */
export const holiday = (state = {}, action) => {
  if (action.type === FETCH_ALL_HOLIDAYS) {
    const data = get(action, 'value.data.data.getCentreHolidaysOfYear');
    if (data) {
      return Object.assign(
        {},
        {
          [action.value.data.key]: {
            ...action.value,
            data,
          },
        }
      );
    }

    return Object.assign(
      {},
      {
        [action.value.data.key]: action.value,
      }
    );
  }

  return state;
};

/**
 *
 * Example Data
 *
 * data: {
 *  data: {
 *    getCentreHolidaysOfYear: {
 *      totalCount: 3,
 *      data: [{
 *         ID: 24,
 *         centreHolidays: {
 *            totalCount: 1,
 *            data: [{
 *              centre: {
 *                ID: 1,
 *                label: "The Caterpillar's Cove @ Ju"
 *              }
 *            }]
 *          }
 *          description: "Professional Development"
 *          from: "2020-11-18 00:00:00"
 *          holidayImageKey: ""
 *          isPublic: false
 *          title: "Professional Development"
 *          to: "2020-11-18 23:59:59"
 *      }]
 *    }
 *  }
 * }
 */

export const centreClosureDays = (state = {}, action) => {
  if (action.type === FETCH_CLOSURE_DAYS) {
    const data = get(action, 'value.data.data.getCentreHolidaysOfYear.data');
    if (data) {
      const closureDays =
        get(data, 'length') &&
        data.map(eachData => {
          const from = moment(eachData.from).format(DATE_PICKER_FORMAT);
          const to = moment(eachData.to).format(DATE_PICKER_FORMAT);
          if (from === to) {
            return from;
          }
          const diff = Math.abs(
            moment(eachData.from).diff(moment(eachData.to), 'days')
          );
          const days = [];
          for (let day = 0; day <= diff; day++) {
            days.push(
              moment(eachData.from).add(day, 'days').format(DATE_PICKER_FORMAT)
            );
          }
          return days;
        });
      return Object.assign(
        {},
        {
          ...action.value,
          data: get(closureDays, 'length')
            ? closureDays.reduce(
                (day, deepClosureDays) => day.concat(deepClosureDays),
                []
              )
            : [],
        }
      );
    }

    return Object.assign(
      {},
      {
        ...action.value,
      }
    );
  }

  return state;
};

export default holiday;
