import SkRequest from './BaseRequest';
import { QueryBuilder } from '../queryBuilder';
import {
  FETCH_CLASS_ATTENDANCE,
  FETCH_FILTER_FOR_CLASS_ATTENDANCE,
  FETCH_CHILD_ATTENDANCE,
  CURRENT_CHILD_ATTENDANCE,
  GET_LATE_PICKUPS,
  FETCH_ATTENDANCE_DETAILS,
  GET_FILTERS_COUNTS,
  CURRENT_CHILD_ATTENDANCE_HISTORY,
  FETCH_ATTENDANCE_MODAL_DETAILS,
} from './actionTypes';
import localstorage from '../../utils/localStorage';
import {
  GET_LATE_PICKUPS_DETAILS,
  CREATE_CHECK_IN_CHECK_OUT,
  UPDATE_CHECK_IN_CHECK_OUT,
  CREATE_SESSION_ENTRY,
  GET_CLASS_ATTENDANCE,
  FILTERS_FOR_CLASS_ATTENDANCE,
  GET_ATTENDANCE_DETAILS,
  GET_FILTERS_COUNTS_MODEL,
  GET_CHILD_ATTENDANCE_HISTORY,
  UPDATE_LATE_PICKUP_STATUS,
} from '../models/attendanceManagementModels';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { DEFAULT_ERROR } from '../../utils/constants';

const _mQueryBuilder = new QueryBuilder();
// const _mMutationAuthBuilder = new MutationBuilder();

_mQueryBuilder.setNewQuery('getChildAttendances', {
  total: 'string',
  childAttendances: {
    child: {
      ID: 'int',
      firstname: 'string',
      lastname: 'string',
    },
    date: 'timestamp',
    present: 'boolean',
    remarks: 'string',
    health: {
      id: 'int',
      temperature: 'string',
      remarks: 'string',
      type: 'string',
      active: 'boolean',
      time: 'timestamp',
    },
    attendances: {
      id: 'int',
      temperature: 'string',
      remarks: 'string',
      type: 'string',
      active: 'boolean',
      time: 'timestamp',
    },
  },
});

const dispatchChildAttendanceModalDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ATTENDANCE_MODAL_DETAILS,
    key: 'attendanceDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassAttendance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CLASS_ATTENDANCE,
    key: 'classAttendance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFilterForClassAttendance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_FILTER_FOR_CLASS_ATTENDANCE,
    key: 'filterForClassAttendance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchClassAttendanceDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ATTENDANCE_DETAILS,
    key: 'attendanceDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchChildAttendance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CHILD_ATTENDANCE,
    key: 'childAttendance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const currentChildAttendance = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: CURRENT_CHILD_ATTENDANCE,
    key: 'currentChildAttendance',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatChildAttendanceHistory = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: CURRENT_CHILD_ATTENDANCE_HISTORY,
    key: 'currentChildAttendanceHistory',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLatePickupDetail = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_LATE_PICKUPS,
    key: 'latePickupDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFilterAndCounts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: GET_FILTERS_COUNTS,
    key: 'filtersAndCounts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchChildAttendanceModalDetails = reqData => async dispatch => {
  dispatchChildAttendanceModalDetails(dispatch);
  try {
    if (reqData) {
      dispatchChildAttendanceModalDetails(dispatch, false, reqData);
    } else {
      dispatchChildAttendanceModalDetails(dispatch, false, null, DEFAULT_ERROR);
    }
  } catch (ex) {
    dispatchChildAttendanceModalDetails(dispatch, false, null, DEFAULT_ERROR);
  }
};

export const fetchChildAttendanceDetails = reqData => async dispatch => {
  dispatchChildAttendance(dispatch);
  const query = _mQueryBuilder.getBulkQueryAsString({
    getChildAttendances: reqData,
  });
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchChildAttendance(dispatch, false, data.data);
    } else {
      dispatchChildAttendance(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchChildAttendance(dispatch, false, null, { error: ex.message });
  }
};

export const createSessionEntry = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_SESSION_ENTRY,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getCheckInOutPopupDetails = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ATTENDANCE_DETAILS,
        variables: reqData,
      },
      type: 'query',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const createCheckInCheckOut = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CREATE_CHECK_IN_CHECK_OUT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateCheckInCheckOut = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_CHECK_IN_CHECK_OUT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const updateLatePickupStatus = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_LATE_PICKUP_STATUS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const verifyCheckInCheckOut = attendanceIDs => async () => {
  try {
    const bulkMutationReqData = attendanceIDs.map((id, index) => ({
      queryKey: `checkInVerify${index + 1}`,
      key: 'verifyCheckInOut',
      query: '',
      variables: [
        {
          dto: {
            checkInOutId: id,
            status: 'verified',
          },
        },
      ],
    }));
    const response = await SkApolloRequest({
      params: bulkMutationReqData,
      type: 'bulkMutation',
    });
    return response;
  } catch (ex) {
    return ex;
  }
};

export const fetchChildAttendances = reqData => async dispatch => {
  currentChildAttendance(dispatch);
  const query = _mQueryBuilder.getQueryAsString('getChildAttendances', reqData);
  try {
    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query,
      },
      method: 'POST',
    });

    if (data.success) {
      currentChildAttendance(dispatch, false, data.data);
    } else {
      currentChildAttendance(dispatch, false, null, data.error);
    }
  } catch (ex) {
    currentChildAttendance(dispatch, false, null, { error: ex.message });
  }
};

export const fetchChildAttendanceHistory = reqData => async dispatch => {
  dispatChildAttendanceHistory(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CHILD_ATTENDANCE_HISTORY,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatChildAttendanceHistory(dispatch, false, data.data);
    } else {
      dispatChildAttendanceHistory(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatChildAttendanceHistory(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchAttendanceDetails = reqData => async dispatch => {
  dispatchClassAttendance(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CLASS_ATTENDANCE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassAttendance(dispatch, false, data.data);
    } else {
      dispatchClassAttendance(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchClassAttendance(dispatch, false, null, { error: ex.message });
  }
};

export const fetchFilterForClassAttendance = reqData => async dispatch => {
  dispatchFilterForClassAttendance(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: FILTERS_FOR_CLASS_ATTENDANCE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFilterForClassAttendance(dispatch, false, data.data);
    } else {
      dispatchFilterForClassAttendance(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFilterForClassAttendance(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchFilterAndCounts = reqData => async dispatch => {
  dispatchFilterAndCounts(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_FILTERS_COUNTS_MODEL,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFilterAndCounts(dispatch, false, data.data);
    } else {
      dispatchFilterAndCounts(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFilterAndCounts(dispatch, false, null, { error: ex.message });
  }
};

export const fetchLatePickupDetails = reqData => async dispatch => {
  dispatchLatePickupDetail(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_LATE_PICKUPS_DETAILS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchLatePickupDetail(dispatch, false, data.data);
    } else {
      dispatchLatePickupDetail(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchLatePickupDetail(dispatch, false, null, { error: ex.message });
  }
};

export const fetchClassAttendanceDetails = reqData => async dispatch => {
  dispatchClassAttendanceDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ATTENDANCE_DETAILS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchClassAttendanceDetails(dispatch, false, data.data);
    } else {
      dispatchClassAttendanceDetails(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchClassAttendanceDetails(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchBulkClassAttendanceDetails = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: reqData,
      type: 'bulkQuery',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default fetchAttendanceDetails;
