import SkRequest from './BaseRequest';
import { MutationBuilder } from '../queryBuilder';

const _mMutationBuilder = new MutationBuilder();

_mMutationBuilder.setNewMutation('createForm1', {
  ID: 'number',
});

export const createFormOne = (IDEnquiry, participants = []) => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('createForm1', {
          IDEnquiry,
          participants,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export default createFormOne;
