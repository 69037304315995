import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { has, get } from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import Button from 'components/common/Button';
import { getCountryCode } from 'utils/countryCodeUtils';
import {
  showSnackBarMessage as showSnackBarMessageAction,
  fetchUserDetails as fetchUserDetailsAction,
} from 'redux/actions';
import { schoolIDMap } from 'utils/constants';
import Text from 'components/common/Text';
import { BASE_COLOR } from 'theme/variables';

import { getPrimaryColor, ImageLazyLoader } from '../../../../utils';

const formgrid = 12;
const logoHeight = '77';

const getInfo = renderData => {
  return renderData.map(eachItem => (
    <Grid container direction="column" style={{ marginBottom: '16px' }}>
      <Grid item>
        <Text smallTitle color="draft" style={{}}>
          {eachItem.label}
        </Text>
      </Grid>
      <Grid item>
        <Text body color={BASE_COLOR.snackBar.greyBackground}>
          {eachItem.value}
        </Text>
      </Grid>
    </Grid>
  ));
};

const getPhoneNumber = (countryCode, phoneNumber) => {
  if ((!countryCode && !phoneNumber) || !phoneNumber) {
    return '-';
  }
  if (!countryCode) {
    return phoneNumber;
  }
  return `${getCountryCode(countryCode)} ${phoneNumber}`;
};

const ContactDetailsNotMatch = props => {
  const {
    cdnurl,
    theme,
    t,
    schoolID,
    linkpassUserInfoFromClaim,
    sn2UserInfo,
    onProceedLinkAccount,
    onCancel,
    isDiffPhone,
    isDiffEmail,
  } = props;
  const currentSchoolDetails = schoolIDMap[schoolID];
  const schoolName = get(currentSchoolDetails, 'name');

  let logo = `${cdnurl}/Auth/logo.png`;
  let banner = `${cdnurl}/Auth/banner-right.png`;
  if (has(theme, 'banner')) {
    ({ banner } = theme);
  }
  if (get(theme, 'logo')) {
    ({ logo } = theme);
  }

  return (
    <div className="s7t-auth-container">
      <Grid container className="s7t-loging-background s7t-full-height">
        <Grid item xs={12} sm={12} md={5}>
          <Card className="s7t-full-height s7t-auth-card-shadow s7t-auth-card-overflow">
            <CardContent className="s7t-center-div">
              <Grid container justify="center" className="s7t-display-content">
                <Grid item sm={formgrid} className="s7t-auto-margin">
                  <Grid item xs={12} className="s7t-margin-bottom4pct">
                    <img alt="" src={logo} height={logoHeight} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="s7t-width-100pct w3-left"
                    style={{ marginBottom: '22px' }}
                  >
                    <hr
                      className="s7t-Rectangle-4"
                      style={{
                        background: getPrimaryColor(props),
                      }}
                    />
                    <Text type="header5">
                      {t('auth.contactDetailsDoNotMatch')}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="w3-left w3-margin-right"
                    style={{ marginBottom: '22px' }}
                  >
                    <Typography>
                      <Trans
                        i18nKey="auth.contactDetailsDoNotMatchDesc"
                        values={{ schoolName }}
                      />
                    </Typography>
                  </Grid>
                </Grid>

                {isDiffEmail && (
                  <Grid style={{ marginBottom: '32px' }}>
                    <Grid style={{ marginBottom: '8px' }}>
                      <Text
                        bold
                        fontSize="14px"
                        textColor={schoolID === 1 ? 'primary' : 'secondary'}
                      >
                        {t('common.email')}{' '}
                      </Text>
                    </Grid>
                    {getInfo([
                      {
                        label: t('auth.linkpassEmail'),
                        value: get(linkpassUserInfoFromClaim, 'Email') || '-',
                      },
                      {
                        label: (
                          <Trans
                            i18nKey="auth.currentSN2Email"
                            values={{ schoolName }}
                          />
                        ),
                        value: get(sn2UserInfo, 'email') || '-',
                      },
                    ])}
                  </Grid>
                )}

                {isDiffPhone && (
                  <Grid style={{ marginBottom: '32px' }}>
                    <Grid style={{ marginBottom: '8px' }}>
                      <Text
                        bold
                        fontSize="14px"
                        textColor={schoolID === 1 ? 'primary' : 'secondary'}
                      >
                        {t('common.mobileNumber2')}
                      </Text>
                    </Grid>

                    {getInfo([
                      {
                        label: t('auth.linkpassMobileNo'),
                        value: `${getPhoneNumber(
                          get(linkpassUserInfoFromClaim, 'CountryCode'),
                          get(linkpassUserInfoFromClaim, 'PhoneNumber')
                        )}`,
                      },
                      {
                        label: (
                          <Trans
                            i18nKey="auth.currentSN2MobileNo"
                            values={{ schoolName }}
                          />
                        ),
                        value: `${getPhoneNumber(
                          get(sn2UserInfo, 'mobilePhoneCountryCode'),
                          get(sn2UserInfo, 'mobilePhone')
                        )}`,
                      },
                    ])}
                  </Grid>
                )}

                <Grid style={{ marginBottom: '20px' }}>
                  <Text bold>
                    {t('auth.proceedToConnectYourLinkpassAccount')}
                  </Text>
                </Grid>
                <Grid style={{ marginBottom: '20px' }}>
                  <Button
                    type="button"
                    button="primary"
                    fullWidth
                    onClick={() => {
                      onProceedLinkAccount();
                    }}
                    className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
                  >
                    <Trans i18nKey="common.proceed" />
                  </Button>
                </Grid>
                <Grid item xs={12} className="s7t-margin-bottom10">
                  <Button
                    type="button"
                    button="secondary"
                    fullWidth
                    onClick={onCancel}
                    className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
                  >
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <ImageLazyLoader
            alt="school banner for login"
            className="s7t-full-height s7t-login-grid-image"
            src={banner}
          />
        </Grid>
      </Grid>
    </div>
  );
};

ContactDetailsNotMatch.propTypes = {
  onCancel: PropTypes.func.isRequired,
  linkpassUserInfoFromClaim: PropTypes.shape({}).isRequired,
  sn2UserInfo: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  cdnUrl: get(state, 'cdnUrl.data'),
});

const mapDispatchToProps = {
  showSnackBarMessage: showSnackBarMessageAction,
  fetchUserDetails: fetchUserDetailsAction,
};

export default compose(
  withTranslation(),
  withTheme
)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ContactDetailsNotMatch)
  )
);
