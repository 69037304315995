import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import {
  OVERVIEW,
  BROADCAST,
  ENRICHMENT_PROGRAM_LIST,
  ENRICHMENT_VENDOR_VIEW,
  ENRICHMENT_ATTENDANCE_OVERVIEW,
} from '../constants';

// New Parent Dashboard with static design
const ParentDashboard = lazy(() =>
  import('../../components/views/ParentPortal/index')
);

const PrincipalDashboard = lazy(() =>
  import('../../components/views/Dashboard/PrincipalDashboard')
);
const TeacherDashboard = lazy(() =>
  import('../../components/views/Dashboard/TeacherDashboard')
);
const Broadcast = lazy(() => import('../../components/views/Broadcast'));

const isEP = process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true';
const commonProtectedRoutes = [
  {
    url: OVERVIEW,
    component: {
      default: PrincipalDashboard,
      user: !isEP
        ? ParentDashboard
        : () => <Redirect to={ENRICHMENT_PROGRAM_LIST} />,
      principal: PrincipalDashboard,
      staff: TeacherDashboard,
      vendor: () => <Redirect to={ENRICHMENT_VENDOR_VIEW} />,
      trainer: () => <Redirect to={ENRICHMENT_ATTENDANCE_OVERVIEW} />,
    },
  },
  {
    url: BROADCAST,
    component: {
      default: Broadcast,
    },
  },
];

export default commonProtectedRoutes;
