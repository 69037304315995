import React, { Component } from 'react';
import {
  TextField,
  MenuItem,
  InputAdornment,
  CircularProgress,
  ClickAwayListener,
} from '@material-ui/core';
import { get, debounce, isEqual, cloneDeep } from 'lodash';
import { Search, ClearOutlined } from '@material-ui/icons';
import FieldWrapper from './FieldWrapper';

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAutoComplete: false,
      searchVal: '',
    };
    const { handleSearch } = this.props;
    this.searchOptionSelected = false;
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.handleClear = this.handleClear.bind(this);

    this.triggerSearch = debounce(e => handleSearch(e), 1000);
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      searchVal: value,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { value } = this.props;
    if (!isEqual(newProps.value, value)) {
      this.setState({
        searchVal: newProps.value,
      });
    }
  }

  hideAutoComplete() {
    if (this.searchField) {
      this.searchOptionSelected = false;
      this.setState({
        showAutoComplete: false,
      });
    }
  }

  handleClickAway = () => {
    const { showAutoComplete, searchVal } = this.state;
    if (showAutoComplete) {
      const { onClose } = this.props;
      if (onClose) {
        onClose(searchVal);
      }
      document.body.style.overflow = 'unset';
      this.hideAutoComplete();
    }
  };

  handleSearch(e) {
    const { value } = e.target;
    const { hideSearchResults } = this.props;
    this.searchOptionSelected = false;
    e.persist();
    document.body.style.overflow = hideSearchResults ? 'unset' : 'hidden';
    this.setState(
      {
        showAutoComplete: true,
        searchVal: value,
      },
      () => this.triggerSearch(e)
    );
  }

  handleOptionSelect(option) {
    document.body.style.overflow = 'unset';
    this.hideAutoComplete();
    const { handleOptionClick } = this.props;
    const clickedOption = cloneDeep(option);
    this.searchOptionSelected = true;
    this.setState(
      {
        searchVal: option.description,
        showAutoComplete: false,
      },
      () => handleOptionClick(clickedOption)
    );
  }

  handleClear() {
    const { handleClear, value } = this.props;
    document.body.style.overflow = 'unset';
    if (!value) {
      return;
    }
    this.hideAutoComplete();
    this.searchOptionSelected = false;
    this.setState(
      {
        searchVal: '',
        showAutoComplete: false,
      },
      handleClear
    );
  }

  render() {
    const {
      label,
      name,
      error,
      value,
      disabled,
      placeholder,
      onBlur,
      labelStyle,
      searchResults,
      isClearable,
      showLoader,
      hideSearchResults = false,
    } = this.props;
    const { showAutoComplete, searchVal } = this.state;

    return (
      <FieldWrapper
        label={label}
        name={name}
        error={error}
        styles={labelStyle}
        disabled={disabled}
      >
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div>
            <TextField
              id={name}
              name={name}
              fullWidth
              value={searchVal}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={onBlur}
              ref={node => {
                this.searchField = node;
              }}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className="s7t-text-lightgrey" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {showLoader ? (
                      <CircularProgress disableShrink size={20} />
                    ) : (
                      isClearable && (
                        <ClearOutlined
                          disabled={!value}
                          className="s7t-text-lightgrey s7t-cursor"
                          onClick={this.handleClear}
                        />
                      )
                    )}
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              // {...this.props}
            />
            {showAutoComplete && get(searchResults, 'length') > 0 && (
              <div style={{ position: 'relative' }}>
                <div className="s7t-autocomplete-search">
                  {searchResults.map((eachResult, index) => (
                    <MenuItem
                      divider
                      className="s7t-autocomplete-search-result-li"
                      onClick={() => this.handleOptionSelect(eachResult.data)}
                      key={`${index}-${get(eachResult, 'data.ID', '')}`}
                      value={get(eachResult, 'data.ID')}
                    >
                      {eachResult.comp}
                    </MenuItem>
                  ))}
                </div>
              </div>
            )}
            {showAutoComplete && !showLoader && !searchResults && (
              <div
                style={{
                  position: 'relative',
                  display: hideSearchResults && 'none',
                }}
              >
                <div className="s7t-autocomplete-search">
                  <MenuItem
                    divider
                    className="s7t-autocomplete-search-result-li"
                    onClick={() => this.setState({ showAutoComplete: false })}
                  >
                    No Result Found
                  </MenuItem>
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </FieldWrapper>
    );
  }
}

SearchField.defaultProps = {
  isClearable: true,
};

export default SearchField;
