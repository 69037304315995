import styled, { css } from 'styled-components';
import { get } from 'lodash';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${props => get(props, 'iconColor')};
`;

export const StyledExpansionPanel = styled(ExpansionPanel)`
  ${props => {
    if (!props.noMargin) {
      return `
        margin-bottom: 16px;
      `;
    }
  }}
  background-color: ${props => get(props, 'backgroundColor', 'white')};
  &.MuiExpansionPanel-root {
    &:before {
      background-color: white;
    }
  }
  ${props => {
    if (!props.defaultExpanded) {
      return `
        box-shadow: none;
        border-radius: 0;
      `;
    }
    if (props.defaultExpanded) {
      return `
        border-top: 1px solid #dadada;
      `;
    }
  }}
`;

export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  padding: ${props =>
    get(props, 'styleProps.summaryRootPadding') || '0px 40px'};
  ${props => {
    if (get(props, 'styleProps.defaultExpanded')) {
      return `
        &.MuiExpansionPanelSummary-root {
                marginLeft: 0;
        }
      `;
    }
  }}
  ${props => {
    if (props.withBorder) {
      return `
        padding-top: 3px;
        border-radius: 6px;
        border: 1px solid ${props.theme.baseColor.neutral.mediumGrey};
      `;
    }
  }}

  ${props => {
    if (props.withBorderBottom) {
      return `
        border-bottom: 1px solid ${props.theme.baseColor.neutral.mediumGrey};
      `;
    }
  }}

  ${props =>
    !props.displayIcon &&
    css`
      padding: 0;
      .MuiExpansionPanelSummary-expandIcon {
        display: none;
      }
    `}
`;

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  padding: ${props => get(props, 'panelDetailsPadding', '0 34px')};
  ${props => {
    if (!props.fullWidth) {
      return `
        max-width: 1200px;
    `;
    }
  }}
  margin: auto;
`;
