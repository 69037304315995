import gql from 'graphql-tag';

const HOUSEHOLD_INFO_FRAGMENT = gql`
  fragment HouseholdInfoData on RegistrationHouseholdDTO {
    PrimaryApplicantHighestEducation
    SecondaryApplicantHighestEducation
    FamilyMemberCount
  }
`;

const EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment EmergencyContactData on EmergencyContactDTO {
    firstName
    lastName
    email
    relationship
    identificationNo
    postalCode
    address
    floorNo
    unitNo
    blockNo
    mobilePhone
    mobilePhoneCC
  }
`;

const HEALTH_INFO_FRAGMENT = gql`
  fragment HealthInfoData on ChildHealthInformationDto {
    immunizations {
      isImmunized
      immunizationDetails {
        disease
        exists
        remarks
      }
    }
    familyDoctorDetails {
      name
      email
      contactNumber
      countryCode
      clinicName
      clinicPostalCode
      clinicBuilding
      clinicCity
      clinicFloor
      clinicUnit
      clinicBlockNo
      clinicCountry
      remarks
    }
    physicalConditions {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    nonFoodAllergies {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    foodAllergies {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
    specialNeeds {
      medicalConditions {
        disease
        exists
        remarks
      }
      medicalConditionRemarks
    }
  }
`;

const ADDITIONAL_INFO_DETAIL_FRAGMENT = gql`
  fragment AdditionalInfoData on AdditionalInfoDTO {
    HouseholdInfo {
      ...HouseholdInfoData
    }
    EmergencyContactInfo {
      ...EmergencyContactData
    }
    HealthInfo {
      ...HealthInfoData
    }
    Registration {
      tcAcknowledgeBy
      marketingConsentBy
      showNOC
      registrationChildMappings {
        data {
          fkRegistrationChild
        }
      }
    }
    Step
    IsConsented
    MarketingChannels {
      IsEmail
      IsSMS
      IsPhone
    }
    MarketingConsent
  }
  ${HOUSEHOLD_INFO_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
  ${HEALTH_INFO_FRAGMENT}
`;

export const GET_REG_ADDITIONAL_INFO = gql`
  query getRegAdditionalInfoDetails($registrationID: Int!) {
    getRegAdditionalInfo(IDRegistration: $registrationID) {
      ...AdditionalInfoData
    }
  }
  ${ADDITIONAL_INFO_DETAIL_FRAGMENT}
`;

export const SET_REG_ADDITIONAL_INFO = gql`
  mutation saveRegAdditionalInfo(
    $registrationID: Int!
    $step: Int!
    $regForm: AdditionalInfo!
  ) {
    saveRegAdditionalInfo(
      IDRegistration: $registrationID
      step: $step
      regForm: $regForm
    ) {
      ...AdditionalInfoData
    }
  }
  ${ADDITIONAL_INFO_DETAIL_FRAGMENT}
`;

export const FETCH_APPLICANT_CHANGES = gql`
  query getRegParentChanges($IDRegChild: Int!) {
    getRegParentChanges(IDRegChild: $IDRegChild) {
      mainApplicant {
        label
        oldValue
        newValue
      }
      spouse {
        label
        oldValue
        newValue
      }
    }
  }
`;
